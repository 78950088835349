import { isZitadelAuthBackendFromOidcDiscovery } from "@/auth/login";
import { OidcConfig } from "@/auth/types";
import { z } from "zod";
import { getEnvironmentConfig } from "./environment";
import { retryingFetch } from "./fetch";
import log from "./log";

// There are a lot more possible things in OIDC discovery. We're just describing
// the fields we rely on here.
const OidcDiscoverySchema = z.object({
    authorization_endpoint: z.string(),
    token_endpoint: z.string(),
});

const loadTimeHref = location.href;

/**
 * @function getRedirectUri - Get the redirect URI for the current environment.
 * It will return the current URI with search params stripped,
 * unless the current URI is the login page, in which case it will return the bond URI.
 *
 * @param baseUrl - The base URL of the current environment, e.g. https://bondtest.uk
 * @returns The redirect URI for the current environment.
 */
const getRedirectUri = (
    baseUrl: string,
    baseZitadelUrl: string | undefined,
    isZitadel: boolean,
) => {
    const currentUri = loadTimeHref.split("?")[0];
    const loginUiPaths = ["/login", "/logout"];
    const isLoginUI = loginUiPaths.some(p => currentUri.includes(p));

    if (isZitadel) {
        return isLoginUI ?
            new URL("/bond", baseZitadelUrl || baseUrl).toString()
            : currentUri;
    }
    else {
        const relative = new URL(loadTimeHref).pathname;

        return isLoginUI ?
            new URL("/bond", baseUrl).toString()
            : new URL(relative, baseUrl).toString();
    }
};

export async function getOidcConfig() {
    const env = getEnvironmentConfig();
    const url = `${env.oidcAuthority}/.well-known/openid-configuration`;

    const response = await retryingFetch({ logPrefix: "getOidcConfig", withResponse: "json" }, url);

    if (response instanceof Response) {
        throw new Error(
            `Failed to retrieve OIDC discovery document: ${response.status} ${response.statusText}`,
        );
    }

    // We also need to compute the client ID. For the time being, we have our Zitadel
    // IDs hard coded. If we find we're NOT on zitadel, we fall back to our static
    // client ID for Avos auth.
    const isZitadel = isZitadelAuthBackendFromOidcDiscovery(response);

    const client_id = isZitadel ? env.clientId : "user@bondauth";

    const schemaParseResult = OidcDiscoverySchema.safeParse(response);

    if (!schemaParseResult.success) {
        throw new Error(`Failed to parse OIDC discovery document: ${schemaParseResult.error}`);
    }

    // We only need these two endpoints from the autodiscovery configuration
    const { token_endpoint, authorization_endpoint } = schemaParseResult.data;

    const redirect_uri = getRedirectUri(env.redirectUri, env.zitadelRedirectUri, isZitadel);

    log.info(
        `OIDC auto-discovery: client_id=${client_id}, token_endpoint=${token_endpoint}, authorization_endpoint=${authorization_endpoint}, redirect_uri=${redirect_uri}`,
    );

    const oidcConfig: OidcConfig = {
        client_id,
        authorization_endpoint,
        token_endpoint,
        redirect_uri,
        token_scope: "openid profile email offline_access",
    };
    return oidcConfig;
}
