import React from "react";

import { BondId } from "@/domain/domain";
import usePrivacyDomainString from "@/hooks/usePrivacyDomainString";
import { Optional } from "@/misc/types";

export default function BondPrivacyDomain(
    { id }: { id: Optional<BondId>; },
): React.JSX.Element {
    const privacyDomainString = usePrivacyDomainString({ bondId: id });
    return (
        <>
            {privacyDomainString}
        </>
    );
}
