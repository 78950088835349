// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file search/search.proto (package search, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { BlobId, BondId, CallId, MessageId, UserId } from "../domain/domain_pb.js";

/**
 * @generated from message search.Result
 */
export const Result = proto3.makeMessageType(
  "search.Result",
  () => [
    { no: 1, name: "score", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "bond", kind: "message", T: BondResult, oneof: "result" },
    { no: 3, name: "message", kind: "message", T: MessageResult, oneof: "result" },
    { no: 4, name: "call", kind: "message", T: CallResult, oneof: "result" },
    { no: 5, name: "attachment", kind: "message", T: AttachmentResult, oneof: "result" },
  ],
);

/**
 * @generated from message search.Highlight
 */
export const Highlight = proto3.makeMessageType(
  "search.Highlight",
  () => [
    { no: 1, name: "snippet", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "matched_tokens", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message search.BondHighlight
 */
export const BondHighlight = proto3.makeMessageType(
  "search.BondHighlight",
  () => [
    { no: 1, name: "title", kind: "message", T: Highlight },
    { no: 2, name: "summary", kind: "message", T: Highlight },
    { no: 3, name: "detailed_summary", kind: "message", T: Highlight },
  ],
);

/**
 * @generated from message search.BondResult
 */
export const BondResult = proto3.makeMessageType(
  "search.BondResult",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "highlight", kind: "message", T: BondHighlight },
  ],
);

/**
 * @generated from message search.MessageHighlight
 */
export const MessageHighlight = proto3.makeMessageType(
  "search.MessageHighlight",
  () => [
    { no: 1, name: "text", kind: "message", T: Highlight },
  ],
);

/**
 * @generated from message search.MessageResult
 */
export const MessageResult = proto3.makeMessageType(
  "search.MessageResult",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "message_id", kind: "message", T: MessageId },
    { no: 3, name: "author_id", kind: "message", T: UserId },
    { no: 4, name: "highlight", kind: "message", T: MessageHighlight },
  ],
);

/**
 * @generated from message search.CallHighlight
 */
export const CallHighlight = proto3.makeMessageType(
  "search.CallHighlight",
  () => [
    { no: 1, name: "title", kind: "message", T: Highlight },
    { no: 2, name: "short_summary", kind: "message", T: Highlight },
    { no: 3, name: "detailed_summary", kind: "message", T: Highlight },
  ],
);

/**
 * @generated from message search.CallResult
 */
export const CallResult = proto3.makeMessageType(
  "search.CallResult",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "call_id", kind: "message", T: CallId },
    { no: 3, name: "start_message", kind: "message", T: MessageId },
    { no: 4, name: "end_message", kind: "message", T: MessageId },
    { no: 5, name: "highlight", kind: "message", T: CallHighlight },
  ],
);

/**
 * @generated from message search.AttachmentHighlight
 */
export const AttachmentHighlight = proto3.makeMessageType(
  "search.AttachmentHighlight",
  () => [
    { no: 1, name: "filename", kind: "message", T: Highlight },
    { no: 2, name: "short_description", kind: "message", T: Highlight },
    { no: 3, name: "long_description", kind: "message", T: Highlight },
  ],
);

/**
 * @generated from message search.AttachmentResult
 */
export const AttachmentResult = proto3.makeMessageType(
  "search.AttachmentResult",
  () => [
    { no: 1, name: "message_id", kind: "message", T: MessageId },
    { no: 2, name: "blob_id", kind: "message", T: BlobId },
    { no: 3, name: "bond_id", kind: "message", T: BondId },
    { no: 4, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "highlight", kind: "message", T: AttachmentHighlight },
  ],
);

/**
 * @generated from message search.SearchRequest
 */
export const SearchRequest = proto3.makeMessageType(
  "search.SearchRequest",
  () => [
    { no: 1, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message search.SearchResponse
 */
export const SearchResponse = proto3.makeMessageType(
  "search.SearchResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: Result, repeated: true },
    { no: 2, name: "highlight_start_tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "highlight_end_tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

