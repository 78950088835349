import { DependencyList, EffectCallback, useEffect } from "react";

import { useAppSelector } from "@/store/redux";
import type { RootState } from "@/store/types";

interface useSelectorEffectProps {
    deps: DependencyList;
    effect: EffectCallback;
    selector: (state: RootState) => boolean;
}

/** @function Run an effect only when the result of a selector evaluates to true.
 */
export function useSelectorEffect(props: useSelectorEffectProps) {
    const { deps, effect, selector } = props;

    const selectedValue = useAppSelector(selector);

    // `effect` is potentially recreated on every call to the hook,
    // so adding it to the dependencies here could cause a re-run of the effect
    // on every re-render.
    useEffect(
        () => {
            if (selectedValue) {
                return effect();
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [...deps, selectedValue],
    );
}
