import { useEffect, useState } from "react";

import { selectBackendInfo } from "@/features/meta";
import { isNativeIosPlatform, isNativePlatform } from "@/misc/capacitor";
import { useAppSelector } from "@/store/redux";
import { App } from "@capacitor/app";

export function VersionInfo(): React.JSX.Element {
    const backendInfo = useAppSelector(selectBackendInfo);

    const [nativeVersion, setNativeVersion] = useState<string>("");
    useEffect(() => {
        if (isNativePlatform) {
            const prefix = isNativeIosPlatform ? "v" : "";

            App.getInfo().then(info => setNativeVersion(prefix + info.version));
        }
    }, []);

    const innerClassNames = "c-version";

    return (
        <div className="c-sidebar__versions">
            <div className={innerClassNames}>Backend: {backendInfo?.version}</div>
            <div className={innerClassNames}>Instance: {backendInfo?.instance}</div>

            {(!isNativePlatform || nativeVersion !== __BEYOND_FRONTEND_VERSION__) && (
                <div className={innerClassNames}>
                    Frontend: {__BEYOND_FRONTEND_VERSION__}
                </div>
            )}

            {isNativePlatform && (
                <div className={innerClassNames}>
                    App version: {nativeVersion}
                </div>
            )}
        </div>
    );
}
