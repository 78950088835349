import { RefCallback, useCallback } from "react";

interface UseRefCallbackArgs<T> {
    onUnmount?: () => void;
    parentRefCb?: RefCallback<T>;
}

/** @function Manage a React RefCallback for a HTMLElement.
 * Note this will run the callback when the node mounts, then each
 * time the callbacks' dependencies change.
 * When the node unmounts, an optional cleanup function is called.
 *
 * @param cb the callback to run after the ref gets attached to the node
 * @param args an object containing the following:
 * - `parentRefCb` - an optional ref callback to wrap
 * - `onUnmount` - an optional callback to run when the node is unmounted
 * @returns the ref callback.
 */
export default function useRefCallback<T extends HTMLElement>(
    cb: RefCallback<T>,
    args?: UseRefCallbackArgs<T>,
): RefCallback<T> {
    const { onUnmount, parentRefCb } = args ?? {};

    const refCallback: RefCallback<T> = useCallback(node => {
        // Call wrapped ref callback
        parentRefCb?.(node);

        // Cleanup node on unmount
        if (!node) {
            onUnmount?.();
            return;
        }

        // Call effect
        cb(node);
    }, [cb, onUnmount, parentRefCb]);

    return refCallback;
}
