import Linkify from "linkify-react";
import { Opts as LinkifyOpts } from "linkifyjs";

import TextFields, { TextField } from "@/components/gui/TextFields";
import MentionView from "@/components/messages/MentionView";
import { ContentMention } from "@/domain/mentions";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import { partialComponent } from "@/misc/utils";

interface ChatMessageTextViewProps {
    text: string;
    mentions: ContentMention[];
    linkifyOpts?: LinkifyOpts;
    inline: boolean;
}

/**
 * @deprecated
 */
export const ChatMessageTextView: React.FC<ChatMessageTextViewProps> = props => {
    const { text, mentions, linkifyOpts, inline } = props;

    const fields: TextField[] = useShallowEqualsMemo(
        () =>
            mentions.map(m => ({
                range: { start: m.startOffset, end: m.endOffset },
                Component: partialComponent(MentionView, { mention: m }),
            })),
        [mentions],
    );

    const textFields = (
        <TextFields
            text={text ?? ""}
            fields={fields}
            DefaultComponent={partialComponent(Linkify, { options: linkifyOpts })}
        />
    );

    return inline ? textFields : <p>{textFields}</p>;
};

export default ChatMessageTextView;
