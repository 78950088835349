import classNames from "classnames";
import ColorThief from "colorthief";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DiscoverBondFollowButton } from "@/components/gui/BondActions";
import TimeAgo from "@/components/gui/TimeAgo";
import * as d from "@/domain/domain";
import { selectCurrentUserId } from "@/features/auth";
import {
    selectBondById,
    selectBondImageUrl,
    selectBondTitle,
    selectChannelIdByBondId,
    selectDetailedBondSummary,
} from "@/features/bonds";
import { selectIsRead, selectSortedUserIdsWithUnreadPair } from "@/features/channels";
import { selectBondIdsForDiscover } from "@/features/filterPanel";
import { selectKnownSquadNames } from "@/features/squads";
import { selectUser } from "@/features/users";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import useSortedUsers from "@/hooks/useSortedUsers";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppSelector } from "@/store/redux";

function UserName(
    { userId, comma, and, last, additional, isRead }: {
        userId: d.UserId;
        comma: boolean;
        and: boolean;
        last: boolean;
        additional: number;
        isRead: boolean;
    },
): React.JSX.Element {
    const currentUserId = useAppSelector(selectCurrentUserId);
    const user = useSelectorArgs(selectUser, userId);
    const classes = classNames("c-mention", {
        "c-mention--read": isRead,
    });
    const userName = userId == currentUserId ? "You" : user?.nickname || "";

    return (
        <span className={classes}>
            {userName}
            {comma && ", "}
            {and && " & "}
            {last && !!additional && ` + ${additional}`}
            {!comma && !and && " "}
        </span>
    );
}

function PreviewBondContributors(
    { previewUsers, isRead }: { previewUsers: d.UserId[]; isRead: boolean; },
): React.JSX.Element {
    const processed = useShallowEqualsMemo(() => {
        const slicedPreviewUsers = previewUsers.slice(0, 3);
        const additionalUsers = previewUsers.length - slicedPreviewUsers.length;

        return {
            slicedPreviewUsers,
            additionalUsers,
        };
    }, [previewUsers]);

    return (
        <>
            {processed.slicedPreviewUsers.map((cid, idx, arr) => {
                const last = idx + 1 === arr.length;
                const and = idx + 2 === arr.length;
                const comma = idx + 2 < arr.length;

                return (
                    <UserName
                        key={cid}
                        userId={cid}
                        comma={comma}
                        and={and}
                        last={last}
                        additional={processed.additionalUsers}
                        isRead={isRead}
                    />
                );
            })}
        </>
    );
}

function BondCardContributors({ bondId }: { bondId: d.BondId; }): React.JSX.Element {
    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const sortedUsersWithUnreadPair = useSortedUsers(selectSortedUserIdsWithUnreadPair, channelId);
    return (
        <PreviewBondContributors
            previewUsers={sortedUsersWithUnreadPair}
            isRead={false}
        />
    );
}

function DiscoverCard({ bondId }: { bondId: d.BondId; }): React.JSX.Element {
    const [backgroundColor, setBackgroundColor] = useState("");
    const isMobile = isMobileBrowser();

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const isRead = useSelectorArgs(selectIsRead, channelId);

    const imgSrc = useSelectorArgs(selectBondImageUrl, bondId);

    const bo = useSelectorArgs(selectBondById, bondId);
    const bondTitle = useSelectorArgs(selectBondTitle, bondId);

    const bondSummary = useSelectorArgs(selectDetailedBondSummary, bondId) || "";

    // FIXME: duplicated with BondCard
    const showEmoji = useBooleanFeatureFlag("display-bond-emoji");
    const title = `${showEmoji && bondTitle.emoji ? bondTitle.emoji + " " : ""}${bondTitle.title}`;

    const squadIds = bo?.squadIds || [];
    const squadNames = useSelectorArgs(selectKnownSquadNames, squadIds);
    const timeFrom = bo?.lastActivityAt || 0;

    type RGB = [number, number, number];
    type HSL = [number, number, number];

    const rgbToHsl = useCallback(([r, g, b]: RGB): HSL => {
        r /= 255;
        g /= 255;
        b /= 255;
        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        const l = (max + min) / 2;
        let s: number;
        let h = 0;

        if (max === min) {
            h = s = 0; // achromatic
        }
        else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }

        return [h * 360, s * 100, l * 100];
    }, []);

    const scoreColor = useCallback(
        (color: RGB): number => {
            const [_, s, l] = rgbToHsl(color);
            const lightnessPenalty = Math.abs(l - 50) / 50;
            const saturationPenalty = (100 - s) / 200;

            const cost = lightnessPenalty + saturationPenalty;
            return 1 - cost;
        },
        [rgbToHsl],
    );

    const getBestColor = useCallback(
        (palette: RGB[]): RGB => {
            return palette.reduce((best, current) => {
                const currentScore = scoreColor(current);
                const bestScore = scoreColor(best);
                return currentScore > bestScore ? current : best;
            });
        },
        [scoreColor],
    );

    useEffect(() => {
        const colorThief = new ColorThief();

        const loadImageAndExtractColor = (imageSource: string) => {
            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.src = imageSource;
            img.onload = () => {
                try {
                    const palette = colorThief.getPalette(img, 6);
                    const [r, g, b] = getBestColor(palette);
                    setBackgroundColor(`rgb(${r}, ${g}, ${b}, 0.6)`);
                }
                catch (error) {
                    console.error("Error getting color:", error);
                }
            };
        };

        loadImageAndExtractColor(imgSrc);
    }, [setBackgroundColor, imgSrc, getBestColor]);

    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/bond/${d.extractUUID(bondId)}`);
    };

    const cardClasses = classNames("c-card-discover", {
        "c-card-discover--desktop": !isMobile,
        "c-card-discover--read": isRead,
    });

    const cardImageWrapperClasses = classNames("c-card-discover__image-wrapper", {
        "c-card-discover__image-wrapper--desktop": !isMobile,
    });

    const cardImageClasses = classNames("c-card-discover__image", {
        "c-card-discover__image--desktop": !isMobile,
    });

    const cardTitleClasses = classNames("c-card-discover__title", {
        "c-card-discover__title--desktop": !isMobile,
    });

    const cardSummaryClasses = classNames("c-card-discover__summary", {
        "c-card-discover__summary--desktop": !isMobile,
    });

    const cardFooterClasses = classNames("c-card-discover__footer", {
        "c-card-discover__footer--desktop": !isMobile,
    });

    return (
        <div className="c-card-discover-wrapper" onClick={onClick}>
            <div className={cardClasses} style={{ backgroundColor }}>
                <figure className={cardImageWrapperClasses}>
                    <img
                        src={imgSrc}
                        alt=""
                        className={cardImageClasses}
                    />
                </figure>
                <div className="c-card-discover__details">
                    <div className="c-discover-meta__group">{squadNames.join(",")}</div>
                    <h3 className={cardTitleClasses}>{title}</h3>
                    <p className={cardSummaryClasses}>
                        {bondSummary}
                    </p>
                </div>
                <div className={cardFooterClasses}>
                    <div className="c-discover-meta">
                        <time className="c-discover-meta__time">
                            <TimeAgo from={timeFrom} live={true} />
                        </time>
                    </div>
                    <div className="c-discover-meta">
                        <div className="c-discover-meta__participants">
                            <BondCardContributors bondId={bondId} />
                        </div>
                    </div>
                    <DiscoverBondFollowButton id={bondId} isFollowed={false} isMobile={isMobile} />
                </div>
            </div>
        </div>
    );
}

// See also: MobileDiscoverView. We may want to combine these.
export default function DiscoverView(): React.JSX.Element {
    // allBondIds is sorted by lastActivityAt in descending order
    const allBondIds = useAppSelector(selectBondIdsForDiscover);

    return (
        <div className="c-content">
            <div className="c-cards-discover">
                {allBondIds.slice(0, 120).map(bondId => (
                    <DiscoverCard key={bondId} bondId={bondId} />
                ))}
            </div>
        </div>
    );
}
