import { nanoid } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import queue from "@/ds/queue";
import { initSentry } from "@/misc/sentry";
// dprint-ignore (order of imports matters here)
import { logSrcStr } from "@/misc/log/impl";
import { isDedicatedWorker } from "@/workers/util";

// These declarations are missing from typescript's type definitions
declare global {
    interface FileSystemDirectoryHandle extends FileSystemHandle {
        [Symbol.asyncIterator](): AsyncIterableIterator<[string, FileSystemHandle]>;
        entries(): AsyncIterableIterator<[string, FileSystemHandle]>;
        keys(): AsyncIterableIterator<string>;
        values(): AsyncIterableIterator<FileSystemHandle>;
    }
}

export const logArchiveWorkerConfig = {
    directoryName: "logs-beyond",
    maxFileSizeBytes: 1 * 1024 * 1024,
    maxNumFiles: 20, // number of files can be greater than this if files are younger than minAgeMinutes
    minAgeMinutes: 10, // files younger than this are never deleted
    lockName: "log-archive-lock",
};

async function getLogDirectory(create: boolean) {
    const root: FileSystemDirectoryHandle = await navigator.storage.getDirectory();
    return await root.getDirectoryHandle(logArchiveWorkerConfig.directoryName, {
        create: create,
    });
}

interface FileModificationInfo {
    name: string;
    lastModified: number;
}

async function getLogFiles() {
    try {
        const logDirectory = await getLogDirectory(false);
        const logFiles: FileModificationInfo[] = [];
        const isFileHandle = (handle: FileSystemHandle): handle is FileSystemFileHandle =>
            handle.kind === "file";

        for await (const [name, handle] of logDirectory) {
            if (!isFileHandle(handle)) {
                continue;
            }
            const file = await handle.getFile();
            logFiles.push({ name: name, lastModified: file.lastModified });
        }

        return { fileInfos: logFiles, directory: logDirectory };
    }
    catch (e) {
        Sentry.captureException(e, { level: "error" });
        return { fileInfos: [], directory: null };
    }
}

const tokenRedacterRe = /"(access|id|refresh)_token": ?"([^"]+)"/gi;
const tokenRedacterFn = (_match: string, tokenType: string, _token: string) => {
    return `"${tokenType}_token": "[redacted]"`;
};

// log lines are returned in no particular order
export async function getArchivedLogLines(): Promise<string[]> {
    try {
        let contents: (string | undefined)[] = [];
        await navigator.locks.request(logArchiveWorkerConfig.lockName, async _lock => {
            const { fileInfos, directory } = await getLogFiles();
            if (fileInfos.length == 0 || !directory) {
                return [];
            }
            const getFileContents = async (name: string): Promise<string | undefined> => {
                const logFileHandle = await directory.getFileHandle(name);
                if (!logFileHandle) return;
                const file = await logFileHandle.getFile();
                return await file.text();
            };
            const contentsPromises = fileInfos.map(fi => fi.name).map(getFileContents);
            contents = await Promise.all(contentsPromises);
        });
        return contents
            .join("")
            .split("\n")
            .map(l => l.replaceAll(tokenRedacterRe, tokenRedacterFn))
            .filter(l => l.length > 0);
    }
    catch (e) {
        console.error("Getting archived logs failed", e);
        Sentry.captureException(e, { level: "error" });
        return [];
    }
}

async function removeOldFiles() {
    return navigator.locks.request(logArchiveWorkerConfig.lockName, async _lock => {
        const { fileInfos, directory } = await getLogFiles();
        const msNow = Date.now();
        const minAgeMs = logArchiveWorkerConfig.minAgeMinutes * 60 * 1000;

        const files = fileInfos
            .filter(fi => (msNow - fi.lastModified) > minAgeMs)
            .sort((a: FileModificationInfo, b: FileModificationInfo) => {
                return a.lastModified - b.lastModified;
            })
            .map(fi => fi.name);
        const numToDelete = files.length - logArchiveWorkerConfig.maxNumFiles + 1;
        if (numToDelete <= 0) {
            return;
        }
        const logFilesToDelete = files.slice(0, numToDelete);
        await Promise.allSettled(logFilesToDelete.map(name => directory?.removeEntry(name)));
    });
}

export class LogArchiveDedicatedWorkerContext {
    public fileAccessHandle: FileSystemSyncAccessHandle | undefined;
    writtenBytes: number = 0;
    sessionId = nanoid(5);
    filename = "";

    async createNewLogFile() {
        if (this.fileAccessHandle) {
            this.fileAccessHandle.close();
            this.fileAccessHandle = undefined;
            this.writtenBytes = 0;
        }

        try {
            await removeOldFiles();
        }
        catch (e) {
            console.error("Removing old log files failed", e);
            Sentry.captureException(e, { level: "error" });
        }

        const logDirectory = await getLogDirectory(true);
        this.filename = `log_${Date.now()}_${this.sessionId}.txt`;
        const fileHandle = await logDirectory.getFileHandle(this.filename, { create: true });
        this.fileAccessHandle = await fileHandle.createSyncAccessHandle();
    }

    async write(logLine: any) {
        try {
            if (
                !this.fileAccessHandle ||
                this.writtenBytes >= logArchiveWorkerConfig.maxFileSizeBytes
            ) {
                await this.createNewLogFile();
            }
            const encoder = new TextEncoder();
            const encodedMessage = encoder.encode(logLine + "\n");
            const written = this.fileAccessHandle?.write(encodedMessage, { at: this.writtenBytes });
            this.fileAccessHandle?.flush();
            this.writtenBytes += written ?? 0;
        }
        catch (e) {
            console.error("Writing log line to file failed", e);
            Sentry.captureException(e, { level: "error" });
        }
    }
}

type LogPostMessageType = {
    source?: string;
    logLine?: string;
};

if (isDedicatedWorker()) {
    const context = new LogArchiveDedicatedWorkerContext();

    const logMessageQueue = queue(async logLine => {
        await context.write(logLine);
    });

    const processEvent = ({ data }: MessageEvent<LogPostMessageType>) => {
        const { source, logLine } = data;
        if (source !== logSrcStr || !logLine) return;

        logMessageQueue(logLine);
    };

    self.onload = () => {
        console.log("Log archive web worker loaded");
        // "onload" is the correct place here to avoid accessing global variables too early.
        initSentry({ internalLogsAsBreadcrumb: true });
    };

    self.onmessage = processEvent;
}
