import { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import { associateUserWithAuthRequest, generatePasscode } from "@/api/auth";
import { loadCodeVerifier } from "@/auth/storage";
import { isDevEnv } from "@/misc/environment";
import log from "@/misc/log";

export default function EmailView(): React.JSX.Element {
    const navigate = useNavigate();
    const [params, _] = useSearchParams();
    const authReqId = params.get("auth_request_id");
    const passcodeUrl = `/login/passcode?auth_request_id=${encodeURIComponent(authReqId || "")}`;

    // TODO: email validation
    const [isFailed, setIsFailed] = useState(false);
    const [userEmail, setUserEmail] = useState("");

    // Autofill previous user ID
    useEffect(() => {
        const previousUser = localStorage.getItem("x-beyond-temp-userid");
        if (previousUser) {
            setUserEmail(previousUser);
        }
    }, []);
    const saveDevUser = (newUserId: string) => {
        if (isDevEnv && newUserId) {
            localStorage.setItem("x-beyond-temp-userid", newUserId);
        }
    };

    const signIn = useCallback(async () => {
        try {
            const codeVerifier = await loadCodeVerifier();

            if (!authReqId || !codeVerifier) {
                log.error("No auth request ID or code verifier provided");
                setIsFailed(true);
                return;
            }

            // Append '@example.com' if it's not a valid email address (dev-only)
            const email = isDevEnv && !userEmail.includes("@") ? `${userEmail}@example.com`
                : userEmail;

            const response = await associateUserWithAuthRequest(authReqId, email, codeVerifier);
            if (!response.isSuccess) {
                log.error("Failed to associate user with auth request:", response.reason);
                setIsFailed(true);
                return;
            }

            const { isNewUser, userId } = response.response;

            const passcodeResp = await generatePasscode(userId, {
                authRequestId: authReqId,
                codeVerifier,
            });
            if (!passcodeResp.isSuccess) {
                log.error("Failed to generate passcode:", passcodeResp.reason);
                setIsFailed(true);
                return;
            }

            saveDevUser(email);

            navigate(passcodeUrl, {
                replace: true,
                state: { codeVerifier, isNewUser, userId, userEmail: email },
            });
        }
        catch (e) {
            log.error("Failed to sign in:", e);
            setIsFailed(true);
        }
    }, [authReqId, navigate, passcodeUrl, userEmail]);

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                signIn();
            }
        };
        document.addEventListener("keypress", handleKeyPress);

        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        };
    }, [signIn]);

    if (!authReqId) {
        log.error("No auth request ID provided");
        return <Navigate to="/login" />;
    }

    return (
        <div className="c-signin-wrapper">
            <fieldset className="c-signin">
                {
                    <div className="c-signin__element c-signin__element--active">
                        <label htmlFor="userEmail" className="c-signin__label">Email</label>
                        <div className="c-signin__value">
                            <input
                                type="text"
                                id="userEmail"
                                name="signin-input"
                                placeholder="Enter your email"
                                value={userEmail}
                                autoComplete="off"
                                maxLength={100}
                                className="c-signin__input"
                                onChange={e => setUserEmail(e.target.value)}
                            />
                        </div>
                    </div>
                }
                <button
                    className="cp-btn cp-btn-signin"
                    title="Continue"
                    onClick={signIn}
                >
                    Continue
                </button>
                {isFailed && (
                    <div className="c-signin__element c-signin__element--error">
                        <label className="c-signin__error">Failed to sign in</label>
                    </div>
                )}
            </fieldset>
        </div>
    );
}
