import { useCallback, useEffect, useRef, useState } from "react";

import useBackoff, { ActionBeginReturnType } from "@/hooks/useBackoff";

export default function useQueueProcessor<T>(
    items: Array<T>,
    filter?: Map<T, any>,
): {
    target: T | undefined;
    attempts: number;
    succeeded: () => void;
    failed: (retry?: boolean) => void;
} {
    const [target, setTarget] = useState<T | undefined>(undefined);

    // Ref to contain the callbacks for the executed action.
    const actionResultRef = useRef<ActionBeginReturnType>();

    const done = useCallback((args: { success: boolean; }) => {
        actionResultRef.current?.(args.success);

        setTarget(undefined);
        actionResultRef.current = undefined;
    }, [setTarget]);

    const { actionIsPermitted, attempts, actionBegin } = useBackoff();

    const succeeded = useCallback(() => {
        done({ success: true });
    }, [done]);

    const failed = useCallback((retry: boolean = true) => {
        done({ success: retry });
    }, [done]);

    useEffect(() => {
        if (target || !actionIsPermitted) return;

        const next = filter ? items.find(i => filter.has(i)) : items[0];
        if (next === undefined) return;

        actionResultRef.current = actionBegin();

        setTarget(next);
        // Note: it may be beneficial to select the target randomly from the list
        // of available things to not cause immediate reselection.
        // If this is done, then this is no longer a queue processor but a set processor.
    }, [actionBegin, actionIsPermitted, items, target, filter]);

    return { target, attempts, succeeded, failed };
}
