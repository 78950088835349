import { useCallback, useRef } from "react";

import { Client as RtcClient, LocalTrack } from "@avos-io/rtc-client";

import {
    AsyncMediaPublishFunc,
    inputDevices,
    MediaInputKind,
    MediaInputKind_Type,
    MediaPublisherMap,
    MediaPublishFunc,
    MediaUnpublishFunc,
} from "@/domain/mediaDevices";
import { CallActionStatus, selectCallJoinStatus } from "@/features/calls";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import log from "@/misc/log";
import { Optional, PartialRecord } from "@/misc/types";
import { objectKeyedBy } from "@/misc/utils";
import { useAppSelector } from "@/store/redux";

// These function types mirror the (un)publish functions exposed by the RTC client:

// Publish a MediaStream and resolve the RTC client LocalTracks (which e.g. expose
// callbacks to replace/unpublish media).
type PublishRtcTrackFunc = (stream: MediaStream) => Promise<Optional<LocalTrack[]>>;
// Unpublish a RTC LocalTrack, optionally stopping the track's media stream.
type UnpublishRtcTrackFunc = (stopMediaStream: boolean) => void;

export interface useRtcClientPublishersProps {
    getClient: () => Optional<RtcClient>;
}

/** @function
 * Exposes the relevant RTC session media management functions for each input media kind,
 * depending on the media which has previously been published into the session.
 * We first publish a MediaStream into the session, which does complex connection
 * negotiation between peers in the session. To save us from re-negotiating on
 * selecting a new media device (e.g. switch device by selector/unplugging device),
 * we then use the `replaceTracks` method to switch the media in place. This continues
 * until we unpublish the media, at which point we unpublish the media (closing
 * the peer connection), and revert back to publishing.
 *
 * This hook also guards us from publishing media into a session until we've joined it.
 *
 * @param getClient a function to get the current RTC client object. We use a callback
 * instead of some state as we store the session in a ref in the parent, and want
 * to retrieve it on demand upon publishing media.
 *
 * @returns an object with the following fields:
 * - `publishers` - a map from input media kinds to functions to publish/
 * unpublish media of that kind.
 * - `resetPublishers` - a function to reset the publish fallback functions
 * (intended) to be called at the end of each session.
 */
export function useRtcClientPublishers(props: useRtcClientPublishersProps) {
    const { getClient } = props;

    const callJoinStatus = useAppSelector(selectCallJoinStatus);

    // Get the session publish function for the media kind.
    // CAREFUL: want the current value of the client *at the point that publish is called*.
    // Using the arrow func below (s => getClient()...(s)) achieves that.
    //
    // If you instead used the value directly, then you capture that value
    // at the point that publish is *first* called.
    const getSessionPublishFunc = useCallback((k: MediaInputKind_Type): PublishRtcTrackFunc => {
        if (k === MediaInputKind.Display) {
            return async s => getClient()?.publishDisplayMediaTracks(s);
        }
        else {
            return async s => getClient()?.publishUserMediaTracks(s);
        }
    }, [getClient]);

    // Only publish media once we've joined the session
    const publishOnJoin = useCallback(
        (publish: AsyncMediaPublishFunc): AsyncMediaPublishFunc => {
            return async stream => {
                if (callJoinStatus === CallActionStatus.Completed) await publish(stream);
            };
        },
        [callJoinStatus],
    );

    // Functions to replace media tracks instead of publishing for each kind of media.
    // We use a ref here as we read and update them in Promises in the same useMemo.
    const replaceTrackFuncs = useRef<PartialRecord<MediaInputKind_Type, MediaPublishFunc>>({});

    // Functions to unpublish media for each media kind.
    const unpublishFuncs = useRef<PartialRecord<MediaInputKind_Type, MediaUnpublishFunc>>({});
    const onUnpublish = useCallback((kind: MediaInputKind_Type) => {
        replaceTrackFuncs.current[kind] = undefined;
        unpublishFuncs.current[kind] = undefined;
    }, []);
    const updateUnpublisher = useCallback(
        (kind: MediaInputKind_Type, unpublish: UnpublishRtcTrackFunc) => {
            // Don't update the unpublish function if already defined
            // (stopping us from re-triggering effects).
            if (unpublishFuncs.current[kind]) {
                return;
            }

            // Update the unpublish function for the media kind.
            // We don't want to stop media here as the useMediaDevice hook
            // will handle this for us when cleaning up the MediaStream.
            unpublishFuncs.current[kind] = () => {
                log.info("Unpublishing media track", kind);
                const stopMediaStream = false;
                unpublish(stopMediaStream);
                onUnpublish(kind);
            };
        },
        [onUnpublish],
    );

    // Function called after publishing an RTC LocalTrack, setting a flag to
    // not unpublish media when ending the stream externally, and
    // updating the replaceTrack/unpublish functions.
    // Note we set the flag to *not* unpublish media on ending, so we can instead
    // replace the MediaStream in the existing RTC track when switching devices.
    const handleLocalTrack = useCallback((kind: MediaInputKind_Type) => (track: LocalTrack) => {
        track.unpublishOnMediaTrackEnded = false;

        updateUnpublisher(kind, (stopMediaStream: boolean) => track.unpublish(stopMediaStream));

        replaceTrackFuncs.current[kind] = stream =>
            stream.getTracks().forEach(newMediaTrack => track.replaceMediaTrack(newMediaTrack));
    }, [updateUnpublisher]);

    // Function to publish media of a specific kind on joining the session, or
    // replace the media tracks if already published media for that kind.
    const publishOrReplaceOnJoin = useCallback((kind: MediaInputKind_Type) =>
        publishOnJoin(
            async s => {
                // Replace track if already published
                const replaceTrackFunc = replaceTrackFuncs.current[kind];
                if (replaceTrackFunc) {
                    log.info("Replacing media track", kind);
                    replaceTrackFunc(s);
                }
                else {
                    // Publish tracks into RTC session and use the resolved
                    // RTC LocalTracks to update our replaceTrack/unpublish functions.
                    const publish = getSessionPublishFunc(kind);

                    log.info("Publishing media track", kind);
                    await publish(s).then(ts => {
                        ts?.forEach(handleLocalTrack(kind));
                    });
                }
            },
        ), [getSessionPublishFunc, handleLocalTrack, publishOnJoin]);

    // Functions to unpublish media tracks in the session for each media kind.
    // We memoise a function to call whatever is in the ref to not cause us to
    // re-fetch media as we join new sessions.
    const unpublishers = useShallowEqualsMemo(() =>
        objectKeyedBy(inputDevices, k => {
            return () => unpublishFuncs.current[k]?.();
        }), []);

    // Functions to publish/replace or unpublish media tracks in the session for each media kind.
    const publishers: MediaPublisherMap = useShallowEqualsMemo(() => {
        return objectKeyedBy(inputDevices, k => ({
            publish: publishOrReplaceOnJoin(k),
            unpublish: unpublishers[k],
        }));
    }, [publishOrReplaceOnJoin, unpublishers]);

    // Reset the replaceTrack/unpublish functions on ending the session
    const resetPublishers = useCallback(() => {
        replaceTrackFuncs.current = {};
        unpublishFuncs.current = {};
    }, []);

    return {
        publishers,
        resetPublishers,
    };
}
