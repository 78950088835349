import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import Avatar from "@/components/gui/Avatar";
import { selectCurrentSquadIds } from "@/features/squads";
import { useInterestedSquads } from "@/hooks/interest/useInterest";
import useAddressParams from "@/hooks/useAddressParams";
import useFreshSquadObservers from "@/hooks/useFreshSquadObservers";
import { useAppSelector } from "@/store/redux";

function PresenceHeader(): React.JSX.Element {
    const pathSegments = useLocation().pathname.split("/");
    const viewLocation = pathSegments.length > 1 ? pathSegments[1] : "";
    const { squadId } = useAddressParams();

    const currentUserSquads = useAppSelector(selectCurrentSquadIds);

    const squadsToSubObservers = useMemo(() => {
        if (squadId === undefined) {
            return currentUserSquads;
        }
        return [squadId];
    }, [squadId, currentUserSquads]);

    useInterestedSquads(squadsToSubObservers);
    const sortedSquadObservers = useFreshSquadObservers(squadsToSubObservers);

    // This logic is getting really nasty. Does it ever evaluate to false?
    const showPresenceHeader = viewLocation == "bond" ||
        viewLocation == "squad" ||
        (pathSegments[1] == "mobile" && pathSegments[2] == "tab" && pathSegments[3] == "mybonds");

    if (!showPresenceHeader) {
        return <></>;
    }

    return (
        <div className="c-presence c-presence--inbox">
            <div className="c-presence__humans c-presence__humans--inbox">
                {sortedSquadObservers.map(userId => (
                    <Avatar
                        userId={userId}
                        key={userId}
                        showPresence={true}
                        size="presence"
                    />
                ))}
            </div>
        </div>
    );
}

export default PresenceHeader;
