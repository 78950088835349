import { AutoComplete } from "@/components/gui/AutoComplete";
import Avatar from "@/components/gui/Avatar";
import SensitiveText from "@/components/gui/SensitiveText";
import * as d from "@/domain/domain";
import { userNameForMention } from "@/domain/mentions";
import { fetchUsersForPerson, selectUserIdsKnownToCurrentUser } from "@/features/squads";
import { getPersonByEmail } from "@/features/users";
import { useInterestedUsers } from "@/hooks/interest/useInterest";
import useAudienceSuggestionDomain, {
    EmailSubdomainOptions,
    EmailSuggestionContent,
    UserSubdomainOptions,
    UserSuggestionContent,
} from "@/hooks/useAudienceSuggestionDomain";
import { Point } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import { useCallback, useMemo, useState } from "react";

const EmailLookupSuggestionContent: EmailSuggestionContent = ({ entity }) => (
    <>
        <div className="u-truncate-auto">
            Search for&nbsp;
            <em>
                <SensitiveText>{entity}</SensitiveText>
            </em>
            &nbsp;by email.
        </div>
    </>
);

const UserLookupSuggestionContent: UserSuggestionContent = ({ entity }) => (
    <>
        <Avatar userId={entity.id} showPresence={false} size="suggestion" />
        <div className="u-truncate-auto">
            <em>
                <SensitiveText>{userNameForMention(entity)}</SensitiveText>
            </em>
            &nbsp;&bull;&nbsp;
            <SensitiveText>{entity.name}</SensitiveText>
        </div>
    </>
);

export type ChooseUserFunc = (_: d.UserId) => void;

interface UserLookupAutoCompleteProps {
    anchor: Point;
    chooseUser: ChooseUserFunc;
    query: string;
}

export const UserLookupAutoComplete = (props: UserLookupAutoCompleteProps): React.JSX.Element => {
    const { anchor, chooseUser: choose, query } = props;
    const dispatch = useAppDispatch();

    const [lookedUpUserIds, setLookedUpUserIds] = useState<d.UserId[]>([]);
    const knownUserIds = useAppSelector(selectUserIdsKnownToCurrentUser);
    const relevantUserIds = [...lookedUpUserIds, ...knownUserIds];
    useInterestedUsers(relevantUserIds);

    const chooseUserByEmail = useCallback(async (targetEmail: string) => {
        const personId = await dispatch(getPersonByEmail({ email: targetEmail })).unwrap().catch(
            e => {
                console.error(e);
                return undefined;
            },
        );
        if (!personId) {
            alert(`No user found with email "${targetEmail}".`);
            return;
        }

        const userIds = await dispatch(fetchUsersForPerson({ personId })).unwrap();
        if (userIds.length === 0) {
            alert(`No user found with email "${targetEmail}".`);
            return;
        }

        setLookedUpUserIds(userIds.map(u => u.userId));
    }, [dispatch, setLookedUpUserIds]);

    // Build the audience suggestion domain
    const emailSubdomainOptions = useMemo<EmailSubdomainOptions>(
        () => ({
            query,
            Content: EmailLookupSuggestionContent,
            action: chooseUserByEmail,
        }),
        [query, chooseUserByEmail],
    );
    const userSubdomainOptions = useMemo<UserSubdomainOptions>(
        () => ({
            idsToFilter: knownUserIds,
            idsNotToFilter: lookedUpUserIds,
            Content: UserLookupSuggestionContent,
            action: u => choose(u.id),
        }),
        [knownUserIds, lookedUpUserIds, choose],
    );
    const suggestionDomain = useAudienceSuggestionDomain(
        {
            email: emailSubdomainOptions,
            user: userSubdomainOptions,
        },
    );

    return (
        <AutoComplete
            anchor={anchor}
            verticalAnchor="bottom"
            horizontalClampBehaviour="clamp"
            suggestionDomain={suggestionDomain}
            query={query}
        />
    );
};
