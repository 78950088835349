import { nanoid } from "@reduxjs/toolkit";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";

import { setStateReduce } from "@/misc/setStateReduce";

export type SharedStateSetter<T> = Dispatch<SetStateAction<T>>;

export default function createSharedStateHook<T>(initialValue: T) {
    let sharedValue: T = initialValue;

    const stateSetters: Map<string, SharedStateSetter<T>> = new Map();

    const setAllStates: SharedStateSetter<T> = (value: SetStateAction<T>) => {
        sharedValue = setStateReduce(sharedValue, value);
        stateSetters.forEach(setter => setter(sharedValue));
    };

    return function useSharedState(): [T, SharedStateSetter<T>] {
        const [state, setState] = useState<T>(sharedValue);

        useEffect(() => {
            const key = nanoid();
            stateSetters.set(key, setState);
            return () => {
                stateSetters.delete(key);
            };
        }, [setState]);

        return [state, setAllStates];
    };
}
