import React, { ReactNode, useEffect, useState } from "react";

interface SlideInPanelProps {
    title?: string;
    progress?: JSX.Element;
    children: ReactNode;
    openTrigger?: boolean; // rising edge trigger
}

const SlideInPanel: (props: SlideInPanelProps) => React.JSX.Element = props => {
    const [isOpen, setIsOpen] = useState(false);
    const togglePanel = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (props.openTrigger) {
            setIsOpen(true);
        }
    }, [props.openTrigger]);

    // if clicked outside of the panel, close it
    useEffect(() => {
        const closePanel = (e: MouseEvent) => {
            if (isOpen && e.target instanceof HTMLElement) {
                if (!e.target.closest(".slide-in-panel")) {
                    setIsOpen(false);
                }
            }
        };

        document.addEventListener("click", closePanel);
        return () => document.removeEventListener("click", closePanel);
    }, [isOpen]);

    return (
        <div className={`slide-in-panel slide-in-panel--${isOpen ? "open" : "closed"}`}>
            <div className="slide-in-panel__panel-header" onClick={togglePanel}>
                <h3>{props.title || "no title"}</h3>
                <span className="slide-in-panel__panel-header--title">{props.progress}</span>
                <span className={"toggle-icon toggle-icon--" + (isOpen ? "open" : "closed")}>
                </span>
            </div>
            <div className="slide-in-panel__content">{props.children}</div>
            <div
                className={`slide-in-panel__reveal slide-in-panel__reveal--${
                    isOpen ? "open" : "closed"
                }`}
                onClick={togglePanel}
            >
            </div>
        </div>
    );
};

export default SlideInPanel;
