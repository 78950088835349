import { AudienceAction, AudienceMember, AudienceOp, isSquadId, isUserId } from "@/domain/audience";
import * as d from "@/domain/domain";
import { SquadOverview } from "@/domain/squads";
import { UserOverview } from "@/domain/users";
import { separateDiscriminatedUnion } from "@/misc/utils";

export interface SquadMention {
    target: d.SquadId;
}

export interface UserMention {
    target: d.UserId;
}

export type Mention =
    | SquadMention
    | UserMention;

/**
 * @deprecated
 */
export type ContentMention = Mention & {
    startOffset: number;
    endOffset: number;
};

export const isSquadMention = (mention: Mention): mention is SquadMention =>
    isSquadId(mention.target);

export const isUserMention = (mention: Mention): mention is UserMention => isUserId(mention.target);

export const extractSquadIdsFromMentions = (mentions: Mention[]): d.SquadId[] => {
    return mentions.map(m => m.target).filter(isSquadId);
};

export const newSquadMention = (squadId: d.SquadId): Mention => ({ target: squadId });

export const newUserMention = (userId: d.UserId): Mention => ({ target: userId });

export const filterMentions = (
    mentions: Mention[],
): { userIds: d.UserId[]; squadIds: d.SquadId[]; } => {
    const [userIds, squadIds] = separateDiscriminatedUnion<d.UserId, d.SquadId>(
        isUserId,
        mentions.map(m => m.target),
    );
    return {
        userIds,
        squadIds,
    };
};

export const squadNameForMention = (squad: SquadOverview) => squad.name;

export const userNameForMention = (user: UserOverview) =>
    (user.nickname.trim() || user.name.split(/\s+/g)[0]) ?? "Unknown";

export const audienceOpForAdd = (member: AudienceMember): AudienceOp => ({
    target: member,
    action: AudienceAction.Add,
});

export const audienceOpForMention = (mention: Mention): AudienceOp => ({
    target: mention.target,
    action: AudienceAction.AddNotify,
});
