import { CallId } from "@/domain/domain";
import { UserOverview } from "@/domain/users";
import { userSortByNickname } from "@/domain/users";
import { selectCallById, selectNonContributingParticipantIds } from "@/features/calls";
import { selectUsers } from "@/features/users";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import { Optional } from "@/misc/types";
import { useInterestedUsers } from "./interest/useInterest";

const sortByNickname = { sort: userSortByNickname };

/**
 * Order call participants by how many transcript lines they create in the call.
 * Call participants that did not create any transcript lines are ordered at the end,
 * and sorted by their nickname.
 */
export default function useOrderedCallParticipants(callId: Optional<CallId>): UserOverview[] {
    const call = useSelectorArgs(selectCallById, callId);
    const nonContributingParticipantIds = useSelectorArgs(
        selectNonContributingParticipantIds,
        call?.id,
    );

    const contributingParticipants = useSelectorArgs(selectUsers, call?.participantsByContribution);

    useInterestedUsers(call?.participantsByContribution);
    useInterestedUsers(nonContributingParticipantIds);
    const nonContributingParticipants = useSelectorArgs(
        selectUsers,
        nonContributingParticipantIds,
        sortByNickname,
    );

    const participants = useShallowEqualsMemo(
        () => [...contributingParticipants, ...nonContributingParticipants],
        [contributingParticipants, nonContributingParticipants],
    );
    return participants;
}
