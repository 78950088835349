import { type RefCallback, useCallback } from "react";

import { MutableRefList } from "@/misc/types";
import { setRefs } from "@/misc/utils";

const useMergedRefs = <T>(...refs: MutableRefList<T>): RefCallback<T> =>
    useCallback((val: T) => {
        setRefs(val, ...refs);
    }, [refs]);

export default useMergedRefs;
