// Adjusted from redux-toolkit's implementation of nanoid
const urlAlphabet = "abcdefghijklmnopqrstuvwxyz012345";

export const prettyNanoid = (size = 21) => {
    let id = "";
    // A compact alternative for `for (var i = 0; i < step; i++)`.
    let i = size;
    while (i--) {
        // `| 0` is more compact and faster than `Math.floor()`.
        id += urlAlphabet[(Math.random() * 32) | 0];
    }
    return id;
};
