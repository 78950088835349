import * as Sentry from "@sentry/react";

import { getEnvironmentConfig, isDevEnv } from "@/misc/environment";
import log from "@/misc/log";

let sentryConfig: SentryConfig | undefined;
// The Sentry SDK annoyingly doesn't export a lot of its types, hence the dance here to try
// and type this array sanely.
type SentryReplayIntegration = ReturnType<typeof Sentry.replayIntegration>;
let sentryReplayIntegration: SentryReplayIntegration | undefined;

export interface SentryConfig {
    replay?: SentryReplayOptions;
    internalLogsAsBreadcrumb?: boolean;
}

export interface SentryReplayOptions {
    sessionSampleRate: number;
    errorSampleRate: number;
}

let internalLogger: {
    info: (msg: string, ...args: any[]) => void;
    error: (msg: string, ...args: any[]) => void;
} = {
    info: () => {},
    error: () => {},
};

const sentryLogger = {
    info: (msg: string, ...args: any[]) =>
        Sentry.addBreadcrumb({ message: msg, category: "info", level: "info", data: args }),
    error: (msg: string, ...args: any[]) =>
        Sentry.addBreadcrumb({ message: msg, category: "error", level: "error", data: args }),
};

function setLogger(cfg?: SentryConfig) {
    if (cfg?.internalLogsAsBreadcrumb) {
        internalLogger = sentryLogger;
    }
    else {
        internalLogger = log;
    }
}

function sentryEnabled() {
    // Disabled by default on localhost to avoid a lot of noise in Sentry, but you can hack this to `true`
    // to test changes to Sentry stuff locally.
    return !isDevEnv;
}

export function initSentry(cfg?: SentryConfig) {
    if (!sentryEnabled()) return;

    setLogger(cfg);

    // Sentry is proxied in real clusters. But we can just talk to it directly in development.
    const targetConfig = getEnvironmentConfig();
    const sentryDsn =
        `https://adbef5c8f9f2024d67c415643ff732ab@${targetConfig.sentryHost}/4507746791456849`;
    const integrations: SentryReplayIntegration[] = [];

    // Replay is a horror; it can only be instantiated one time. So we need to track an instance
    // of it. The reality is, once it's been loaded once it's there forever and the only thing
    // we really change is the sampling rate.
    if (cfg?.replay) {
        if (!sentryReplayIntegration) {
            sentryReplayIntegration = Sentry.replayIntegration();
        }
        integrations.push(sentryReplayIntegration);
    }

    Sentry.init({
        dsn: sentryDsn,
        environment: self.location.hostname,
        integrations: integrations,
        replaysSessionSampleRate: cfg?.replay?.sessionSampleRate,
        replaysOnErrorSampleRate: cfg?.replay?.errorSampleRate,
    });
    sentryConfig = cfg;
    internalLogger.info(`Sentry initialised ${cfg?.replay && "with replay integration" || ""}`);
}

export function reinitSentry(cfg?: SentryConfig) {
    if (!sentryEnabled()) return;

    setLogger(cfg);

    if (JSON.stringify(cfg) === JSON.stringify(sentryConfig)) {
        return;
    }

    internalLogger.info("Sentry re-init requested with change in config", cfg);

    // Asynchronously close the old client
    new Promise((reject, resolve) => {
        Sentry.getClient()?.close().then(reject, resolve);
    }).catch(err => {
        internalLogger.error("Error closing Sentry client", err);
    }).finally(() => {
        // Start a new client with replay enabled
        initSentry(cfg);
    });
}
