// This is where things get a bit more interesting... The rest of the state machine etc. can
// just be run on our shared worker. But this is the part where we need to run it in the UI,

import { listAvailableUsersHttp, ListAvailableUsersResponse } from "@/api/client";
import * as d from "@/domain/domain";
import { OrgOverview } from "@/domain/squads";
import { getLocalPreferredUserOrgId, setLocalPreferredUserOrgId } from "@/features/auth";
import { getEnvironmentConfig } from "@/misc/environment";
import log from "@/misc/log";
import { getOidcConfig } from "@/misc/oidcConfig";
import { delay } from "@/misc/promises";
import { prepareAuthRequest, requestAccessToken } from "./authRequests";
import { persistFromAuthRequestResponses } from "./stateMachine";
import { authRequestStorage } from "./storage";
import { AuthRequestStateSchema, OidcConfig } from "./types";

// The very first part of an OIDC login -- this will return a URL that the caller can use
// to redirect through to to the login UI via the OIDC provider.
export async function beginAuthenticateWithAuthProvider(
    config: OidcConfig,
): Promise<URL> {
    const [requestState, authUrl] = await prepareAuthRequest(config);

    // Other than figuring out where to redirect to, the only thing we need to do is save
    // some state in session storage.
    await authRequestStorage().save(JSON.stringify(requestState));

    // The caller should do a window.location.href = authUrl.toString()
    return authUrl;
}

// The second part of OIDC logic -- validates state and obtains tokens based on the code.
// To be able to run the state machine, persistFromAuthRequestResponses() needs to be
// called to persist data to idb for the state machine to work from.
export async function completeAuthenticateWithAuthProvider(
    config: OidcConfig,
    code: string,
    state: string,
) {
    const storage = authRequestStorage();
    try {
        const authRequestRaw = await storage.load();

        if (authRequestRaw === null) {
            throw new Error("Auth: no auth request state found");
        }

        const authRequest = AuthRequestStateSchema.parse(JSON.parse(authRequestRaw));

        if (state !== authRequest.state || code === null) {
            log.error(`Auth: state mismatch or code invalid`);
            throw new Error("Auth: state mismatch or code invalid");
        }

        const tokenResponse = await requestAccessToken(
            config,
            authRequest,
            code,
        );
        const valid_until = tokenResponse.expires_in &&
            (Date.now().valueOf() + 1000 * tokenResponse.expires_in);

        return {
            token: tokenResponse,
            valid_until,
        };
    }
    finally {
        storage.save(null);
    }
}

function getRedirectOrLocation(response: Response): string {
    if (response.redirected) {
        return response.url;
    }

    const location = response.headers.get("Location");
    if (location) return location;

    throw new Error(`No redirect or Location header in response`);
}

/* Extracts a relative URL from a Response that was returned as a redirect in part of the
 * auth flow. This only makes sense to call as part of the auth flow from login views,
 * do not call this function from any other state.
 *
 * This function throws if any state is not as expected.
 */
export function relativeUrlForRedirectResponse(response: Response): [string, URL] {
    if (!response.ok) {
        throw new Error(`Sign-in failed: ${response.status} ${response.statusText}`);
    }

    const location = getRedirectOrLocation(response);
    const url = new URL(location);

    return [url.pathname + url.search, url];
}

function selectUserOrg(usersAndOrgs: ListAvailableUsersResponse) {
    const currentSelection = getLocalPreferredUserOrgId();

    if (currentSelection) {
        // If the user has selected something already; perhaps by using the org selector,
        // then keep using that.
        const found = usersAndOrgs.find(u => u.userId === currentSelection.userId);
        if (found) return found;
    }

    if (usersAndOrgs.length === 1) {
        return usersAndOrgs[0];
    }

    // If there are multiple orgs, there must be one non-personal one, so pick the
    // first of those.
    return usersAndOrgs.filter(u => !u.org?.personal)[0];
}

/**
 * Handles the final steps of login, eventually reloading the app with the auth state setup.
 *
 * This includes support for Zitadel flows; pass in `URLSearchParams` for that case. For new
 * auth, pass in a `Response` from fetching the final callback URI.
 */
export async function finalLoginSteps(authResponse: Response | URLSearchParams): Promise<[{
    userId: d.UserId;
    org?: OrgOverview;
}, ListAvailableUsersResponse]> {
    let sp: URLSearchParams;
    let fullUrl: URL;

    if (authResponse instanceof URLSearchParams) {
        sp = authResponse;
        fullUrl = new URL(window.location.href);
    }
    else {
        const [relativeUrl, fullUrlFromResponse] = relativeUrlForRedirectResponse(authResponse);

        fullUrl = fullUrlFromResponse;
        sp = fullUrl.searchParams;

        log.info(`Login flow: redirecting to ${relativeUrl}`);
    }

    const code = sp.get("code") || "";
    const state = sp.get("state") || "";

    const tokens = await completeAuthenticateWithAuthProvider(
        await getOidcConfig(),
        code,
        state,
    );

    const usersAndOrgs = await listAvailableUsersHttp(tokens.token.access_token);

    log.info(`Got users and orgs`, usersAndOrgs);

    if (usersAndOrgs.length === 0) {
        throw new Error(`Invalid zero-length user list response received`);
    }

    const id = selectUserOrg(usersAndOrgs);

    setLocalPreferredUserOrgId({ userId: id.userId, orgId: id.org.id });
    await persistFromAuthRequestResponses(tokens, id.userId);

    // We need to reload to set up our store correctly for this user ID. This might
    // also start a shared worker for the store.
    log.info(`Reloading after choosing userId`, id);

    // TODO: think about broadcasting auth state to make other tabs reload here

    fullUrl.searchParams.delete("code");
    fullUrl.searchParams.delete("state");

    // Ensure we do a relative reload; needed on mobile where the URL origin may not
    // match.
    window.location.href = fullUrl.pathname + fullUrl.search;

    // Give the browser time to reload the page. Else we'll keep doing stuff for a bit
    // until the reload happens.
    await delay(10000);

    // In the future, if we didn't require a reload above, then the caller may want
    // this info.
    return [id, usersAndOrgs];
}

export function isZitadelAuthBackendFromOidcDiscovery(json: any): boolean {
    return json["authorization_endpoint"]?.includes("oauth/v2/authorize") || false;
}

/**
 * A nasty hack to allow compatibility. This function will be removed in the future.
 *
 * Queries the OIDC discovery endpoint and looks for a string that is Zitadel specific.
 */
export async function isZitadelAuthBackend(): Promise<boolean> {
    const config = getEnvironmentConfig();

    const url = `${config.oidcAuthority}/.well-known/openid-configuration`;
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(
            `Failed to retrieve OIDC discovery document: ${response.status} ${response.statusText}`,
        );
    }
    const json = await response.json();

    return isZitadelAuthBackendFromOidcDiscovery(json);
}
