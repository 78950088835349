import { useEffect } from "react";

import { registerWakeLockInterest, unregisterWakeLockInterest } from "@/features/meta";
import useLocalDispatch from "./useLocalDispatch";

export default function useWakeLock(predicate: boolean) {
    const localDispatch = useLocalDispatch();

    useEffect(() => {
        if (!predicate) return;

        localDispatch(registerWakeLockInterest());

        return () => {
            localDispatch(unregisterWakeLockInterest());
        };
    }, [localDispatch, predicate]);
}
