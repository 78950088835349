import { PayloadAction } from "@reduxjs/toolkit";

import {
    bondHasSquadId,
    bondIsArchived,
    bondIsFollowed,
    bondIsNotArchived,
    bondIsNotFollowed,
    bondIsUnread,
    BondOverviewPredicate,
    hasImage,
    heraldHasSquadId,
    heraldIsFollowed,
} from "@/domain/bonds";
import { unreadMessageCount } from "@/domain/channels";
import * as d from "@/domain/domain";
import { resetStore, selectCurrentUserId } from "@/features/auth";
import { selectArchivedBondsSet, selectBondHeralds, selectBonds } from "@/features/bonds";
import { selectAllStagedSequenceNumbers, selectHasMentions } from "@/features/channels";
import { memoizeOptions } from "@/features/selectors";
import { Optional } from "@/misc/types";
import { checkPersistor } from "@/persist/shared";
import { createLocalSlice } from "@/store/localSlice";
import { createAppSelector } from "@/store/redux";
import type { RootState } from "@/store/types";

export type FilterOptions = "all" | "dismissed" | "unread";

export interface FilterPanelState {
    filter: FilterOptions;
}

const getInitialState = (
    props?: Partial<FilterPanelState>,
): FilterPanelState => ({
    filter: props?.filter ?? "all",
});

export const filterPanelSlice = createLocalSlice({
    name: "filterPanel",
    initialState: getInitialState(),
    selectors: {
        filter: state => state.filter,
    },
    reducers: {
        setFilter: (state, action: PayloadAction<FilterOptions>) => {
            state.filter = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

export const {
    setFilter,
} = filterPanelSlice.actions;

const selectors = filterPanelSlice.getSelectors((state: RootState) => state.filterPanel);
export const {
    filter: selectFilter,
} = selectors;

// Sort and filter implementations:

// Possible filters:
// My Bonds, no filter:             followed && not dismissed
// My Bonds, dismissed filter:      followed && dismissed
// My Bonds, unread filter:         followed && not dismissed && unread  (?)
// Squad, no filter:                squad
// Squad: unread filter:            squad && unread
export const selectBondIdsForBondList = createAppSelector(
    [
        state => selectBonds(state),
        selectCurrentUserId,
        state => selectArchivedBondsSet(state),
        state => selectAllStagedSequenceNumbers(state),
        selectFilter,
        (_state, squadId: Optional<d.SquadId>) => squadId,
    ],
    (previews, currentUserId, archivedSet, stagedSequenceNumberMap, filter, squadId) => {
        const predicates: BondOverviewPredicate[] = [];

        if (squadId !== undefined) {
            // With a squad filter applied, all bonds for that squad
            predicates.push(bondHasSquadId(squadId));

            // For squads, only the "unread" filter means anything
            if (filter === "unread") {
                predicates.push(bondIsUnread(stagedSequenceNumberMap));
            }
        }
        else {
            // Without, only followed bonds (Inbox)
            predicates.push(bondIsFollowed(currentUserId));

            if (filter === "all") {
                // For My Bonds, the "all" filter actually means "not dismissed"
                predicates.push(bondIsNotArchived(archivedSet));
            }
            else if (filter === "dismissed") {
                predicates.push(bondIsArchived(archivedSet));
            }
            else {
                // For My Bonds, the "unread" filter means "not dismissed and unread"
                predicates.push(bondIsNotArchived(archivedSet));
                predicates.push(bondIsUnread(stagedSequenceNumberMap));
            }
        }

        return previews.filter(bo => predicates.every(p => p(bo))).map(bo => bo.id);
    },
);

/** selectInterestingBondIdsForBondList produces a list of bond IDs which are a
 * superset of the list of bond IDs produced by selectBondIdsForBondList, using
 * only the information available in the bond heralds.
 * These bond IDs should be then used to register interest in the bond, so that
 * the bond can be fetched and filtered properly.
 */
export const selectInterestingBondIdsForBondList = createAppSelector(
    [
        state => selectBondHeralds(state),
        (_state, squadId: Optional<d.SquadId>) => squadId,
    ],
    (heralds, squadId) => {
        // With a squad filter applied, include all bonds for that squad
        // Without, include only followed bonds (Inbox)
        const predicate = (squadId !== undefined) ? heraldHasSquadId(squadId) : heraldIsFollowed;
        return heralds.filter(bo => predicate(bo)).map(bo => bo.bondId);
    },
    memoizeOptions.weakMapShallow,
);

// Hackity hack. A quick and dirty frontend only implementation of bonds we might like
// to discover.
export const selectBondIdsForDiscover = createAppSelector(
    [
        state => selectBonds(state),
        selectCurrentUserId,
        state => selectArchivedBondsSet(state),
        state => selectAllStagedSequenceNumbers(state),
    ],
    (previews, currentUserId, archivedSet) => {
        const predicates: BondOverviewPredicate[] = [];

        predicates.push(bondIsNotArchived(archivedSet));
        predicates.push(bondIsNotFollowed(currentUserId));
        predicates.push(hasImage);

        return previews.filter(bo => predicates.every(p => p(bo))).map(bo => bo.id);
    },
);

const selectAllFollowedBonds = createAppSelector(
    [state => selectBonds(state), selectCurrentUserId],
    (overviews, currentUserId) => {
        if (!currentUserId) {
            return [];
        }

        return overviews.filter(bo => bo.followers?.includes(currentUserId) ?? false);
    },
);

export const selectNumberOfFollowedUnreadMentions = createAppSelector(
    [
        selectAllFollowedBonds,
        state => selectAllStagedSequenceNumbers(state),
        state => selectArchivedBondsSet(state),
        state => state,
    ],
    (followed, stagedSequenceNumberMap, archivedBondSet, state) => {
        const numMentioned = followed
            .filter(
                bo =>
                    !archivedBondSet.has(bo.id) &&
                    unreadMessageCount(
                            stagedSequenceNumberMap[bo.channelId],
                            bo.maxSequenceNumber,
                        ) > 0 &&
                    selectHasMentions(state, bo.channelId),
            ).length;

        return numMentioned;
    },
    memoizeOptions.weakMapShallow,
);

export const selectOfflineNumberOfFollowedUnreadBonds = createAppSelector(
    [
        selectAllFollowedBonds,
        state => selectAllStagedSequenceNumbers(state),
        state => selectArchivedBondsSet(state),
    ],
    (followed, stagedSequenceNumberMap, archivedBondSet) => {
        const numUnread = followed
            .filter(
                bo =>
                    !archivedBondSet.has(bo.id) &&
                    unreadMessageCount(
                            stagedSequenceNumberMap[bo.channelId],
                            bo.maxSequenceNumber,
                        ) > 0,
            );

        return numUnread.length;
    },
    memoizeOptions.weakMapShallow,
);

export const reducer = filterPanelSlice.reducer;

// Persistence.

export const persistor = {
    stores: {},
};
checkPersistor<FilterPanelState>(persistor);
