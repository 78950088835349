import { registerPlugin } from "@capacitor/core";

export interface LoggingPlugin {
    nativeLog(options: { value: string; }): Promise<undefined>;
    dumpLogs(): Promise<{ logs: string[]; }>;
}

const Logging = registerPlugin<LoggingPlugin>("Logging");

export default Logging;
