// Copyright 2015 The gRPC Authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// The canonical version of this proto can be found at
// https://github.com/grpc/grpc-proto/blob/master/grpc/health/v1/health.proto

// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file grpc/health/v1/health.proto (package grpc.health.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message grpc.health.v1.HealthCheckRequest
 */
export const HealthCheckRequest = proto3.makeMessageType(
  "grpc.health.v1.HealthCheckRequest",
  () => [
    { no: 1, name: "service", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message grpc.health.v1.HealthCheckResponse
 */
export const HealthCheckResponse = proto3.makeMessageType(
  "grpc.health.v1.HealthCheckResponse",
  () => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(HealthCheckResponse_ServingStatus) },
  ],
);

/**
 * @generated from enum grpc.health.v1.HealthCheckResponse.ServingStatus
 */
export const HealthCheckResponse_ServingStatus = proto3.makeEnum(
  "grpc.health.v1.HealthCheckResponse.ServingStatus",
  [
    {no: 0, name: "UNKNOWN"},
    {no: 1, name: "SERVING"},
    {no: 2, name: "NOT_SERVING"},
    {no: 3, name: "SERVICE_UNKNOWN"},
  ],
);

