const loggableValue = (value: any) => {
    switch (typeof value) {
        case "string":
        case "number":
        case "boolean":
            return value;
        case "bigint":
        case "symbol":
            return value.toString();
        case "undefined":
            return "undefined";
        case "object":
            if (typeof Element !== "undefined" && value instanceof Element) {
                return `Element (${value.nodeName})`;
            }
            if (typeof HTMLElement !== "undefined" && value instanceof HTMLElement) {
                return `HTMLElement (${value.nodeName})`;
            }
            else if (typeof Event !== "undefined" && value instanceof Event) {
                return `Event (${value.type})`;
            }
            else if (value instanceof Error) {
                let ret = "";

                if ("name" in value && value.name !== "" && value.name !== "Error") {
                    ret += "Error: ";
                }

                ret += value.toString() + " (";

                if ("cause" in value) ret += value.cause + ",";

                ret += value.stack?.replaceAll("\n", "");

                return ret + ")";
            }
            return value;
        case "function":
            return value.toString().replaceAll("\n", "");
    }
};

/**
    Wraps JSON.stringify in such a way that it (should) never throw.

    This should ONLY be used to display or log values, since there are NO guarantees made
    on the parsability of the resulting JSON string.
*/
export function stringifyToUnparsableJSON(value: any, space?: string | number): string {
    return JSON.stringify(value, (_, v) => loggableValue(v), space);
}
