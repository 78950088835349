import { produce } from "immer";
import { jwtDecode } from "jwt-decode";
import { z } from "zod";

import * as d from "@/domain/domain";
import log from "@/misc/log";
import { delay, promiseWithResolvers } from "@/misc/promises";
import { refreshAccessToken } from "./authRequests";
import { authRequestStorage, tokenStorage } from "./storage";
import { AuthMachineStorage, OidcConfig, TokenResponse, TokenResponseSchema } from "./types";

// External interface
export type State =
    // Initial load. Will transition in time.
    | "Initialising"
    | // Currently refreshing, but don't have a valid token at this time. Will transition in time.
    "Refreshing"
    | // Authenticated with a valid token.
    "Authenticated"
    | // A fatal error was hit, we are not unauthenticated.
    "Error";

export interface TokenChangeDetails {
    token: string | null;
    subject: string | null;
    validUntil: number | null;
}

export interface Config {
    oidc: OidcConfig;
    storage?: AuthMachineStorage;
    userId: d.UserId;
    onTokenChange?: (details: TokenChangeDetails) => void;
    onStateChange?: (state: State) => void;
}

export interface AuthMachine {
    state(): State;
    token(): string | null;
    validUntil(): number | null;
    subject(): string | null;

    stop(clearAuth: boolean): Promise<void>;
}

type AuthMachineInternal = AuthMachine & {
    _internalConfig: Config;
    _internalState: AuthMachineState;
};

const StorageSchema = z.object({
    token: TokenResponseSchema.optional(),
    valid_until: z.number().optional(),
});
export type StorageType = z.infer<typeof StorageSchema>;

// Auth machine internals

interface AuthMachineState {
    state: State;

    oidcStorage?: StorageType;
    oidcStorageApi: AuthMachineStorage;
    refreshingPromise?: Promise<TokenResponse>;

    oidc: OidcConfig;

    stopController: AbortController;
    stopSignal: AbortSignal;
    stoppedPromise: Promise<void>;
    stoppedResolve: () => void;
}

export function createAuthStateMachine(config: Config): AuthMachine {
    const ab = new AbortController();
    const { promise: stoppedPromise, resolve: stoppedResolve } = promiseWithResolvers<void>();

    const machine: AuthMachineInternal = {
        _internalState: {
            state: "Initialising",
            oidcStorageApi: config.storage ?? tokenStorage(config.userId),
            oidc: config?.oidc,
            stopController: ab,
            stopSignal: ab.signal,
            stoppedPromise,
            stoppedResolve,
        },
        _internalConfig: config,
        state() {
            return this._internalState.state;
        },
        subject() {
            if (!this._internalState.oidcStorage?.token?.id_token) return null;
            try {
                const jwt = jwtDecode(this._internalState.oidcStorage.token.id_token);
                return jwt.sub ?? null;
            }
            catch (_e) {
                return null;
            }
        },
        token() {
            return this._internalState.oidcStorage?.token?.access_token ?? null;
        },
        validUntil() {
            return this._internalState.oidcStorage?.valid_until ?? null;
        },
        async stop(clearAuth) {
            this._internalState.stopController.abort("stop() called");
            await this._internalState.stoppedPromise;

            if (clearAuth) clearMachineStorage(this._internalState);
        },
    };

    return machine;
}

export async function runAuthStateMachine(extMachine: AuthMachine) {
    try {
        while (true) {
            await stepAuthStateMachine(extMachine);
        }
    }
    catch (_e) {
        log.debug(`Auth: Stopping state machine`);
    }

    const im: AuthMachineInternal = extMachine as AuthMachineInternal;
    im._internalState.stoppedResolve();
}

async function stepAuthStateMachine(extMachine: AuthMachine) {
    const callbacks: Record<State, (m: AuthMachineState) => Promise<AuthMachineState>> = {
        Initialising: onInitialising,
        Refreshing: onRefreshing,
        Authenticated: onAuthenticated,
        Error: onError,
    };
    const im: AuthMachineInternal = extMachine as AuthMachineInternal;

    const [origState, origToken] = [
        im._internalState.state,
        im._internalState.oidcStorage?.token?.access_token,
    ];

    try {
        im._internalState = await callbacks[im._internalState.state](im._internalState);
    }
    catch (e) {
        log.error(`Auth:${im._internalState.state}: Unexpected error leaked to top level`, e);
        im._internalState = transition(im._internalState, "Error");
    }

    if (
        im._internalState.state === "Authenticated" &&
        (origState !== "Authenticated" ||
            origToken !==
                im._internalState.oidcStorage?.token?.access_token)
    ) {
        await persistOidcState(im);

        im._internalConfig.onTokenChange?.({
            token: im.token(),
            subject: im.subject(),
            validUntil: im.validUntil(),
        });
    }

    if (im._internalConfig.onStateChange && origState !== im._internalState.state) {
        im._internalConfig.onStateChange(im.state());
    }

    if (im.state() === "Error") {
        throw new Error("Stopping state machine due to Error state");
    }
}

async function persistOidcState(im: AuthMachineInternal) {
    logPersisting(im._internalState.oidcStorage);
    await im._internalState.oidcStorageApi.save(im._internalState.oidcStorage);
}

async function clearMachineStorage(machine: AuthMachineState) {
    machine.oidcStorageApi.save(null);
    machine.oidcStorageApi.dispose?.(true);
}

function transition(
    machine: AuthMachineState,
    state: State,
    next?: Partial<Omit<AuthMachineState, "state">>,
): AuthMachineState {
    return { ...machine, state, ...next };
}

async function onInitialising(machine: AuthMachineState): Promise<AuthMachineState> {
    // We need to ensure some minimum time to give us enough time to use and refresh the token
    const minTokenValidityMs = 1000 * 60;

    // Check our serialised state.
    // If none, or erroneous => Unauthenticated
    // If good, check timeout. If expired => Refreshing, else Authenticated
    try {
        const data = await machine.oidcStorageApi.load();
        if (data === null) {
            log.info(`Auth:${machine.state}: No auth state found`);
            return transition(machine, "Error");
        }

        const storage = StorageSchema.parse(data);

        if (
            storage.valid_until !== undefined &&
            Date.now().valueOf() + minTokenValidityMs < storage.valid_until
        ) {
            log.info(`Auth:${machine.state}: Token valid`);
            return transition(machine, "Authenticated", { oidcStorage: storage });
        }

        // Unsure if the token is valid. We need to refresh.
        log.info(`Auth:${machine.state}: Token possibly expired, refreshing`);
        return transition(machine, "Refreshing", { oidcStorage: storage });
    }
    catch (_err) {
        log.error(`Auth:${machine.state}: Error loading auth state`, _err);
        return transition(machine, "Error");
    }
}

// Here we need to do a singular refresh, and if it works, move to authenticated. This can be a
// reasonable case on startup, where the access token has expired but we may still have a valid
// refresh token.
async function onRefreshing(machine: AuthMachineState): Promise<AuthMachineState> {
    if (machine.oidcStorage?.token?.refresh_token === undefined) {
        log.error(`Auth:${machine.state}: no refresh token found in storage`);
        return transition(machine, "Error");
    }

    try {
        const promise = machine.refreshingPromise ?? refreshAccessToken(
            machine.oidc,
            machine.oidcStorage.token.refresh_token,
            machine.stopSignal,
        );

        const refreshAccessTokenResponse = await promise;

        const valid_until = refreshAccessTokenResponse.expires_in &&
            (Date.now().valueOf() + 1000 * refreshAccessTokenResponse.expires_in);

        return transition(machine, "Authenticated", {
            oidcStorage: produce(machine.oidcStorage, draft => {
                draft.token = { ...draft.token, ...refreshAccessTokenResponse };
                draft.valid_until = valid_until;
            }),
            refreshingPromise: undefined,
        });
    }
    catch (e) {
        log.error(`Auth:${machine.state}: Error refreshing token`, e);
        return transition(machine, "Error", { refreshingPromise: undefined });
    }
}

// While authenticated we need to refresh the token in the background.
async function onAuthenticated(machine: AuthMachineState): Promise<AuthMachineState> {
    const refreshGraceMs = 1000 * 60 * 5;
    const minRemainingMs = 1000;

    try {
        const valid_until = machine.oidcStorage?.valid_until ?? Date.now();
        const remaining = valid_until - Date.now();

        // If we had some nasty time discontinuity, move back to refreshing
        if (remaining < minRemainingMs) {
            return transition(machine, "Refreshing");
        }

        // If less than e.g. 5 minutes remaining, do a background refresh
        if (remaining < refreshGraceMs) {
            if (machine.oidcStorage?.token?.refresh_token === undefined) {
                log.error(`Auth:${machine.state}: no refresh token found in storage`);
                return transition(machine, "Error");
            }

            const refreshingPromise = refreshAccessToken(
                machine.oidc,
                machine.oidcStorage.token.refresh_token,
                machine.stopSignal,
            );

            const p = await Promise.race([
                refreshingPromise,
                // Wrap "delay" in a promise that allows us to distinguish it from the refreshAccessToken
                new Promise<"delay">((res, rej) =>
                    delay(remaining, machine.stopSignal).then(_ => res("delay")).catch(e => rej(e))
                ),
            ]);

            if (p === "delay") {
                // Ok, our access token has timed out, and refreshing has yet to finish :(
                return transition(machine, "Refreshing", {
                    oidcStorage: produce(machine.oidcStorage, draft => {
                        draft.token = {
                            ...draft.token,
                            token_type: "Bearer",
                            access_token: "invalid",
                        };
                        draft.valid_until = undefined;
                    }),
                    refreshingPromise,
                });
            }

            const refreshAccessTokenResponse = p;

            const valid_until = refreshAccessTokenResponse.expires_in &&
                (Date.now().valueOf() + 1000 * refreshAccessTokenResponse.expires_in);

            // Transitioning back to the same state is fine; it will just loop again
            return transition(machine, "Authenticated", {
                oidcStorage: produce(machine.oidcStorage, draft => {
                    draft.token = { ...draft.token, ...refreshAccessTokenResponse };
                    draft.valid_until = valid_until;
                }),
            });
        }

        await delay(Math.min(remaining - refreshGraceMs, 1000 * 60 * 30), machine.stopSignal);

        return transition(machine, "Authenticated");
    }
    catch (e) {
        log.error(`Auth:${machine.state}: Error`, e);
        return transition(machine, "Error");
    }
}

async function onError(machine: AuthMachineState): Promise<AuthMachineState> {
    await delay(1000 * 60 * 30, machine.stopSignal);
    return transition(machine, "Error");
}

async function loadStorage(userId: d.UserId) {
    return tokenStorage(userId).load().then(data => {
        if (!data) {
            return null;
        }

        return StorageSchema.parse(data);
    });
}

export async function hasOidcState(userId: d.UserId): Promise<boolean> {
    return loadStorage(userId).then(storage => !!storage?.token).catch(() => false);
}

export async function hasAuthRequestState(): Promise<boolean> {
    return authRequestStorage().load().then(authRequest => !!authRequest).catch(() => false);
}

export async function persistFromAuthRequestResponses(tokens: StorageType, userId: d.UserId) {
    const storage = tokenStorage(userId);
    logPersisting(tokens);
    await storage.save(tokens);
}

function logPersisting(tokens: StorageType | undefined) {
    if (!tokens) return;

    if (tokens.valid_until !== undefined) {
        log.info(
            `Persisting auth state: valid_until=${tokens.valid_until} = ${
                new Date(tokens.valid_until).toISOString()
            }`,
        );
    }
    else {
        log.warn(`Persisting auth state: no valid_until`);
    }
}
