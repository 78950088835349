import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import { SquadAndMyBondsSelector } from "@/components/SquadAndMyBondsSelector";
import {
    selectAllSquadsDominantPresenceIconPair,
    selectInboxDominantPresenceIconPair,
    selectInboxUnreadBondsExist,
    selectMobileSquadsTabHighlighted,
} from "@/features/presence";
import usePresenceIcon from "@/hooks/usePresenceIcon";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { mobilePathToViewName, MobileViewName } from "@/misc/mobilePathToTab";

interface TabInfo {
    title: string;
    path: string;
    id: "bonds" | "squads" | "discover";
    view: Array<MobileViewName>;
    presenceIconString?: string;
    newActivity?: boolean;
}

function Tab(
    {
        title,
        path,
        highlight,
        id,
        presenceIconString,
        newActivity = false,
    }: TabInfo & {
        highlight: boolean;
    },
): React.JSX.Element {
    const classes = classNames(`c-footer-btn c-footer-btn--${id}`, {
        [`is-selected`]: highlight,
    });

    const showPresenceIndicator = (id === "bonds" || id === "squads") && !!presenceIconString;
    const showNewActivityDot = !showPresenceIndicator && newActivity;

    return (
        <div className="c-footer__item">
            <Link to={path} className={classes}>
                {title}
            </Link>

            {showPresenceIndicator && (
                <div className="c-footer__activity">
                    <span
                        className="c-activity-icon"
                        style={{
                            backgroundImage: `url("${presenceIconString}")`,
                        }}
                    />
                </div>
            )}

            {showNewActivityDot && (
                <div className="c-footer__activity">
                    <div className="c-activity-update" />
                </div>
            )}
        </div>
    );
}

export function MobileTabsView(): React.JSX.Element {
    const { pathname } = useLocation();

    const inboxPresenceIconString = usePresenceIcon(selectInboxDominantPresenceIconPair);
    const allSquadsPresenceIconString = usePresenceIcon(selectAllSquadsDominantPresenceIconPair);
    const inboxUnreadBondsExist = useSelectorArgs(selectInboxUnreadBondsExist);
    const mobileSquadsTabUnread = useSelectorArgs(selectMobileSquadsTabHighlighted);

    const tabs: Array<TabInfo> = useMemo(() => [
        {
            title: "Inbox",
            path: "/mobile/tab/mybonds",
            id: "bonds",
            view: ["mybonds"],
            presenceIconString: inboxPresenceIconString,
            newActivity: inboxUnreadBondsExist,
        },
        {
            title: "Groups",
            path: "/mobile/tab/mysquads",
            id: "squads",
            view: ["mysquads", "squad"],
            presenceIconString: allSquadsPresenceIconString,
            newActivity: mobileSquadsTabUnread,
        },
        {
            title: "Discover",
            path: "/mobile/tab/discover",
            id: "discover",
            view: ["discover"],
        },
    ], [
        allSquadsPresenceIconString,
        inboxPresenceIconString,
        inboxUnreadBondsExist,
        mobileSquadsTabUnread,
    ]);
    const view = mobilePathToViewName(pathname);
    const isHighlighted = useCallback((tab: typeof tabs[0]) => {
        return tab.view.includes(view);
    }, [view]);

    // Only render if we're on one of the tabs; i.e. one of them is highlighted
    if (!tabs.some(isHighlighted)) {
        return <></>;
    }

    const className = classNames("c-footer", {
        "c-footer--groups": mobilePathToViewName(pathname) === "mysquads",
    });

    return (
        <footer className={className}>
            {tabs.map(x => <Tab key={x.path} highlight={isHighlighted(x)} {...x} />)}
        </footer>
    );
}

export function MobileSquadSelectorView(): React.JSX.Element {
    return <SquadAndMyBondsSelector showMyBonds={false} />;
}
