import { createElement, FC, ReactNode, useMemo } from "react";
import { Fragment } from "react/jsx-runtime";

import SensitiveText from "@/components/gui/SensitiveText";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import { NumberRange } from "@/misc/types";

export interface TextFieldProps {
    text: string;
}

export interface TextField {
    range: NumberRange;
    Component: FC<TextFieldProps>;
}

export interface TextFieldsProps {
    text: string;
    fields: TextField[];
    DefaultComponent?: FC<{ children?: ReactNode; }>;
}

/**
 * Render a text string as a fragment with substrings marked up by components: e.g.:
 *
 * text: "Hello world, this is an example!"
 * fields: [
 *    {start: 6, end: 11: Component: (text) => (<span class="bright">{text}</span>)},
 *    {start: 24, end: 31: Component: (text) => (<b>{text}</b>)}
 * ]
 * output: (<>Hello <span class="bright">world</span>, this is an <b>example</b>!</>)
 *
 * The fields must be non-overlapping. Zero-length fields will be ignored
 */
export const TextFields = (props: TextFieldsProps): React.JSX.Element => {
    const { text, fields } = props;
    const DefaultComponent = props.DefaultComponent ?? Fragment;

    const sortedFields = useMemo(
        () => [...fields].sort((a, b) => a.range.start - b.range.start),
        [fields],
    );

    const boundaries = useShallowEqualsMemo(
        () => [
            0,
            ...sortedFields.flatMap(f => [f.range.start, f.range.end]),
            text.length,
        ],
        [sortedFields, text.length],
    );

    const elements = boundaries.windows(2)
        .map(w => text.substring(w[0], w[1]))
        .map<React.ReactNode>((s, i) => (
            <Fragment key={i}>
                {(i % 2 == 0) ?
                    (
                        <DefaultComponent>
                            <SensitiveText>{s}</SensitiveText>
                        </DefaultComponent>
                    ) :
                    createElement(fields[Math.floor(i / 2)].Component, { text: s })}
            </Fragment>
        ));

    return <>{elements}</>;
};

export default TextFields;
