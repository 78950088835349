import { useCallback } from "react";

/**
 * This hook is used to load more content when the user scrolls to the bottom of a container. There is
 * no guarantee that `loadMore()` will not be called multiple times, so it is up to the caller to ensure
 * that this does not cause any issues.
 *
 * @param endDivRef A reference to the div that marks the end of the content. This can simply be an empty
 *                  div, and should be at the very bottom of the container.
 * @param loadMore A function that loads more content. This should be from a React.useCallback().
 */
export default function useLoadMoreOnScroll(
    endDivRef: React.RefObject<HTMLDivElement>,
    loadMore: () => void,
) {
    const onScroll = useCallback((e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (endDivRef.current === null) {
            return;
        }

        if (
            e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
                endDivRef.current.getBoundingClientRect().top
        ) {
            loadMore();
        }
    }, [loadMore, endDivRef]);

    return onScroll;
}
