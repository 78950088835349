import * as d from "@/domain/domain";
import { type OrgOverview as v17_OrgOverview } from "@/domain/squads";
import type { Optional } from "@/misc/types";

interface v16_OrgOverview {
    id: d.OrgId;
    name: string;
}

type TranslateOrgsOverviewArg = [
    orgId: d.OrgId,
    oldOrgOverview: Optional<v16_OrgOverview>,
];
export const translateOrgsOverview = (
    [orgId, oldOrgOverview]: TranslateOrgsOverviewArg,
): Optional<[d.OrgId, v17_OrgOverview]> => {
    if (!oldOrgOverview) return;

    return [orgId, {
        id: orgId,
        name: oldOrgOverview.name,
        personal: false,
    }];
};

export type OrgOverview = v16_OrgOverview;
