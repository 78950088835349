import shallowEqual from "@/misc/shallowEqual";

export type CounterState<T> = T[];

export interface Counter<T> {
    add(state: CounterState<T>, ...values: T[]): CounterState<T>;
    remove(state: CounterState<T>, ...values: T[]): CounterState<T>;
    getInitialState: () => CounterState<T>;
}

/** @function Maintain a counter for the added/removed elements.
 * The methods to update the counter take the previous `CounterState` as an argument,
 * and return the new `CounterState` to the caller.
 * We do a `shallowEqual` check between the old/updated state, and only return
 * a new `CounterState` instance when it has been meaningfully updated
 * (i.e. not shallow equal).
 *
 * @returns a `Counter`.
 */
export function createCounter<T>(): Counter<T> {
    const c = new Map<T, number>();

    // Convert the count map into an array of its keys (which are in order from the Map)
    const getState = (): CounterState<T> => [...c.keys()];

    // Return the new state object if keys not shallow equal to last state
    const updateState = (state: CounterState<T>) => {
        const newState = getState();
        if (shallowEqual(state, newState)) {
            return state;
        }
        else {
            return newState;
        }
    };

    // Add value(s) to the counter and return the new state
    const add = (state: CounterState<T>, ...values: T[]): CounterState<T> => {
        values.forEach(v => {
            const current = c.get(v) ?? 0;
            c.set(v, current + 1);
        });

        return updateState(state);
    };

    // Remove value(s) from the counter (deleting if necessary) and return the new state.
    // Note this silently returns when removing a value not present in the counter.
    const remove = (state: CounterState<T>, ...values: T[]): CounterState<T> => {
        values.forEach(v => {
            const current = c.get(v);
            if (!current) {
                return state;
            }

            if (current < 2) {
                c.delete(v);
            }
            else {
                c.set(v, current - 1);
            }
        });

        return updateState(state);
    };

    return {
        getInitialState: () => ([] as CounterState<T>),
        add,
        remove,
    };
}
