export const queue = <T>(cb: (t: T) => Promise<void>) => {
    const q: T[] = [];

    let timer: ReturnType<typeof setTimeout> | null = null;

    const run = async () => {
        if (q.length === 0) {
            timer = null;
            return;
        }

        const t = q.shift();
        if (t) {
            // log.info("Running with", t);
            await cb(t);
        }

        timer = setTimeout(run, 0);
    };

    return ((...ts: T[]) => {
        // log.info("Adding", ts);
        q.push(...ts);

        if (!timer) {
            timer = setTimeout(run, 0);
        }
    });
};

export default queue;
