import {
    selectQueryAnswer,
    selectQueryQuestion,
    selectQuerySearchResults,
    selectQueryStatus,
} from "@/features/intel";
import useSelectorArgs from "@/hooks/useSelectorArgs";

import { useEffect, useMemo, useRef } from "react";

import { QueryId } from "@/domain/intel";
import purify from "dompurify";
import hljs from "highlight.js";
import { Marked, MarkedExtension } from "marked";
import alertPlugin from "marked-alert";
import footnotePlugin from "marked-footnote";
import { markedHighlight } from "marked-highlight";
import texPlugin from "marked-katex-extension";
import { QueryAnswerStatus } from "../../gen/proto/domain/domain_pb";
import { FeatureFlagged } from "./FeatureFlags";
import { SensitiveJSX } from "./gui/SensitiveText";
import SlideInPanel from "./SlideInPanel";

const dotHtml = "<span class='c-query__answer-dot'/>";

const wrapCodeWithLanguage = (code: string, language: string) => {
    return "<div class='c-query__answer--code-lang'>" + language + "</div>" + code;
};

hljs.configure({
    classPrefix: "code-highlight-",
    cssSelector: ".code-highlight",
});

const createParser = (
    plugins: MarkedExtension[],
) => {
    // syntax highlighting requires being treated 'specially'
    const md = new Marked(
        markedHighlight({
            emptyLangClass: "code-block",
            langPrefix: "code-block language-",
            highlight(code, lang, _info) {
                const language = hljs.getLanguage(lang) ? lang : "text";
                const highlighted = hljs.highlight(code, { language });
                return wrapCodeWithLanguage(highlighted.value, language);
            },
        }),
    );

    plugins.forEach(plugin => md.use(plugin));
    return md;
};

// We probably want to use a different markdown parser
// marked is for entire documents, and doesn't support incremental parsing.
// As we are streaming the answer, we should ideally use a parser that supports that.

// ! KaTeX needs CSS.
// ! It is available here, but it's *hellishly* long.
// ! It also makes everything fly off the screen! whee!
// ! https://cdn.jsdelivr.net/npm/katex@0.16.8/dist/katex.css

const plugins: MarkedExtension[] = [
    footnotePlugin(/* opts */),
    texPlugin(/* opts */),
    alertPlugin(/* opts */),
    // TODO consider these plugins:
    // https://www.npmjs.com/package/marked-extended-tables
    // https://www.npmjs.com/package/marked-smartypants
];

type QueryAnswerProps = {
    queryId: QueryId;
};

export function QueryAnswer(
    props: QueryAnswerProps,
): React.JSX.Element {
    const parser = useMemo(() =>
        createParser(
            plugins,
        ), []);

    const status = useSelectorArgs(selectQueryStatus, props.queryId) ??
        QueryAnswerStatus.UNSPECIFIED;

    const question = useSelectorArgs(selectQueryQuestion, props.queryId) ?? "";
    const searchResults = useSelectorArgs(selectQuerySearchResults, props.queryId) ?? "";
    const answer = useSelectorArgs(selectQueryAnswer, props.queryId);

    const answeringIndicator = status == QueryAnswerStatus.ANSWERING ? dotHtml : "";
    const markdownString = (answer ?? "") + answeringIndicator; // bad. dotHtml is visible in code blocks!

    const parsed = parser.parse(markdownString).toString();
    // ! WARNING: XSS RISK
    // ! PLEASE CHECK WHAT I'M DOING HERE
    // ! I am using dompurify to sanitise parsed HTML (as suggested by marked)
    // ! Is this 'enough'? I don't know. I'm a !(frontend || security) expert.

    // ref to scroll to bottom when the answer changes
    // this is clunky, and bad, but it works for now
    const bottomRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView();
        }
    }, [markdownString]);

    const inProgress = status !== QueryAnswerStatus.DONE && status !== QueryAnswerStatus.ERROR &&
        status !== QueryAnswerStatus.UNSPECIFIED;

    const sanitisedAnswer = status === QueryAnswerStatus.UNSPECIFIED ?
        (
            <div>
                No activity yet. Try writing a question in the search bar and pressing enter.
            </div>
        ) :
        (
            <div
                dangerouslySetInnerHTML={{
                    __html: purify.sanitize(parsed),
                }}
            />
        );

    const debugSearchResults = (
        <>
            {searchResults && (
                <details>
                    <summary>Search Results</summary>
                    <pre className="c-query__search-results">
            {searchResults}
                    </pre>
                </details>
            )}
        </>
    );

    return (
        <SlideInPanel
            title={statusText(status)}
            progress={inProgress
                ? <LengthBasedSpinner length={answer?.length ?? 0} />
                : <></>}
            openTrigger={status === QueryAnswerStatus.FORMULATING_SEARCH}
        >
            <div className="c-query__question">{question}</div>
            <div className="c-query__answer">
                <SensitiveJSX>
                    {sanitisedAnswer}
                    <FeatureFlagged flag="ai-querying-raw-search" match={true}>
                        {debugSearchResults}
                    </FeatureFlagged>
                </SensitiveJSX>
                <div ref={bottomRef} />
            </div>
        </SlideInPanel>
    );
}

// Some friends who tell you what the status means
function statusText(status: QueryAnswerStatus) {
    switch (status) {
        case QueryAnswerStatus.ERROR:
            return "😡 Error";
        case QueryAnswerStatus.SEARCHING:
            return "😶‍🌫️ Searching...";
        case QueryAnswerStatus.FORMULATING_SEARCH:
            return "🧐 Formulating search terms...";
        case QueryAnswerStatus.ANSWERING:
            return "🤔 Answering your question...";
        case QueryAnswerStatus.DONE:
            return "😇 Finished";
        default:
            return "😵‍💫 Unknown status";
    }
}

const LengthBasedSpinner = ({
    length = 0,
    ratio = 1.5,
    short = 200,
    medium = 400,
}) => {
    // Calculate rotation based on text length
    const rotation = length / ratio; // do not take remainder - angle must always increase.

    // Determine length category
    const getLengthClass = () => {
        if (length === 0) return "empty";
        if (length < short) return "short";
        if (length < medium) return "medium";
        return "long";
    };

    return (
        <div className="lenspinner-container">
            <div className="lenspinner-wrapper">
                <div className="lenspinner-track" />
                <div
                    className={`lenspinner-progress ${getLengthClass()}`}
                    style={{ transform: `rotate(${rotation}deg)` }}
                />
                <div className="lenspinner-counter">
                    <span className={getLengthClass()}>
                        {length}
                    </span>
                </div>
            </div>
        </div>
    );
};
