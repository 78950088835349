import { useMemo } from "react";

import { MarkupDelta, renderDeltaToTextForAutoCompleteQuery, sliceDelta } from "@/domain/delta";
import { useShallowEquals } from "@/hooks/useShallowEquals";
import { NumberRange, Optional } from "@/misc/types";
import { escapeForRegex } from "@/misc/utils";

export interface RichTextAutoCompleteQuery {
    trigger: string;
    text: string;
    range: NumberRange;
}

interface useRichTextAutoCompleteQueryOptions {
    maxLength?: number;
}

export const useRichTextAutoCompleteQuery = (
    triggers: string[],
    markup: MarkupDelta,
    offset: number,
    options: useRichTextAutoCompleteQueryOptions = {},
): Optional<RichTextAutoCompleteQuery> => {
    const maxLength = options.maxLength ?? 1000;

    const memoisedTriggers = useShallowEquals(triggers);

    const re = useMemo(() => {
        const mergedTriggers = memoisedTriggers
            .map(escapeForRegex)
            .map(t => `(?:${t})`)
            .join("|");

        // (?<=^|\\s|\\p{Ps}|\\p{Pe}) : Positive lookbehind for start of string, whitespace, or opening/closing punctuation
        // (${mergedTriggers})        : Any of the given trigger strings
        // ([^\\s\\p{P}]*)$           : Any number of non-whitespace non-punctuation characters until the end of the string
        return new RegExp(`(?<=^|\\s|\\p{Ps}|\\p{Pe})(${mergedTriggers})([^\\s\\p{P}]*)$`, "u");
    }, [memoisedTriggers]);

    return useMemo(() => {
        const windowStart = Math.max(0, offset - maxLength);
        const window = sliceDelta(markup, offset - maxLength, offset);
        const windowText = renderDeltaToTextForAutoCompleteQuery(window);

        const match = re.exec(windowText) ?? undefined;
        return match && {
            trigger: match[1],
            text: match[2],
            range: {
                start: windowStart + match.index,
                end: windowStart + match.index + match[0].length,
            },
        };
    }, [re, markup, offset, maxLength]);
};

export default useRichTextAutoCompleteQuery;
