import { useCallback, useRef, useState } from "react";

import {
    DeviceGroup,
    DeviceKind,
    DeviceKind_Type,
    getDeviceName,
    MediaGroup,
    MediaGroup_Type,
    mediaGroupDeviceKinds,
    MediaToggle,
} from "@/domain/mediaDevices";
import {
    DevicePermissions,
    selectAllDeviceIds,
    selectCurrentDevice,
    selectDeviceByIdAndKind,
    selectDevicePermissions,
    setCurrentDevice,
} from "@/features/mediaDevices";
import useEnumeratedDevices from "@/hooks/media/useEnumeratedDevices";
import useOutsideClick from "@/hooks/useOutsideClick";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { isNativePlatform } from "@/misc/capacitor";
import { useAppDispatch } from "@/store/redux";
import classNames from "classnames";

interface ControlDetails {
    btnClassName: string;
    btnTypeClassName: string;
    name: string;
    title: string;
}

function getControlDetails(active: boolean, mediaGroup: MediaGroup_Type): ControlDetails {
    switch (mediaGroup) {
        case MediaGroup.Audio:
            return {
                btnClassName: `c-btn-call--audio`,
                btnTypeClassName: "audio",
                name: "Microphone",
                title: active ? "Mute" : "Unmute",
            };
        case MediaGroup.Video:
            return {
                btnClassName: `c-btn-call--camera`,
                btnTypeClassName: "video",
                name: "Camera",
                title: `Turn camera ${active ? "off" : "on"}`,
            };
        case MediaGroup.Display:
            return {
                btnClassName: `c-btn-call--share`,
                btnTypeClassName: "share",
                name: "Share",
                title: active ? "Stop sharing content" : "Share content",
            };
    }
}

export type MediaDeviceControlProps = {
    onToggle?: (isEnabling: boolean) => void;
    control: MediaToggle;
    mediaGroup: MediaGroup_Type;
};

enum MediaDeviceToggleSize {
    Default,
    Compact,
}

interface MediaDeviceToggleProps extends MediaDeviceControlProps {
    size: MediaDeviceToggleSize;
}

export default function MediaDeviceControl(props: MediaDeviceControlProps): React.JSX.Element {
    const { control, mediaGroup } = props;

    const [expanded, setExpanded] = useState(false);

    // Close selector if clicked outside expanded div
    const expandButtonRef = useRef<HTMLButtonElement>(null);
    const closeDropdown = useCallback((target: EventTarget | null) => {
        // Filter out clicks on the button which shows the dropdown itself.
        if (expandButtonRef && expandButtonRef.current?.contains(target as Node)) {
            return;
        }
        setExpanded(false);
    }, [setExpanded]);

    const { name } = getControlDetails(control.active, mediaGroup);
    const btnType = getControlDetails(control.active, mediaGroup)?.btnTypeClassName || "";

    const toggleContainerClassName = classNames(
        `c-popover__button c-popover__button--${btnType}`,
        expanded && "is-selected",
        control.active && "is-active",
    );

    return (
        <div className="c-popover">
            <div className={toggleContainerClassName}>
                <MediaDeviceToggle {...props} size={MediaDeviceToggleSize.Default} />
                {btnType !== "share" && (
                    <button
                        className="c-btn-call c-btn-call--options"
                        title={`${name} options`}
                        onClick={() => setExpanded(e => !e)}
                        ref={expandButtonRef}
                    >
                        {`${name} options`}
                    </button>
                )}
            </div>
            {expanded && mediaGroup !== MediaGroup.Display && (
                <MediaDeviceControlDropdown
                    closeDropdown={closeDropdown}
                    deviceGroup={mediaGroup}
                />
            )}
        </div>
    );
}

export function MediaDeviceControlCompact(props: MediaDeviceControlProps): React.JSX.Element {
    return <MediaDeviceToggle {...props} size={MediaDeviceToggleSize.Compact} />;
}

function MediaDeviceToggle(props: MediaDeviceToggleProps): React.JSX.Element {
    const { control, mediaGroup, size, onToggle } = props;

    const { btnClassName, name, title } = getControlDetails(control.active, mediaGroup);

    const toggleButtonClassName = classNames(
        "c-btn-call",
        btnClassName,
        {
            "c-btn-call--no-options": size === MediaDeviceToggleSize.Compact,
            "is-active": control.active,
            "is-disabled": !control.active,
        },
    );

    const toggle = useCallback(() => {
        const isEnabling = !control.active;
        control.toggle();
        onToggle?.(isEnabling);
    }, [control, onToggle]);

    return (
        <button
            className={toggleButtonClassName}
            title={title}
            onClick={toggle}
        >
            {name}
        </button>
    );
}

interface MediaDeviceControlDropdownProps {
    closeDropdown: (target: EventTarget | null) => void;
    deviceGroup: DeviceGroup;
}

function MediaDeviceControlDropdown(props: MediaDeviceControlDropdownProps): React.JSX.Element {
    const { closeDropdown, deviceGroup } = props;

    const [dropdownDivRef] = useOutsideClick<HTMLDivElement>(closeDropdown);

    const deviceKinds = mediaGroupDeviceKinds[deviceGroup];

    useEnumeratedDevices(deviceGroup);

    return (
        <div className="c-popover__content c-popover-content is-visible" ref={dropdownDivRef}>
            {deviceKinds.map((kind, i) => (
                <MediaDeviceDropdown
                    deviceKind={kind}
                    showSeparator={i !== 0}
                    key={kind}
                />
            ))}
        </div>
    );
}

interface MediaDeviceDropdownProps {
    deviceKind: DeviceKind_Type;
    showSeparator: boolean;
}

function MediaDeviceDropdown(props: MediaDeviceDropdownProps): React.JSX.Element {
    const { deviceKind, showSeparator } = props;
    const dispatch = useAppDispatch();

    const permissions = useSelectorArgs(selectDevicePermissions, deviceKind);
    const deviceIds = useSelectorArgs(selectAllDeviceIds, deviceKind);

    const selectedDevice = useSelectorArgs(selectCurrentDevice, deviceKind);

    // Callback to select a device, only when we have permissions
    // (i.e. when our list of devices is up to date).
    const setDeviceSelected = useCallback(
        (id: string) =>
            permissions === DevicePermissions.Granted && dispatch(setCurrentDevice(deviceKind, id)),
        [deviceKind, dispatch, permissions],
    );

    let deviceName = getDeviceName(deviceKind);

    // If we are running on a native platform, do not show the speaker selection
    // as choosing audio output is not exposed by the operating system.
    // Also, rename the microphone selector as it is the only audio selector now.
    if (isNativePlatform) {
        if (deviceKind === DeviceKind.AudioOutput) {
            return <></>;
        }
        else if (deviceKind === DeviceKind.AudioInput) {
            deviceName = "Audio device";
        }
    }

    const className = `c-popover-content__section ${
        showSeparator ? "c-popover-content--divider" : ""
    }`;
    return (
        <>
            <div className={className}>{deviceName}</div>
            {deviceIds.map(dId => (
                <DeviceRow
                    id={dId}
                    key={dId}
                    isSelected={dId === selectedDevice}
                    kind={deviceKind}
                    setDeviceSelected={setDeviceSelected}
                />
            ))}
        </>
    );
}

interface DeviceRowProps {
    id: string;
    isSelected: boolean;
    kind: DeviceKind_Type;
    setDeviceSelected: (id: string) => void;
}

function DeviceRow(props: DeviceRowProps): React.JSX.Element {
    const { id, isSelected, kind, setDeviceSelected } = props;

    const device = useSelectorArgs(selectDeviceByIdAndKind, id, kind);
    if (!device) {
        return <></>;
    }

    return (
        <button
            className={`c-popover-content__item ${isSelected ? "is-selected" : ""}`}
            onClick={() => setDeviceSelected(id)}
        >
            {device.label || "Unknown device"}
        </button>
    );
}
