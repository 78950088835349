import SensitiveText from "@/components/gui/SensitiveText";
import TimeAgoCatchup from "@/components/gui/TimeAgoCatchup";
import * as d from "@/domain/domain";
import { selectChannelIdByBondId } from "@/features/bonds";
import { selectDeltaKnowledgeBond, updateStagedSequenceNumberIfGreater } from "@/features/channels";
import {
    selectShowSidebarSummary,
    selectSidebarSummaryLastReadSequenceNumber,
} from "@/features/meta";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { Optional } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import { useCallback } from "react";

function MarkAsReadButton(
    { channelId, isDisabled }: { channelId: Optional<d.ChannelId>; isDisabled: boolean; },
): React.JSX.Element {
    const knowledge = useSelectorArgs(selectDeltaKnowledgeBond, channelId);
    const dispatch = useAppDispatch();

    const markAsRead = useCallback(() => {
        if (!channelId || knowledge?.lastSummarisedSeq === undefined) {
            log.error(`channel id or knowledge is undefined`);
            return;
        }
        dispatch(
            updateStagedSequenceNumberIfGreater({
                channelId: channelId,
                sequenceNumber: knowledge.lastSummarisedSeq,
            }),
        );
    }, [channelId, knowledge?.lastSummarisedSeq, dispatch]);

    return (
        <button
            className="c-btn-solid c-btn-solid--read"
            disabled={isDisabled}
            onClick={isDisabled ? () => {} : markAsRead}
        >
            <div className="c-btn-solid__icon c-btn-solid__icon--read"></div>
            Mark as read
        </button>
    );
}

function SummaryListContent(
    { summaryItems }: { summaryItems: string[]; },
): React.JSX.Element {
    if (summaryItems.length === 0) return <></>;
    return (
        <div className="c-summary__generated">
            <ul>
                {summaryItems.map((item, idx) => {
                    return (
                        <li key={idx}>
                            <SensitiveText>{item}</SensitiveText>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export interface BondSidebarSummaryViewProps {
    bondId: d.BondId;
}

export function BondSidebarSummaryView(
    { bondId }: BondSidebarSummaryViewProps,
): React.JSX.Element {
    const channelId = useSelectorArgs(selectChannelIdByBondId(bondId));
    const knowledge = useSelectorArgs(selectDeltaKnowledgeBond, channelId);
    const isMobile = isMobileBrowser();
    const showSidebarSummary = useAppSelector(selectShowSidebarSummary);
    const currentLastReadSequenceNumber = useAppSelector(
        selectSidebarSummaryLastReadSequenceNumber,
    );

    if (isMobile || !showSidebarSummary) return <></>;
    return (currentLastReadSequenceNumber ?? 0) === knowledge?.lastReadSeqNumber ? (
        <div
            className={"c-sidebar c-summary c-summary--open"}
        >
            <div className="c-sidebar__content c-sidebar__content--summary">
                <div className="c-summary__content">
                    {knowledge.deltaSummaryBond.length !== 0 && (
                        <TimeAgoCatchup
                            describeLive={false}
                            from={knowledge.firstNewMessageTs}
                            liveSessionStartNum={knowledge.liveSessionStartedAt}
                        />
                    )}
                    <SummaryListContent summaryItems={knowledge.deltaSummaryBond} />
                    {knowledge.deltaSummaryBondLive.length !== 0 && (
                        <TimeAgoCatchup
                            describeLive={true}
                            from={knowledge.firstNewMessageTs}
                            liveSessionStartNum={knowledge.liveSessionStartedAt}
                        />
                    )}
                    <SummaryListContent summaryItems={knowledge.deltaSummaryBondLive} />
                </div>
                <div className="c-summary__footer">
                    <MarkAsReadButton channelId={channelId} isDisabled={false} />
                </div>
            </div>
        </div>
    ) : (
        <div
            className={"c-sidebar c-summary c-summary--open"}
        >
            <div className="c-sidebar__content c-sidebar__content--summary">
                <div className="c-summary__content">
                    <div className="c-spinner">
                        <div className="c-spinner__inner"></div>
                    </div>
                </div>
                <div className="c-summary__footer">
                    <MarkAsReadButton channelId={channelId} isDisabled={true} />
                </div>
            </div>
        </div>
    );
}
