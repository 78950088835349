import { Duration, DurationLike } from "luxon";
import { useEffect, useState } from "react";

import { addIdleSubscriber, removeIdleSubscriber, selectIdleSince } from "@/features/meta";
import useLocalDispatch from "@/hooks/useLocalDispatch";
import useTimerTarget from "@/hooks/useTimerTarget";
import { useAppSelector } from "@/store/redux";

/** Wrapper around useIdleTimer() such that we only have at most one instantiated
 * useIdleTimer(). It's quite a heavy-weight library so it seems worth managing it.
 *
 * Requires <IdleTracker /> to be rendered somewhere in the app.
 */
export default function useIdle(timeout: DurationLike) {
    const [isIdle, setIsIdle] = useState(false);
    const { nowMs, setTarget } = useTimerTarget();
    const idleSince = useAppSelector(selectIdleSince);
    const localDispatch = useLocalDispatch();

    const timeoutMs = Duration.fromDurationLike(timeout).valueOf();

    useEffect(() => {
        const { payload } = localDispatch(addIdleSubscriber(timeoutMs));
        return () => {
            localDispatch(removeIdleSubscriber(payload.id));
        };
    }, [localDispatch, timeoutMs]);

    useEffect(() => {
        if (!idleSince) {
            setIsIdle(false);
            return;
        }

        const next = idleSince + timeoutMs;

        if (next <= nowMs) {
            setIsIdle(true);
        }
        else {
            setIsIdle(false);
            setTarget(next);
        }
    }, [idleSince, nowMs, timeoutMs, setTarget, setIsIdle]);

    return isIdle;
}
