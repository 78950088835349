import { DependencyList } from "react";

import * as d from "@/domain/domain";
import { SelectorPair } from "@/features/selectors";
import { useInterestedUsers } from "@/hooks/interest/useInterest";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useShallowEquals } from "@/hooks/useShallowEquals";
import { Optional } from "@/misc/types";

/** @function
 * Use a user selector pair function with `useSelector`.
 * This will implicitly register interest in the users you are attempting to select.
 *
 * Example usage:
 * `const observerIds = useSortedUsers(selectSortedBondObserverIdsPair, bondId);`
 *
 * This is very similar internally to `useSelectorArgs`.
 *
 * @param pair the selector pair,
 * @param deps the further arguments to the selector pair
 * @returns {d.UserId[]} the result of calling `sorted: (state: RootState, ...deps) => d.UserId[]`
 */
export default function useSortedUsers<Deps extends DependencyList = DependencyList>(
    [allSelector, sortedSelector]: SelectorPair<Deps, Optional<d.UserId[]>, Optional<d.UserId[]>>,
    ...deps: Deps
): d.UserId[] {
    const allIds = useSelectorArgs(allSelector, ...deps);
    useInterestedUsers(allIds);

    const sortedIds = useSelectorArgs(sortedSelector, ...deps);
    return useShallowEquals(sortedIds ?? []);
}
