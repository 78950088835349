import { useCallback, useEffect, useMemo, useRef } from "react";

import * as d from "@/domain/domain";
import {
    AsyncMediaPublishFunc,
    inputDevices,
    MediaInputKind,
    MediaPublisherMap,
    MediaToggleMap,
} from "@/domain/mediaDevices";
import { isTrackActive, Participant } from "@/domain/rtc";
import { selectCurrentUserId } from "@/features/auth";
import {
    CallActionStatus,
    selectCallJoinStatus,
    selectCallStartStatus,
    selectCurrentParticipantId,
    setCallStartStatus,
    setCurrentParticipantId,
} from "@/features/calls";
import { useMediaTracks } from "@/hooks/media/useMediaTrack";
import { useRtcClient } from "@/hooks/rtc/useRtcClient";
import { useRtcSessionStart } from "@/hooks/rtc/useRtcSessionStart";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import log from "@/misc/log";
import { Optional } from "@/misc/types";
import { objectKeyedBy, uuidv4 } from "@/misc/utils";
import { useAppDispatch, useAppSelector } from "@/store/redux";

// Mock self participant ID when we don't have a real participant ID.
// This means we can show the user's self-view while we wait to start & join a call.
const dummyCurrentParticipantId = d.fromRawRtcParticipantId(uuidv4());

export interface UseRtcClientWithMediaControlsProps {
    bondId: Optional<d.BondId>;
    callId: Optional<d.CallId>;
    onLeave?: () => void;
}

export default function useRtcClientWithMediaControls(props: UseRtcClientWithMediaControlsProps) {
    const { bondId, callId, onLeave } = props;

    const dispatch = useAppDispatch();

    const userId = useAppSelector(selectCurrentUserId);
    const callStartStatus = useAppSelector(selectCallStartStatus);
    const callJoinStatus = useAppSelector(selectCallJoinStatus);

    const currentParticipantId = useAppSelector(selectCurrentParticipantId);

    // Maintain a ref to a function (which is defined below) to disable inactive
    // (i.e. enabled but muted) tracks upon leaving the session. This stops us
    // from starting a new session unless we've explicitly unmuted the track.
    // We need a ref here as there is a cyclic dependency between resetting tracks
    // in useRtcClient and publishing tracks in useMediaTracks.
    const resetInactiveTracks = useRef<() => void>();
    const onLeaveSession = useCallback(() => {
        resetInactiveTracks.current?.();
        dispatch(setCurrentParticipantId());

        onLeave?.();
    }, [onLeave, dispatch]);

    const { leaveSession, muteTrack, otherParticipants, publishers, updateLocationConfig } =
        useRtcClient({
            callId,
            onLeave: onLeaveSession,
        });

    // Start session in bond with backoff + retries
    const startSession = useRtcSessionStart({ bondId });

    // Publish media into an ongoing call or use the action supplied in props
    const publishOrFallback = useCallback(
        (publish: AsyncMediaPublishFunc): AsyncMediaPublishFunc => {
            return async stream => {
                // Not already started a call and not aware of an existing call
                if (callStartStatus === CallActionStatus.None && !callId) {
                    log.debug("Publish fallback - starting session...");
                    startSession();
                }
                // Only publish once we've successfully joined
                else if (callJoinStatus === CallActionStatus.Completed) {
                    log.debug("Publishing media as joined call...");
                    await publish(stream);
                }
            };
        },
        [callStartStatus, callJoinStatus, callId, startSession],
    );

    const publishersWithFallback: MediaPublisherMap = useShallowEqualsMemo(
        () =>
            objectKeyedBy(
                inputDevices,
                track => ({
                    ...publishers[track],
                    publish: publishOrFallback(s => publishers[track].publish(s)),
                }),
            ),
        [publishOrFallback, publishers],
    );

    const selfTracks = useMediaTracks({
        endOnToggle: callStartStatus !== CallActionStatus.Completed,
        muteTrack,
        publishers: publishersWithFallback,
    });

    // Abort starting call when all tracks are disabled
    useEffect(() => {
        if (
            callStartStatus === CallActionStatus.None ||
            callStartStatus === CallActionStatus.Completed
        ) {
            return;
        }

        const tracks = inputDevices.map(d => selfTracks(d).track);
        if (tracks.some(t => t.enabled)) {
            return;
        }

        dispatch(setCallStartStatus(CallActionStatus.None));
    }, [callStartStatus, dispatch, selfTracks]);

    // Implement function defined above to disable inactive tracks on leaving session.
    useEffect(() => {
        resetInactiveTracks.current = () =>
            inputDevices.forEach(d => selfTracks(d).resetInactiveTrack());
    }, [selfTracks]);

    const selfParticipant: Optional<Participant> = useMemo(() => {
        return userId && {
            id: currentParticipantId ?? dummyCurrentParticipantId,
            userId,
            audioTrack: selfTracks(MediaInputKind.AudioInput).track,
            displayTrack: selfTracks(MediaInputKind.Display).track,
            videoTrack: selfTracks(MediaInputKind.VideoInput).track,
        };
    }, [selfTracks, userId, currentParticipantId]);

    const callParticipants = useShallowEqualsMemo(() => {
        return selfParticipant ? [selfParticipant, ...otherParticipants] : otherParticipants;
    }, [selfParticipant, otherParticipants]);

    const mediaControls: MediaToggleMap = useShallowEqualsMemo(
        () =>
            objectKeyedBy(inputDevices, input => ({
                active: isTrackActive(selfTracks(input).track),
                toggle: selfTracks(input).toggleTrack,
                disable: selfTracks(input).disableTrack,
            })),
        [selfTracks],
    );

    const leaveAndDisableAll = useCallback(() => {
        inputDevices.forEach(kind => mediaControls[kind].disable());
        leaveSession();
    }, [leaveSession, mediaControls]);

    return useMemo(() => ({
        leaveSession: leaveAndDisableAll,
        selfParticipant,
        otherParticipants,
        callParticipants,
        mediaControls,
        updateLocationConfig,
    }), [
        leaveAndDisableAll,
        selfParticipant,
        otherParticipants,
        callParticipants,
        mediaControls,
        updateLocationConfig,
    ]);
}
