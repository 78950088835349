// Wrapper around text that ensures it will not be reported to PostHog.
// This only needs to be applied to elements the user clicks on, and for text that comes
// from the database/an RPC, not anything static.
export default function SensitiveText({ children }: { children: string; }): React.JSX.Element {
    return <span className="ph-no-capture">{children}</span>;
}

// Wrapper around JSX that ensures it will not be reported to PostHog.
// This only needs to be applied to elements the user clicks on, and for text that comes
// from the database/an RPC, not anything static.
// You should only use this for sensitive elements that are not text. e.g. parsed markdown.
export function SensitiveJSX({ children }: { children: React.ReactNode; }): React.JSX.Element {
    return <span className="ph-no-capture">{children}</span>;
}
