import { useEffect, useRef } from "react";

import { useVolume } from "@/hooks/media/useVolume";

const VOLUME_SHIFT = 50;

interface IVolumeMeterProps {
    enabled?: boolean;
    stream?: MediaStream;
}

export default function VolumeMeter(props?: IVolumeMeterProps): React.JSX.Element {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const volume = useVolume({
        enabled: props?.enabled ?? false,
        stream: props?.stream,
    });

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }

        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        if (!context) {
            return;
        }
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.fillStyle = "#434A51";
        context.fillRect(0, 0, canvas.width, canvas.height);

        if (volume) {
            // Squish into a [0, 1] range and render bar of that size
            const volumeStrength = (volume + VOLUME_SHIFT) / VOLUME_SHIFT;
            context.fillStyle = "#6a87d0";
            context.fillRect(0, 0, canvas.width * Math.min(volumeStrength, 1.0), canvas.height);
        }
    }, [volume]);

    return (
        <div className="c-settings-meter">
            <canvas ref={canvasRef} className="c-settings-meter__bar" />
            <svg
                className="c-settings-meter__mask"
                preserveAspectRatio="none"
            >
                <defs>
                    <clipPath id="meterClip">
                        <rect width="7.69%" height="12" x="0%" rx="8" ry="8" />
                        <rect width="7.69%" height="12" x="10.26%" rx="8" ry="8" />
                        <rect width="7.69%" height="12" x="20.52%" rx="8" ry="8" />
                        <rect width="7.69%" height="12" x="30.78%" rx="8" ry="8" />
                        <rect width="7.69%" height="12" x="41.04%" rx="8" ry="8" />
                        <rect width="7.69%" height="12" x="51.30%" rx="8" ry="8" />
                        <rect width="7.69%" height="12" x="61.56%" rx="8" ry="8" />
                        <rect width="7.69%" height="12" x="71.82%" rx="8" ry="8" />
                        <rect width="7.69%" height="12" x="82.08%" rx="8" ry="8" />
                        <rect width="7.69%" height="12" x="92.34%" rx="8" ry="8" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}
