import { RefCallback, useCallback, useEffect, useState } from "react";

import { checkMediaDeviceExists, DeviceKind } from "@/domain/mediaDevices";
import { Track } from "@/domain/rtc";
import { selectCurrentDevice, selectPreferredDevice } from "@/features/mediaDevices";
import useMediaElementRef from "@/hooks/media/useMediaElementRef";
import useRefCallback from "@/hooks/useRefCallback";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { Optional } from "@/misc/types";

type HTMLAudioElementWithSink = HTMLAudioElement & ({ setSinkId?: (deviceId: string) => void; });

interface useAudioSinkRefProps {
    track?: Track;
}
export default function useAudioSinkRef(
    props: useAudioSinkRefProps,
): RefCallback<HTMLAudioElementWithSink> {
    const { track } = props;

    const currentSpeakerId = useSelectorArgs(selectCurrentDevice, DeviceKind.AudioOutput);
    const preferredSpeakerId = useSelectorArgs(selectPreferredDevice, DeviceKind.AudioOutput);

    // Preferred speaker device might've been disconnected, so check it still
    // exists before setting the sink ID.
    const [existingPreferredSpeakerId, setExistingPreferredSpeakerId] = useState<Optional<string>>(
        undefined,
    );
    useEffect(() => {
        // Check if media device exists when we only have a preferred device
        if (currentSpeakerId || !preferredSpeakerId) {
            return;
        }

        // Track whether useEffect has exited by the time we've checked the device exists
        let hasCleanedUp = false;

        checkMediaDeviceExists(DeviceKind.AudioOutput, preferredSpeakerId).then(deviceExists => {
            if (deviceExists && !hasCleanedUp) {
                setExistingPreferredSpeakerId(preferredSpeakerId);
            }
        });

        return () => {
            hasCleanedUp = true;
        };
    }, [currentSpeakerId, preferredSpeakerId]);

    const audioRefCb = useMediaElementRef<HTMLAudioElementWithSink>({ track });

    const setSinkId = useCallback((node: HTMLAudioElementWithSink) => {
        if (currentSpeakerId) {
            // `setSinkId` isn't supported in all environments
            node.setSinkId?.(currentSpeakerId);
        }
        else if (existingPreferredSpeakerId) {
            node.setSinkId?.(existingPreferredSpeakerId);
        }
    }, [currentSpeakerId, existingPreferredSpeakerId]);

    return useRefCallback(setSinkId, { parentRefCb: audioRefCb });
}
