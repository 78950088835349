// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file intel/intel.proto (package intel, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { ChannelId, QueryAnswerStatus, UserId } from "../domain/domain_pb.js";

/**
 * @generated from message intel.SuggestBondTitleRequest
 */
export const SuggestBondTitleRequest = proto3.makeMessageType(
  "intel.SuggestBondTitleRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "bond_content_draft", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message intel.SuggestBondTitleResponse
 */
export const SuggestBondTitleResponse = proto3.makeMessageType(
  "intel.SuggestBondTitleResponse",
  () => [
    { no: 1, name: "bond_title_suggestion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message intel.GetDeltaSummaryBondRequest
 */
export const GetDeltaSummaryBondRequest = proto3.makeMessageType(
  "intel.GetDeltaSummaryBondRequest",
  () => [
    { no: 1, name: "last_read_sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "channel_id", kind: "message", T: ChannelId },
    { no: 3, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message intel.GetDeltaSummaryBondResponse
 */
export const GetDeltaSummaryBondResponse = proto3.makeMessageType(
  "intel.GetDeltaSummaryBondResponse",
  () => [
    { no: 1, name: "max_message_sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "delta_summary_bond", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "delta_summary_bond_live", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "first_new_message_ts", kind: "message", T: Timestamp },
    { no: 5, name: "live_session_started_at_ts", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message intel.QueryRequest
 */
export const QueryRequest = proto3.makeMessageType(
  "intel.QueryRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message intel.QueryResponse
 */
export const QueryResponse = proto3.makeMessageType(
  "intel.QueryResponse",
  () => [
    { no: 1, name: "answer_delta", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "result" },
    { no: 2, name: "progress_status", kind: "enum", T: proto3.getEnumType(QueryAnswerStatus), oneof: "result" },
    { no: 3, name: "search_results", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "result" },
  ],
);

