import { BlobCredentials, BlobMetadata } from "@/domain/blobs";
import * as d from "@/domain/domain";
import { type AvatarBlob as v22_AvatarBlob } from "@/domain/users";
import type { Optional } from "@/misc/types";

interface v21_AvatarBlob {
    blobId: d.BlobId;
    credentials: BlobCredentials;
    metadata: BlobMetadata;
}

type TranslateAvatarBlobArg = [
    blobId: d.BlobId,
    oldAvatarBlob: Optional<v21_AvatarBlob>,
];
export const translateAvatarBlob = (
    [blobId, oldAvatarBlob]: TranslateAvatarBlobArg,
): Optional<[d.BlobId, v22_AvatarBlob]> => {
    if (!oldAvatarBlob) return;

    // Strip the query string from the URL
    const publicUrl = oldAvatarBlob.credentials.url.split("?")[0] ?? "";

    return [blobId, {
        blobId: oldAvatarBlob.blobId,
        metadata: oldAvatarBlob.metadata,
        publicUrl: publicUrl,
    }];
};

export type AvatarBlob = v21_AvatarBlob;
