import { captureException } from "@sentry/react";
import { deleteDB, DeleteDBCallbacks, openDB, OpenDBCallbacks } from "idb";

import type { UserId } from "@/domain/domain";
import log from "@/misc/log";
import { promiseWithResolvers } from "@/misc/promises";
import { isString, Optional, TypedKeys } from "@/misc/types";
import { separate } from "@/misc/utils";
import { getPersistorMap } from "@/persist/map";
import { removeInvalidBrowserStores } from "@/persist/persist";
import { indexedDbAvailable } from "@/persist/shared";
import { isIdbStore, storeIsValidForVersion } from "@/persist/store";
import type {
    AnyIdbStoreUpdate,
    Connection,
    IdbStore,
    PersistenceMetadata,
    PersistenceUpdate,
    RWTransaction,
} from "@/persist/types";
import { clearKey } from "@/persist/updates";

const dbNamePrefix = "avos";

const getIdbStores = (): IdbStore[] =>
    Object
        .values(getPersistorMap())
        .flatMap(s => Object.values(s.stores ?? {}))
        .filter(isIdbStore);

let dbPromise: Promise<Connection | null> | null = null;

const deleteAvosDB = async () => {
    const callbacks: DeleteDBCallbacks = {
        blocked: (currentVersion, event) => {
            log.warn(`DB removal blocked`, { currentVersion, event });
        },
    };

    const databases = await indexedDB.databases();
    databases
        .map(db => db.name)
        .filter((dbName): dbName is string => !!dbName?.startsWith(dbNamePrefix))
        .forEach(dbName => {
            deleteDB(dbName, callbacks);
        });
};

export const idb = async (
    dbUser: Optional<UserId>,
    { currentDBVersion, oldestMigratableVersion }: PersistenceMetadata,
    upgradeBlockedAction?: () => void,
) => {
    const stores = getIdbStores();

    if (!indexedDbAvailable() || !dbUser) {
        return null;
    }

    if (dbPromise) {
        return dbPromise;
    }

    const callbacks: OpenDBCallbacks<unknown> = {
        upgrade: (db, oldVersion, newVersion, tx) => {
            log.info(`IDB upgrade ${oldVersion} => ${newVersion}`, {
                oldObjectStores: db.objectStoreNames,
            });

            const thisVersion = newVersion ?? currentDBVersion;
            const canMigrate = oldVersion >= oldestMigratableVersion;
            const initialCreation = oldVersion === 0;

            // When migrating from a version that is too old, delete all the
            // object stores we find in the IDB. The next step will
            // create all the object stores we need.
            if (!canMigrate && !initialCreation) {
                log.info(
                    `Existing DB version too old (${oldVersion}), deleting all object stores`,
                );
                Object.values(db.objectStoreNames).forEach(n => {
                    db.deleteObjectStore(n);
                });
            }

            const [currentStores, oldStores] = separate(
                storeIsValidForVersion(thisVersion),
                stores,
            );

            // Create any object stores that don't already exist in the IDB.
            currentStores
                .map(({ name }) => name)
                .filter(name => !db.objectStoreNames.contains(name))
                .forEach(name => db.createObjectStore(name));

            if (initialCreation) return;

            if (!canMigrate) {
                log.info(
                    `DB recreated without migration (${oldVersion} => ${thisVersion})`,
                );
                return;
            }

            doMigration(tx, oldVersion)
                .then(() => {
                    log.info(`DB migration (${oldVersion} => ${thisVersion}) complete`);
                }, e => {
                    log.error(`DB migration (${oldVersion} => ${thisVersion}) failed`, e);
                }).finally(() => {
                    oldStores
                        .map(({ name }) => name)
                        .filter(name => db.objectStoreNames.contains(name))
                        .forEach(name => {
                            log.info(`Deleting old object store ${name}`);
                            db.deleteObjectStore(name);
                        });
                });
        },
        blocking: (
            currentVersion: number,
            blockedVersion: number,
            event: IDBVersionChangeEvent,
        ) => {
            log.warn(`Blocking DB upgrade`, currentVersion, blockedVersion, event);
            upgradeBlockedAction?.();
        },
    };

    const { promise, resolve } = promiseWithResolvers<Connection | null>();

    dbPromise = promise;

    while (true) {
        try {
            const dbName = makeDbName(dbUser);
            const db = await openDB(dbName, currentDBVersion, callbacks);
            resolve(db);
        }
        catch (e) {
            if (e instanceof DOMException && e.name === "VersionError") {
                log.warn(`Downgrading DB`);
                await deleteAvosDB();
                continue;
            }

            log.error(`Failed to open DB`, e);
            resolve(null);
        }

        break;
    }

    return dbPromise;
};

export const purge = async () => {
    await closeConnection();

    await deleteAvosDB();
    log.info(`Removed IndexedDB`);
};

export const closeConnection = async (): Promise<boolean> => {
    const p = dbPromise;
    if (!p) return false;

    dbPromise = null;
    const conn = await p;
    conn?.close();
    return true;
};

/**  For a given about-to-be-transaction, determine all the objectStore
 * names that we will be touching.
 */
const getTxStoreNames = (
    pd: AnyIdbStoreUpdate[],
): string[] => [...pd.map(update => update[0].name).toSet()];

export const idbUpdateParser =
    (tx: RWTransaction) => ([{ name: storeName }, id, data]: PersistenceUpdate) => {
        const s = tx.objectStore(storeName);

        if (!isString(id)) {
            throw new Error("id must be a string");
        }

        if (data !== undefined) {
            return s.put(data, id);
        }
        else {
            return s.delete(id);
        }
    };

export const idbCommit = async (conn: Connection, updates: AnyIdbStoreUpdate[]) => {
    const stores = getTxStoreNames(updates);
    if (stores.length === 0) return;

    const tx = conn.transaction(stores, "readwrite");
    const clearPromises: Promise<void>[] = [];

    const genericParser = idbUpdateParser(tx);

    const parseUpdate = (update: PersistenceUpdate) => {
        const [{ name }, id, _] = update;

        if (id == clearKey) {
            const store = tx.objectStore(name);
            const clearPromise = store.clear();
            clearPromises.push(clearPromise);
            return clearPromise;
        }

        return genericParser(update);
    };

    const promises = updates.map(parseUpdate);

    try {
        await Promise.all(clearPromises);
        await Promise.all(promises);
        tx.commit();
        await tx.done;
    }
    catch (e) {
        log.error(`Persistence transaction failed`, e);
        captureException(e, { level: "fatal" });
    }
};

const doMigration = async <T>(tx: RWTransaction, oldVersion: number) => {
    const pm = getPersistorMap<T>();
    const keys = TypedKeys(pm);

    log.info(`About to migrate`);

    const promises = keys.flatMap(name => {
        const migrate = pm[name].migrate;
        if (!migrate) return [];
        return [[name, migrate(tx, oldVersion)]];
    });

    const settled = await Promise.allSettled(promises.map(([_, p]) => p));

    const rejected = promises
        .filter((_, i) => settled[i].status === "rejected")
        .map(([name, _]) => name);

    if (rejected.length !== 0) {
        log.error(`Migration failures: ${rejected.join(", ")}`);
    }

    removeInvalidBrowserStores(pm);
};

function makeDbName(userId: UserId): string {
    return `${dbNamePrefix}-${userId}`;
}
