import { ConnectError, Interceptor, StreamResponse } from "@connectrpc/connect";
import { Context, context, propagation, Span, SpanKind, trace } from "@opentelemetry/api";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { Resource } from "@opentelemetry/resources";
import { BasicTracerProvider, BatchSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { StackContextManager } from "@opentelemetry/sdk-trace-web";
import {
    SEMATTRS_MESSAGE_TYPE,
    SEMATTRS_RPC_GRPC_STATUS_CODE,
    SEMATTRS_RPC_METHOD,
    SEMATTRS_RPC_SERVICE,
    SEMATTRS_RPC_SYSTEM,
    SEMRESATTRS_SERVICE_NAME,
    SEMRESATTRS_SERVICE_VERSION,
} from "@opentelemetry/semantic-conventions";

import { getEnvironmentConfig } from "@/misc/environment";

function createProvider(url: string) {
    const provider = new BasicTracerProvider({
        resource: new Resource(
            {
                [SEMRESATTRS_SERVICE_NAME]: "frontend",
                [SEMRESATTRS_SERVICE_VERSION]: __BEYOND_FRONTEND_VERSION__,
            },
            // We might be able to have this promise resolve once we receive the version from
            // the backend. But note tracing doesn't appear to work if the promise never resolves,
            // so this will need some testing to see what the effect of it is.
            /*new Promise<ResourceAttributes>(_resolve => {
                setTimeout(_resolve, 5000);
                if (false) {
                    _resolve({
                        [SemanticResourceAttributes.SERVICE_VERSION]: "",
                    });
                }
            }),*/
        ),
    });

    provider.addSpanProcessor(
        new BatchSpanProcessor(
            new OTLPTraceExporter({
                url: url,
                // "compression" is not yet supported by the browser. This is largely due to
                // people worrying about gzip bombs on the server side. It would be nice for
                // us, as these traces are quite large and very compressable, but without
                // some effort to manually do this (e.g. by extending OLTPTraceExporter), we
                // can't do so.
                //
                // https://medium.com/@abhinav.ittekot/why-http-request-compression-is-almost-never-supported-5de68067b245
                // compression: "gzip" as any, // CompressionAlgorithm.GZIP,
            }),
            {
                scheduledDelayMillis: 5000,
                exportTimeoutMillis: 15000,
                maxQueueSize: 2048,
            },
        ),
    );

    provider.register();

    return provider;
}

export const { getTracingEnabled, setTracingEnabled } = (() => {
    let allowed = false;
    let ctxMgr: StackContextManager | null = null;
    let provider: BasicTracerProvider | null = null;

    const targetConfig = getEnvironmentConfig();

    return {
        getTracingEnabled: () => allowed,
        setTracingEnabled: (allow: boolean) => {
            if (allow === allowed) return;
            allowed = allow;

            if (allow) {
                ctxMgr = new StackContextManager();
                ctxMgr.enable();
                context.setGlobalContextManager(ctxMgr);

                provider = createProvider(targetConfig.tracingUrl);
            }
            else {
                ctxMgr?.disable();
                ctxMgr = null;

                provider?.forceFlush();
                provider?.shutdown();
                provider = null;
            }
        },
    };
})();

const traceContext = (() => {
    let ctx: Context;
    return {
        get: () => ctx,
        set: (newCtx: Context) => {
            ctx = newCtx;
        },
    };
})();

export const setContext = traceContext.set;

export const tracingInterceptor: Interceptor = next => req => {
    if (!getTracingEnabled()) {
        return next(req);
    }

    const parentContext = traceContext.get();
    const tracer = trace.getTracer("goat");

    return tracer.startActiveSpan(
        `grpc:${req.service.typeName}/${req.method.name}`,
        {
            kind: SpanKind.CLIENT,
            attributes: {
                url: req.url,
                [SEMATTRS_RPC_SYSTEM]: "grpc",
                [SEMATTRS_RPC_METHOD]: req.method.name,
                [SEMATTRS_RPC_SERVICE]: req.service.typeName,
            },
        },
        parentContext,
        async (span: Span) => {
            // This copies the current OpenTelemetry metadata into the request headers. This will
            // add traceparent/tracecontext.
            propagation.inject(context.active(), req.header, {
                set(hdrs, key, value) {
                    hdrs.set(key, typeof value === "string" ? value : String(value));
                },
            });

            function finishSpan(err: unknown) {
                if (err === null || err === undefined) {
                    span.setAttribute(SEMATTRS_RPC_GRPC_STATUS_CODE, 0);
                }
                else if (err instanceof ConnectError) {
                    if (err.code) {
                        // span.setStatus(_grpcStatusCodeToSpanStatus(err.code));
                        span.setAttribute(SEMATTRS_RPC_GRPC_STATUS_CODE, err.code);
                    }
                    span.setAttributes({
                        "grpc.error_name": err.name,
                        "grpc.error_message": err.metadata.get("grpc-message") || err.message,
                    });
                }
                else if (err instanceof Error) {
                    span.recordException(err);
                }
                else {
                    // Technically shouldn't happen
                    span.setAttribute(SEMATTRS_RPC_GRPC_STATUS_CODE, 999);
                    span.setAttributes({
                        "grpc.error_name": "non-connect error",
                        "grpc.error_message": String(err),
                    });
                }
            }

            let uploadDone = false;
            let downloadDone = false;
            let ended = false;

            function cleanup() {
                if (ended) return;
                if (uploadDone && downloadDone) {
                    span.end();
                    ended = true;
                }
            }

            try {
                let req2 = req;

                if (req.stream) {
                    req2 = {
                        ...req,
                        message: async function* () {
                            try {
                                for await (const m of req.message) {
                                    span.addEvent("client-message", {
                                        [SEMATTRS_MESSAGE_TYPE]: "sent",
                                    });
                                    yield m;
                                }
                                yield* req.message;
                            }
                            finally {
                                uploadDone = true;
                                cleanup();
                            }
                        }(),
                    };
                }
                else {
                    uploadDone = true;
                }

                const res = await next(req2);

                finishSpan(null);

                if (res.stream) {
                    const logStream = async function* (res: StreamResponse) {
                        try {
                            for await (const m of res.message) {
                                span.addEvent("server-message", {
                                    [SEMATTRS_MESSAGE_TYPE]: "received",
                                });
                                yield m;
                            }
                            yield* res.message;
                        }
                        finally {
                            downloadDone = true;
                            cleanup();
                        }
                        // Definitely done now...
                    };
                    return {
                        ...res,
                        message: logStream(res),
                    };
                }
                else {
                    downloadDone = true;
                }

                return res;
            }
            catch (err: unknown) {
                finishSpan(err);

                throw err;
            }
            finally {
                cleanup();
            }
        },
    );
};
