// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=js+ts"
// @generated from file notifications/notifications.proto (package notifications, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AckNotificationRequest, AckNotificationResponse, DismissNotificationRequest, DismissNotificationResponse, GetNotificationsRequest, GetNotificationsResponse, SendTestNativeNotificationRequest, SendTestNativeNotificationResponse, SubActiveNotificationsRequest, SubActiveNotificationsResponse, SubNotificationChangesRequest, SubNotificationChangesResponse, SubWebNotificationChangesRequest, SubWebNotificationChangesResponse } from "./notifications_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service notifications.NotificationService
 */
export const NotificationService = {
  typeName: "notifications.NotificationService",
  methods: {
    /**
     * GetNotifications ...
     *
     * @generated from rpc notifications.NotificationService.GetNotifications
     */
    getNotifications: {
      name: "GetNotifications",
      I: GetNotificationsRequest,
      O: GetNotificationsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DismissNotification ...
     *
     * @generated from rpc notifications.NotificationService.DismissNotification
     */
    dismissNotification: {
      name: "DismissNotification",
      I: DismissNotificationRequest,
      O: DismissNotificationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * AckNotification ...
     *
     * @generated from rpc notifications.NotificationService.AckNotification
     */
    ackNotification: {
      name: "AckNotification",
      I: AckNotificationRequest,
      O: AckNotificationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SendTestNativeNotification ...
     *
     * @generated from rpc notifications.NotificationService.SendTestNativeNotification
     */
    sendTestNativeNotification: {
      name: "SendTestNativeNotification",
      I: SendTestNativeNotificationRequest,
      O: SendTestNativeNotificationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SubActiveNotifications streams back the complete list of active notifications for a user each time it changes.
     *
     * @generated from rpc notifications.NotificationService.SubActiveNotifications
     */
    subActiveNotifications: {
      name: "SubActiveNotifications",
      I: SubActiveNotificationsRequest,
      O: SubActiveNotificationsResponse,
      kind: MethodKind.BiDiStreaming,
    },
    /**
     * SubNotificationChanges streams back new notifications and dismissals for each given user.
     *
     * @generated from rpc notifications.NotificationService.SubNotificationChanges
     */
    subNotificationChanges: {
      name: "SubNotificationChanges",
      I: SubNotificationChangesRequest,
      O: SubNotificationChangesResponse,
      kind: MethodKind.BiDiStreaming,
    },
    /**
     * SubWebNotificationChanges streams back rich webpage notifications and dismissals for the authenticated person.
     *
     * @generated from rpc notifications.NotificationService.SubWebNotificationChanges
     */
    subWebNotificationChanges: {
      name: "SubWebNotificationChanges",
      I: SubWebNotificationChangesRequest,
      O: SubWebNotificationChangesResponse,
      kind: MethodKind.ServerStreaming,
    },
  }
};

