import {
    combineReducers,
    configureStore,
    createDynamicMiddleware,
    PayloadAction,
} from "@reduxjs/toolkit";

import { WebsocketRpcsInterface } from "@/api/transport";
import * as auth from "@/features/auth";
import { selectCurrentUserId } from "@/features/auth";
import { startManagingAuth } from "@/features/authManagement";
import * as bondCreation from "@/features/bondCreation";
import * as bonds from "@/features/bonds";
import * as calls from "@/features/calls";
import * as channels from "@/features/channels";
import * as messages from "@/features/chats";
import * as connection from "@/features/connection";
import {
    startDetectingOnlineStatus,
    startManagingConnection,
} from "@/features/connectionManagement";
import * as filterPanel from "@/features/filterPanel";
import * as intel from "@/features/intel";
import * as interest from "@/features/interest";
import * as mediaDevices from "@/features/mediaDevices";
import * as meta from "@/features/meta";
import { startDebugListening } from "@/features/middleware";
import * as notifications from "@/features/notifications";
import { proxiedThunkRegistry } from "@/features/proxiedThunk";
import * as search from "@/features/search";
import * as squads from "@/features/squads";
import { startConnectedListeners } from "@/features/streams";
import * as users from "@/features/users";
import log from "@/misc/log";
import { getOidcConfig } from "@/misc/oidcConfig";
import { setPersistorMap } from "@/persist/map";
import { startPersisting } from "@/persist/persist";
import { indexedDbAvailable } from "@/persist/shared";
import { tagProxyAction } from "@/store/locations";
import {
    type AppAppendListener,
    type AppListenerMiddleware,
    appListenerMiddleware,
    startAppListening,
} from "@/store/middleware";
import {
    isProxiedRequest,
    isProxiedResponse,
    isThunkRequest,
    listenForProxiedDispatches,
    ProxiedMessageDirection,
    proxyMiddleware,
    startBroadcastingDispatches,
} from "@/store/proxy";
import type { AppStore, PersistorMap, RootReducerMap, RootState, StoreConfig } from "@/store/types";

const rootReducerMap: RootReducerMap = {
    auth: auth.reducer,
    bondCreation: bondCreation.reducer,
    bonds: bonds.reducer,
    calls: calls.reducer,
    channels: channels.reducer,
    connection: connection.reducer,
    filterPanel: filterPanel.reducer,
    intel: intel.reducer,
    interest: interest.reducer,
    mediaDevices: mediaDevices.reducer,
    messages: messages.reducer,
    meta: meta.reducer,
    notifications: notifications.reducer,
    search: search.reducer,
    squads: squads.reducer,
    users: users.reducer,
};

const combinedReducer = combineReducers({ ...rootReducerMap });

const rootPersistorMap: PersistorMap<RootState> = {
    auth: auth.persistor,
    bondCreation: bondCreation.persistor,
    bonds: bonds.persistor,
    calls: calls.persistor,
    channels: channels.persistor,
    connection: connection.persistor,
    filterPanel: filterPanel.persistor,
    intel: intel.persistor,
    interest: interest.persistor,
    mediaDevices: mediaDevices.persistor,
    messages: messages.persistor,
    meta: meta.persistor,
    notifications: notifications.persistor,
    search: search.persistor,
    squads: squads.persistor,
    users: users.persistor,
};

setPersistorMap(rootPersistorMap);

export type SetupStoreArgs = Partial<
    StoreConfig & {
        listenerMiddleware: AppListenerMiddleware;
        dispatchThunkResponse: (store: AppStore, action: PayloadAction<unknown>) => void;
        websocketRpcs: () => WebsocketRpcsInterface;
    }
>;

export function setupStore(preloadedState?: Partial<RootState>, args?: SetupStoreArgs) {
    preloadedState ??= {};

    const persist = args?.persist && indexedDbAvailable();
    const broadcast = args?.broadcast ?? false;
    const proxy = args?.proxy ?? false;
    const manageConnection = (args?.manageConnection ?? true) && !proxy;
    const startListeners = (args?.startListeners ?? false) && manageConnection;
    const debug = args?.debug ?? false;
    const manageAuth = args?.manageAuth ?? false;

    if (proxy && args?.manageConnection) {
        throw new Error(`cannot proxy and manage connection`);
    }

    if (!manageConnection && args?.startListeners) {
        throw new Error(`cannot run listeners without managing connection`);
    }

    if (broadcast && proxy) {
        throw new Error(`cannot both redirect and broadcast dispatched actions`);
    }

    if (args?.persist && !persist) {
        log.warn(`Persistence requested but IndexedDB not available`);
    }

    const dynamicMiddleware = createDynamicMiddleware();

    preloadedState.meta = meta.getInitialMetaState({
        ...preloadedState.meta,
        config: {
            persist,
            broadcast,
            proxy,
            manageConnection,
            startListeners,
            debug,
        },
    });

    const listenerMW = args?.listenerMiddleware ?? appListenerMiddleware;
    const appendListener: AppAppendListener = startAppListening(listenerMW);

    const store = configureStore({
        reducer: combinedReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                // You may want to disable these locally to improve performance
                // immutableCheck: false,
                // serializableCheck: false,
            })
                .prepend(listenerMW.middleware)
                .concat(dynamicMiddleware.middleware),
        preloadedState,
    });

    const cleanupFns: Array<() => void> = [];
    Object.assign(store, {
        cleanup: () => {
            cleanupFns.forEach(f => f());
        },
    });

    if (debug) {
        const c = startDebugListening(appendListener, debug);
        cleanupFns.push(c);
    }

    if (manageConnection) {
        const c = startDetectingOnlineStatus(store);
        cleanupFns.push(c);

        const cs = startManagingConnection(appendListener, { rpcs: args?.websocketRpcs });
        cleanupFns.push(...cs);
    }

    if (manageAuth) {
        const c = startManagingAuth(appendListener, { oidcConfig: getOidcConfig });
        cleanupFns.push(c);
    }

    const userId = selectCurrentUserId(store.getState());

    if (startListeners) {
        const cs = startConnectedListeners(appendListener, userId);
        cleanupFns.push(...cs);
    }

    if (!userId) {
        return store;
    }

    if (proxy) {
        const mw = proxyMiddleware(userId);
        dynamicMiddleware.addMiddleware(mw);

        const c = listenForProxiedDispatches(
            userId,
            ProxiedMessageDirection.Broadcast,
            msg => {
                if (!isProxiedResponse(msg)) return;

                const { action } = msg;
                if (args?.dispatchThunkResponse) {
                    args.dispatchThunkResponse(store, action);
                }
                else {
                    store.dispatch(action);
                }
            },
        );
        cleanupFns.push(c);
    }

    if (broadcast) {
        const c1 = listenForProxiedDispatches(
            userId,
            ProxiedMessageDirection.Request,
            msg => {
                if (!isProxiedRequest(msg)) return;

                if (isThunkRequest(msg)) {
                    const { typePrefix, arg } = msg.thunk;
                    const thunk = proxiedThunkRegistry.get(typePrefix);

                    if (!thunk) {
                        log.error(`Proxied thunk ${typePrefix} not present in registry`);
                    }
                    else {
                        store.dispatch(tagProxyAction(thunk(arg)));
                    }
                }
                else {
                    store.dispatch(tagProxyAction(msg.action));
                }
            },
        );

        const c2 = startBroadcastingDispatches(userId, appendListener);

        cleanupFns.push(c1, c2);
    }

    if (persist) {
        const dbUpgradeBlockingAction = () => {
            store.dispatch(meta.idbUpgradeRequired());
        };

        const c = startPersisting(userId, dbUpgradeBlockingAction, appendListener);
        cleanupFns.push(c);
    }

    return store;
}
