import { DependencyList, EffectCallback } from "react";

import { selectActiveStatus } from "@/features/connection";
import { useSelectorEffect } from "@/hooks/useSelectorEffect";

/** @function Fire an effect only when: The tab is visible, in-focus and recently interacted with
 */
export function useActiveEffect(effect: EffectCallback, deps: DependencyList) {
    useSelectorEffect({ effect, deps, selector: selectActiveStatus });
}
