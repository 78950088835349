import * as d from "@/domain/domain";
import { isDevEnv } from "@/misc/environment";
import { deleteDB, IDBPDatabase, openDB, OpenDBCallbacks } from "idb";
import { AuthMachineStorage, AuthRequestStateSchema } from "./types";
import {
    ascii_to_uint8array,
    base64url_decode,
    base64url_encode,
    cbckey_from_string,
    decrypt_cbc,
    encrypt_cbc,
    uint8array_to_ascii,
} from "./util";

// These need to be 16 bytes. The idea is just to provide some obfuscation, not security.
const defaultEncryptionKeyString = "76031c6762678790";
const defaultEncryptionIv = ascii_to_uint8array("fb7ff654b65c40c3");

export class EncryptedStorageWrapper implements AuthMachineStorage {
    constructor(private readonly _next: AuthMachineStorage) {}

    async dispose() {
        if ("dispose" in this._next) return (this._next as any).dispose();
    }

    async load() {
        const data = await this._next.load();
        if (data === null) return null;

        try {
            const ciphertext = base64url_decode(data);
            const plaintext = await decrypt_cbc(
                await cbckey_from_string(defaultEncryptionKeyString),
                defaultEncryptionIv,
                ciphertext,
            );
            const ascii = uint8array_to_ascii(new Uint8Array(plaintext));
            return JSON.parse(ascii);
        }
        catch (_e) {
            return null;
        }
    }

    async save(state: any | null) {
        if (state === null) return this._next.save(state);

        const stateString = JSON.stringify(state);

        const ciphertext = await encrypt_cbc(
            await cbckey_from_string(defaultEncryptionKeyString),
            defaultEncryptionIv,
            ascii_to_uint8array(stateString),
        );
        const base64 = base64url_encode(ciphertext);
        await this._next.save(base64);
    }
}

export class IdbStorage implements AuthMachineStorage {
    private readonly callbacks: OpenDBCallbacks<unknown> = {
        upgrade(database, oldVersion, _newVersion, _transaction, _event) {
            if (oldVersion === 0) {
                database.createObjectStore(IdbStorage.storeName);
            }
        },
    };

    private static readonly storeName = "state";
    private static readonly keyName = "0";
    private dbPromise: Promise<IDBPDatabase<unknown>>;
    private dbName: string;

    constructor(userId: d.UserId) {
        this.dbName = `auth-${userId}`;
        this.dbPromise = openDB(this.dbName, 1, this.callbacks);
    }

    async dispose(del: boolean = false) {
        const db = await this.getDB();
        db.close();

        if (del) {
            // Delete asynchronously to avoid blocking the main thread
            deleteDB(this.dbName).catch(_e => {});
        }
    }

    private async getDB() {
        return this.dbPromise;
    }

    async load() {
        const db = await this.getDB();

        const data = await db.get(IdbStorage.storeName, IdbStorage.keyName);

        if (data === undefined) return null;

        return data;
    }

    async save(state: any | null) {
        const db = await this.getDB();

        if (state === null) {
            await db.delete(IdbStorage.storeName, IdbStorage.keyName);
            return;
        }

        await db.put(IdbStorage.storeName, state, IdbStorage.keyName);
    }
}

export class SessionStorage implements AuthMachineStorage {
    private static readonly storeName = "auth/authRequest";

    constructor() {
    }

    async load() {
        return window.sessionStorage.getItem(SessionStorage.storeName) ?? null;
    }

    async save(state: any | null) {
        if (state === null) {
            window.sessionStorage.removeItem(SessionStorage.storeName);
            return;
        }
        window.sessionStorage.setItem(SessionStorage.storeName, state);
    }
}

export function tokenStorage(userId: d.UserId): AuthMachineStorage {
    const baseStorage = new IdbStorage(userId);

    if (isDevEnv) {
        return baseStorage;
    }

    return new EncryptedStorageWrapper(baseStorage);
}

export function authRequestStorage() {
    const baseStorage = new SessionStorage();

    if (isDevEnv) {
        return baseStorage;
    }

    return new EncryptedStorageWrapper(baseStorage);
}

export async function loadCodeVerifier(): Promise<string | null> {
    const data = await authRequestStorage().load();
    if (!data) {
        return null;
    }

    return AuthRequestStateSchema.parse(JSON.parse(data)).code_verifier;
}
