import * as d from "@/domain/domain";
import { userIsActive } from "@/domain/users";
import { selectSortedBondObserverIdsPair } from "@/features/bonds";
import { selectUsers } from "@/features/users";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import useSortedUsers from "@/hooks/useSortedUsers";

const selectUserAdjustments = {
    filter: userIsActive,
};

export default function useFreshBondObservers(bondId: d.BondId) {
    const observerIds = useSortedUsers(selectSortedBondObserverIdsPair, bondId);

    // Filter out observers who are not currently active
    const users = useSelectorArgs(selectUsers, observerIds, selectUserAdjustments);
    const ids = useShallowEqualsMemo(() => users.map(uo => uo.id), [users]);
    const idSet = useShallowEqualsMemo(() => ids.toSet(), [ids]);

    return {
        ids,
        idSet,
    };
}
