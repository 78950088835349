import BondsListView from "./BondsListView";
import { ShowSearchResultsWhenAvailable } from "./SearchView";

// The "Inbox" view on the Phase3 UI
export function MyBondsView(): React.JSX.Element {
    return (
        <ShowSearchResultsWhenAvailable>
            <BondsListView />
        </ShowSearchResultsWhenAvailable>
    );
}
