import AvatarUploadControls from "@/components/AvatarUploadControls";
import { CloseButton } from "@/components/buttons/Close";
import useDialogOpenRef from "@/hooks/useDialogOpenRef";
import useDialogOutsideClick from "@/hooks/useDialogOutsideClick";
import { useNavigateBack } from "@/hooks/useNavigateBack";
import { useCallback } from "react";

export function AvatarView(): React.JSX.Element {
    const { navigateBack } = useNavigateBack({ defaultPath: "..", replaceDefault: true });

    const dialogRef = useDialogOpenRef();

    const closeModal = useCallback(() => {
        navigateBack();
    }, [navigateBack]);

    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    return (
        <dialog
            className="c-dialog c-dialog--short"
            onClose={closeModal}
            onMouseDown={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            <header className="c-dialog__header c-dialog__header--centered">
                <h1 className="c-dialog__title">Profile photo</h1>
                <CloseButton side="right" onClick={closeModal} />
            </header>
            <article className="c-dialog__content-wrapper">
                <div className="c-dialog__content c-dialog__content--profile">
                    <AvatarUploadControls />
                </div>
            </article>
        </dialog>
    );
}
