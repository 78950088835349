import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { FeatureFlagged } from "@/components/FeatureFlags";
import MessageComposer from "@/components/MessageComposer";
import RichTextMessageComposer from "@/components/RichTextMessageComposer";
import { bondCreationDraftTarget, newChannelDraftTarget } from "@/domain/channels";
import { EmitterSources, plainifyMarkupDelta } from "@/domain/delta";
import * as d from "@/domain/domain";
import { getDraftMentions, getDraftMessageMarkup } from "@/domain/draftChatContent";
import { isSquadMention } from "@/domain/mentions";
import { DraftChatMessage } from "@/domain/messages";
import { selectCurrentOrgId } from "@/features/auth";
import {
    createBondFromCallThunk,
    createBondFromMessageThunk,
    selectBondCreationAudience,
    selectBondTitleSuggestion,
    selectBondUserSpecifiedTitle,
} from "@/features/bondCreation";
import { updateDraftMarkup } from "@/features/channels";
import { selectActiveStatus } from "@/features/connection";
import { useTakeSingleRtcSessionLock } from "@/hooks/createSharedWebLock";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { Optional } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import { nanoid } from "@reduxjs/toolkit";

const placeholder = "Message...";

export interface NewBondMessageComposerProps {
    backAction: () => void;
    tabIndex?: number;
}

export const NewBondMessageComposer: FC<NewBondMessageComposerProps> = ({
    backAction,
    tabIndex,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const orgId = useAppSelector(selectCurrentOrgId);
    const userSpecifiedTitle = useAppSelector(selectBondUserSpecifiedTitle);
    const aiGeneratedTitle = useAppSelector(selectBondTitleSuggestion);
    const audience = useAppSelector(selectBondCreationAudience);

    const idempotentKey = useMemo(() => nanoid(), []);

    const createBondFromMsg = useCallback((draft: Optional<DraftChatMessage>) => {
        if (!draft) return;

        // In the future, we'll want this to be context dependent. Hence, don't
        // extract it from the store inside the thunk, do it here.
        if (!orgId) {
            log.warn(`No orgId for new bond from message`);
            return;
        }

        log.info("Creating bond with message");

        // Include explicit squad mentions from the draft in the bond creation request
        const mentions = getDraftMentions(draft.content).filter(isSquadMention);

        dispatch(createBondFromMessageThunk({
            orgId,
            bondTitles: {
                userSpecifiedTitle,
                aiGeneratedTitle,
            },
            msg: draft,
            audience, // Setting an audience separately from the mentions is a phase 3 feature
            mentions,
            idempotentKey,
        }))
            .unwrap()
            .then(bond => {
                log.debug("Navigating to new bond", bond.id, "created from message");
                navigate("/bond/" + d.extractUUID(bond.id), { replace: true });
            })
            .catch(err => {
                log.error("Error creating bond from message", err);
            });
    }, [
        dispatch,
        orgId,
        userSpecifiedTitle,
        aiGeneratedTitle,
        audience,
        navigate,
        idempotentKey,
    ]);

    // Only create a bond from a call if we are able to take the rtc session lock.
    // This helps ensure we only create a bond if we are ready to go live in it straight away.
    const active = useAppSelector(selectActiveStatus);
    const haveSingleSessionLock = useTakeSingleRtcSessionLock(active);

    // Currently, this only creates the bond, NOT the call.
    // The rtc hooks start the call in the bond once we have navigated to it from here.
    const createBondFromCall = useCallback((draft: Optional<DraftChatMessage>) => {
        if (!haveSingleSessionLock) return;

        // In the future, we'll want this to be context dependent. Hence, don't
        // extract it from the store inside the thunk, do it here.
        if (!orgId) {
            log.warn(`No orgId for new bond from call`);
            return;
        }

        const transferDraftToChannel = (
            draft: Optional<DraftChatMessage>,
            channelId: d.ChannelId,
        ) => {
            if (!draft) return;

            const newDraftTarget = newChannelDraftTarget(channelId);
            dispatch(
                updateDraftMarkup({
                    draftTarget: newDraftTarget,
                    update: { markup: plainifyMarkupDelta(getDraftMessageMarkup(draft.content)) },
                    source: EmitterSources.API,
                }),
            );
        };

        log.info("Creating bond from call");

        dispatch(createBondFromCallThunk({
            orgId,
            bondTitles: {
                userSpecifiedTitle,
                aiGeneratedTitle,
            },
            audience,
            idempotentKey,
        })).unwrap()
            .then(bond => {
                log.debug("Navigating to new bond", bond.id, "created from call");

                transferDraftToChannel(draft, bond.channelId);
                navigate("/bond/" + d.extractUUID(bond.id), { replace: true });
            })
            .catch(err => {
                log.error("Error creating bond from call", err);
            });
    }, [
        dispatch,
        haveSingleSessionLock,
        orgId,
        userSpecifiedTitle,
        aiGeneratedTitle,
        audience,
        navigate,
        idempotentKey,
    ]);

    return (
        <FeatureFlagged
            flag={"rich-text-composer"}
            match={true}
            wrapWithDiv={false}
            fallback={
                <MessageComposer
                    id="comms-input-bond-creation"
                    key="comms-input-bond-creation"
                    draftTarget={bondCreationDraftTarget}
                    msgCompletionAction={createBondFromMsg}
                    mediaEnableAction={createBondFromCall}
                    escapeAction={backAction}
                    bondComposer={true}
                    placeholder={placeholder}
                    tabIndex={tabIndex}
                />
            }
        >
            <RichTextMessageComposer
                id="comms-input-bond-creation"
                key="comms-input-bond-creation"
                draftTarget={bondCreationDraftTarget}
                msgCompletionAction={createBondFromMsg}
                mediaEnableAction={createBondFromCall}
                escapeAction={backAction}
                bondComposer={true}
                placeholder={placeholder}
                tabIndex={tabIndex}
                autoFocus={!isMobileBrowser()}
            />
        </FeatureFlagged>
    );
};

export default NewBondMessageComposer;
