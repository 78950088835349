import { useCallback, useEffect, useState } from "react";
import TinyGesture from "tinygesture";

function transition(div: HTMLDivElement, translateX: number, transition: boolean) {
    if (transition) {
        div.style.transition = "transform 0.2s ease-in";
    }
    else {
        div.style.transition = "";
    }
    if (translateX != 0) {
        div.style.transform = `translateX(${translateX}px)`;
    }
    else {
        div.style.transform = "";
    }
}

interface SliderOptions {
    revealWidthRight?: number;
    revealWidthLeft?: number;
    snapThreshold?: number;
    swipeSensitivity?: number;
}

const useSlider = (id: string, options: SliderOptions = {}): React.RefCallback<HTMLDivElement> => {
    const [divRef, setDivRef] = useState<HTMLDivElement | null>(null);
    const refCb = useCallback((node: HTMLDivElement | null) => {
        setDivRef(node);
    }, [setDivRef]);

    const {
        revealWidthRight: optRevealWidthRight,
        revealWidthLeft: optRevealWidthLeft,
        snapThreshold: optSnapThreshold,
        swipeSensitivity: optSwipeSensitivity,
    } = options;

    useEffect(() => {
        if (!divRef) return;
        let swiped = 0;
        let startOffset = 0;
        const decelerationOnOverflow = 4;
        const revealWidthRight = optRevealWidthRight ?? 160;
        const revealWidthLeft = optRevealWidthLeft ?? 80;
        const snapThreshold = optSnapThreshold ?? 1.5;
        const swipeSensitivity = optSwipeSensitivity ?? 1;
        const snapWidthLeft = revealWidthLeft / snapThreshold;
        const snapWidthRight = revealWidthRight / snapThreshold;

        let swipeDirection: "horizontal" | "vertical" | null = null;

        const gesture = new TinyGesture(divRef, {
            mouseSupport: true,
            diagonalSwipes: false,
            threshold: (type, _self) => {
                // Keep vertical threshold at a minimum
                if (type === "y") {
                    return 25;
                }

                // This is the default implementation of the threshold function
                return Math.max(
                    25,
                    Math.floor(
                        0.15 *
                            (type === "x"
                                ? window.innerWidth || document.body.clientWidth
                                : window.innerHeight || document.body.clientHeight),
                    ),
                );
            },
        });

        const handlePanMove = (_event: Event) => {
            if (!divRef || gesture.touchMoveX === null) return;

            let touchMoveX = gesture.touchMoveX;

            // Prevent horizontal swiping if the user is actually scrolling vertically by
            // remembering what direction they started a swipe in
            if (
                gesture.swipingDirection !== null && swipeDirection === null &&
                (gesture.swipingDirection === "horizontal" ||
                    gesture.swipingDirection === "vertical")
            ) {
                swipeDirection = gesture.swipingDirection;
            }

            if (swipeDirection === "vertical") {
                touchMoveX = 0;
            }

            const getX = (x: number) => {
                x = x * swipeSensitivity; // Apply sensitivity factor
                if (x > 0) {
                    if (x < revealWidthLeft) return x;
                    return (x - revealWidthLeft) / decelerationOnOverflow + revealWidthLeft;
                }
                else {
                    if (x > -revealWidthRight) return x;
                    return (x + revealWidthRight) / decelerationOnOverflow - revealWidthRight;
                }
            };
            const newX = getX(startOffset + touchMoveX);
            if (newX >= snapWidthLeft) {
                swiped = revealWidthLeft;
            }
            else if (newX <= -snapWidthRight) {
                swiped = -revealWidthRight;
            }
            else {
                swiped = 0;
            }
            transition(divRef, newX, false);
        };

        const handlePanEnd = () => {
            if (!divRef) return;
            if (swiped === 0) {
                startOffset = 0;
            }
            else {
                startOffset = swiped;
            }
            transition(divRef, swiped, true);

            swipeDirection = null;
        };

        const handleTap = () => {
            if (!divRef) return;
            swiped = 0;
            startOffset = 0;
            transition(divRef, 0, true);
        };

        gesture.on("panmove", handlePanMove);
        gesture.on("panend", handlePanEnd);
        gesture.on("tap", handleTap);

        return () => {
            if (divRef) {
                transition(divRef, 0, false);
            }
            gesture.destroy();
        };
    }, [
        id,
        divRef,
        optRevealWidthRight,
        optRevealWidthLeft,
        optSnapThreshold,
        optSwipeSensitivity,
    ]);

    return refCb;
};

export default useSlider;
