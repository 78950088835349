import { memoizeOptions } from "@/features/selectors";
import { Combiner, createSelector, SelectorArray } from "reselect";

/**
 * Behaves similarly to createSelector, but the returned selector does not accept any
 * arguments and always runs its input selectors when called. Because the input selectors
 * are always run, they can be impure. As usual, if the result of the input selectors does
 * not change, the output selector does not run.
 *
 * LRU memoisation is always used.
 *
 * This is useful for memoising values that are sampled.
 */
export const createImpureSelector = <InputSelectors extends SelectorArray<void>, Result>(
    ...args: [
        ...inputSelectors: InputSelectors,
        combiner: Combiner<InputSelectors, Result>,
    ]
): () => Result => {
    // Look, a legitimate use case for lru memoization!
    const selector = createSelector(...args, memoizeOptions.lru);

    // Return a thunk that calls the selector with a changed state value, forcing
    // the input selectors to rerun
    let seq = 0;
    return () => selector(...[seq++] as unknown as Parameters<typeof selector>);
};
