import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import classNames from "classnames";
import React, {
    CSSProperties,
    ForwardedRef,
    forwardRef,
    SetStateAction,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { createPortal } from "react-dom";

import AttachmentUploadControls from "@/components/AttachmentUploadControls";
import AudienceHint from "@/components/AudienceHint";
import { CloseButton } from "@/components/buttons/Close";
import CallControls, { CallControlsLocation } from "@/components/gui/CallControls";
import ComposerButton from "@/components/gui/ComposerButton";
import TextArea from "@/components/gui/TextArea";
import { MentionAutoComplete } from "@/components/MentionAutoComplete";
import { MessageHighlighter } from "@/components/MessageHighlighter";
import { DraftTarget } from "@/domain/channels";
import { isDraftContentV1 } from "@/domain/draftChatContent";
import { messageHysteresisBondTitleSuggestion, minimumTriggerMessageLength } from "@/domain/intel";
import { Mention } from "@/domain/mentions";
import {
    DraftChatMessage,
    MessageSendableStatus,
    messageSendableStatusText,
} from "@/domain/messages";
import { clearBondTitleSuggestion, selectBondCreationPrefixLength } from "@/features/bondCreation";
import {
    insertDraftMention,
    insertDraftText,
    selectDraft,
    selectDraftContentMentions,
    selectDraftMessageSendableStatus,
    selectDraftText,
    updateDraftText,
} from "@/features/channels";
import { fetchBondTitleSuggestionThunk } from "@/features/intel";
import { MetaInterestCounterKey, selectInterestInKey } from "@/features/interest";
import { useMetaInterest } from "@/hooks/interest/useInterest";
import useAutoCompleteQuery, { ignoreRangesFromMentions } from "@/hooks/useAutoCompleteQuery";
import { useConnectedEffect } from "@/hooks/useConnectedEffect";
import useDebounceWithoutTimeout from "@/hooks/useDebounceWithoutTimeout";
import { useOffsetScreenPos } from "@/hooks/useOffsetScreenPos";
import useOutsideClick from "@/hooks/useOutsideClick";
import usePrevious from "@/hooks/usePrevious";
import { useSelection } from "@/hooks/useSelection";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import { extractCSSProperties } from "@/misc/css";
import { isMobileBrowser } from "@/misc/mobile";
import { setStateReduce } from "@/misc/setStateReduce";
import { Focusable, FocusableOptions, NumberRange, Optional, Scrollable } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";

export const typingTimeoutDelay = 5 * 1000;

export interface ComposerStates {
    textFocused: boolean;
    emojiPickerOpen: boolean;
    mentionMenuOpen: boolean;
}

export interface MessageComposerProps {
    id: string;
    draftTarget: DraftTarget;

    msgCompletionAction: (draft: DraftChatMessage | undefined) => void;
    mediaEnableAction?: (draft: DraftChatMessage | undefined) => void;

    numberOfParticipants?: number;
    bondComposer?: boolean;
    placeholder?: string;

    showCallLocationAction?: () => void;

    onModalChange?: (current: boolean) => void;
    onTextAreaFocus?: () => void;
    onTextAreaBlur?: () => void;

    escapeAction?: React.KeyboardEventHandler;
    discardAction?: () => void;

    tabIndex?: number;
}

const triggers = ["@"];

export const MessageComposer = forwardRef((
    props: MessageComposerProps,
    ref: ForwardedRef<Focusable>,
): React.JSX.Element => {
    const {
        draftTarget,
        msgCompletionAction: onMessageCompletion,
        mediaEnableAction: onMediaEnable,
        bondComposer,
        placeholder,
        onModalChange,
        onTextAreaFocus: onTextAreaFocus,
        onTextAreaBlur: onEditorBlur,
        escapeAction,
        discardAction,
        tabIndex,
        showCallLocationAction,
    } = props;
    const numberOfParticipants = props.numberOfParticipants ?? 0;

    const dispatch = useAppDispatch();

    const typingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    const [currentlyTyping, setCurrentlyTyping] = useState<boolean>(false);
    useMetaInterest(currentlyTyping, MetaInterestCounterKey.Typing);

    const draft = useSelectorArgs(selectDraft, draftTarget);
    const draftText = useSelectorArgs(selectDraftText, draftTarget);
    const mentions = useSelectorArgs(selectDraftContentMentions, draftTarget);
    const prefixLength = useAppSelector(selectBondCreationPrefixLength);

    const caretHint = isDraftContentV1(draft?.content) ?
        draft?.content.caretHint.location :
        undefined;
    const caretHintId = isDraftContentV1(draft?.content) ?
        draft?.content.caretHint.id :
        undefined;

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const highlighterRef = useRef<Scrollable>(null);

    const {
        definedSelection,
        onSelectionChange,
    } = useSelection(textAreaRef);

    const focusTextArea = useCallback((options?: FocusableOptions) => {
        const ta = textAreaRef.current;
        if (!ta) return;

        ta.focus();
        if (options?.goToEnd) {
            const length = ta.value?.length;
            if (length) {
                ta.setSelectionRange(length, length);
            }
        }
        else {
            ta.setSelectionRange(
                definedSelection.start,
                definedSelection.end,
            );
        }
    }, [definedSelection]);

    // This is necessary to adjust the selection on external changes to the draft text (e.g. clearing the draft)
    const prevDraftText = usePrevious(draftText);
    useEffect(() => {
        if (draftText != prevDraftText) {
            onSelectionChange();
        }
    }, [onSelectionChange, draftText, prevDraftText]);

    // Get the current mention query (if one is active)
    const ignoreRanges = useShallowEqualsMemo(
        () => ignoreRangesFromMentions(mentions),
        [mentions],
    );

    const mentionQuery = useAutoCompleteQuery(
        triggers,
        draftText,
        definedSelection.end,
        { ignoreRanges },
    );

    const prevMentionOffset = usePrevious(mentionQuery?.range.start);

    // Get the screen position of mention query (if one is active)
    const mentionAnchor = useOffsetScreenPos(textAreaRef, mentionQuery?.range.start);

    // Show the mention autocomplete if a mention query is present and the autocomplete has not
    // already been shown for this mention query offset
    const [showMentionAutoComplete, setShowMentionAutoComplete] = useState(false);
    useEffect(() => {
        if (prevMentionOffset != mentionQuery?.range.start) {
            setShowMentionAutoComplete(mentionQuery !== undefined);
        }
    }, [prevMentionOffset, mentionQuery]);

    useEffect(() => {
        // Both the window and the textArea must be focused to be considered typing
        if (
            draftText !== "" && document.hasFocus() &&
            textAreaRef.current === document.activeElement
        ) {
            setCurrentlyTyping(true);

            // Clear the existing timeout and start a new one
            if (typingTimeout.current !== null) {
                clearTimeout(typingTimeout.current);
            }
            typingTimeout.current = setTimeout(() => {
                setCurrentlyTyping(false);
            }, typingTimeoutDelay);
        }
        else {
            setCurrentlyTyping(false);
        }
    }, [draftText]);

    const msgCompletionBlocked = useSelectorArgs(
        selectInterestInKey,
        MetaInterestCounterKey.BlockMsgCompletion,
    );
    const msgSendableStatus = useSelectorArgs(
        selectDraftMessageSendableStatus,
        draftTarget,
        false,
    );
    const complete = useCallback((_e: any) => {
        // Suppress completion when e.g. the mention autocomplete is shown with suggestions
        if (msgCompletionBlocked) {
            return;
        }

        if (msgSendableStatus == MessageSendableStatus.Ready) {
            onMessageCompletion(draft);
        }
    }, [msgCompletionBlocked, draft, msgSendableStatus, onMessageCompletion]);

    const onToggleMedia = useCallback((isEnabling: boolean) => {
        if (isEnabling) onMediaEnable?.(draft);
    }, [onMediaEnable, draft]);

    const sendButtonText = messageSendableStatusText(msgSendableStatus);

    const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
    const showEmojiPickerRef = useRef<HTMLButtonElement>(null);
    const outsideClickCallback = useCallback((target: EventTarget | null) => {
        // Filter out clicks on the button which shows the emoji picker itself.
        if (showEmojiPickerRef && showEmojiPickerRef.current?.contains(target as Node)) {
            return;
        }
        setShowEmojiPicker(false);
    }, [showEmojiPickerRef, setShowEmojiPicker]);
    const [emojiPickerDivRef] = useOutsideClick<HTMLDivElement>(outsideClickCallback);

    const setText = useCallback((textAction: SetStateAction<string>) => {
        if (!textAreaRef.current) {
            // This shouldn't happen, but if it does we certainly won't have the right
            // information available to update the content correctly, so just don't bother
            return;
        }

        const newText = setStateReduce(draftText, textAction);

        dispatch(
            updateDraftText({
                draftTarget,
                newText,
                selections: {
                    oldSelectionStart: definedSelection.start,
                    oldSelectionEnd: definedSelection.end,
                    newSelectionEnd: textAreaRef.current.selectionEnd,
                },
            }),
        );
    }, [dispatch, draftTarget, draftText, definedSelection]);

    const insertEmoji = useCallback((emoji: { native: string; }) => {
        if (!textAreaRef.current) {
            // This shouldn't happen, but if it does we certainly won't have the right
            // information available to update the content correctly, so just don't bother
            return;
        }

        dispatch(
            insertDraftText({
                draftTarget,
                insertedText: emoji.native,
                range: { start: definedSelection.start, end: definedSelection.end },
            }),
        );

        setShowEmojiPicker(false);

        focusTextArea();
    }, [dispatch, draftTarget, setShowEmojiPicker, definedSelection, focusTextArea]);

    const insertMention = useCallback(
        (
            mention: Mention,
            text: string,
            range: NumberRange,
        ) => {
            if (!textAreaRef.current) {
                // This shouldn't happen, but if it does we certainly won't have the right
                // information available to update the content correctly, so just don't bother
                return;
            }

            dispatch(
                insertDraftMention({
                    draftTarget,
                    mention,
                    text,
                    range,
                }),
            );
        },
        [dispatch, draftTarget],
    );

    // Notify the parent when a modal opens or closes
    const modalState = showMentionAutoComplete || showEmojiPicker;
    const prevModalState = usePrevious(modalState);
    useLayoutEffect(() => {
        if (modalState != prevModalState) {
            onModalChange?.(modalState);
        }
    }, [onModalChange, modalState, prevModalState]);

    // Document-level escape key handler (for when composer is not focussed)
    useEffect(() => {
        if (!showEmojiPicker) return;

        const check = (e: KeyboardEvent) => {
            // Ignore events for all keys bar 'Escape'
            if (e.key != "Escape") return;

            // Always close the emoji viewer if open
            if (!showEmojiPicker) return;

            e.preventDefault();
            focusTextArea();
            setShowEmojiPicker(false);
        };

        document.addEventListener("keydown", check);
        return () => document.removeEventListener("keydown", check);
    }, [showEmojiPicker, setShowEmojiPicker, focusTextArea]);

    const onTextAreaEscape = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        // Always close the mention autocomplete if open
        if (showMentionAutoComplete) {
            e.preventDefault();
            e.stopPropagation();
            setShowMentionAutoComplete(false);
        }
        // Blur the text area if focussed
        else if (textAreaRef.current) {
            e.preventDefault();
            textAreaRef.current.blur();
            escapeAction?.(e);
        }
    }, [escapeAction, showMentionAutoComplete, setShowMentionAutoComplete]);

    // Element-level key handler (for when composer is focussed)
    const onTextAreaKeyDown = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        switch (e.key) {
            case "z":
            case "Z": {
                if (e.ctrlKey) {
                    // Disable the undo shortcut as the undo stack interacts badly with the
                    // mention offset arithmetic. It will still be possible to undo via the
                    // context menu but it often has unexpected effects on the message content
                    e.preventDefault();
                    e.stopPropagation();
                    break;
                }
                break;
            }
        }
    }, []);

    const onTextAreaBlurWrapper = useCallback(() => {
        onEditorBlur?.();
        setCurrentlyTyping(false);
    }, [onEditorBlur]);

    // Get the text area style to copy to the highlighter
    const [textAreaStyle, setTextAreaStyle] = useState<Optional<CSSProperties>>(undefined);
    const doComputeTextAreaStyleRef = useRef(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!textAreaRef.current || !doComputeTextAreaStyleRef.current) {
            return;
        }
        doComputeTextAreaStyleRef.current = false;

        const style = extractCSSProperties(window.getComputedStyle(textAreaRef.current));
        // 24 == 2 * $composer-actions-padding-x
        style["width"] = `${textAreaRef.current.clientWidth + 24}px`;
        // 16 == 2 * $composer-actions-padding-y
        style["height"] = `${textAreaRef.current.clientHeight + 16}px`;

        setTextAreaStyle(style);
    });

    // Set the text and check for any style changes
    const onTextAreaChange = useCallback((value: string) => {
        setText(value);

        doComputeTextAreaStyleRef.current = true;
    }, [setText]);

    // Sync the text area scrolling with the highlighter
    const onTextAreaScroll = useCallback(() => {
        if (!textAreaRef.current || !highlighterRef.current) {
            return;
        }

        const { scrollTop, scrollLeft } = textAreaRef.current;
        highlighterRef.current?.scrollTo(scrollLeft, scrollTop);

        doComputeTextAreaStyleRef.current = true;
    }, []);

    useImperativeHandle(ref, () => ({
        focus: focusTextArea,
        blur: () => textAreaRef.current?.blur(),
        hasFocus: () => document.activeElement === textAreaRef.current,
    }), [focusTextArea]);

    useMetaInterest(showEmojiPicker, MetaInterestCounterKey.BlockHotkey);

    const onClickControlArea = useCallback((e: React.MouseEvent<HTMLElement>) => {
        if (e.currentTarget === e.target) {
            focusTextArea({ goToEnd: true });
        }
    }, [focusTextArea]);

    const debouncedSuggestionQueryString = useDebounceWithoutTimeout(
        draftText,
        undefined,
        messageHysteresisBondTitleSuggestion,
    );

    useConnectedEffect(() => {
        // We don't generate title suggestions for bonds that already created
        if (numberOfParticipants !== 0) return;
        // Whitespace at the start of the message is removed by
        // the selector. We need whitespace at the end as part of our
        // determination of when to fetch new suggestions. But we don't
        // want to include that whitespace in the soliciting text.
        const solicitingText = debouncedSuggestionQueryString.trimEnd();

        if (!solicitingText) {
            dispatch(clearBondTitleSuggestion());
            return;
        }

        if (solicitingText.length < minimumTriggerMessageLength) return;

        dispatch(
            fetchBondTitleSuggestionThunk({ bondContentDraft: solicitingText }),
        );
    }, [dispatch, debouncedSuggestionQueryString, numberOfParticipants]);

    const classes = classNames("c-composer", {
        "c-composer--bond": bondComposer,
    });

    const showHeader = bondComposer && discardAction;
    const headerClasses = classNames("c-composer__header", {
        "c-composer__header--bond": bondComposer,
    });

    return (
        <>
            {showMentionAutoComplete && !showEmojiPicker && mentionAnchor && mentionQuery &&
                createPortal(
                    <MentionAutoComplete
                        anchor={mentionAnchor}
                        query={mentionQuery}
                        insert={insertMention}
                        draftTarget={draftTarget}
                    />,
                    document.body,
                )}
            <div className={classes} data-testid={props.id}>
                {showEmojiPicker && (
                    <div ref={emojiPickerDivRef} className="c-emoji-wrapper">
                        <Picker
                            data={data}
                            onEmojiSelect={insertEmoji}
                            emojiSize={19}
                            previewPosition={"none"}
                            theme="dark"
                            autoFocus={true}
                        />
                    </div>
                )}
                {showHeader && (
                    <div className={headerClasses}>
                        <div className="c-composer-header-count">
                            {bondComposer && discardAction && (
                                <CloseButton
                                    title="Discard draft"
                                    onClick={discardAction}
                                    side={"composer"}
                                />
                            )}
                        </div>
                    </div>
                )}
                <div className="c-composer__actions">
                    <AudienceHint draftTarget={draftTarget} />
                    <MessageHighlighter
                        ref={highlighterRef}
                        text={draftText}
                        mentions={mentions}
                        prefixLength={prefixLength}
                        style={textAreaStyle}
                    />
                    <TextArea
                        id={props.id}
                        value={draftText}
                        caretHint={caretHint}
                        caretHintId={caretHintId}
                        placeholder={placeholder}
                        className="c-textarea--composer"
                        onKeyDown={onTextAreaKeyDown}
                        onValueChange={onTextAreaChange}
                        onEnter={complete}
                        onEscape={onTextAreaEscape}
                        onFocus={onTextAreaFocus}
                        onBlur={onTextAreaBlurWrapper}
                        onSelect={onSelectionChange}
                        onScroll={onTextAreaScroll}
                        maxLength={8000}
                        ref={textAreaRef}
                        style={{
                            height: `${Math.min(draftText.split("\n").length * 20, 320)}px`,
                        }}
                        tabIndex={tabIndex}
                    />
                    <div
                        className="c-composer__controls c-composer-controls"
                        onClick={onClickControlArea}
                    >
                        <div className="c-composer__btn-group">
                            {!isMobileBrowser() && (
                                <ComposerButton
                                    content="Emojis"
                                    onClick={() => setShowEmojiPicker(old => !old)}
                                    buttonRef={showEmojiPickerRef}
                                    extraStyle="c-btn-composer--emojis"
                                    title="Emoji"
                                />
                            )}
                            <AttachmentUploadControls draftTarget={draftTarget} />
                        </div>
                        <div className="c-composer__btn-group">
                            <CallControls
                                location={CallControlsLocation.LiveBond}
                                onToggle={onToggleMedia}
                            />
                        </div>
                        <div className="c-composer__btn-group">
                            {showCallLocationAction && (
                                <ComposerButton
                                    content="Set location"
                                    onClick={showCallLocationAction}
                                    extraStyle="c-btn-composer--location"
                                    title="Set call location"
                                />
                            )}
                            <ComposerButton
                                content={"Send"}
                                onClick={complete}
                                extraStyle="c-btn-composer--send"
                                disabled={msgSendableStatus != MessageSendableStatus.Ready}
                                title={sendButtonText}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default MessageComposer;
