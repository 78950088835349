import { useCallback, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { completeAuthRequest, verifyPasscode } from "@/api/auth";
import { finalLoginSteps } from "@/auth/login";
import { upsertOrgs } from "@/features/squads";
import log from "@/misc/log";
import { useAppDispatch } from "@/store/redux";
import { z } from "zod";

// The shape of the location state passed from EmailView
const LocationStateSchema = z.object({
    codeVerifier: z.string(),
    isNewUser: z.boolean(),
    userEmail: z.string(),
    userId: z.string(),
});

export default function PasscodeView(): React.JSX.Element {
    const [params, _] = useSearchParams();
    const authReqId = params.get("auth_request_id");

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const locationState = LocationStateSchema.safeParse(location.state);
    const { codeVerifier, isNewUser, userEmail, userId } = locationState.success
        ? locationState.data : {};

    const [otp, setOtp] = useState("");

    const [isFailed, setIsFailed] = useState(false);

    const signIn = useCallback(async () => {
        try {
            if (!authReqId || !codeVerifier || !userId) {
                setIsFailed(true);
                return;
            }

            const passcodeResp = await verifyPasscode(userId, otp, {
                authRequestId: authReqId,
                codeVerifier,
            });
            if (!passcodeResp.isSuccess) {
                log.error("Failed to sign in", passcodeResp.reason);
                setIsFailed(true);
                return;
            }

            if (isNewUser) {
                const userDetailsUrl = `/login/details?auth_request_id=${authReqId}`;
                navigate(userDetailsUrl, { replace: true, state: { codeVerifier, userId } });
                return;
            }

            const completeAuthResp = await completeAuthRequest(authReqId, codeVerifier);
            if (!completeAuthResp.isSuccess) {
                log.error("Failed to complete auth request:", completeAuthResp.reason);
                setIsFailed(true);
                return;
            }
            const { callbackUri } = completeAuthResp.response;

            const cbResponse = await fetch(callbackUri, {
                headers: {
                    "X-Avos-302-To-200": "true",
                },
            });

            const [_id, usersAndOrgs] = await finalLoginSteps(cbResponse);

            // !!! We never get here (yet). The above function will reload the window.

            // This is pointless, but harmless, today because we're not running on a store that
            // we're going to continue using (after setting the user ID and reloading below
            // we'll transition to an indexedDB). But I leave it here to show we could make
            // use of the org data we're provided in the future.
            dispatch(upsertOrgs(usersAndOrgs.filter(x => !!x.org).map(u => u.org)));

            // FIXME - should use actual redirect URI, or whatever, to ensure we get to the
            // right place.
            navigate("/bond");
        }
        catch (err) {
            log.error("Failed to sign in with passcode", err);
            setIsFailed(true);
        }
    }, [authReqId, codeVerifier, isNewUser, navigate, otp, userId, dispatch]);

    useEffect(() => {
        if (otp.length >= 8) {
            signIn();
        }
    }, [otp, signIn]);

    if (!authReqId) {
        log.error("No auth request ID provided");
        return <Navigate to="/login" />;
    }

    if (!userId || !userEmail) {
        log.error("No user ID or email provided");
        return <Navigate to="/login" />;
    }

    return (
        <div className="c-signin-wrapper">
            <fieldset className="c-signin">
                <>
                    <div>
                        <div className="c-signin__value">Hello {userEmail}!</div>
                    </div>
                    <div className="c-signin__element c-signin__element--active">
                        <label htmlFor="otp" className="c-signin__label">Passcode</label>
                        <div className="c-signin__value">
                            <input
                                type="text"
                                id="otp"
                                name="signin-input"
                                placeholder="Enter 8-digit OTP"
                                maxLength={8}
                                value={otp}
                                autoComplete="off"
                                className="c-signin__input"
                                onChange={e => setOtp(e.target.value)}
                            />
                        </div>
                    </div>
                </>
                {isFailed && (
                    <div className="c-signin__element c-signin__element--error">
                        <label className="c-signin__error">Failed to sign in</label>
                    </div>
                )}
            </fieldset>
        </div>
    );
}
