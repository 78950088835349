import { FeatureFlagged } from "@/components/FeatureFlags.tsx";
import { BondTopbarActions } from "@/components/gui/BondActions.tsx";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals.ts";
import { NewBondLocationState } from "@/misc/locationState";
import { Optional } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import BondChatParticipants from "../components/BondChatParticipants.tsx";
import { FilterBondsAndPresenceHeader } from "../components/FilterButtonAndPresenceHeader";
import BondPrivacyDomain from "../components/gui/BondPrivacyDomain";
import LinkButton from "../components/gui/LinkButton";
import SensitiveText from "../components/gui/SensitiveText";
import { NewBondTitleBar } from "../components/NewBondTitleBar";
import PresenceHeader from "../components/PresenceHeader";
import { useSidebar } from "../context/SidebarContext.tsx";
import * as d from "../domain/domain";
import { canOpenLiveView, orderParticipants } from "../domain/rtc.ts";
import { selectCurrentUserId } from "../features/auth.ts";
import { selectBondTitle } from "../features/bonds";
import { searchThunk, selectQuery, setQuery } from "../features/search";
import { selectSquadById } from "../features/squads";
import useRtcSessionContext from "../hooks/rtc/useRtcSessionContext.ts";
import useAddressParams from "../hooks/useAddressParams";
import { useNavigateBack } from "../hooks/useNavigateBack";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { isMobileBrowser } from "../misc/mobile";
import { mobilePathToViewName, MobileViewName } from "../misc/mobilePathToTab";

import { QueryAnswer } from "@/components/QueryAnswer";
import { newQueryId } from "@/domain/intel";
import {
    purgeQuery,
    queryThunk,
    selectCurrentQueryId,
    updateQueryId,
    updateQueryQuestion,
} from "@/features/intel";
import useLocalDispatch from "@/hooks/useLocalDispatch";

export function DesktopTopbarViewInternal(): React.JSX.Element {
    const { squadId } = useAddressParams();
    const { pathname } = useLocation();

    const dispatch = useAppDispatch();
    const localDispatch = useLocalDispatch();

    const inputRef = useRef<HTMLInputElement>(null);

    const isDiscover = pathname === "/discover";
    const isNewBond = pathname === "/bond/new";

    const query = useSelector(selectQuery);
    const currentQueryId = useAppSelector(selectCurrentQueryId);

    const searchEnabled = useBooleanFeatureFlag("search-feature-frontend");

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const newQuery = e.target.value;
        localDispatch(setQuery(newQuery));
        if (searchEnabled) dispatch(searchThunk({ query: newQuery }));
    }, [dispatch, localDispatch, searchEnabled]);

    const handleQuestionAsk = useCallback((q: string) => {
        if (currentQueryId) {
            localDispatch(purgeQuery(currentQueryId));
        }

        const id = newQueryId();
        localDispatch(updateQueryId(id));
        localDispatch(updateQueryQuestion({ question: q, queryId: id }));

        // I need a way to close the stream / abort the query
        // This dispatch gives an abort function, but I can't serialize it
        // (meaning I can't store it in the state). To get around this, I'm
        // storing it in a local state, which is a bit of a hack.

        dispatch(queryThunk({ question: q, queryId: id }));
    }, [localDispatch, dispatch, currentQueryId]);

    const newBondLocationState = useMemo<Optional<NewBondLocationState>>(
        () =>
            squadId &&
            {
                newBond: {
                    id: nanoid(),
                    navigatedFromSquad: squadId,
                    prefillAudienceMembers: [squadId],
                },
            },
        [squadId],
    );

    const handleClearClick = () => {
        dispatch(setQuery(""));
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    return (
        <>
            <header className="c-header c-header--desktop">
                <FeatureFlagged flag={"search-feature-frontend"} match={true}>
                    {!isDiscover && (
                        <div className="c-bond-search c-bond-search--desktop">
                            <input
                                type="text"
                                placeholder="Search"
                                className="c-bond-search__input ph-no-capture"
                                required
                                ref={inputRef}
                                value={query}
                                onChange={handleInputChange}
                                onKeyDown={e => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        handleQuestionAsk(query);
                                    }
                                }}
                                id="searchBar"
                            />
                            <label
                                className="c-bond-search__icon c-bond-search__icon--search"
                                htmlFor="searchBar"
                            >
                                Search
                            </label>
                            <button
                                className="c-bond-search__clear"
                                disabled={query.length === 0}
                                onClick={handleClearClick}
                            >
                                Clear
                            </button>
                        </div>
                    )}
                </FeatureFlagged>
                <LinkButton
                    to={"/bond/new"}
                    state={newBondLocationState}
                    className="c-btn-create-bond c-btn-create-bond--desktop"
                    title="Create bond"
                    // TODO: remove this BODGE once proper in-bond/new-bond topbars are implemented
                    // Use "visibility: hidden" rather than removing the component so the flex layout doesn't change
                    style={{ visibility: isNewBond ? "hidden" : "unset" }}
                >
                    Create bond
                </LinkButton>
            </header>
            {!isDiscover && (
                <FeatureFlagged flag="ai-querying" match={true}>
                    <QueryAnswer queryId={currentQueryId!} />
                </FeatureFlagged>
            )}
            {!isDiscover && query.length == 0 && <FilterBondsAndPresenceHeader />}
        </>
    );
}

function NewBondTopbar(): React.JSX.Element {
    const { navigateBack } = useNavigateBack();
    const backAction = navigateBack;
    const isMobile = isMobileBrowser();

    const className = classNames("c-header", {
        "c-header--desktop": !isMobile,
    });

    return (
        <header className={className}>
            <NewBondTitleBar backAction={backAction} tabIndex={1} />
        </header>
    );
}

function DesktopTopbarView(): React.JSX.Element {
    const { pathname } = useLocation();
    const { bondId } = useAddressParams();
    const isBond = bondId !== undefined;
    const isNewBond = pathname === "/bond/new";

    return (
        <>
            {!isBond && !isNewBond && <DesktopTopbarViewInternal />}
            {isBond && !isNewBond && <BondViewTopbar />}
            {isNewBond && <NewBondTopbar />}
        </>
    );
}

function MobileTopbarViewInternal({ view }: { view: MobileViewName; }): React.JSX.Element {
    const { navigateBack } = useNavigateBack();
    const { squadId } = useAddressParams();
    const squad = useSelectorArgs(selectSquadById, squadId);

    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const asideRef = useRef<HTMLDivElement>(null);

    const closeAside = useCallback((event: TouchEvent | MouseEvent) => {
        if (
            buttonRef.current &&
            !buttonRef.current.contains(event.target as Node) &&
            asideRef.current &&
            !asideRef.current.contains(event.target as Node)
        ) {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("touchstart", closeAside);
            document.addEventListener("mousedown", closeAside);
            return () => {
                document.removeEventListener("touchstart", closeAside);
                document.removeEventListener("mousedown", closeAside);
            };
        }
    }, [isOpen, closeAside]);

    const title = {
        mybonds: "Inbox",
        mysquads: "Groups",
        bond: "",
        squad: (squad?.name || ""),
        discover: "Discover",
    }[view];

    const showBackButton = view === "squad";
    const showPlusButton = view === "mybonds" || view === "squad";

    const { pathname } = useLocation();

    const className = classNames("c-header", {
        "c-header--groups": mobilePathToViewName(pathname) === "mysquads",
    });

    return (
        <header className={className}>
            {showBackButton &&
                (
                    <button
                        className="c-btn-return c-btn-return--groups"
                        title="Return"
                        onClick={() => navigateBack()}
                    >
                        Return
                    </button>
                )}

            <h1 className="c-header__title">{title}</h1>

            {showPlusButton &&
                (
                    <LinkButton to={"/bond/new"} className="c-btn-create-bond" title="Create bond">
                        Create bond
                    </LinkButton>
                )}
        </header>
    );
}

function BondViewTopbar(): React.JSX.Element {
    const { navigate, navigateBack } = useNavigateBack();
    const { bondId } = useAddressParams();
    const isMobile = isMobileBrowser();
    const bondTitle = useSelectorArgs(selectBondTitle, bondId);

    const showEmoji = useBooleanFeatureFlag("display-bond-emoji");

    const title = `${showEmoji && bondTitle.emoji ? bondTitle.emoji + " " : ""}${bondTitle.title}`;

    const currentUserId = useAppSelector(selectCurrentUserId);

    const { callParticipants } = useRtcSessionContext();
    const { orderedParticipants } = useShallowEqualsMemo(
        () => orderParticipants(callParticipants),
        [callParticipants],
    );

    const canOpenLiveViewMemo = useMemo(
        () => canOpenLiveView(orderedParticipants, currentUserId),
        [orderedParticipants, currentUserId],
    );

    // Memoised for (indirect) context dependency
    const participantsComponent = useMemo(() =>
        bondId && (
            <BondChatParticipants
                bondId={bondId}
                canOpenLiveView={canOpenLiveViewMemo}
            />
        ), [bondId, canOpenLiveViewMemo]);

    const showRenameBondModalAction = useCallback(() => {
        if (bondId) {
            navigate(`${d.extractUUID(bondId)}/modify`);
        }
    }, [navigate, bondId]);

    const { isOpen } = useSidebar();

    const nonMobileHeader = (
        <header className="c-header c-header--desktop">
            <button
                className={`c-btn-return c-btn-return--desktop ${!isOpen ? "sidebar-closed" : ""}`}
                onClick={navigateBack}
                title="Return"
            >
                Return
            </button>
            <div
                className="c-bond-title c-bond-title--desktop"
                onClick={showRenameBondModalAction}
            >
                <div className="c-bond-title__participants c-bond-title__participants--desktop">
                    <BondPrivacyDomain id={bondId} />
                </div>
                <div className="c-middot c-bond-title__separator">&#183;</div>
                <h1 className="c-bond-title__title c-bond-title__title--desktop u-truncate-auto">
                    <SensitiveText>{title}</SensitiveText>
                </h1>
            </div>
        </header>
    );

    const mobileHeader = (
        <header className="c-header">
            <button
                className="c-btn-return"
                onClick={navigateBack}
                title="Return"
            >
                Return
            </button>
            <div
                className="c-bond-title"
                onClick={showRenameBondModalAction}
            >
                <h1 className="c-bond-title__title u-truncate-title">
                    <SensitiveText>{title}</SensitiveText>
                </h1>
                <div className="c-bond-title__participants">
                    <BondPrivacyDomain id={bondId} />
                </div>
            </div>
            {bondId && <BondTopbarActions bondId={bondId} />}
        </header>
    );

    return (
        <div className="c-header-wrapper">
            {!isMobile ? nonMobileHeader : mobileHeader}
            {participantsComponent}
        </div>
    );
}

function MobileTopbarView(): React.JSX.Element {
    const { pathname } = useLocation();
    const view = mobilePathToViewName(pathname);
    const isBond = view === "bond";
    const isNewBond = pathname === "/bond/new";
    const query = useSelector(selectQuery);

    return (
        <>
            {!isBond && !isNewBond && (
                <>
                    <MobileTopbarViewInternal view={mobilePathToViewName(pathname)} />
                    {query.length == 0 && <PresenceHeader />}
                </>
            )}
            {isBond && !isNewBond && <BondViewTopbar />}
            {isNewBond && <NewBondTopbar />}
        </>
    );
}

export function TopbarView(): React.JSX.Element {
    return isMobileBrowser() ? <MobileTopbarView /> : <DesktopTopbarView />;
}
