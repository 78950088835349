import { transport } from "@/api/transport";
import {
    fromProtoBondId,
    fromProtoChannelId,
    pbAudienceOpSet,
    pbBlobId,
    pbOrgId,
    pbTimestamp,
    pbUserId,
} from "@/api/util";
import { AudienceMember, isSquadId } from "@/domain/audience";
import { BondOverview, BondTitles } from "@/domain/bonds";
import * as d from "@/domain/domain";
import { audienceOpForAdd, audienceOpForMention, filterMentions, Mention } from "@/domain/mentions";
import { UnsentChatMessage } from "@/domain/messages";
import log from "@/misc/log";
import { createPromiseClient } from "@connectrpc/connect";
import { BondService } from "../../gen/proto/bonds/bonds_connect";
import {
    CreateBondFromCallRequest,
    CreateBondFromCallResponse,
    CreateBondFromChatMessageRequest,
    CreateBondFromChatMessageResponse,
    CreateBondRequest,
} from "../../gen/proto/bonds/bonds_pb";
import { PrivacyLevel } from "../../gen/proto/domain/domain_pb";

export const service = createPromiseClient(BondService, transport);
export default service;

// Both createBond functions return a BondOverview that is vaguely the same as what we expect to be
// returned by the server.
//
// TODO: put this into the redux store while we wait for the server to respond and then update it
// when the server responds. This will allow the user to create the bond and then immediately see it in the UI.

interface CreateBondParams {
    senderId: d.UserId;
    orgId: d.OrgId;
    privacy?: PrivacyLevel;
    bondTitles?: BondTitles;

    // Don't have mentions when we create bond from call,
    // but always have an audience
    audience: AudienceMember[];
    mentions?: Mention[];

    idempotentKey: string;
}

function getCreateBondRequest(
    params: CreateBondParams,
) {
    const {
        senderId,
        orgId,
        privacy,
        bondTitles,
        audience,
        idempotentKey,
    } = params;

    const mentions = params.mentions ?? [];
    const audienceOps = [
        ...audience.map(audienceOpForAdd),
        ...mentions.map(audienceOpForMention),
    ];

    // Eagerly extract squads from mentions for responsiveness in the UI
    const { squadIds: mentionedSquadIds } = filterMentions(mentions);
    const squadIds = [...mentionedSquadIds, ...audience.filter(isSquadId)];

    const calcPrivacy = privacy ??
        ((squadIds.length == 0) ? PrivacyLevel.PRIVATE : PrivacyLevel.OPEN);

    return {
        bond: new CreateBondRequest({
            userId: pbUserId(senderId),
            orgId: pbOrgId(orgId),
            privacy: calcPrivacy,
            bondTitles: {
                aiGeneratedTitle: bondTitles?.aiGeneratedTitle ?? "",
                userSpecifiedTitle: bondTitles?.userSpecifiedTitle ?? "",
            },
            idempotentKey,
        }),
        audienceOps: pbAudienceOpSet(audienceOps),
        squadIds,
        privacy: calcPrivacy,
    };
}

export interface CreateBondFromMsgParams extends CreateBondParams {
    msg: UnsentChatMessage;
    officialAttachmentIds?: d.BlobId[];
}

export async function createBondFromMsg(params: CreateBondFromMsgParams): Promise<BondOverview> {
    const {
        senderId,
        orgId,
        bondTitles,
        msg,
        officialAttachmentIds,
    } = params;

    const { bond, audienceOps, squadIds, privacy } = getCreateBondRequest(params);
    const req = new CreateBondFromChatMessageRequest({
        bond,
        audienceOps,
        message: {
            clientTxAt: pbTimestamp(msg.clientTxTs),
            content: JSON.stringify(msg.content),
            attachmentIds: officialAttachmentIds?.map(pbBlobId),
        },
    });

    let resp: CreateBondFromChatMessageResponse;
    try {
        resp = await service.createBondFromChatMessage(req);
    }
    catch (e) {
        log.error(e);
        return Promise.reject(e);
    }

    return {
        id: fromProtoBondId(resp.response?.newBondId),
        channelId: fromProtoChannelId(resp.response?.newChannelId),
        orgId,
        squadIds,
        privacy,
        knowledge: {
            userSpecifiedTitle: bondTitles?.userSpecifiedTitle ?? "",
            aiGeneratedTitle: bondTitles?.aiGeneratedTitle ?? "",
            summary: "",
            detailedSummary: "",
            imageUrl: "",
        },
        contributors: [senderId],
        followers: [senderId], // Could add mentioned users here, except we need to know if they're external or not...
        externalUsers: [],

        lastActivityAt: msg.clientTxTs,
        maxSequenceNumber: 1,
        liveCallIds: [],
    };
}

export type CreateBondFromCallParams = CreateBondParams & Pick<UnsentChatMessage, "clientTxTs">;

/** Does NOT create the call itself.
 *
 * So it is possible to create an empty bond if this is called without
 * subsequently going live in that bond.
 */
export async function createBondFromCall(params: CreateBondFromCallParams): Promise<BondOverview> {
    const {
        senderId,
        orgId,
        bondTitles,
        clientTxTs,
    } = params;

    const { bond, audienceOps, squadIds, privacy } = getCreateBondRequest(params);
    const req = new CreateBondFromCallRequest({ bond, audienceOps });

    let resp: CreateBondFromCallResponse;
    try {
        resp = await service.createBondFromCall(req);
    }
    catch (e) {
        log.error(e);
        return Promise.reject(e);
    }

    return {
        id: fromProtoBondId(resp.newBond?.newBondId),
        channelId: fromProtoChannelId(resp.newBond?.newChannelId),
        orgId,
        squadIds,
        privacy,
        knowledge: {
            userSpecifiedTitle: bondTitles?.userSpecifiedTitle ?? "",
            aiGeneratedTitle: bondTitles?.aiGeneratedTitle ?? "",
            summary: "",
            detailedSummary: "",
            imageUrl: "",
        },
        contributors: [senderId],
        followers: [senderId], // Could add mentioned users here, except we need to know if they're external or not...
        externalUsers: [],

        lastActivityAt: clientTxTs,
        maxSequenceNumber: 1,

        // Currently don't create the call when we create the bond, so no call IDs yet.
        liveCallIds: [],
    };
}
