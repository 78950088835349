import { DateTime, Interval } from "luxon";

export interface IntervalDescCatchup {
    descFrom?: string;
    descTo: string;
}

export function describeTimeAgoCatchup(
    now: DateTime<boolean>,
    fromNum: number,
    describeLive: boolean,
    liveSessionStartNum?: number,
): IntervalDescCatchup {
    const from = DateTime.fromMillis(fromNum);
    const liveSessionStart = liveSessionStartNum !== undefined
        ? DateTime.fromMillis(liveSessionStartNum) : undefined;

    const interval = Interval.fromDateTimes(from, now);

    const v = intervalDescriptionCatchup(interval, describeLive, liveSessionStart);

    return { descFrom: v?.descFrom, descTo: v.descTo };
}

function intervalDescriptionCatchup(
    i: Interval,
    describeLive: boolean,
    liveSessionStart?: DateTime<boolean>,
): { descFrom?: string; descTo: string; } {
    const descriptionTo = !describeLive && liveSessionStart !== undefined
        ? liveSessionStart.toFormat(`HH:mm`) : "Now";
    if (describeLive && liveSessionStart) {
        return {
            descFrom: "Live " + liveSessionStart.toFormat(`HH:mm`),
            descTo: descriptionTo,
        };
    }

    if (!i.start || !i.end || !i.isValid) {
        return { descTo: descriptionTo };
    }
    const doNotReturnFromField = !describeLive && liveSessionStart
        ? Interval.fromDateTimes(i.start, liveSessionStart).count("minutes") < 3
        : i.count("minutes") < 3;
    if (doNotReturnFromField) {
        return { descTo: descriptionTo };
    }

    const dayCount = i.count("days");

    let descriptionFrom: string = "";
    if (dayCount == 1) {
        descriptionFrom = i.start.toFormat("HH:mm");
    }
    if (dayCount == 2) {
        descriptionFrom = i.start.toFormat("'Yesterday'");
    }
    if (2 < dayCount && dayCount <= 7) {
        descriptionFrom = i.start.toFormat("cccc");
    }

    if (descriptionFrom) {
        return { descFrom: descriptionFrom, descTo: descriptionTo };
    }

    const yearCount = i.count("years");

    if (yearCount == 1) {
        descriptionFrom = i.start.toFormat("LLLL d");
    }
    else {
        descriptionFrom = i.start.toFormat("LLLL d yyyy");
    }
    return { descFrom: descriptionFrom, descTo: descriptionTo };
}
