import { useCallback, useEffect, useMemo, useState } from "react";

import {
    BackoffProps,
    BackoffState,
    backoffUpdater,
    defaultBackoffProps,
    defaultBackoffState,
} from "@/domain/backoff";
import useTimerTarget from "@/hooks/useTimerTarget";

type BackoffAndPermittedState = BackoffState & {
    permitted: boolean;
    forbidden: boolean;
};

export type ActionBeginReturnType = (succeeded: boolean) => void;

/** @function Manage the backoff and retry logic around an action that we want
 * to retry after failure (e.g. sending an RPC, uploading a file...).
 *
 * @param props the backoff config - e.g. backoff interval, max delay exponent
 * @returns an object with the following fields:
 * - `actionIsPermitted` - a boolean indicating whether the action is ready after backoff
 * - `attempts` - the number of *failed* attempts
 * - `actionBegin` - a callback to begin the action, which returns callbacks to mark
 * the action as succeeded/failed. NB: actionBegin should be called at the start
 * of each attempt of the action.
 * - `resetBackoffState` - a callback to reset the backoff state back to the default.
 */
const useBackoff = (props: BackoffProps = defaultBackoffProps) => {
    const { intervalMs, truncateAt } = props;

    if (props.truncateAt && props.truncateAt < 0) {
        throw new Error("truncateAt must be zero or positive");
    }

    const { nowMs, setTarget } = useTimerTarget();

    const defaultState = useCallback((): BackoffAndPermittedState => ({
        ...defaultBackoffState(),
        permitted: true,
        forbidden: false,
    }), []);

    const updateBackoff = useMemo(() => backoffUpdater({ intervalMs, truncateAt }), [
        intervalMs,
        truncateAt,
    ]);

    const [backoff, setBackoff] = useState<BackoffAndPermittedState>(defaultState());

    const delayElapsed = useCallback(
        () => setBackoff(backoff => ({ ...backoff, permitted: true })),
        [setBackoff],
    );

    const updateForbidden = useCallback((forbidden: boolean) => {
        setBackoff(backoff => ({ ...backoff, forbidden }));
    }, [setBackoff]);

    const resetBackoffState = useCallback(() => {
        setBackoff(defaultState());
    }, [setBackoff, defaultState]);

    const actionFailed = useCallback(() => {
        setBackoff(({ attempts }) => ({
            ...updateBackoff(attempts),
            permitted: false,
            forbidden: false,
        }));
    }, [setBackoff, updateBackoff]);

    const actionBegin = useCallback((): ActionBeginReturnType => {
        updateForbidden(true);

        let finished = false;

        return (succeeded: boolean) => {
            if (finished) return;
            finished = true;

            if (succeeded) {
                resetBackoffState();
            }
            else {
                actionFailed();
            }
        };
    }, [actionFailed, resetBackoffState, updateForbidden]);

    const { permitted, forbidden, nextAttempt } = backoff;

    useEffect(() => {
        if (permitted) return;

        if (nextAttempt === undefined || nextAttempt <= nowMs) {
            delayElapsed();
        }
        else {
            setTarget(nextAttempt);
        }
    }, [nowMs, delayElapsed, setTarget, permitted, nextAttempt, updateForbidden]);

    return {
        actionIsPermitted: permitted && !forbidden,
        attempts: backoff.attempts,
        actionBegin,
        resetBackoffState,
    };
};

export default useBackoff;
