import { createSelectorCreator, lruMemoize } from "@reduxjs/toolkit";
import { DependencyList } from "react";

import { weakMapMemoize } from "@/features/weakMapMemoize";
import { arraysAreEqual } from "@/misc/primatives";
import shallowEqual from "@/misc/shallowEqual";
import type { RootState } from "@/store/types";

export const shallowArrayEqualSelectorCreator = createSelectorCreator(weakMapMemoize, {
    resultEqualityCheck: arraysAreEqual,
});

export const memoizeOptions = {
    // lruMemoize is a bad choice if you have more consumers than the
    // configured number of slots in the LRU. For cases with unbounded
    // consumers, don't try to guess a number of slots. Instead, use a
    // weakMapMemoize. This will use a map `input values` => `selected value`,
    // with a weak reference to the input values, giving unbounded caching
    // without causing memory leaks. It's possible to clear the cache, but
    // we don't bother here (yet).
    weakMap: {
        memoize: weakMapMemoize,
    },
    lru: {
        memoize: lruMemoize,
    },
    weakMapShallow: {
        memoize: weakMapMemoize,
        memoizeOptions: {
            resultEqualityCheck: shallowEqual,
        },
    },
    lruShallow: {
        memoize: lruMemoize,
        memoizeOptions: {
            resultEqualityCheck: shallowEqual,
        },
    },
};

export type AnySelector<Deps extends DependencyList = DependencyList, R = any> = (
    state: RootState,
    ...deps: Deps
) => R;

export type SelectorDeps<F> = F extends AnySelector<infer Deps, any> ? Deps
    : never;

export type SelectorPair<Deps extends DependencyList, R, T> = [
    first: AnySelector<Deps, R>,
    second: AnySelector<Deps, T>,
];

// Take a more general selector type and forget all non-function information about it.
// `create(App)Selector` typing isn't quite kosher, I think, so we need to forget
// some of its internal state annotation.
type FunctionType<
    S extends AnySelector<Deps, R>,
    Deps extends DependencyList,
    R = ReturnType<S>,
> = S extends AnySelector<Deps, R> ? AnySelector<Deps, R> : never;

export const createSelectorPair = <
    S extends AnySelector<Deps, R>,
    U extends AnySelector<Deps, T>,
    Deps extends DependencyList = SelectorDeps<S>,
    R = ReturnType<S>,
    T = ReturnType<U>,
>(
    first: FunctionType<S, Deps, R>,
    second: FunctionType<U, Deps, T>,
): SelectorPair<Deps, R, T> => {
    return [first, second];
};
