import { SeverityLevel } from "@sentry/react";

export type logProps = Record<string, any>;
// When we actually come to produce a logline, we want our props
// to be "string"y, corresponding to a(n implicit) cast
// `logProps` -> `logLineProps`
export type logLineProps = Record<string, string>;

export enum LogLevel {
    Debug = 70,
    Info = 80,
    Warn = 90,
    Error = 100,
}

export const logLevelProps: Record<LogLevel, {
    name: string;
    fmt: [string, string];
    sentry: SeverityLevel;
    console: () => typeof console.log;
}> = {
    [LogLevel.Debug]: {
        name: "DBG",
        fmt: ["color: green; font-style: italic;", "font-style: italic;"],
        sentry: "debug",
        console: () => console.debug,
    },
    [LogLevel.Info]: {
        name: "INF",
        fmt: ["", ""],
        sentry: "info",
        console: () => console.info,
    },
    [LogLevel.Warn]: {
        name: "WRN",
        fmt: ["color: orange; font-weight: bold;", "color: orange;"],
        sentry: "warning",
        console: () => console.warn,
    },
    [LogLevel.Error]: {
        name: "ERR",
        fmt: ["color: red; font-weight: bold;", "color: red;"],
        sentry: "error",
        console: () => console.error,
    },
};

export interface LogLine {
    msg: string;
    level: LogLevel;
    tsUtc: number;

    args?: string[];
    props?: logLineProps;
    tabId?: string;
}
