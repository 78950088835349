import { createBrowserRouter } from "react-router-dom";

type Router = ReturnType<typeof createBrowserRouter>;

// We do this juggling to avoid any users of our Router needing to import Routes, which
// depends on a huge amount of the UI components, which would likely result in an
// import loop.
export const { getRouter, setRouter } = (() => {
    let router: Router;
    return {
        getRouter: () => router,
        setRouter: (r: Router) => {
            router = r;
        },
    };
})();
