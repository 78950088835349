import { useMemo } from "react";

import { ContentMention } from "@/domain/mentions";
import { useShallowEquals } from "@/hooks/useShallowEquals";
import { NumberRange, Optional } from "@/misc/types";
import { escapeForRegex, rangesIntersect } from "@/misc/utils";

export interface AutoCompleteQuery {
    trigger: string;
    text: string;
    range: NumberRange;
}

interface useAutoCompleteQueryOptions {
    maxLength?: number;
    ignoreRanges?: NumberRange[];
}

export const ignoreRangesFromMentions = (mentions?: ContentMention[]): NumberRange[] =>
    mentions?.map(m => ({ start: m.startOffset, end: m.endOffset + 1 })) ?? [];

export const useAutoCompleteQuery = (
    triggers: string[],
    text: string,
    offset?: number,
    options: useAutoCompleteQueryOptions = {},
): Optional<AutoCompleteQuery> => {
    const maxLength = options.maxLength ?? 1000;

    const memoisedIgnoreRanges = useShallowEquals(options.ignoreRanges ?? []);

    const offsetInIgnoreRange = useMemo(
        () =>
            offset === undefined ||
            memoisedIgnoreRanges.some(r => r.start <= offset && offset < r.end),
        [offset, memoisedIgnoreRanges],
    );

    const memoisedTriggers = useShallowEquals(triggers);

    const re = useMemo(() => {
        const mergedTriggers = memoisedTriggers
            .map(escapeForRegex)
            .map(t => `(?:${t})`)
            .join("|");

        // (?<=^|\\s|\\p{Ps}|\\p{Pe}) : Positive lookbehind for start of string, whitespace, or opening/closing punctuation
        // (${mergedTriggers})        : Any of the given trigger strings
        // ([^\\s\\p{P}]*)$           : Any number of non-whitespace non-punctuation characters until the end of the string
        return new RegExp(`(?<=^|\\s|\\p{Ps}|\\p{Pe})(${mergedTriggers})([^\\s\\p{P}]*)$`, "u");
    }, [memoisedTriggers]);

    const query = useMemo(() => {
        if (offsetInIgnoreRange || offset === undefined) {
            return undefined;
        }

        const windowStart = Math.max(0, offset - maxLength);
        const prefix = text.substring(windowStart, offset);

        const match = re.exec(prefix) ?? undefined;
        return match && {
            trigger: match[1],
            text: match[2],
            range: {
                start: windowStart + match.index,
                end: windowStart + match.index + match[0].length,
            },
        };
    }, [re, text, offset, maxLength, offsetInIgnoreRange]);

    const queryIntersectsIgnoreRange = useMemo(
        () =>
            query === undefined ||
            memoisedIgnoreRanges.some(r => rangesIntersect(r, query.range)),
        [query, memoisedIgnoreRanges],
    );

    return queryIntersectsIgnoreRange ? undefined : query;
};

export default useAutoCompleteQuery;
