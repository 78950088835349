import { useEffect, useState } from "react";

import { selectWakeLockInterest, setWakeLockHeld } from "@/features/meta";
import useLocalDispatch from "@/hooks/useLocalDispatch";
import log from "@/misc/log";
import { useAppSelector } from "@/store/redux";

const isScreenLockSupported = "wakeLock" in navigator;

const isCurrentTabVisible = () => (document.visibilityState === "visible");

const WakeLockManager = (): React.JSX.Element => {
    const [visible, setVisible] = useState(isCurrentTabVisible());

    // Whether we hold the wake lock is tab-specific.
    const localDispatch = useLocalDispatch();

    const wakeLockInterest = useAppSelector(selectWakeLockInterest);

    useEffect(() => {
        if (isScreenLockSupported && visible && wakeLockInterest > 0) {
            const promise = navigator.wakeLock.request("screen")
                .then(sentinel => {
                    localDispatch(setWakeLockHeld(true));
                    log.info("Screen Wake Lock acquired");
                    return sentinel;
                }, e => log.warn(`Error acquiring wakeLock:`, e));

            return () => {
                promise.then(sentinel => {
                    sentinel?.release();
                    localDispatch(setWakeLockHeld(false));
                    log.info("Screen Wake Lock released");
                });
            };
        }
    }, [localDispatch, visible, wakeLockInterest]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            setVisible(isCurrentTabVisible());
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    return <></>;
};

export default WakeLockManager;
