import { createContext, PropsWithChildren } from "react";

import { MediaToggleMap } from "@/domain/mediaDevices";
import { Participant } from "@/domain/rtc";
import { selectSelectedBondId } from "@/features/bonds";
import useRtcSessionMultitasking, {
    UseRtcSessionMultitaskingProps,
} from "@/hooks/rtc/useRtcSessionMultitasking";
import { useAppSelector } from "@/store/redux";

export interface RtcSession {
    callParticipants: Participant[];
    isMultitasking: boolean;
    mediaControls?: MediaToggleMap;
    leaveSession?: () => void;
    updateLocationConfig?: (location: string, receiveMediaFromSameLocation?: boolean) => void;
}

export const RtcSessionContext = createContext<RtcSession>({
    callParticipants: [],
    isMultitasking: false,
});

export type RtcSessionContextProviderProps =
    & PropsWithChildren
    & Pick<UseRtcSessionMultitaskingProps, "disableMultitasking">;

export default function RtcSessionContextProvider(
    props: RtcSessionContextProviderProps,
): React.JSX.Element {
    const { disableMultitasking } = props;

    const bondId = useAppSelector(selectSelectedBondId);
    const context: RtcSession = useRtcSessionMultitasking({ bondId, disableMultitasking });

    return (
        <RtcSessionContext.Provider value={context}>
            {props.children}
        </RtcSessionContext.Provider>
    );
}
