import * as d from "@/domain/domain";
import { SquadOverview } from "@/domain/squads";
import { UserOverview } from "@/domain/users";
import { Optional } from "@/misc/types";

export type AudienceMember =
    | d.SquadId
    | d.UserId;

export type AudienceMemberOverview =
    | SquadOverview
    | UserOverview;

export enum AudienceAction {
    Unspecified = "unspecified",
    Add = "add",
    AddNotify = "add_notify",
    Remove = "remove",
}

export type AudienceOp = {
    target: AudienceMember;
    action: AudienceAction;
};

export const isSquadId = (member: Optional<AudienceMember>): member is d.SquadId =>
    !!member?.startsWith("urn:beyond:squad:");

export const isUserId = (member: Optional<AudienceMember>): member is d.UserId =>
    !!member?.startsWith("urn:beyond:user:");

export const isSquadOverview = (overview: AudienceMemberOverview): overview is SquadOverview =>
    overview.id.startsWith("urn:beyond:squad:");

export const isUserOverview = (overview: AudienceMemberOverview): overview is UserOverview =>
    overview.id.startsWith("urn:beyond:user:");
