import { createPromiseClient } from "@connectrpc/connect";

import { BondService } from "../../gen/proto/bonds/bonds_connect";
import * as bonds_pb from "../../gen/proto/bonds/bonds_pb";
import * as clients_pb from "../../gen/proto/clients/clients_pb";
import * as devices_pb from "../../gen/proto/devices/devices_pb";

import clientService from "@/api/client";
import deviceService, { observersParser } from "@/api/devices";
import { streamHandler } from "@/api/stream";
import { transport } from "@/api/transport";
import * as util from "@/api/util";
import {
    fromProtoBondId,
    fromProtoBondSet,
    fromProtoCallId,
    fromProtoChannelId,
    fromProtoOrgId,
    fromProtoSquadId,
    fromProtoSquadSet,
    fromProtoTimestamp,
    fromProtoUserId,
    pbUserId,
    toProtoBondSet,
} from "@/api/util";
import type {
    BondHerald,
    BondInvite,
    BondInvitee,
    BondKnowledgePreview,
    BondOverview,
    CatchupKnowledge,
} from "@/domain/bonds";
import * as d from "@/domain/domain";
import { UserObservation } from "@/domain/presence";
import { translateAsyncIterable } from "@/misc/iterable";
import { Diff, Optional } from "@/misc/types";
import { separateDiscriminatedUnion } from "@/misc/utils";

// Re-export proto types for use in the rest of the frontend
export { BondSet, PrivacyLevel } from "../../gen/proto/domain/domain_pb";

const service = createPromiseClient(BondService, transport);
export default service;

function translateBondKnowledgePreview(
    bk?: bonds_pb.BondKnowledgePreview,
): BondKnowledgePreview {
    if (!bk) {
        return {} as BondKnowledgePreview;
    }

    return {
        userSpecifiedTitle: bk.userSpecifiedTitle,
        aiGeneratedTitle: bk.aiGeneratedTitle,
        summary: bk.summary,
        detailedSummary: bk.detailedSummary,
        imageUrl: bk.imageUrl,
    };
}

function translateBondOverview(bo: bonds_pb.BondOverview): BondOverview {
    return {
        id: fromProtoBondId(bo.id),
        orgId: fromProtoOrgId(bo.orgId),
        channelId: fromProtoChannelId(bo.channelId),
        squadIds: bo.squadIds?.map(fromProtoSquadId) || [],
        privacy: bo.privacy,
        knowledge: translateBondKnowledgePreview(bo.knowledgePreview),
        contributors: bo.contributors?.ids?.map(fromProtoUserId) || [],
        followers: bo.followers?.ids?.map(fromProtoUserId) || [],
        externalUsers: bo.externalUsers?.ids?.map(fromProtoUserId) || [],
        lastActivityAt: fromProtoTimestamp(bo.lastActivityAt),
        maxSequenceNumber: util.bigintToNumber(bo.maxSequenceNumber),
        liveCallIds: bo.liveCalls?.ids?.map(fromProtoCallId) || [],
    };
}

export function pbBondOverview(bond: BondOverview): bonds_pb.BondOverview {
    const contents = {
        id: util.pbBondId(bond.id),
        orgId: util.pbOrgId(bond.orgId),
        channelId: util.pbChannelId(bond.channelId),
        squadIds: bond.squadIds.map(util.pbSquadId),
        privacy: bond.privacy,
        knowledgePreview: bond.knowledge,
        contributors: util.pbUserSet(bond.contributors),
        followers: util.pbUserSet(bond.followers),
        externalUsers: util.pbUserSet(bond.externalUsers),
        lastActivityAt: util.pbTimestamp(bond.lastActivityAt),
        maxSequenceNumber: BigInt(bond.maxSequenceNumber),
        liveCalls: util.pbCallSet(bond.liveCallIds),
    };
    return new bonds_pb.BondOverview(contents);
}

function translateBondHerald(bh: bonds_pb.BondHerald): BondHerald {
    const h: BondHerald = {
        bondId: fromProtoBondId(bh.bondId),
        squadIds: fromProtoSquadSet(bh.squadIds),
        lastActivityAt: fromProtoTimestamp(bh.lastActivityAt),
        currentUserIsFollower: bh.requesterIsFollower,
    };
    return h;
}

function translateBondInvitee(bi?: bonds_pb.BondInvitee): BondInvitee {
    if (!bi) {
        throw new Error("BondInvitee is undefined");
    }

    if (bi.via.case == "email") {
        return { case: "email", email: bi.via.value };
    }
    else if (bi.via.case == "userId") {
        return { case: "userId", userId: fromProtoUserId(bi.via.value) };
    }
    else {
        throw new Error(`unexpected BondInvitee case: ${bi.via.case}`);
    }
}

function translateBondInvite(bi: bonds_pb.BondInvite): BondInvite {
    return {
        bondId: fromProtoBondId(bi.bondId),
        originatorId: fromProtoUserId(bi.originatorId),
        expiry: bi.expiry.case == "expired"
            ? { expired: true }
            : { expired: false, expiresAt: fromProtoTimestamp(bi.expiry.value) },
        target: bi.target.case == "isCommon"
            ? { case: "common" }
            : { case: "invitee", invitee: translateBondInvitee(bi.target.value) },
    };
}

interface FollowBondArgs {
    bondId: d.BondId;
    follow: boolean;
}

export async function setFollowBond(
    args: FollowBondArgs,
): Promise<void> {
    const req = new clients_pb.FollowBondRequest({
        bondId: util.pbBondId(args.bondId),
        follow: args.follow,
    });
    await clientService.followBond(req);
}

interface ArchiveBondArgs {
    bondId: d.BondId;
    archive: boolean;
}

export async function setArchiveBond(
    args: ArchiveBondArgs,
): Promise<void> {
    const req = new clients_pb.ArchiveBondRequest({
        bondId: util.pbBondId(args.bondId),
        archive: args.archive,
    });
    await clientService.archiveBond(req);
}

interface UpdateBondTitleArgs {
    bondId: d.BondId;
    title: string;
}

export async function updateBondTitle(
    args: UpdateBondTitleArgs,
): Promise<void> {
    const req = new bonds_pb.UpdateBondUserSpecifiedTitleRequest({
        bondId: util.pbBondId(args.bondId),
        content: args.title,
    });

    await service.updateBondUserSpecifiedTitle(req);
}

interface ModifyBondMembershipArgs {
    bondId: d.BondId;
    userIdsToAdd: d.UserId[];
    squadIdsToAdd: d.SquadId[];
}

export async function modifyBondMembership(
    args: ModifyBondMembershipArgs,
): Promise<void> {
    // Currently this RPC only supports adding to membership, not removing.
    const req = new clients_pb.ModifyBondMembershipRequest({
        bondId: util.pbBondId(args.bondId),
        addToMembership: true,
        users: util.pbUserSet(args.userIdsToAdd),
        squads: util.pbSquadSet(args.squadIdsToAdd),
    });

    await clientService.modifyBondMembership(req);
}

interface InviteUserToBondArgs {
    bondId: d.BondId;
    invitedUserId: d.UserId;
}

export async function inviteUserToBond(
    args: InviteUserToBondArgs,
): Promise<void> {
    const req = new clients_pb.InviteUserToBondRequest({
        bondId: util.pbBondId(args.bondId),
        invitedUserId: util.pbUserId(args.invitedUserId),
    });
    await clientService.inviteUserToBond(req);
}

interface InviteUserToBondViaEmailArgs {
    bondId: d.BondId;
    invitedEmailAddress: string;
}

export async function inviteUserToBondViaEmail(
    args: InviteUserToBondViaEmailArgs,
): Promise<string> {
    const req = new clients_pb.InviteUserToBondViaEmailRequest({
        bondId: util.pbBondId(args.bondId),
        invitedEmailAddress: args.invitedEmailAddress,
    });
    const resp = await clientService.inviteUserToBondViaEmail(req);
    return resp.inviteLink;
}

interface GetShareableBondInviteLinkArgs {
    bondId: d.BondId;
}

export async function getShareableBondInviteLink(
    args: GetShareableBondInviteLinkArgs,
): Promise<string> {
    const req = new clients_pb.GetShareableBondInviteLinkRequest({
        bondId: util.pbBondId(args.bondId),
    });
    const resp = await clientService.getShareableBondInviteLink(req);
    return resp.inviteLink;
}

interface FindBondInviteArgs {
    opaqueCode: string;
}
interface FindBondInviteResp {
    invite: BondInvite;
}

export async function retrieveBondInvite(
    args: FindBondInviteArgs,
): Promise<Optional<FindBondInviteResp>> {
    const req = new bonds_pb.FindBondInviteRequest({
        opaqueCode: args.opaqueCode,
    });
    const resp = await service.findBondInvite(req);
    return resp.invite && {
        invite: translateBondInvite(resp.invite),
    };
}

interface RedeemBondInviteArgs {
    opaqueCode: string;
}

export async function redeemBondInvite(
    args: RedeemBondInviteArgs,
): Promise<Optional<d.BondId>> {
    const req = new clients_pb.RedeemBondInviteCodeRequest({
        opaqueCode: args.opaqueCode,
    });
    const resp = await clientService.redeemBondInviteCode(req);
    return fromProtoBondId(resp.bondId);
}

export async function* observeBond(
    bondId: d.BondId,
    signal: AbortSignal,
): AsyncGenerator<void, void, unknown> {
    const req = new clients_pb.ObserveBondRequest({
        bondId: util.pbBondId(bondId),
    });

    const resp = clientService.observeBond(req, { signal });

    yield* streamHandler(resp, () => {}, observeBond.name);
}

export type BondOverviewDU = {
    case: "overview";
    overview: BondOverview;
};
export type DeletedBondDU = {
    case: "deleted";
    deletedId: d.BondId;
};

export type BondOverviewOrDeleted = BondOverviewDU | DeletedBondDU;

function bondOverviewParser(
    res:
        | bonds_pb.SubBondResponse
        | bonds_pb.SubBondsV2Response,
): BondOverviewOrDeleted {
    switch (res.bondOrDeleted?.case) {
        case "bond": {
            const overview = translateBondOverview(res.bondOrDeleted.value);
            return { case: "overview", overview: overview };
        }
        case "deletedId": {
            const deletedId = fromProtoBondId(res.bondOrDeleted.value);
            return { case: "deleted", deletedId: deletedId };
        }
        default:
            throw new Error(`unexpected bondOrDeleted case: ${res.bondOrDeleted?.case}`);
    }
}

export async function* subBond(
    bondId: d.BondId,
    signal: AbortSignal,
): AsyncGenerator<BondOverviewOrDeleted, void, unknown> {
    const req = new bonds_pb.SubBondRequest({
        bondId: util.pbBondId(bondId),
    });

    const logPrefix = `subBond`;

    const resp = service.subBond(req, { signal });

    yield* streamHandler(resp, bondOverviewParser, logPrefix);
}

export type BondHeraldDU = {
    case: "herald";
    herald: BondHerald;
};

function bondHeraldParser(
    res: bonds_pb.SubBondsListResponse,
): BondHeraldOrDeleted {
    switch (res.heraldOrDeleted?.case) {
        case "herald": {
            const herald = translateBondHerald(res.heraldOrDeleted.value);
            return { case: "herald", herald: herald };
        }
        case "deletedId": {
            const deletedId = fromProtoBondId(res.heraldOrDeleted.value);
            return { case: "deleted", deletedId: deletedId };
        }
        default:
            throw new Error(`unexpected overviewOrDeleted case: ${res.heraldOrDeleted?.case}`);
    }
}

export type BondHeraldOrDeleted = BondHeraldDU | DeletedBondDU;

export async function* subBondsList(
    userId: d.UserId,
    signal: AbortSignal,
): AsyncGenerator<BondHeraldOrDeleted, void, unknown> {
    const req = new bonds_pb.SubBondsListRequest({
        userId: pbUserId(userId),
    });

    const logPrefix = `subBonds`;

    const resp = service.subBondsList(req, { signal });

    yield* streamHandler(resp, bondHeraldParser, logPrefix);
}

async function* translateBondDiff(v: Diff<d.BondId>) {
    if (v.added?.length) {
        yield new bonds_pb.SubBondsV2Request({
            bondIds: toProtoBondSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new bonds_pb.SubBondsV2Request({
            bondIds: toProtoBondSet(v.removed),
            addToSub: false,
        });
    }
}

export async function* subBondsV2(
    reqStream: AsyncIterableIterator<Diff<d.BondId>>,
    signal: AbortSignal,
) {
    const translation = translateAsyncIterable(reqStream, translateBondDiff);

    const stream = service.subBondsV2(translation, { signal });

    yield* streamHandler(stream, bondOverviewParser, subBondsV2.name);
}

export async function* observeSquadBondsList(
    squadId: d.SquadId,
    userId: d.UserId,
    signal: AbortSignal,
): AsyncGenerator<void, void, unknown> {
    const req = new clients_pb.ObserveBondsPreviewsRequest({
        squadId: util.pbSquadId(squadId),
        userId: util.pbUserId(userId),
    });

    const logPrefix = `observeBondPreviews`;

    const resp = clientService.observeBondsPreviews(req, { signal });

    yield* streamHandler(resp, () => {}, logPrefix);
}

export async function* subArchivedBonds(
    userId: d.UserId,
    signal: AbortSignal,
) {
    const req = new bonds_pb.SubArchivedBondsListDiffRequest({
        userId: pbUserId(userId),
    });

    const logPrefix = `streamArchivedBonds`;

    const resp = service.subArchivedBondsListDiff(req, { signal });

    let first = true;
    const parse = (pb: bonds_pb.SubArchivedBondsListDiffResponse) => {
        const overwrite = first;
        first = false;
        return ({
            overwrite: overwrite,
            added: fromProtoBondSet(pb.bondIdsAdded),
            removed: fromProtoBondSet(pb.bondIdsRemoved),
        });
    };

    yield* streamHandler(resp, parse, logPrefix);
}

export type BondObservations = {
    viewId: d.BondId;
    observations: Array<UserObservation>;
};

async function* translateBondObserversDiff(v: Diff<d.BondId>) {
    if (v.added?.length) {
        yield new devices_pb.SubObserversV2Request({
            viewUrns: v.added.map(bid => bid as string),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new devices_pb.SubObserversV2Request({
            viewUrns: v.removed.map(bid => bid as string),
            addToSub: false,
        });
    }
}

export async function* subBondObservers(
    reqStream: AsyncIterableIterator<Diff<d.BondId>>,
    signal: AbortSignal,
): AsyncGenerator<BondObservations, void, unknown> {
    const logPrefix = `subBondObservers`;

    const translation = translateAsyncIterable(reqStream, translateBondObserversDiff);

    const resp = deviceService.subObserversV2(translation, { signal });

    yield* streamHandler(resp, observersParser(d.parseBondUrn), logPrefix);
}

export interface BondContemporaries {
    bondId: d.BondId;
    userIds: d.UserId[];
}

function bondContemporariesParser(res: bonds_pb.SubBondContemporariesResponse): BondContemporaries {
    return {
        bondId: fromProtoBondId(res.bondId),
        userIds: util.fromProtoUserSet(res.bondContemporaries),
    };
}

async function* translateBondContemporariesDiff(v: Diff<d.BondId>) {
    if (v.added?.length) {
        yield new bonds_pb.SubBondContemporariesRequest({
            bondIds: toProtoBondSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new bonds_pb.SubBondContemporariesRequest({
            bondIds: toProtoBondSet(v.removed),
            addToSub: false,
        });
    }
}

export async function* subBondContemporaries(
    reqStream: AsyncIterableIterator<Diff<d.BondId>>,
    signal: AbortSignal,
) {
    const logPrefix = "subBondContemporaries";

    const translation = translateAsyncIterable(reqStream, translateBondContemporariesDiff);

    const stream = service.subBondContemporaries(translation, { signal });

    yield* streamHandler(stream, bondContemporariesParser, logPrefix);
}

type CatchupKnowledgeOrDeletedA = {
    case: "knowledge";
    value: CatchupKnowledge;
};
type CatchupKnowledgeOrDeletedB = {
    case: "deleted";
    deletedId: d.BondId;
};
export type CatchupKnowledgeOrDeleted =
    | CatchupKnowledgeOrDeletedA
    | CatchupKnowledgeOrDeletedB;
export const separateCatchupKnowledgeOrDeleteds = (xs: CatchupKnowledgeOrDeleted[]) =>
    separateDiscriminatedUnion<
        CatchupKnowledgeOrDeletedA,
        CatchupKnowledgeOrDeletedB
    >(x => x.case == "knowledge", xs);
export const toCatchupKnowledgeOrDeleted = (
    value: CatchupKnowledge,
): CatchupKnowledgeOrDeletedA => ({
    case: "knowledge",
    value,
});
export const deletedCatchupKnowledgeOrDeleted = (
    deletedId: d.BondId,
): CatchupKnowledgeOrDeletedB => ({
    case: "deleted",
    deletedId,
});

function catchupSummariesParser(
    res: bonds_pb.SubBondCatchupSummariesResponse,
): CatchupKnowledgeOrDeleted {
    switch (res.summaryOrDeleted.case) {
        case "summary":
            return toCatchupKnowledgeOrDeleted({
                bondId: fromProtoBondId(res.summaryOrDeleted.value.bondId),
                summary: res.summaryOrDeleted.value.summary,
                lastSummarisedSeq: Number(res.summaryOrDeleted.value.lastSummarisedSeq),
            });
        case "deletedId":
            return deletedCatchupKnowledgeOrDeleted(fromProtoBondId(res.summaryOrDeleted.value));
        default:
            throw new Error(
                `unexpected catchupKnowledgeOrDeleted case: ${res.summaryOrDeleted?.case}`,
            );
    }
}

const translateCatchupSummariesDiff = (userId: d.UserId) => {
    return async function* (v: Diff<d.BondId>) {
        if (v.added?.length) {
            yield new bonds_pb.SubBondCatchupSummariesRequest({
                userId: pbUserId(userId),
                addToSub: true,
                bondIds: toProtoBondSet(v.added),
            });
        }
        if (v.removed?.length) {
            yield new bonds_pb.SubBondCatchupSummariesRequest({
                userId: pbUserId(userId),
                addToSub: false,
                bondIds: toProtoBondSet(v.removed),
            });
        }
    };
};

interface SubBondCatchupSummariesArgs {
    userId: d.UserId;
}

export async function* subBondCatchupSummaries(
    reqStream: AsyncIterableIterator<Diff<d.BondId>>,
    args: SubBondCatchupSummariesArgs,
    signal: AbortSignal,
) {
    const logPrefix = "subBondContemporaries";
    const { userId } = args;

    const translation = translateAsyncIterable(
        reqStream,
        translateCatchupSummariesDiff(userId),
    );

    const stream = service.subBondCatchupSummaries(translation, { signal });

    yield* streamHandler(stream, catchupSummariesParser, logPrefix);
}
