import { Optional } from "@/misc/types";
import { useEffect, useMemo, useState } from "react";

type UseOnScreenOptions = {
    threshold?: number;
    freezeOnceVisible?: boolean;
};

/**
 * Tracks whether an HTML element is visible on screen
 *
 * @param element the html element to track. If null, this hook always returns false.
 * @param options an object containing the following:
 * - `threshold` - an optional number between 0 and 1, indicating the percentage
 *   of the target's visibility that should trigger a change in state.
 * - `freezeOnceVisible` - If true, freezes the visibility state once the
 *   element becomes visible.
 * @returns whether the HTML element is present on the screen.
 */
export const useOnScreen = (
    element: Optional<HTMLElement>,
    options: UseOnScreenOptions = {},
) => {
    const [isIntersecting, setIntersecting] = useState(false);
    const [hasIntersected, setHasIntersected] = useState(false);
    const observer = useMemo(() => {
        return new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting);
                if (entry.isIntersecting) setHasIntersected(entry.isIntersecting);
            },
            { threshold: options.threshold },
        );
    }, [options.threshold]);

    useEffect(() => {
        setIntersecting(false);
        setHasIntersected(false);
    }, [element]);

    useEffect(() => {
        if (!observer || element === undefined) return;

        observer.observe(element);
        return () => observer.unobserve(element);
    }, [observer, element]);

    return hasIntersected && options.freezeOnceVisible || isIntersecting;
};

export default useOnScreen;
