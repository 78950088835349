import { createSelector } from "reselect";

import { AudienceMember, AudienceMemberOverview, isSquadId } from "@/domain/audience";
import { SquadId, UserId } from "@/domain/domain";
import { memoizeOptions } from "@/features/selectors";
import { selectSquads } from "@/features/squads";
import { selectUsers } from "@/features/users";
import { separateDiscriminatedUnion } from "@/misc/utils";
import { createAppSelector } from "@/store/redux";

const separateAudienceMemo = createSelector(
    (audience: AudienceMember[]) => audience,
    audience => separateDiscriminatedUnion<SquadId, UserId>(isSquadId, audience),
    memoizeOptions.weakMapShallow,
);

export const selectAudienceOverviews = createAppSelector(
    [
        state => state,
        (_, audience: AudienceMember[]) => separateAudienceMemo(audience),
    ],
    (state, [squadReferences, userReferences]): AudienceMemberOverview[] => {
        return [
            ...selectSquads(state, squadReferences),
            ...selectUsers(state, userReferences),
        ];
    },
    memoizeOptions.weakMapShallow,
);
