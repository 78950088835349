import { BlockBlobClient, newPipeline } from "@azure/storage-blob";
import { ErrorBoundary } from "@sentry/react";
import { RouterProvider } from "react-router-dom";

import IdleTracker from "@/components/IdleTracker";
import AttachmentManager, {
    FileStashContext,
    fileStashMap,
} from "@/components/managers/AttachmentManager";
import WakeLockManager from "@/components/managers/WakeLockManager";
import RefreshOnNewVersion from "@/components/RefreshOnNewVersion";
import ToastContainer from "@/components/ToastContainer";
import WindowFocusTracker from "@/components/WindowFocusTracker";
import RtcSessionContextProvider from "@/context/RtcSessionContext";
import { SidebarProvider } from "@/context/SidebarContext";
import { isMobileBrowser } from "@/misc/mobile";
import { getRouter } from "@/misc/router";

const blockBlobClientGenerator = (url: string) => {
    return new BlockBlobClient(
        url,
        newPipeline(),
    );
};

export default function Session(): React.JSX.Element {
    return (
        <ErrorBoundary>
            <WakeLockManager />
            <WindowFocusTracker />
            <RefreshOnNewVersion />
            <IdleTracker />
            <ToastContainer />
            <FileStashContext.Provider value={fileStashMap}>
                <AttachmentManager clientFactory={blockBlobClientGenerator} />
                <RtcSessionContextProvider disableMultitasking={isMobileBrowser()}>
                    <SidebarProvider>
                        <RouterProvider router={getRouter()} />
                    </SidebarProvider>
                </RtcSessionContextProvider>
            </FileStashContext.Provider>
        </ErrorBoundary>
    );
}
