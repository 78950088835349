import { selectFollowedUnreadBondsCount } from "@/features/bonds";
import { selectConnectedWithUserId } from "@/features/connection";
import { selectOfflineNumberOfFollowedUnreadBonds } from "@/features/filterPanel";
import { MetaInterestCounterKey } from "@/features/interest";
import { backendRTTDebounceDuration } from "@/misc/backend";
import { useMetaInterest } from "./interest/useInterest";
import useDebounce from "./useDebounce";
import useSelectorArgs from "./useSelectorArgs";

export default function useDebouncedFollowedUnreadCount(): number {
    useMetaInterest(true, MetaInterestCounterKey.FollowedUnreadCount);

    const followedUnreadBondsCount = useSelectorArgs(selectFollowedUnreadBondsCount);

    const offlineFollowedUnreadCountEstimate = useSelectorArgs(
        selectOfflineNumberOfFollowedUnreadBonds,
    );

    const connected = useSelectorArgs(selectConnectedWithUserId);
    const unread = connected ? followedUnreadBondsCount : offlineFollowedUnreadCountEstimate;

    return useDebounce(
        unread,
        backendRTTDebounceDuration,
        undefined,
        (oldValue, newValue) => oldValue > newValue,
    );
}
