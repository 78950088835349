import speakingBubbleIcon from "../../assets/icons/speaking-bubble-icon.svg";
import speakingIcon from "../../assets/icons/speaking-icon.svg";
import typingBubbleIcon from "../../assets/icons/typing-bubble-icon.svg";
import typingIcon from "../../assets/icons/typing-icon.svg";
import * as domain_pb from "../../gen/proto/domain/domain_pb";

import * as d from "@/domain/domain";
import { isTrackActive, Track } from "@/domain/rtc";
import { Optional } from "@/misc/types";

export interface UserObservation {
    userId: d.UserId;
    current: boolean;
    startedAt?: d.Timestamp;
    endedAt?: d.Timestamp;
}

export enum PresenceMode {
    Unknown = "unknown",
    Typing = "typing",
    Speaking = "speaking",
    Videoing = "video-calling",
    ScreenSharing = "screen-sharing",
    LiveObserving = "live-observing",
}

export const getPresenceIcon = (mode?: PresenceMode): string => {
    switch (mode) {
        case PresenceMode.Typing:
            return typingIcon;
        case PresenceMode.Speaking:
            return speakingIcon;
        case PresenceMode.Videoing:
            return speakingIcon; // Temporary
        case PresenceMode.ScreenSharing:
            return speakingIcon; // Temporary
        default:
            return "";
    }
};

export const getPresenceBubbleIcon = (mode?: PresenceMode): string => {
    switch (mode) {
        case PresenceMode.Typing:
            return typingBubbleIcon;
        case PresenceMode.Speaking:
            return speakingBubbleIcon;
        case PresenceMode.Videoing:
            return "";
        case PresenceMode.ScreenSharing:
            return "";
        case PresenceMode.LiveObserving:
            return "";
        default:
            return "";
    }
};

// The presence modes that are visible in a user's Avatar name pill.
// NB: Does NOT include ScreenSharing, because that is visible in the name pill for the *display tile*.
export const visibleUserPresenceModes = [
    PresenceMode.Speaking,
    PresenceMode.Typing,
];
export const isVisibleUserPresenceMode = (mode: PresenceMode) =>
    visibleUserPresenceModes.includes(mode);

export const livePresenceModes = [
    PresenceMode.LiveObserving,
    PresenceMode.ScreenSharing,
    PresenceMode.Speaking,
    PresenceMode.Videoing,
];
export const isLivePresence = (mode: PresenceMode) => livePresenceModes.includes(mode);
export const hasLivePresence = (modes?: PresenceMode[]) => modes?.some(isLivePresence) ?? false;

export const getDominantPresenceMode = (modes: PresenceMode[]): Optional<PresenceMode> => {
    if (modes.includes(PresenceMode.ScreenSharing)) {
        return PresenceMode.ScreenSharing;
    }
    if (modes.includes(PresenceMode.Videoing)) {
        return PresenceMode.Videoing;
    }
    if (modes.includes(PresenceMode.Speaking)) {
        return PresenceMode.Speaking;
    }
    if (modes.includes(PresenceMode.Typing)) {
        return PresenceMode.Typing;
    }
    if (modes.includes(PresenceMode.LiveObserving)) {
        return PresenceMode.LiveObserving;
    }
};

export function translatePresenceMode(mode: domain_pb.PresenceMode): PresenceMode | undefined {
    switch (mode) {
        case domain_pb.PresenceMode.SPEAKING:
            return PresenceMode.Speaking;
        case domain_pb.PresenceMode.TYPING:
            return PresenceMode.Typing;
        case domain_pb.PresenceMode.VIDEOING:
            return PresenceMode.Videoing;
        case domain_pb.PresenceMode.SCREEN_SHARING:
            return PresenceMode.ScreenSharing;
        case domain_pb.PresenceMode.LIVE_OBSERVING:
            return PresenceMode.LiveObserving;
        case domain_pb.PresenceMode.UNSPECIFIED:
            return undefined;
        default:
            return undefined;
    }
}

export function livePresenceModesFromTracks(
    audioTrack?: Track,
    videoTrack?: Track,
    isDisplay?: boolean,
) {
    // Assumes that the person is live
    const modes: PresenceMode[] = [PresenceMode.LiveObserving];

    // TODO: voice detection (see beyond#365)
    if (isTrackActive(audioTrack)) {
        modes.push(PresenceMode.Speaking);
    }
    if (isTrackActive(videoTrack)) {
        modes.push(
            isDisplay ? PresenceMode.ScreenSharing : PresenceMode.Videoing,
        );
    }

    return modes;
}
