import { ClientService } from "../../gen/proto/clients/clients_connect";
import * as clients_pb from "../../gen/proto/clients/clients_pb";

import { streamHandler } from "@/api/stream";
import { transport } from "@/api/transport";
import * as util from "@/api/util";
import * as d from "@/domain/domain";
import { OrgOverview } from "@/domain/squads";
import { getEnvironmentConfig } from "@/misc/environment";
import { createPromiseClient } from "@connectrpc/connect";

// Note: Calls to the client service are defined in the api file of the service
// which is relevant to the specific call. We export the PromiseClient here to
// act as the single source for the client service.

const service = createPromiseClient(ClientService, transport);
export default service;

// pbRtcParticipantId is not serialisable, so unwrap response in the API layer
// to prevent redux complaining about unserialisable payload actions.
export interface GetCallAccessTokenResponse {
    accessToken: string;
    participantId: d.RtcParticipantId;
}

export async function getAccessToken(
    sessionId: d.RtcSessionId,
    userId: d.UserId,
): Promise<GetCallAccessTokenResponse> {
    const req = new clients_pb.GetCallAccessTokenRequest({
        sessionId: util.pbRtcSessionId(sessionId),
        userId: util.pbUserId(userId),
    });
    const resp = await service.getCallAccessToken(req);
    return {
        accessToken: resp.accessToken,
        participantId: util.fromProtoRtcParticipantId(resp.participantId),
    };
}

export async function reportFirebaseToken(token: string): Promise<void> {
    const req = new clients_pb.ReportFirebaseTokenRequest({ token });

    await service.reportFirebaseToken(req);
}

export async function reportApnsToken(token: string): Promise<void> {
    const req = new clients_pb.ReportApnsTokenRequest({ token });

    await service.reportApnsToken(req);
}

export async function* sustainBrowserFocusReport(
    signal: AbortSignal,
): AsyncGenerator<void, void, unknown> {
    const req = new clients_pb.ReportBrowserFocusRequest({
        recordedAt: util.pbTimestamp(Date.now()),
    });

    const logPrefix = `sustainBrowserFocusReport`;

    const resp = service.reportBrowserFocus(req, { signal });

    yield* streamHandler(resp, () => {}, logPrefix);
}

export async function* sustainTypingActivityReport(
    signal: AbortSignal,
): AsyncGenerator<void, void, unknown> {
    const req = new clients_pb.ReportTypingActivityRequest({
        recordedAt: util.pbTimestamp(Date.now()),
    });

    const logPrefix = `sustainTypingActivityReport`;

    const resp = service.reportTypingActivity(req, { signal });

    yield* streamHandler(resp, () => {}, logPrefix);
}

export type ListAvailableUsersResponse = Array<{
    userId: d.UserId;
    org: OrgOverview;
}>;

// This is a special case where we need to use the HTTP client instead of GRPC since
// it needs to be able to be called before the Websocket connection is established.
export async function listAvailableUsersHttp(
    accessToken: string,
): Promise<ListAvailableUsersResponse> {
    const targetConfig = getEnvironmentConfig();
    const targetURL = new URL(targetConfig.httpApiBaseUrl + "/client/available_users");

    const response = await fetch(targetURL, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) {
        throw new Error(
            `Failed to list available users: ${response.status} ${response.statusText}`,
        );
    }

    const body = await response.arrayBuffer();
    const resp = clients_pb.ListAvailableUsersResponse.fromBinary(new Uint8Array(body));

    return resp.users.filter(u => u.userId && u.org).map(u => ({
        userId: util.fromProtoUserId(u.userId),
        org: {
            id: util.fromProtoOrgId(u.org!.id),
            name: u.org!.name,
            personal: u.org!.isPersonal,
        },
    }));
}
