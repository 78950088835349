import { MultitaskingMediaControls } from "@/components/gui/MultitaskingMediaControls";
import { isMobileBrowser } from "@/misc/mobile";
import { MobileTabsView } from "@/views/MobileViews";

export function Footer(): React.JSX.Element {
    return (
        <>
            {isMobileBrowser() && <MobileTabsView />}
            <MultitaskingMediaControls />
        </>
    );
}
