import { useEffect, useState } from "react";

export default function useDebounce<T>(
    currentValue: T,
    delay: number,
    initialValue?: T,
    shouldForceChange?: (oldValue: T, newValue: T) => boolean,
): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(initialValue ?? currentValue);

    useEffect(() => {
        if (shouldForceChange?.(debouncedValue, currentValue)) {
            setDebouncedValue(currentValue);
        }
    }, [currentValue, shouldForceChange, debouncedValue]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(currentValue);
        }, delay);
        return () => {
            clearTimeout(timer);
        };
    }, [currentValue, delay]);

    return debouncedValue;
}
