import { useCallback, useState } from "react";

import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import log from "@/misc/log";

// useSet maintains an internal Set wrapped in React state.
// It returns a set of callbacks to update the Set in a React-ful way (copying
// the Set before updating to ensure a rerender is triggered).
//
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
export default function useSet<T>(initialEntries?: Iterable<T>) {
    const [theSet, setTheSet] = useState<Set<T>>(new Set(initialEntries));

    const add = useCallback((value: T) => {
        setTheSet(prev => {
            const copy = new Set(prev);
            copy.add(value);
            return copy;
        });
    }, []);

    const remove = useCallback((value: T) => {
        setTheSet(prev => {
            if (!prev.has(value)) {
                log.warn(`Tried to remove value '${value}' not present in set.`);
                return prev;
            }

            const copy = new Set(prev);
            copy.delete(value);
            return copy;
        });
    }, []);

    const has = useCallback((value: T) => theSet.has(value), [theSet]);

    const reset = useCallback(() => {
        setTheSet(() => new Set());
    }, []);

    const values = useShallowEqualsMemo(() => [...theSet.values()], [theSet]);

    const sorted = useShallowEqualsMemo(() => [...theSet.sorted()], [theSet]);

    return { add, remove, has, reset, values, sorted };
}
