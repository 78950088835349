import type { Action } from "@reduxjs/toolkit";
import { useCallback } from "react";

import { tagLocalThunk } from "@/store/locations";
import { useAppDispatch } from "@/store/redux";

export default function useLocalDispatch() {
    const dispatch = useAppDispatch();

    return useCallback(
        <A extends Action>(action: A) => dispatch(tagLocalThunk(action)),
        [dispatch],
    );
}
