import { useCallback, useEffect, useMemo } from "react";

import * as d from "@/domain/domain";
import { UserOverview, userSortByActivity } from "@/domain/users";
import { selectConnected } from "@/features/connection";
import { selectObservationsForSquadIds } from "@/features/squads";
import { selectUsers } from "@/features/users";
import { useInterestedSquads, useInterestedUsers } from "@/hooks/interest/useInterest";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import useTimerTarget from "@/hooks/useTimerTarget";
import { useAppSelector } from "@/store/redux";

const showInactivePeriod = 60 * 60 * 1000; // 60 minutes

export default function useFreshSquadObservers(squadsToSubObservers: d.SquadId[]) {
    useInterestedSquads(squadsToSubObservers);

    // Select these observations from the redux store
    const squadObservations = useSelectorArgs(selectObservationsForSquadIds, squadsToSubObservers);
    const squadObservers = useShallowEqualsMemo(
        () => Array.from(squadObservations.map(uob => uob.userId)),
        [squadObservations],
    );

    useInterestedUsers(squadObservers);

    const isConnected = useAppSelector(selectConnected);

    const { nowMs, setTarget } = useTimerTarget();

    const presenceIsFresh = useCallback((u: UserOverview) => {
        if (!isConnected) return false;
        if (!u.activity || u.activity.active) return true;

        return nowMs < u.activity.inactiveSince + showInactivePeriod;
    }, [nowMs, isConnected]);

    const adjustments = useMemo(() => {
        return {
            filter: presenceIsFresh,
            sort: userSortByActivity, // NB: splits ties on inactive time
        };
    }, [presenceIsFresh]);

    const observerOverviews = useSelectorArgs(selectUsers, squadObservers, adjustments);

    useEffect(() => {
        if (observerOverviews.length === 0) return;

        const eldest = observerOverviews[observerOverviews.length - 1];
        // Not possible, but appease tsc.
        if (!eldest.activity || eldest.activity.active) return;

        setTarget(eldest.activity.inactiveSince + showInactivePeriod);
    }, [observerOverviews, setTarget]);

    return useShallowEqualsMemo(() => observerOverviews.map(uo => uo.id), [observerOverviews]);
}
