import { Draft as ImmerDraft, PayloadAction } from "@reduxjs/toolkit";

import {
    createBondFromCall,
    CreateBondFromCallParams,
    createBondFromMsg,
    CreateBondFromMsgParams,
} from "@/api/bondCreation";
import { AnyLocalAttachment, isUploadedAttachment, ProposedAttachment } from "@/domain/attachments";
import { AudienceMember } from "@/domain/audience";
import {
    bondCreationDraftTarget,
    DraftTarget,
    DraftType,
    isBondCreationDraftTarget,
} from "@/domain/channels";
import {
    breakMentionsChange,
    mentionDiff,
    parseMarkupDelta,
    plainifyChangeDelta,
} from "@/domain/delta";
import {
    convertDraftContentToV1,
    convertDraftContentToV2,
    DraftChatContent_V1,
    switchDraftContentVersion,
    withChange,
    withMarkup,
} from "@/domain/draftChatContent";
import {
    convertDraftToUnsentMessage,
    DraftChatMessage,
    emptyDraftChatMessage,
} from "@/domain/messages";
import { resetStore, selectCurrentUserId } from "@/features/auth";
import { emojiRegex } from "@/features/bonds";
import {
    addAttachmentsToDraft,
    clearAttachmentsFromDraft,
    insertDraftMention,
    InsertDraftMentionArgs,
    insertDraftText,
    InsertDraftTextArgs,
    selectLocalAttachmentsDraftForMessage,
    stageMessageForChannel,
    updateDraftMarkup,
    updateDraftText,
    UpdateDraftTextArgs,
} from "@/features/channels";
import { deleteAttachmentFromDraftThunk, selectLocalAttachment } from "@/features/chats";
import { fetchBondTitleSuggestionThunk } from "@/features/intel";
import { createLocalAsyncThunk } from "@/features/localThunk";
import { selectValidSquadIdsForMention, selectValidUserIdsForMention } from "@/features/mentions";
import { createProxiedAsyncThunk } from "@/features/proxiedThunk";
import { memoizeOptions } from "@/features/selectors";
import { unaryThunkHandler } from "@/features/thunk";
import log from "@/misc/log";
import {
    applyTextToContent,
    clearContent,
    ContentMutation,
    insertMentionIntoContent,
    insertTextIntoContent,
} from "@/misc/messageContent";
import { ExpandType, Optional } from "@/misc/types";
import { checkPersistor } from "@/persist/shared";
import { createLocalSlice } from "@/store/localSlice";
import { createAppSelector } from "@/store/redux";
import { RootState } from "@/store/types";

type CreateBondFromMessageArgs = ExpandType<
    & Omit<CreateBondFromMsgParams, "senderId" | "msg" | "officialAttachmentIds">
    & { msg: DraftChatMessage; }
>;
export const createBondFromMessageThunk = createProxiedAsyncThunk(
    "bonds/createFromMessage",
    async (args: CreateBondFromMessageArgs, thunkAPI) => {
        const state = thunkAPI.getState();
        const senderId = selectCurrentUserId(state)!;

        const msg = convertDraftToUnsentMessage(args.msg, Date.now());
        if (!msg) {
            log.warn("Cannot create bond: failed to convert draft to unsent message");
            return thunkAPI.rejectWithValue({ error: "Failed to convert draft to unsent message" });
        }

        const officialAttachmentIds = selectOfficialAttachmentIdsForBondCreationMessage(state);

        return await unaryThunkHandler(
            thunkAPI,
            createBondFromMsg({
                ...args,
                senderId,
                msg,
                officialAttachmentIds,
            }),
            "createBondFromMessage",
        );
    },
);

type CreateBondFromCallArgs = ExpandType<
    Omit<CreateBondFromCallParams, "senderId" | "clientTxTs">
>;
export const createBondFromCallThunk = createProxiedAsyncThunk(
    "bonds/createFromCall",
    async (args: CreateBondFromCallArgs, thunkAPI) => {
        const state = thunkAPI.getState();
        const senderId = selectCurrentUserId(state)!;

        return await unaryThunkHandler(
            thunkAPI,
            createBondFromCall({
                ...args,
                senderId,
                clientTxTs: Date.now(),
            }),
            "createBondFromCall",
        );
    },
);

export const clearDraftThunk = createLocalAsyncThunk(
    "bonds/clearDraft",
    async (
        draftTarget: DraftTarget,
        thunkAPI,
    ): Promise<{ draftTarget: DraftTarget; attachments?: AnyLocalAttachment[]; }> => {
        const state = thunkAPI.getState();
        const attachments = selectLocalAttachmentsDraftForMessage(state, draftTarget);

        return { draftTarget, attachments };
    },
);

export const removeFromBondCreationAudienceThunk = createLocalAsyncThunk(
    "bondCreation/removeFromAudience",
    (
        { member }: { member: AudienceMember; },
        thunkAPI,
    ) => {
        // Remove the audience member from the audience list
        thunkAPI.dispatch(removeFromBondCreationAudience(member));

        // "Break" any mentions for that member (replace with text)
        const draft = selectBondCreationDraft(thunkAPI.getState());
        const contentV2 = convertDraftContentToV2(draft.content);
        thunkAPI.dispatch(updateDraftMarkup({
            draftTarget: bondCreationDraftTarget,
            update: {
                change: plainifyChangeDelta(
                    breakMentionsChange(
                        parseMarkupDelta(contentV2.draftMarkup),
                        member,
                    ),
                ),
            },
            source: "api",
        }));
    },
);

export interface DraftBondTitles {
    userSpecifiedTitle?: string;
    bondTitleSuggestion?: string;
}

export interface BondCreationState {
    draft: DraftChatMessage;

    bondTitles: DraftBondTitles;

    /**
     * Audience that will be used when creating the new bond. This includes all members (squads
     * and users) which will have access to view and engage with the newly created bond. Adding
     * a mention to the draft will automatically include a member in this list.
     */
    audience: AudienceMember[];
}

const getInitialState = (props?: Partial<BondCreationState>): BondCreationState => {
    const draft = props?.draft ?? emptyDraftChatMessage();

    return {
        draft,
        bondTitles: props?.bondTitles ??
            { bondTitleSuggestion: undefined, userSpecifiedTitle: undefined },
        audience: props?.audience ?? [],
    };
};

/**
 * @deprecated
 */
const contentMutationReducer = <P>(
    mutation: ContentMutation<P>,
    options?: {
        validate?: (state: ImmerDraft<BondCreationState>, payload: P) => boolean;
    },
) =>
(
    state: ImmerDraft<BondCreationState>,
    action: PayloadAction<P>,
) => {
    if (!(options?.validate?.(state, action.payload) ?? true)) return;

    const draft = {
        ...state.draft,
        content: convertDraftContentToV1(state.draft.content),
    };

    const newDraft = {
        ...draft,
        content: mutation(draft.content, action.payload),
    };

    state.draft = newDraft;
};

export const bondCreationSlice = createLocalSlice({
    name: "bondCreation",
    initialState: getInitialState(),
    selectors: {
        draft: state => state.draft,
        userSpecifiedTitle: state => state.bondTitles?.userSpecifiedTitle,
        bondTitleSuggestion: state => state.bondTitles?.bondTitleSuggestion,
        audience: state => state.audience,
        prefixLength: state => (state.draft.content as DraftChatContent_V1)?.prefix?.length ?? 0,
    },
    reducers: {
        updateUserSpecifiedTitle: (
            state,
            { payload }: PayloadAction<Optional<string>>,
        ) => {
            state.bondTitles.userSpecifiedTitle = payload;
        },
        clearBondTitleSuggestion: state => state.bondTitles.bondTitleSuggestion = undefined,
        addToAudience: (state, { payload }: PayloadAction<AudienceMember>) => {
            state.audience = [...state.audience, payload].removeDuplicates();
        },
        removeFromAudience: (state, { payload }: PayloadAction<AudienceMember>) => {
            state.audience = state.audience.filter(m => m !== payload);
        },
    },
    extraReducers: builder => {
        builder.addCase(
            clearDraftThunk.fulfilled,
            (state, action) => {
                if (!isBondCreationDraftTarget(action.payload.draftTarget)) return;

                state.bondTitles.userSpecifiedTitle = undefined;
                state.bondTitles.bondTitleSuggestion = undefined;
                state.audience = [];

                switchDraftContentVersion(
                    state.draft.content,
                    _ => contentMutationReducer<{ draftTarget: DraftTarget; }>(
                        clearContent,
                    )(state, action),
                    _ => {
                        state.draft = emptyDraftChatMessage({
                            contentVer: 2,
                            makeClearChange: true,
                        });
                    },
                    () => {},
                );
            },
        );

        builder.addCase(fetchBondTitleSuggestionThunk.fulfilled, (state, action) => {
            state.bondTitles.bondTitleSuggestion = action.payload;
        });
        builder.addCase(fetchBondTitleSuggestionThunk.rejected, (_state, _action) => {
            // throw action.error;
        });

        // TODO: think about optimistic updates here?
        builder.addCase(createBondFromMessageThunk.fulfilled, (state, action) => {
            if (action.meta.arg.msg.localId == state.draft.localId) {
                return getInitialState();
            }
        });
        builder.addCase(createBondFromMessageThunk.rejected, (_state, _action) => {
            // throw action.error;
        });

        builder.addCase(updateDraftMarkup, (state, action) => {
            if (!isBondCreationDraftTarget(action.payload.draftTarget)) {
                return;
            }

            const {
                update: { markup, change },
                source,
            } = action.payload;

            const draft = {
                ...state.draft,
                content: convertDraftContentToV2(state.draft.content),
            };

            const newDraft = {
                ...draft,
                content: change ?
                    withChange(draft.content, change, source) :
                    withMarkup(draft.content, markup!, source),
            };

            const {
                addedMentions,
                lastRemovedMentions,
            } = mentionDiff(draft.content.draftMarkup, newDraft.content.draftMarkup);

            // Update the audience according to the change in mentions
            state.audience = state.audience
                .filter(m => !lastRemovedMentions.some(r => m == r.target))
                .concat(addedMentions.map(m => m.target))
                .removeDuplicates();

            state.draft = newDraft;
        });

        /**
         * @deprecated
         */
        const isBondCreationValidator = {
            validate: (_: any, p: { draftTarget: DraftTarget; }): boolean =>
                p.draftTarget.type === DraftType.BondCreation,
        };

        /**
         * @deprecated
         */
        builder.addCase(
            updateDraftText,
            contentMutationReducer<UpdateDraftTextArgs>(
                applyTextToContent,
                isBondCreationValidator,
            ),
        );
        /**
         * @deprecated
         */
        builder.addCase(
            insertDraftText,
            contentMutationReducer<InsertDraftTextArgs>(
                insertTextIntoContent,
                isBondCreationValidator,
            ),
        );
        /**
         * @deprecated
         */
        builder.addCase(
            insertDraftMention,
            contentMutationReducer<InsertDraftMentionArgs>(
                insertMentionIntoContent,
                isBondCreationValidator,
            ),
        );

        builder.addCase(
            addAttachmentsToDraft,
            (state, { payload: attachments }: PayloadAction<ProposedAttachment[]>) => {
                if (attachments.length === 0) return;

                attachments.forEach(attachment => {
                    if (!isBondCreationDraftTarget(attachment.draftTarget)) return;

                    const { localId } = attachment;
                    const index = state.draft.attachmentIds.indexOf(localId);
                    if (index === -1) {
                        state.draft.attachmentIds.push(localId);
                    }
                });
            },
        );
        builder.addCase(
            deleteAttachmentFromDraftThunk.fulfilled,
            (state, { payload: { localId, draftTarget } }) => {
                if (!isBondCreationDraftTarget(draftTarget)) return;

                const index = state.draft.attachmentIds.indexOf(localId);
                if (index !== -1) {
                    state.draft.attachmentIds.splice(index, 1);
                }
            },
        );

        builder.addCase(
            clearAttachmentsFromDraft,
            (state, { payload: draftTarget }: PayloadAction<DraftTarget>) => {
                if (!isBondCreationDraftTarget(draftTarget)) return;

                state.draft.attachmentIds = [];
            },
        );
        builder.addCase(stageMessageForChannel.fulfilled, (_state, _) => {
            return getInitialState();
        });
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

export const {
    updateUserSpecifiedTitle: updateUserSpecifiedTitle,
    clearBondTitleSuggestion: clearBondTitleSuggestion,
    addToAudience: addToBondCreationAudience,
    removeFromAudience: removeFromBondCreationAudience,
} = bondCreationSlice.actions;

const selectors = bondCreationSlice.getSelectors((state: RootState) => state.bondCreation);
export const {
    draft: selectBondCreationDraft, // guarantees non-undefined DraftChatMessage
    userSpecifiedTitle: selectBondUserSpecifiedTitle,
    bondTitleSuggestion: selectBondTitleSuggestion,
    audience: selectBondCreationAudience,
    prefixLength: selectBondCreationPrefixLength,
} = selectors;

export const selectBondTitleSuggestionWithEmojiSplit = createAppSelector(
    [selectors.bondTitleSuggestion],
    bondTitleSuggestion => {
        if (!bondTitleSuggestion) return { title: "", emoji: "" };
        let emoji = "";
        if (bondTitleSuggestion) {
            emoji = bondTitleSuggestion.match(emojiRegex)?.[0] || "";
        }
        return {
            title: bondTitleSuggestion.replace(emoji, "").trim(),
            emoji: emoji,
        };
    },
);

export const selectOfficialAttachmentIdsForBondCreationMessage = createAppSelector(
    [state => state, selectBondCreationDraft],
    (state, msg) =>
        msg?.attachmentIds
            .map(a => selectLocalAttachment(state, a))
            .filter(isUploadedAttachment)
            .map(a => a.blobId) ?? [],
    memoizeOptions.weakMapShallow,
);

export const selectValidSquadIdsForBondCreationAudience = createAppSelector(
    [selectValidSquadIdsForMention, selectBondCreationAudience],
    (ids, audience) => ids.filter(id => !audience.some(m => m == id)),
    memoizeOptions.weakMapShallow,
);

export const selectValidUserIdsForBondCreationAudience = createAppSelector(
    [selectValidUserIdsForMention, selectBondCreationAudience],
    (ids, audience) => ids.filter(id => !audience.some(m => m == id)),
    memoizeOptions.weakMapShallow,
);

export const reducer = bondCreationSlice.reducer;

// region Persistence

export const persistor = {
    stores: {},
};
checkPersistor<BondCreationState>(persistor);
