import fuzzysort from "fuzzysort";
import deburr from "lodash.deburr";

// similaritySearch takes two strings, deburrs them and then checks them for similarity,
// obtaining a score. This score is then compared against the provided threshold,
// or default threshold if not provided. If the score is greater than the threshold,
// true is returned.
//
// Usage:
//
// similaritySearch("arun", "arun") returns true (score is 0)
//
// similaritySearch("arun", "sam") returns false (score is minus infinity (undefined))
//
// similaritySearch("un", "arun") returns true or false depending on the threshold set (score is -162)
export function similaritySearch(
    searchTerm: string,
    searchSpace: string,
    threshold: number = -1000,
) {
    const deburredTerm = deburr(searchTerm);

    let score: number | undefined;
    if (deburredTerm === searchTerm) {
        score = fuzzysort.single(deburredTerm, deburr(searchSpace))?.score;
    }
    else {
        score = fuzzysort.single(searchTerm, searchSpace)?.score;
    }

    return (score ?? Number.NEGATIVE_INFINITY) > threshold;
}

// similaritySort takes two strings and decides which of them is closer to the search term.
export function similaritySort(searchTerm: string, a: string, b: string) {
    const deburredTerm = deburr(searchTerm);

    let scoreA: number;
    let scoreB: number;
    if (deburredTerm === searchTerm) {
        scoreA = fuzzysort.single(deburredTerm, deburr(a))?.score ??
            Number.NEGATIVE_INFINITY;
        scoreB = fuzzysort.single(deburredTerm, deburr(b))?.score ??
            Number.NEGATIVE_INFINITY;
    }
    else {
        scoreA = fuzzysort.single(searchTerm, a)?.score ??
            Number.NEGATIVE_INFINITY;
        scoreB = fuzzysort.single(searchTerm, b)?.score ??
            Number.NEGATIVE_INFINITY;
    }

    if (scoreA > scoreB) {
        return -1;
    }
    if (scoreB > scoreA) {
        return 1;
    }
    return 0;
}
