import * as d from "@/domain/domain";
import { selectDeltaKnowledgeBond, selectMaxSequenceNumber } from "@/features/channels";
import { fetchDeltaKnowledgeBondThunk } from "@/features/intel";
import {
    selectShowSidebarSummary,
    updateShowSidebarSummary,
    updateSidebarSummaryLastReadSequenceNumber,
} from "@/features/meta";
import useLocalDispatch from "@/hooks/useLocalDispatch";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import log from "@/misc/log";
import { Optional } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import { useEffect, useState } from "react";

export interface BondCatchupDividerProps {
    channelId: Optional<d.ChannelId>;
    savedPublishedSequenceNumber: Optional<number>;
}

export function BondCatchupDivider(
    { channelId, savedPublishedSequenceNumber }: BondCatchupDividerProps,
): React.JSX.Element {
    const [hasDispatchedDeltaKnowledgeRequest, setHasDispatchedDeltaKnowledgeRequest] = useState(
        false,
    );
    const showSidebarSummary = useAppSelector(selectShowSidebarSummary);
    const knowledge = useSelectorArgs(selectDeltaKnowledgeBond, channelId);
    const maxSequenceNumber = useSelectorArgs(selectMaxSequenceNumber, channelId);
    const localDispatch = useLocalDispatch();
    const dispatch = useAppDispatch();
    // If the user never read the bond and he does not follow the bond, then his saved
    // published sequence number is undefined.
    const seqNum = savedPublishedSequenceNumber ?? 0;

    useEffect(() => {
        localDispatch(updateSidebarSummaryLastReadSequenceNumber(seqNum));
    }, [localDispatch, seqNum]);

    if (
        !channelId || maxSequenceNumber === undefined
    ) return <></>;

    const fetchAndDisplayDeltaKnowledgeBond = () => {
        if (!showSidebarSummary) {
            localDispatch(updateShowSidebarSummary(true));
        }

        const knowledgeHasSameReadState = knowledge?.lastReadSeqNumber === seqNum;
        const knowledgeHasSameMaxSeqNum = knowledge?.lastSummarisedSeq === maxSequenceNumber;
        const knowledgeIsUpToDate = knowledgeHasSameReadState && knowledgeHasSameMaxSeqNum;
        /*
        Note: This method is not bullet proof. If the user clicks on this
        button, and go in and out of the bond and click again. It is
        theoretically possible for him to send two requests, if the request time
        is high.
        */
        if (knowledgeIsUpToDate || hasDispatchedDeltaKnowledgeRequest) {
            log.info(
                "knowledge is up to date or we have already dispatched delta knowledge request",
            );
            return;
        }

        setHasDispatchedDeltaKnowledgeRequest(true);
        // TODO: Find good solution when the RPC call fails.
        dispatch(
            fetchDeltaKnowledgeBondThunk({
                channelId: channelId,
                lastReadSequenceNumber: seqNum,
            }),
        );
    };

    const hideCatchupSummary = () => {
        if (showSidebarSummary) localDispatch(updateShowSidebarSummary(false));
    };

    return (
        <div className="c-message-divider">
            <div className="c-btn-catchup">
                <button
                    className="c-btn-catchup__catchup"
                    title="Catch up"
                    onClick={fetchAndDisplayDeltaKnowledgeBond}
                >
                    <div className="c-btn-catchup__catchup-icon"></div>
                    Catch up
                </button>
                {
                    <button
                        className="c-btn-catchup__dismiss"
                        title="Dismiss"
                        onClick={hideCatchupSummary}
                    >
                        Dismiss
                    </button>
                }
            </div>
            <div className="c-message-divider__rule"></div>
        </div>
    );
}
