import classNames from "classnames";
import {
    DetailedHTMLProps,
    HTMLAttributes,
    MouseEventHandler,
    PropsWithChildren,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import { BoundingBox, Optional, Point } from "@/misc/types";

export interface DraggableProps
    extends PropsWithChildren, DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
{
    allowBackgroundSelect?: boolean;
    allowClicksOnDrag?: boolean;
    allowDragOffWindow?: boolean;
    componentBorderOffset?: BoundingBox;
    onMove?: (pos: Point) => void;
    startingPosition?: Point;
}

/** @function Defines a draggable component. Used for call multitasking
 * @param allowBackgroundSelect  Allow background text to
 * be selected as component is dragged if selected
 * @param allowClicksOnDrag Allow draggable to be clicked and dragged if selected
 * @param allowDragOffWindow Allow dragging the component off the window if selected,
 * otherwise the component will be constrained to the window borders
 * @param componentBorderOffset The offset of the draggable from the top left corner of the window
 * @param onMove Callback for when the draggable component is moved
 * @param startingPosition The starting position of the draggable component
 *
 * @returns A draggable component
 */
function Draggable(props: DraggableProps): React.JSX.Element {
    const {
        allowBackgroundSelect,
        allowClicksOnDrag,
        allowDragOffWindow,
        componentBorderOffset,
        onMove,
        startingPosition,

        children,
        ...divProps
    } = props;

    const dragRef = useRef<HTMLDivElement>(null);
    const cursorPositionInComponentRef = useRef({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const currentClickIsDrag = useRef<Optional<boolean>>();

    const offset = useShallowEqualsMemo(() => ({
        top: componentBorderOffset?.top ?? 0,
        left: componentBorderOffset?.left ?? 0,
        right: componentBorderOffset?.right ?? 0,
        bottom: componentBorderOffset?.bottom ?? 0,
    }), [componentBorderOffset]);

    const startPos = useMemo(() => startingPosition ?? { x: 0, y: 0 }, [startingPosition]);
    const [position, setPosition] = useState(startPos);

    const className = useMemo(
        () => classNames(props.className, { "is-grabbed": isDragging }),
        [props.className, isDragging],
    );

    const updatePositionInWindow = useCallback((coords: Point) => {
        if (!dragRef.current) return coords;
        let newX = coords.x;
        let newY = coords.y;
        if (!allowDragOffWindow) {
            const maxPositionX = window.innerWidth - dragRef.current.offsetWidth - offset.right;
            const maxPositionY = window.innerHeight - dragRef.current.offsetHeight - offset.bottom;
            newX = Math.min(Math.max(coords.x, offset.left), maxPositionX);
            newY = Math.min(Math.max(coords.y, offset.top), maxPositionY);
        }
        return { x: newX, y: newY };
    }, [allowDragOffWindow, offset.top, offset.left, offset.right, offset.bottom]);

    useLayoutEffect(() => {
        setPosition(updatePositionInWindow(startPos));
    }, [startPos, updatePositionInWindow]);

    const onWindowResize = useCallback(() => {
        setPosition(curr => updatePositionInWindow(curr));
    }, [updatePositionInWindow]);

    useEffect(() => {
        window.addEventListener("resize", onWindowResize);
        return () => window.removeEventListener("resize", onWindowResize);
    }, [onWindowResize]);

    const onMouseDownInComponent: MouseEventHandler = useCallback(e => {
        currentClickIsDrag.current = false;
        cursorPositionInComponentRef.current = { x: e.pageX - position.x, y: e.pageY - position.y };
    }, [position]);

    const onMouseMoveInDocument = useCallback((e: MouseEvent) => {
        if (currentClickIsDrag.current !== undefined) {
            setIsDragging(true);
            currentClickIsDrag.current = true;
            const newPos = updatePositionInWindow({
                x: e.pageX - cursorPositionInComponentRef.current.x,
                y: e.pageY - cursorPositionInComponentRef.current.y,
            });
            setPosition(newPos);
            onMove?.(newPos);
        }
    }, [onMove, updatePositionInWindow]);

    useEffect(() => {
        document.addEventListener("mousemove", onMouseMoveInDocument);
        return () => document.removeEventListener("mousemove", onMouseMoveInDocument);
    }, [onMouseMoveInDocument]);

    const endClick: MouseEventHandler = useCallback(e => {
        if (!allowClicksOnDrag && currentClickIsDrag.current) e.stopPropagation();
        currentClickIsDrag.current = undefined;
        cursorPositionInComponentRef.current = { x: 0, y: 0 };
        setIsDragging(false);
    }, [allowClicksOnDrag]);

    useEffect(() => {
        if (dragRef.current) {
            dragRef.current.style.left = `${position.x}px`;
            dragRef.current.style.top = `${position.y}px`;
        }
    }, [position]);

    // If we don't allow background selecting, turn off user-select when dragging starts.
    useEffect(() => {
        if (allowBackgroundSelect) return;
        if (!isDragging) return;

        const initialUserSelect = document.body.style.userSelect;
        document.body.style.userSelect = "none";
        return () => {
            document.body.style.userSelect = initialUserSelect;
        };
    }, [allowBackgroundSelect, isDragging]);

    return (
        <div
            {...divProps}
            className={className}
            ref={dragRef}
            onMouseDown={onMouseDownInComponent}
            onClickCapture={endClick}
        >
            {children}
        </div>
    );
}

/** @function
 * Helper component to wrap around children of the Draggable component.
 *
 * If the parent (i.e Draggable) has an onClick, then wrap this around children
 * which have their own onClick if you don't want to compose the two onClicks.
 */
export function StopClicksOnBackground(props: PropsWithChildren): React.JSX.Element {
    const stopPropagation: React.MouseEventHandler = useCallback(e => {
        e.stopPropagation();
    }, []);
    return <div className="u-draggable" onClick={stopPropagation}>{props.children}</div>;
}

export default Draggable;
