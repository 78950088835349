import {
    createRef,
    RefObject,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";

import usePrevious from "@/hooks/usePrevious";
import { isMobileBrowser } from "@/misc/mobile";
import { Optional } from "@/misc/types";
import { modulo, range } from "@/misc/utils";

interface useHighlightedIndexOptions {
    allowTab?: boolean;
    useUnselectedState?: boolean;
    extraAction?: () => void;
}

export const useHighlightedIndex = <E extends HTMLElement>(
    arrowKeysActive: boolean,
    actions: Optional<() => void>[],
    options?: useHighlightedIndexOptions,
): {
    highlightedIndex: Optional<number>;
    offsetHighlightedIndex: (offset: number) => void;
    scrollRefs: RefObject<RefObject<E>[]>;
} => {
    const allowTab = options?.allowTab ?? false;
    const useUnselectedState = options?.useUnselectedState ?? false;
    const extraAction = options?.extraAction;

    // Create refs for scrolling individual items into view
    const scrollRefs = useRef<RefObject<E>[]>([]);

    // Increase the number of refs as needed to match the number of items we have
    scrollRefs.current = range(actions.length).map(i => scrollRefs.current[i] ?? createRef());

    // Create the highlight state
    const initialState = useUnselectedState ? undefined : 0;
    const [highlightedIndex, setHighlightedIndex] = useState<Optional<number>>(initialState);

    // Callbacks for modifying the highlight index
    const resetHighlightedIndex = useCallback(
        () => setHighlightedIndex(initialState),
        [initialState],
    );
    const offsetHighlightedIndex = useCallback((offset: number) => {
        if (actions.length == 0 || highlightedIndex === undefined) {
            setHighlightedIndex(0);
        }
        else {
            const newIndex = modulo(highlightedIndex + offset, actions.length);
            setHighlightedIndex(newIndex);
            scrollRefs.current[newIndex].current?.scrollIntoView({ block: "nearest" });
        }
    }, [highlightedIndex, actions.length]);

    // Update the index when the list length changes
    const prevLength = usePrevious(actions.length) ?? 0;
    useLayoutEffect(() => {
        if (actions.length < prevLength && highlightedIndex !== undefined) {
            // Offset by 0 when the length decreases to clamp the index within the new length
            offsetHighlightedIndex(0);
        }
        else if (actions.length > prevLength) {
            // Reset the index when the length increases
            resetHighlightedIndex();
        }
    }, [
        actions.length,
        prevLength,
        highlightedIndex,
        offsetHighlightedIndex,
        resetHighlightedIndex,
    ]);

    // Handle arrow key and tab keypresses
    const prevArrowKeysActive = usePrevious(arrowKeysActive);
    useEffect(() => {
        if (!arrowKeysActive) {
            if (prevArrowKeysActive) resetHighlightedIndex();
            return;
        }
        if (actions.length == 0) return;

        const handler = (e: KeyboardEvent) => {
            if (isMobileBrowser()) return;

            switch (e.key) {
                case "Enter": {
                    if (e.shiftKey) break;
                    if (highlightedIndex === undefined) break;
                    e.preventDefault();
                    e.stopPropagation();
                    actions[highlightedIndex]?.();
                    extraAction?.();
                    break;
                }
                case "Tab": {
                    if (!allowTab) break;
                    e.preventDefault();
                    e.stopPropagation();
                    offsetHighlightedIndex(e.shiftKey ? -1 : 1);
                    break;
                }
                case "ArrowDown": {
                    e.preventDefault();
                    e.stopPropagation();
                    offsetHighlightedIndex(1);
                    break;
                }
                case "ArrowUp": {
                    e.preventDefault();
                    e.stopPropagation();
                    offsetHighlightedIndex(-1);
                    break;
                }
            }
        };

        document.addEventListener("keydown", handler, true);
        return () => document.removeEventListener("keydown", handler, true);
    }, [
        prevArrowKeysActive,
        arrowKeysActive,
        highlightedIndex,
        resetHighlightedIndex,
        offsetHighlightedIndex,
        extraAction,
        actions,
        allowTab,
    ]);

    return {
        highlightedIndex,
        offsetHighlightedIndex,
        scrollRefs,
    };
};
