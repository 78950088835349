import WebSocket from "isomorphic-ws";

import {
    WebsocketAuthMessageResponse,
    WebsocketAuthMessageResponse_AuthSuccess,
} from "../../gen/proto/clients/clients_pb";

import transport, {
    setAuthInviteCode,
    setBearerToken,
    WebsocketAuthMessage,
    WebsocketError,
    WebsocketErrorCode,
    WebsocketRpcs,
    WebsocketRpcsInterface,
} from "@/api/transport";
import { fromProtoDeviceId, fromProtoUserId, pbUserId } from "@/api/util";
import { defaultBackoffProps } from "@/domain/backoff";
import { backoffManagerCreator, type BackoffResultCallback } from "@/ds/backoff";
import {
    AuthStatus,
    resetAuth,
    selectAuthInviteCode,
    selectAuthStatus,
    selectBearerToken,
    selectCurrentUserId,
} from "@/features/auth";
import {
    ConnectionStatus,
    selectConnectionStatus,
    selectOnlineStatus,
    updateConnectionStatus,
    updateOnlineStatus,
} from "@/features/connection";
import { selectConnectionIdentifiers, updateBackendInfo } from "@/features/meta";
import { getFrontendPlatform } from "@/misc/capacitor";
import { getEnvironmentConfig } from "@/misc/environment";
import log from "@/misc/log";
import { promiseWithResolvers } from "@/misc/promises";
import {
    type AppAppendListener,
    appAppendListener,
    firstOrStatesDifferBySelectors,
    ListenerAPI,
    startLongLivedListener,
    statesDifferBySelectors,
} from "@/store/middleware";
import type { AppStore, RootState } from "@/store/types";

// The number of milliseconds after running the auth callback that we should
// wait before allowing ourselves to mark a websocket connection as
// "successfully connected."
export const connectionSuccessfulDelay = 2048;

export const clientConnectionProtocolVersion = 3;

const targetConfig = getEnvironmentConfig();
const targetURL = new URL(targetConfig.apiBaseUrl + "/client");
const defaultFactory = () => new WebsocketRpcs();

const connectionStatusComparer = statesDifferBySelectors(
    selectAuthStatus,
    selectConnectionStatus,
    selectOnlineStatus,
    selectBearerToken,
);

interface ManageConnectionParameters {
    rpcs?: () => WebsocketRpcsInterface;
}

const getConnectionManager = (factory: () => WebsocketRpcsInterface) => {
    const backoffManager = backoffManagerCreator({
        logging: { prefix: "connection" },
        ...defaultBackoffProps,
    });
    let backoffCallback: BackoffResultCallback | undefined;

    let ws: WebsocketRpcsInterface | undefined = undefined;

    const transition = (api: ListenerAPI, status: ConnectionStatus) => {
        api.dispatch(updateConnectionStatus(status));
    };

    const errorTransition = (
        api: ListenerAPI,
        code: WebsocketErrorCode,
        backoffCallback: (success: boolean) => void,
    ) => {
        backoffCallback(false);
        ws = undefined;

        if (code === WebsocketErrorCode.Unauthorised) {
            transition(api, ConnectionStatus.ClearAuth);
        }
        else if (code === WebsocketErrorCode.UnsupportedProtocol) {
            log.error(`Outdated websocket protocol; Refresh page to update`);
        }
        else {
            transition(api, ConnectionStatus.AwaitReconnect);
        }
    };

    const closeWebsocket = (api: ListenerAPI) => {
        log.info(`Lost authentication, closing websocket`);
        const curWs = ws;
        ws = undefined;
        curWs?.done();

        if (backoffCallback) {
            // This is a "success" pathway, because to be here we are electing
            // to be good citizens and tear down the websocket pre-emptively.
            // In reality, I don't think it matters which method we call.
            backoffCallback(true);
            backoffCallback = undefined;
        }

        transition(api, ConnectionStatus.AwaitAuth);
    };

    return async (api: ListenerAPI, state: RootState) => {
        const authStatus = selectAuthStatus(state);
        const status = selectConnectionStatus(state);
        // This is: "does the browser think we're online?"
        const online = selectOnlineStatus(state);

        const token = selectBearerToken(state);
        const currentUserId = selectCurrentUserId(state);

        // If we lose authentication, then we should close the websocket.
        if (authStatus !== AuthStatus.Authenticated && ws !== undefined) {
            closeWebsocket(api);
            return;
        }

        switch (status) {
            case ConnectionStatus.ShouldConnect: {
                if (ws !== undefined) return;

                if (!online) return;

                if (!token || authStatus !== AuthStatus.Authenticated) {
                    transition(api, ConnectionStatus.AwaitAuth);
                    return;
                }

                const newWs = factory();
                ws = newWs;

                const backoffCb = backoffManager.begin();
                backoffCallback = backoffCb;

                newWs.disconnected.wait().then((err: WebsocketError) => {
                    // It's possible that by the time we're woken up here, we're no longer the active
                    // websocket. In such a case, we need to ignore this event.
                    if (newWs != ws) return;

                    errorTransition(api, err.code, backoffCb);
                });

                newWs.connected.wait().then((err: WebsocketError | null) => {
                    if (newWs != ws) return;

                    if (err) {
                        errorTransition(api, err.code, backoffCb);
                    }
                    else {
                        // Don't transition to `Connected` here - wait for the auth callback
                        // to complete.
                    }
                });

                const connectionIdentifiers = selectConnectionIdentifiers(state);

                const authMessage = new WebsocketAuthMessage({
                    ...connectionIdentifiers,
                    userId: currentUserId && pbUserId(currentUserId),
                    authToken: token ?? "",
                    protocolVersion: clientConnectionProtocolVersion,
                    frontendPlatform: getFrontendPlatform(),
                    frontendVersion: __BEYOND_FRONTEND_VERSION__,
                });

                newWs.connect(targetURL, async (connectedWs: WebSocket) => {
                    const { promise, resolve, reject } = promiseWithResolvers<
                        WebsocketAuthMessageResponse_AuthSuccess
                    >();

                    const authReplyHandler = (e: WebSocket.MessageEvent) => {
                        try {
                            const resp = WebsocketAuthMessageResponse.fromBinary(
                                new Uint8Array(e.data as ArrayBuffer),
                            );

                            if (resp.successOrFailure.case === "success") {
                                resolve(resp.successOrFailure.value);
                            }
                            else {
                                // Reject with the WebsocketAuthMessageResponse_AuthError which is checked
                                // for in transport.ts to determine if this is an auth error.
                                reject(resp.successOrFailure.value);
                            }
                        }
                        catch (e) {
                            reject(e);
                        }
                    };

                    connectedWs.addEventListener("message", authReplyHandler, { once: true });

                    const sendTime = performance.now();
                    connectedWs.send(authMessage.toBinary());

                    log.info(
                        "Sent pre-GOAT websocket authentication message",
                        {
                            deviceTag: authMessage.deviceTag,
                            connTag: `${authMessage.connTagPrefix}::${authMessage.connTagNum}`,
                            userId: authMessage.userId &&
                                fromProtoUserId(authMessage.userId),
                            protocolVersion: authMessage.protocolVersion,
                            frontendPlatform: authMessage.frontendPlatform,
                            frontendVersion: authMessage.frontendVersion,
                        },
                    );

                    try {
                        const success = await promise;
                        const durMs = performance.now() - sendTime;

                        log.info(
                            `Successfully authenticated websocket as user ${
                                fromProtoUserId(success.userId)
                            }, device ${fromProtoDeviceId(success.deviceId)} in ${
                                durMs.toFixed(2)
                            }ms, backend version ${success.version} instance ${success.instance}`,
                        );

                        api.dispatch(
                            updateBackendInfo({
                                instance: success.instance,
                                version: success.version,
                            }),
                        );
                        backoffCb(true);
                    }
                    catch (e) {
                        log.info("Failed to authenticate websocket", e);
                        throw e;
                    }

                    transition(api, ConnectionStatus.Connected);
                });

                transport.reset(newWs, new DOMException("reset", "AbortError"));

                return;
            }
            case ConnectionStatus.AwaitReconnect: {
                const delay = backoffManager.getDelay();

                if (delay > 0) {
                    const statusChanged = await api.condition(connectionStatusComparer, delay);
                    if (statusChanged) return;
                }

                transition(api, ConnectionStatus.AwaitAuth);

                return;
            }
            case ConnectionStatus.ClearAuth: {
                closeWebsocket(api);
                api.dispatch(resetAuth());
                return;
            }
            case ConnectionStatus.AwaitAuth: {
                // Connect immediately if we already have token,
                // else wait to be moved into ShouldConnect externally
                if (token && online && authStatus === AuthStatus.Authenticated) {
                    transition(api, ConnectionStatus.ShouldConnect);
                }

                return;
            }
        }
    };
};

export const startManagingConnection = (
    appendListener: AppAppendListener = appAppendListener,
    params?: ManageConnectionParameters,
) => {
    const { rpcs } = params ?? {};
    const cleanup = [];

    {
        const predicate = firstOrStatesDifferBySelectors(selectBearerToken);
        const c = appendListener(
            {
                predicate,
                effect: (_action, api) => {
                    const state = api.getState();
                    const token = selectBearerToken(state);
                    if (token) {
                        setBearerToken(token);
                    }
                },
            },
        );
        cleanup.push(c);
    }

    {
        // This may need to be a map or list of codes in the future.
        const predicate = statesDifferBySelectors(selectAuthInviteCode);
        const c = appendListener(
            {
                predicate,
                effect: (_action, api) => {
                    const state = api.getState();
                    const inviteCode = selectAuthInviteCode(state);
                    setAuthInviteCode(inviteCode || null);
                },
            },
        );
        cleanup.push(c);
    }

    {
        const connectionManager = getConnectionManager(rpcs ?? defaultFactory);

        const c = startLongLivedListener(
            connectionStatusComparer,
            connectionManager,
            "connection-manager",
            appendListener,
        );

        cleanup.push(c);
    }

    return cleanup;
};

export const startDetectingOnlineStatus = (store: AppStore) => {
    const target = self ?? window;

    let online = target.navigator.onLine;

    const listenerGenerator = (o: boolean) => () => {
        if (o === online) return;

        online = o;
        log.info(`Browser reports status ${online ? "online" : "offline"}`);
        store.dispatch(updateOnlineStatus(online));
    };

    const lOnline = listenerGenerator(true);
    const lOffline = listenerGenerator(false);

    target.addEventListener("online", lOnline);
    target.addEventListener("offline", lOffline);

    store.dispatch(updateOnlineStatus(online));

    return () => {
        target.removeEventListener("online", lOnline);
        target.removeEventListener("offline", lOffline);
    };
};
