import { useCallback, useEffect, useRef, useState } from "react";

import { CallActionStatus } from "@/features/calls";
import useBackoff, { ActionBeginReturnType } from "@/hooks/useBackoff";
import usePrevious from "@/hooks/usePrevious";
import log from "@/misc/log";
import { Optional } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import type { RootState } from "@/store/types";
import { PayloadAction } from "@reduxjs/toolkit";

const initialBackoff = 256;
const maxBackoffExponent = 4;
export const maxCallActionRetries = 10;

interface UseRtcActionBackoffProps {
    selectCallStatus: (state: RootState) => CallActionStatus;
    setCallStatusFromCurrentCallId: (status: CallActionStatus) => PayloadAction<CallActionStatus>;
}

export function useRtcActionBackoff(props: UseRtcActionBackoffProps) {
    const { selectCallStatus, setCallStatusFromCurrentCallId } = props;
    const dispatch = useAppDispatch();

    const callStatus = useAppSelector(selectCallStatus);

    // Store the "can we perform this action" details with a Ref so we can inspect
    // its content in useEffect cleanup functions.
    const actionReadyRef = useRef(false);
    const getActionReady = useCallback(() => actionReadyRef.current, []);
    const resetActionReady = useCallback(() => actionReadyRef.current = false, []);

    // The metadata about the current RTC action.
    // Model the signal as an entire object to cause useEffects to retrigger.
    const [signal, setSignal] = useState({});
    const sendSignal = useCallback((actionReady: boolean) => {
        log.debug("Starting RTC action...");
        actionReadyRef.current = actionReady;
        setSignal({});
    }, []);

    // Backoff and retry
    const { actionIsPermitted, actionBegin, attempts, resetBackoffState } = useBackoff({
        intervalMs: initialBackoff,
        truncateAt: maxBackoffExponent,
    });

    // Store previous attempts so we backoff from error after backoff state updates
    const prevAttemps = usePrevious(attempts);

    // Store refs to the callback returned when beginning a backoff action, so we
    // can access them when the action is successful/failed in the useEffects below.
    const backoffCallbackRef = useRef<Optional<ActionBeginReturnType>>();
    const beginBackoffAction = useCallback(() => {
        backoffCallbackRef.current = actionBegin();
    }, [actionBegin]);
    const succeedBackoffAction = useCallback(() => {
        backoffCallbackRef.current?.(true);
        resetActionReady();
    }, [resetActionReady]);
    const failBackoffAction = useCallback(() => {
        backoffCallbackRef.current?.(false);
        resetActionReady();
    }, [resetActionReady]);

    // Manage the backoff cycle by beginning/succeeding/failing the action
    useEffect(() => {
        switch (callStatus) {
            case CallActionStatus.None:
                resetBackoffState();
                return;
            case CallActionStatus.Ready:
                // Begin the backoff sequence by sending the signal
                // to perform the RTC action.
                beginBackoffAction();
                sendSignal(true);
                return;
            case CallActionStatus.Completed:
                // Mark action as successful
                succeedBackoffAction();
                return;
            case CallActionStatus.Error:
                // Fail backoff action
                failBackoffAction();
                return;
        }
    }, [
        beginBackoffAction,
        callStatus,
        failBackoffAction,
        resetBackoffState,
        sendSignal,
        succeedBackoffAction,
    ]);

    // Recover from error/backoff states once permitted by the backoff.
    // Note this is in a separate useEffect to not double-trigger actions
    // as the backoff state changes.
    useEffect(() => {
        switch (callStatus) {
            case CallActionStatus.Error:
                // Start backoff if not reached max attempts
                // Note we use prevAttempts here to only start backing off
                // after backoff state updates (i.e. attempts incremented).
                if (prevAttemps != attempts && attempts < maxCallActionRetries) {
                    dispatch(setCallStatusFromCurrentCallId(CallActionStatus.Backoff));
                }
                return;
            case CallActionStatus.Backoff:
                // Retrigger action after backoff permits it. This reducer will transition
                // the call status depending on whether the call still exists.
                if (actionIsPermitted) {
                    dispatch(setCallStatusFromCurrentCallId(CallActionStatus.Ready));
                }
                return;
        }
    }, [
        actionIsPermitted,
        attempts,
        callStatus,
        dispatch,
        prevAttemps,
        setCallStatusFromCurrentCallId,
    ]);

    return { signal, getActionReady, sendSignal };
}
