import * as d from "@/domain/domain";
import { nanoid } from "@reduxjs/toolkit";

export type QueryId = `qry-id:${string}`;
export function newQueryId(): QueryId {
    return `qry-id:${nanoid()}`;
}

// Hysteresis rules for bond title suggestion
const isStringLengthHysteresisMet =
    (triggerValue: number) => (oldValue: string, newValue: string) => {
        const lengthDelta = Math.abs(oldValue.trim().length - newValue.trim().length);
        return lengthDelta >= triggerValue;
    };

const messageLengthDiffTrigger = 20;
const isMessageHysteresisMet = isStringLengthHysteresisMet(messageLengthDiffTrigger);

export const minimumTriggerMessageLength = 70;
export const messageHysteresisBondTitleSuggestion = (oldValue: string, newValue: string) => {
    if (!newValue) return true;
    if (newValue.length < minimumTriggerMessageLength) return false;

    return isMessageHysteresisMet(
        oldValue,
        newValue,
    );
};

export interface DeltaKnowledgeBond {
    channelId: d.ChannelId;
    deltaSummaryBond: string[];
    deltaSummaryBondLive: string[];
    lastReadSeqNumber: number;
    lastSummarisedSeq: number;
    firstNewMessageTs: number;
    liveSessionStartedAt?: number;
}
