import React, { useEffect } from "react";

import { SentryReportButtonNoRedux } from "@/components/SentryReportButton";
import log from "@/misc/log";

export const SystemMessage = (props: { message: string; }): React.JSX.Element => {
    const [showRefresh, setShowRefresh] = React.useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setShowRefresh(true), 2500);
        return () => clearTimeout(timer);
    }, [setShowRefresh]);

    return (
        <div>
            <div className="c-bond-status">
                <div className="c-bond-status__message">
                    <div className="c-logo c-logo--status">Bond</div>
                    {props.message}
                </div>
                <SentryReportButtonNoRedux buttonClassNames="c-btn c-btn-report c-btn-report__system_message" />
                {showRefresh && (
                    <button
                        className="c-btn c-btn-force-refresh"
                        type="button"
                        onClick={() => {
                            log.info("User clicked on force-reload button");
                            window.location.reload();
                        }}
                    >
                        Force refresh
                    </button>
                )}
            </div>
            <div>
            </div>
        </div>
    );
};
