import React from "react";

// The `modal` argument determines whether to show the <dialog> as a full modal,
// or simply inlined in the document. Ladle works better with the latter.
export default function useDialogOpenRef(modal?: boolean): React.RefObject<HTMLDialogElement> {
    const dialog = React.useRef<HTMLDialogElement>(null);

    React.useEffect(() => {
        const d = dialog.current;

        if (d && !d.open) {
            if (modal === undefined || modal) {
                d.showModal();
            }
            else {
                d.show();
            }
        }
    }, [modal]);

    return dialog;
}
