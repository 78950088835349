import {
    annotateMarkupDeltaOps,
    CompactMarkupDelta,
    compactMarkupDelta,
    isMentionOp,
    MarkupDelta,
    MentionOp,
    parseMarkupDelta,
    renderDeltaToTextForDisplay,
    trimCompactMarkup,
} from "@/domain/delta";
import * as d from "@/domain/domain";
import {
    DraftChatContent,
    genLocalMessageId,
    switchDraftContentVersion,
} from "@/domain/draftChatContent";
import { ContentMention, Mention } from "@/domain/mentions";
import { contentToMarkupDelta } from "@/misc/messageContent";
import { Optional } from "@/misc/types";

/**
 * @deprecated
 */
export type SanitisedChatContent_V1 = {
    /**
     * @deprecated
     */
    ver: 1 | undefined;
    /**
     * Opaque, client-only identifier for each message.
     * Allows the client to tie together messages received from the
     * cloud and messages that it sent.
     *
     * The backend provides the contract that messages with different
     * ids will be treated as distinct messages for the idempotent
     * semantics of sending a message.
     * @deprecated
     */
    id?: d.UnsentMessageId;
    /**
     * Plaintext version of the message. Not provided if, e.g., the
     * message contains only an uploaded file but no text
     * @deprecated
     */
    message?: string;
    /**
     * List of non-overlapping character ranges representing mentions
     * in the text, ordered by start offset
     * @deprecated
     */
    mentions?: ContentMention[];
};

export type SanitisedChatContent_V2 = {
    /**
     * Version 2 supports rich text markup stored in `messageMarkup`, while
     * flattened plaintext is stored in `message` for AI use
     */
    ver: 2;
    /**
     * Opaque, client-only identifier for each message.
     * Allows the client to tie together messages received from the
     * cloud and messages that it sent.
     *
     * The backend provides the contract that messages with different
     * ids will be treated as distinct messages for the idempotent
     * semantics of sending a message.
     */
    id?: d.UnsentMessageId;
    /**
     * Quill Delta markup describing the message with formatting and embeds
     */
    messageMarkup?: CompactMarkupDelta;
    /**
     * Plaintext version of the message, parsed from the message markup.
     * This can be used for prompting AI models, but should not be
     * displayed to the user unless the message is plaintext-only.
     */
    message?: string;
};

/**
 * This is stored as a string-y blob in the database. But it must
 * be parsed by something on the backend in order for the message
 * to be provided to an AI for the purpose of creating summaries.
 */
export type SanitisedChatContent =
    | SanitisedChatContent_V1
    | SanitisedChatContent_V2;

/**
 * Produce a different result depending on the message content type. If the message
 * type is not recognised, use a default result
 */
const switchContentVersion = <T>(
    content: Optional<SanitisedChatContent>,
    ver1Map: (c: SanitisedChatContent_V1) => T,
    ver2Map: (c: SanitisedChatContent_V2) => T,
    defaultThunk: () => T,
): T => {
    if (content) {
        switch (content.ver) {
            case undefined:
            case 1:
                return ver1Map(content as SanitisedChatContent_V1);
            case 2:
                return ver2Map(content as SanitisedChatContent_V2);
        }
    }
    return defaultThunk();
};

/**
 * Return the string message text from a message content instance
 */
export const getContentMessageText = (content: Optional<SanitisedChatContent>) => content?.message;

/**
 * Return the `MarkupDelta` message markup from a message content instance. If the message version
 * does not store markup, generate markup from the text and mentions list
 */
export const getContentMessageMarkup = (content: Optional<SanitisedChatContent>) =>
    switchContentVersion<Optional<MarkupDelta>>(
        content,
        c1 =>
            contentToMarkupDelta(
                getContentMessageText(c1)!,
                getContent_ContentMentions(c1) ?? [],
            ),
        c2 => parseMarkupDelta(c2.messageMarkup),
        () => undefined,
    );

/**
 * Return a list of mentions from a message content instance
 */
export const getContent_Mentions = (content: Optional<SanitisedChatContent>) =>
    switchContentVersion<Optional<Mention[]>>(
        content,
        c => c.mentions,
        c => getContentMessageMarkup(c)?._instance.ops
            .filter(isMentionOp)
            .map(op => op.insert.mention),
        () => undefined,
    );

/**
 * Return a list of content mentions from a message content instance
 * @deprecated
 */
export const getContent_ContentMentions = (
    content: Optional<SanitisedChatContent>,
): Optional<ContentMention[]> =>
    switchContentVersion(
        content,
        c => c.mentions,
        // This case is useful for keeping your drafts when the rich text composer feature flag is turned off
        c => annotateMarkupDeltaOps(getContentMessageMarkup(c))
            ?.windows(2)
            .filter((v): v is [[MentionOp, number], [any, number]] => isMentionOp(v[0][0]))
            .map(([[op, startOffset], [_, endOffset]]) => ({
                ...op.insert.mention,
                startOffset,
                endOffset,
            })),
        () => undefined,
    );

/**
 * Convert a message content instance to the version 1 format. If it is already
 * in that format return it unmodified
 * @deprecated
 */
export const convertContentToV1 = (
    content: SanitisedChatContent,
): SanitisedChatContent_V1 => {
    const contentVer = content.ver ?? 1;

    if (contentVer == 1) {
        return content as SanitisedChatContent_V1;
    }

    return {
        ver: 1,
        id: content.id,
        message: getContentMessageText(content),
        mentions: getContent_ContentMentions(content),
    };
};

/**
 * Convert a message content instance to the version 2 format. If it is already
 * in that format return it unmodified
 */
export const convertContentToV2 = (
    content: SanitisedChatContent,
): SanitisedChatContent_V2 => {
    const contentVer = content.ver ?? 1;

    if (contentVer == 2) {
        return content as SanitisedChatContent_V2;
    }

    return {
        ver: 2,
        id: content.id,
        messageMarkup: compactMarkupDelta(getContentMessageMarkup(content)),
        message: getContentMessageText(content),
    };
};

/**
 * Takes a DraftChatContent and uses it to fill out a SanitisedChatContent that can
 * be used to send a chat message to the backend
 */
export const sanitiseDraftContent = (
    content: DraftChatContent,
) => switchDraftContentVersion<SanitisedChatContent>(
    content,
    c1 => {
        const { draftId, mentions, draftMessage, trimmedMessage } = c1;
        const leadingWhitespace = draftMessage.length - draftMessage.trimStart().length;
        const movedMentions = mentions?.map(v => ({
            ...v,
            startOffset: v.startOffset - leadingWhitespace,
            endOffset: v.endOffset - leadingWhitespace,
        }));

        return {
            ver: 1,
            id: draftId,
            // `||` is correct as trimmedMessage may be an empty string
            message: trimmedMessage || undefined,
            mentions: movedMentions,
        };
    },
    c2 => {
        const { draftId, draftMarkup } = c2;
        const trimmedMarkup = trimCompactMarkup(draftMarkup);
        return {
            ver: 2,
            id: draftId,
            messageMarkup: trimmedMarkup,
            message: renderDeltaToTextForDisplay(trimmedMarkup) || undefined,
        };
    },
    () => ({
        ver: 2,
        id: genLocalMessageId(),
    }),
);

/**
 * Produce a different result depending on the chat content type. If the
 * type is not recognised, use a default result
 */
export const switchChatContentVersion = <T>(
    content: Optional<SanitisedChatContent>,
    ver1Map: (c1: SanitisedChatContent_V1) => T,
    ver2Map: (c2: SanitisedChatContent_V2) => T,
    defaultThunk: () => T,
): T => {
    if (content) {
        switch (content.ver) {
            case undefined:
            case 1:
                return ver1Map(content as SanitisedChatContent_V1);
            case 2:
                return ver2Map(content as SanitisedChatContent_V2);
        }
    }
    return defaultThunk();
};
