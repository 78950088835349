import * as d from "@/domain/domain";
import { updateStagedSequenceNumberToLocalMax } from "@/features/channels";
import { selectActiveStatus } from "@/features/connection";
import useOnScreen from "@/hooks/useOnScreen";
import { Optional } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import { useEffect } from "react";

export const useLastMessageReader = (
    lastMessage: Optional<HTMLElement>,
    channelId?: d.ChannelId,
) => {
    const isVisible = useOnScreen(lastMessage, {
        threshold: 0.5,
        freezeOnceVisible: true,
    });
    const isActive = useAppSelector(selectActiveStatus);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isVisible && isActive && channelId) {
            dispatch(updateStagedSequenceNumberToLocalMax(channelId));
        }
    }, [isVisible, isActive, dispatch, channelId, lastMessage]);
};
