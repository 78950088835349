import { RefObject, useCallback, useEffect, useLayoutEffect, useState } from "react";

import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import log from "@/misc/log";

const gridPadding = 12;
const gridTileGap = 8;

export function getGridWidth<T extends HTMLElement>(grid: T) {
    return grid.clientWidth - 2 * gridPadding;
}

export function getGridHeight<T extends HTMLElement>(grid: T) {
    return grid.clientHeight - 2 * gridPadding;
}

export function getRowHeight(gridHeight: number, rowCount: number) {
    return (gridHeight - ((rowCount - 1) * gridTileGap)) / rowCount;
}

const handleNaN = (n: number) => isNaN(n) ? 0 : n;
// Map infinity -> 0 and treat infinite-aspect ratio tiles as if they take up no area.
const handleInfinity = (n: number) => isFinite(n) ? n : 0;
const handleAspectRatio = (n: number) => handleInfinity(handleNaN(n));

export interface Dimensions {
    width: number;
    height: number;
}

export interface UseGridLayoutProps<T extends HTMLElement> {
    gridRef: RefObject<T>;
    tileAspectRatios: number[];
}

/** @function Computes the required tile dimensions for a call grid.
 *
 * https://slab.avos.info/posts/call-grid-row-count-heuristic-7gg3lnh8
 *
 * @param props an object containing the following:
 *  - `gridRef` a ref object that will be attached to the flex-box surrounding the tiles.
 *  - `tileAspectRatios` an array of tile aspect ratios.
 * Note that this is *not* a set; the order matters.
 *
 * @returns `tileDimensions` the required dimensions of each tile, in the same order as the provided aspect ratios.
 */
function useGridLayout<T extends HTMLElement>(props: UseGridLayoutProps<T>): Dimensions[] {
    const { gridRef, tileAspectRatios: rawRatios } = props;

    const tileAspectRatios = useShallowEqualsMemo(() => rawRatios.map(handleAspectRatio), [
        rawRatios,
    ]);

    const calculateGridDimensions = useCallback(() => {
        if (gridRef.current) {
            const width = getGridWidth(gridRef.current);
            const height = getGridHeight(gridRef.current);
            if (!(width > 0 && height > 0)) return;

            const aspectRatio = width / height;
            return { width, height, aspectRatio };
        }
    }, [gridRef]);

    const [gridDimensions, setGridDimensions] = useState<
        { width: number; height: number; aspectRatio: number; }
    >({ width: 0, height: 0, aspectRatio: 0 });

    const [rowCount, setRowCount] = useState<number>(0);
    const [rowHeight, setRowHeight] = useState<number>(0);

    // Use area-derived expression to compute the row-count lower bound.
    const calculateRowCount = useCallback(() => {
        if (gridDimensions.aspectRatio <= 0) return;

        const ratioSum = tileAspectRatios.reduce((prev, curr) => prev + curr, 0);

        const computedRowCount = (tileAspectRatios.length <= 1) ? 1
            : Math.ceil(Math.sqrt(ratioSum / gridDimensions.aspectRatio));

        // Since we scale wide tiles to fit grid width, the number of rows is
        // no larger than the number of tiles.
        return Math.min(computedRowCount, tileAspectRatios.length);
    }, [tileAspectRatios, gridDimensions.aspectRatio]);

    // Compute the row-height given a row count.
    // This is an upper bound, since we also scale-down rows that are too wide.
    const calculateRowHeight = useCallback((newRowCount: number) => {
        if (gridDimensions.height <= 0) return;

        const newRowHeight = getRowHeight(gridDimensions.height, newRowCount);
        if (!(0 < newRowHeight && newRowHeight < Infinity)) return;

        return newRowHeight;
    }, [gridDimensions.height]);

    // Compute the width and height for a tile,
    // given the grid's row-height and tile's aspect ratio.
    const calculateTileDimensions = useCallback(
        (maxRowHeight: number, aspectRatio: number): Dimensions => {
            let height = maxRowHeight;
            let width = height * aspectRatio;

            if (width > gridDimensions.width) {
                height = height * (gridDimensions.width / width);
                width = gridDimensions.width;
            }

            return { width, height };
        },
        [gridDimensions.width],
    );

    // Imitate's the flex-box logic to calculate whether a given row-count will cause
    // the flex-box to overflow the grid vertically.
    const validateRowCount = useCallback((newRowCount: number) => {
        // Since we shrink tiles on horizontal-overflow, the number of tiles
        // will be an upper bound on the row-count. Therefore any row-count
        // larger than this will definitely be valid.
        if (newRowCount >= tileAspectRatios.length) return true;

        const newRowHeight = calculateRowHeight(newRowCount);
        if (!newRowHeight) return true;

        const gridWidth = gridDimensions.width;
        const gridHeight = gridDimensions.height;
        if (gridWidth <= 0 || gridHeight <= 0) return true;

        // Iterate through tiles, adding them to the current row where possible
        // and otherwise wrapping them to a new row.
        // This simulates the flex box arranging the tiles.
        //
        // There is a (horizontal) gap between tiles in the same row and a
        // (vertical) gap between rows. This gap only appears *after* rows/tiles,
        // so that the last row in the grid or last tile in a row will NOT have
        // the gap.
        //
        // To handle this here, add the gap *after* each row and tile.
        // We then remove the last row gap after computation.
        // The condition for wrapping each tile onto a new row does NOT add the
        // gap, since the last tile would not have the gap after it. But we DO
        // add the gap to the accumulated row width when adding a tile to a row,
        // since any additional tiles on the row will need to include this gap
        // in their own decision to wrap or not.
        const addTileToGrid = (
            { height: accGridHeight, width: accRowWidth }: Dimensions,
            currentRatio: number,
        ): Dimensions => {
            const tileDimensions = calculateTileDimensions(newRowHeight, currentRatio);
            const shouldWrap = accRowWidth + tileDimensions.width > gridWidth;
            if (shouldWrap) {
                return {
                    height: accGridHeight + tileDimensions.height + gridTileGap,
                    width: tileDimensions.width + gridTileGap,
                };
            }
            else {
                return {
                    height: accGridHeight,
                    width: accRowWidth + tileDimensions.width + gridTileGap,
                };
            }
        };
        const requiredDimensions = tileAspectRatios.reduce<Dimensions>(
            addTileToGrid,
            // start with full width so that first tile triggers new row
            { height: 0, width: gridWidth },
        );

        // remove the optional tile gap after the last row
        requiredDimensions.height = requiredDimensions.height - gridTileGap;

        if (requiredDimensions.height <= gridHeight) {
            return true;
        }
        return false;
    }, [
        calculateRowHeight,
        gridDimensions.width,
        gridDimensions.height,
        tileAspectRatios,
        calculateTileDimensions,
    ]);

    useEffect(() => {
        const setGridSize = () => {
            const newDim = calculateGridDimensions();
            if (newDim) setGridDimensions(newDim);
        };
        setGridSize();
        window.addEventListener("resize", setGridSize);
        return () => window.removeEventListener("resize", setGridSize);
    }, [calculateGridDimensions]);

    // Watch for changes in the grid or tile aspect ratios and update the row-count lower bound.
    useLayoutEffect(() => {
        const newRowCount = calculateRowCount();
        if (!newRowCount) return;

        setRowCount(newRowCount);
        log.debug("Updated row count to", newRowCount);
    }, [calculateRowCount]);

    // Watch for changes in the grid height or row count and update the row-height upper bound.
    useLayoutEffect(() => {
        const newHeight = calculateRowHeight(rowCount);
        if (!newHeight) return;
        setRowHeight(newHeight);
    }, [calculateRowHeight, rowCount]);

    // Validate the above calculations and incremement the row-count if necessary.
    //
    // This would be dangerous, because it both depends on *and* changes rowCount,
    // but for any (finite) set of tile aspect ratios, we will eventually reach
    // the upper bound (= no. of tiles), so incrementing the row-count will
    // eventually break the feedback loop.
    useLayoutEffect(() => {
        const isValid = validateRowCount(rowCount);
        if (!isValid) {
            log.debug(`Row count ${rowCount}, is invalid. Incrementing row count to`, rowCount + 1);

            // Use the value of rowCount that has triggered the current render cycle
            // to avoid races where we increment the row count twice in one render.
            // I.e. *don't* setRowCount(prev => prev + 1).
            setRowCount(rowCount + 1);
        }
    }, [rowCount, validateRowCount]);

    const tileDimensions = useShallowEqualsMemo(
        () => tileAspectRatios.map(ratio => calculateTileDimensions(rowHeight, ratio)),
        [tileAspectRatios, rowHeight, calculateTileDimensions],
    );

    return tileDimensions;
}

export default useGridLayout;
