// Copyright 2015 The gRPC Authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// The canonical version of this proto can be found at
// https://github.com/grpc/grpc-proto/blob/master/grpc/health/v1/health.proto

// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=js+ts"
// @generated from file grpc/health/v1/health.proto (package grpc.health.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { HealthCheckRequest, HealthCheckResponse } from "./health_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Health is gRPC's mechanism for checking whether a server is able to handle
 * RPCs. Its semantics are documented in
 * https://github.com/grpc/grpc/blob/master/doc/health-checking.md.
 *
 * @generated from service grpc.health.v1.Health
 */
export const Health = {
  typeName: "grpc.health.v1.Health",
  methods: {
    /**
     * Check gets the health of the specified service. If the requested service
     * is unknown, the call will fail with status NOT_FOUND. If the caller does
     * not specify a service name, the server should respond with its overall
     * health status.
     *
     * Clients should set a deadline when calling Check, and can declare the
     * server unhealthy if they do not receive a timely response.
     *
     * Check implementations should be idempotent and side effect free.
     *
     * @generated from rpc grpc.health.v1.Health.Check
     */
    check: {
      name: "Check",
      I: HealthCheckRequest,
      O: HealthCheckResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Performs a watch for the serving status of the requested service.
     * The server will immediately send back a message indicating the current
     * serving status.  It will then subsequently send a new message whenever
     * the service's serving status changes.
     *
     * If the requested service is unknown when the call is received, the
     * server will send a message setting the serving status to
     * SERVICE_UNKNOWN but will *not* terminate the call.  If at some
     * future point, the serving status of the service becomes known, the
     * server will send a new message with the service's serving status.
     *
     * If the call terminates with status UNIMPLEMENTED, then clients
     * should assume this method is not supported and should not retry the
     * call.  If the call terminates with any other status (including OK),
     * clients should retry the call with appropriate exponential backoff.
     *
     * @generated from rpc grpc.health.v1.Health.Watch
     */
    watch: {
      name: "Watch",
      I: HealthCheckRequest,
      O: HealthCheckResponse,
      kind: MethodKind.ServerStreaming,
    },
  }
};

