import { useCallback } from "react";

import { OfficialAttachment } from "@/domain/attachments";
import { credentialsValidWithin } from "@/domain/blobs";
import * as d from "@/domain/domain";
import { getAttachmentBlobUrlThunk } from "@/features/chats";
import log from "@/misc/log";
import { useAppDispatch } from "@/store/redux";

type UseDownloadAttachmentProps = {
    attachment: OfficialAttachment;
    backendMsgId?: d.MessageId;
    anchorElementCreator?: () => HTMLAnchorElement;
};

export default function useDownloadAttachment(props: UseDownloadAttachmentProps) {
    const dispatch = useAppDispatch();

    const {
        attachment: {
            credentials,
            id: attachmentId,
            metadata: { fileName, mimeType },
        },
        backendMsgId,
        anchorElementCreator,
    } = props;

    const { url } = credentials;

    const getWorkingLink = useCallback(async () => {
        if (!backendMsgId) return;

        if (credentialsValidWithin(credentials, 10)) return url;

        try {
            const { expiringSasUrl: { url: newUrl } } = await dispatch(getAttachmentBlobUrlThunk({
                blobId: attachmentId,
                messageId: backendMsgId,
            })).unwrap();

            return newUrl;
        }
        catch (e) {
            log.warn(`Failed to fetch new SAS URL for attachment ${attachmentId}`, e);
        }
    }, [attachmentId, dispatch, backendMsgId, credentials, url]);

    return useCallback(async () => {
        const newUrl = await getWorkingLink();
        if (!newUrl) return;

        const a = anchorElementCreator?.() ?? document.createElement("a");
        a.href = newUrl;
        a.download = fileName;
        a.type = mimeType;
        a.click();
        a.remove();
    }, [anchorElementCreator, fileName, getWorkingLink, mimeType]);
}
