import { DependencyList } from "react";

import * as d from "@/domain/domain";
import { SelectorPair } from "@/features/selectors";
import { useInterestedBonds } from "@/hooks/interest/useInterest";
import useSelectorArgs from "@/hooks/useSelectorArgs";

/** @function
 * Use a presence icon selector pair function with `useSelector`.
 * This will implicitly register interest in the bonds you are considering for presence.
 *
 * Example usage:
 * `const presenceIconString = usePresenceIcon(selectSquadDominantPresenceIconPair, id);`
 *
 * This is very similar internally to `useSelectorArgs`.
 *
 * @param pair the selector pair,
 * @param deps the further arguments to the selector pair
 * @returns {string} the result of calling `iconSelector: (state: RootState, ...deps) => string`
 */
export default function usePresenceIcon<Deps extends DependencyList = DependencyList>(
    [intermediateSelector, iconSelector]: SelectorPair<Deps, d.BondId[], string>,
    ...deps: Deps
): string {
    const allIds = useSelectorArgs(intermediateSelector, ...deps);
    useInterestedBonds(allIds);

    return useSelectorArgs(iconSelector, ...deps);
}
