enum PersistenceLocation {
    LocalStorage = "local",
    SessionStorage = "session",
    IndexedDB = "idb",
}

type BrowserStorageLocation = PersistenceLocation.LocalStorage | PersistenceLocation.SessionStorage;

interface Store<_K extends string = string, _V = any> {
    storeType: PersistenceLocation;
    name: string;
    fromVersion: number;
    untilVersion?: number;
}

export type IdbStore<K extends string = string, V = any> = Store<K, V> & {
    storeType: PersistenceLocation.IndexedDB;
};

export type BrowserStore<V = any> = Store<string, V> & {
    storeType: BrowserStorageLocation;
    raw: boolean;
};

export type AnyStore<K extends string = string, V = any> = IdbStore<K, V> | BrowserStore<V>;

export const isIdbStore = <U extends AnyStore<K, V>, K extends string, V>(
    store?: U,
): store is IdbStore<K, V> & U => store?.storeType === "idb";

export const storeIsValidForVersion = (version: number): <K extends string, V>(
    store: AnyStore<K, V>,
) => boolean =>
<K extends string, V>(store: AnyStore<K, V>) =>
    store.fromVersion <= version &&
    (store.untilVersion === undefined || version <= store.untilVersion);

export const isBrowserStore = <U extends AnyStore<K, V>, K extends string, V>(
    store?: U,
): store is BrowserStore<V> & U =>
    !!store && (store.storeType === "local" || store.storeType === "session");

const browserStorageStore = (storeType: BrowserStorageLocation) =>
<V>(
    name: string,
    fromVersion: number,
    untilVersion?: number,
    options?: { raw?: true; },
): BrowserStore<V> => ({
    storeType,
    name,
    fromVersion,
    untilVersion,
    raw: options?.raw ?? false,
});

/** Type helper method for describing a localStorage store.
 *
 * We want to attach type information to operations around the storage itself.
 * Do that here.
 *
 * Keeping this explicit (rather than inferring from types on slices' states)
 * helps to maintain sanity around migrations.
 */
export const localStorageStore = browserStorageStore(PersistenceLocation.LocalStorage);

/** Type helper method for describing a sessionStorage store.
 *
 * We want to attach type information to operations around the storage itself.
 * Do that here.
 *
 * Keeping this explicit (rather than inferring from types on slices' states)
 * helps to maintain sanity around migrations.
 */
export const sessionStorageStore = browserStorageStore(PersistenceLocation.SessionStorage);

/** Type helper method for describing IndexedDB objectstores.
 *
 * We want to attach type information to operations around the objectstores.
 * Do that here.
 *
 * Keeping this explicit (rather than inferring from types on slices' states)
 * helps to maintain sanity around migrations.
 */
export const idbStore = <K extends string, V>(
    name: string,
    fromVersion: number,
    untilVersion?: number,
): IdbStore<K, V> => ({
    storeType: PersistenceLocation.IndexedDB,
    name,
    fromVersion,
    untilVersion,
});
