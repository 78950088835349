import { AudienceMember } from "@/domain/audience";
import { SquadId } from "@/domain/domain";

export interface NewBondLocationState {
    newBond: {
        id: string;
        navigatedFromSquad?: SquadId;
        prefillAudienceMembers?: AudienceMember[];
    };
}

const isNewBondLocationState = (s: LocationState): s is NewBondLocationState =>
    s !== null && typeof s == "object" && "newBond" in s;

export const newBondLocationStateSelector: LocationStateSelector<NewBondLocationState> = {
    guard: isNewBondLocationState,
    defaultState: { newBond: { id: "0" } },
};

export type LocationState = NewBondLocationState;

export interface LocationStateSelector<T extends LocationState> {
    guard: (s: LocationState) => s is T;
    defaultState: T;
}
