import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import BondChatParticipants, {
    BondChatParticipantsLocation,
} from "@/components/BondChatParticipants";
import Draggable, { StopClicksOnBackground } from "@/components/Draggable";
import CallControls, { CallControlsLocation } from "@/components/gui/CallControls";
import { useSidebar } from "@/context/SidebarContext";
import { extractUUID } from "@/domain/domain";
import { canOpenLiveView, orderParticipants } from "@/domain/rtc";
import { selectCurrentUserId } from "@/features/auth";
import { selectCurrentLiveBondId } from "@/features/bonds";
import useRtcSessionContext from "@/hooks/rtc/useRtcSessionContext";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";

const topMargin = 30; // y-distance from top of div to top of window
const rightMargin = 60; // x-distance from right of of div to right of window

// variables avatarSizeDefault and callControlDialogueWidth copied from _variables.scss
const avatarSizeDefault = 48;
const callControlDialogueWidth = (avatarSizeDefault * 3.5) + (16 * 3);
import { useAppSelector } from "@/store/redux";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface MultitaskingMediaControlsProps {
}

export function MultitaskingMediaControls(
    _props: MultitaskingMediaControlsProps,
): React.JSX.Element {
    const navigate = useNavigate();
    const { isOpen } = useSidebar();
    const sideBarWidth = 275;
    const topOffset = 13;
    const rightOffset = 13;

    const { leaveSession, isMultitasking, callParticipants } = useRtcSessionContext();
    const { orderedParticipants } = useShallowEqualsMemo(
        () => orderParticipants(callParticipants),
        [callParticipants],
    );

    // Saves multitasking position in between toggles
    const [position, setPosition] = useState({
        x: window.innerWidth - callControlDialogueWidth - rightMargin,
        y: topMargin,
    });

    const liveBondId = useAppSelector(selectCurrentLiveBondId);

    const returnToLiveBond = useCallback(() => {
        if (!liveBondId) return;
        navigate(`/bond/${extractUUID(liveBondId)}`);
    }, [navigate, liveBondId]);

    const currentUserId = useAppSelector(selectCurrentUserId);
    const canOpenLiveViewMemo = useMemo(
        () => canOpenLiveView(orderedParticipants, currentUserId),
        [orderedParticipants, currentUserId],
    );

    // Memoise components that depend on values pulled out of context above.
    const partipantsComponent = useMemo(() =>
        liveBondId && (
            <BondChatParticipants
                bondId={liveBondId}
                location={BondChatParticipantsLocation.MultitaskingControls}
                canOpenLiveView={canOpenLiveViewMemo}
                hideExpandButton={true}
            />
        ), [liveBondId, canOpenLiveViewMemo]);

    const leaveButton = useMemo(() => (
        <button
            className="c-btn-close-draggable"
            title="Close"
            onClick={leaveSession}
        >
            Close
        </button>
    ), [leaveSession]);

    // When sidebar opens, coodinate system of pages shifts right by sidebar width.
    const componentBorderOffset = useMemo(() => {
        return {
            top: topOffset,
            bottom: 0,
            left: isOpen ? -sideBarWidth : 0,
            right: isOpen ? sideBarWidth + rightOffset : rightOffset,
        };
    }, [isOpen]);

    useEffect(() => {
        setPosition(prev => {
            return {
                ...prev,
                x: prev.x + (isOpen ? -sideBarWidth : sideBarWidth),
            };
        });
    }, [isOpen]);

    // StopClicksOnBackground:
    // * Clicking on the participants should navigate back to the bond;
    // * clicking on the call controls should _not_.
    return useMemo(() => (
        <>
            {isMultitasking && liveBondId && (
                <Draggable
                    className="c-call-control-dialog"
                    startingPosition={position}
                    onMove={setPosition}
                    componentBorderOffset={componentBorderOffset}
                    onClick={returnToLiveBond}
                >
                    <StopClicksOnBackground>
                        {leaveButton}
                    </StopClicksOnBackground>
                    <div className="c-call-control-dialog__content">
                        {partipantsComponent}
                        <StopClicksOnBackground>
                            <CallControls
                                location={CallControlsLocation.Multitasking}
                            />
                        </StopClicksOnBackground>
                    </div>
                </Draggable>
            )}
        </>
    ), [
        isMultitasking,
        liveBondId,
        leaveButton,
        partipantsComponent,
        position,
        returnToLiveBond,
        componentBorderOffset,
    ]);
}
