import { useCallback } from "react";

// See: https://stackoverflow.com/questions/25864259/how-to-close-the-new-html-dialog-tag-by-clicking-on-its-backdrop
export default function useDialogOutsideClick(
    dialogRef: React.RefObject<HTMLDialogElement>,
    close: () => void,
): (ev: React.MouseEvent<HTMLDialogElement, MouseEvent>) => void {
    return useCallback((ev: React.MouseEvent<HTMLDialogElement, MouseEvent>) => {
        if (!dialogRef.current) return;
        const rect = dialogRef.current.getBoundingClientRect();
        const isOutside = ev.clientX < rect.left ||
            ev.clientX > rect.right ||
            ev.clientY < rect.top ||
            ev.clientY > rect.bottom;
        if (isOutside) {
            close();
        }
    }, [dialogRef, close]);
}
