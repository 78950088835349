import { useCallback, useEffect, useState } from "react";

import {
    beginAuthenticateWithAuthProvider,
    isZitadelAuthBackend,
    relativeUrlForRedirectResponse,
} from "@/auth/login";
import { getOidcConfig } from "@/misc/oidcConfig";
import { useNavigate } from "react-router-dom";
import { AuthStatus, selectAuthStatus } from "../features/auth";
import usePrevious from "../hooks/usePrevious";
import { getNativeTarget, isNativePlatform, nativePlatformTargetKey } from "../misc/capacitor";
import { nativeTargets } from "../misc/environment";
import log from "../misc/log";
import { useAppSelector } from "../store/redux";

// NativeTargetSwitcher renders only if the frontend is running natively using
// Capacitor, i.e. using our mobile client.
// It allows users to switch between bo-nd.dev and bondtest.uk.
function NativeTargetSwitcher(): React.JSX.Element {
    const initialTarget = getNativeTarget();
    const [nativeTarget, setNativeTarget] = useState(initialTarget);
    const previousNativeTarget = usePrevious(nativeTarget);

    const toggleNativeTarget = useCallback(() => {
        setNativeTarget(currentTarget =>
            currentTarget === nativeTargets.bondtestUk ? nativeTargets.bondDev
                : nativeTargets.bondtestUk
        );
    }, []);

    useEffect(() => {
        if (previousNativeTarget && previousNativeTarget !== nativeTarget) {
            localStorage.setItem(nativePlatformTargetKey, nativeTarget!);

            // Refresh the page to cause the auth worker
            // to be recreated with the new OIDC authority.
            log.info("Reloading page due to the native target changing");
            window.location.reload();
        }
    }, [nativeTarget, previousNativeTarget]);

    return (
        <>
            <div className="c-signin__element">
                <label className="c-signin__label">Current target</label>
                <div className="c-signin__value">{nativeTarget}</div>
            </div>
            <button
                className="cp-btn cp-btn-signin"
                title="Switch target"
                onClick={toggleNativeTarget}
            >
                Switch target
            </button>
        </>
    );
}

export default function LoginView(): React.JSX.Element {
    const navigate = useNavigate();

    const signIn = useCallback(async () => {
        try {
            const redirectUri = await beginAuthenticateWithAuthProvider(await getOidcConfig());

            if (await isZitadelAuthBackend()) {
                // Temporary hack for Zitadel
                // TODO: remove this once we move to Avos auth backend
                log.info(`Login flow: reloading Zitadel UI at ${redirectUri.href}`);
                window.location.href = redirectUri.href;
                return;
            }

            const response = await fetch(redirectUri, {
                headers: {
                    "X-Avos-302-To-200": "true",
                },
            });

            const [relativeUrl, _] = relativeUrlForRedirectResponse(response);

            log.info(`Login flow: navigating to ${relativeUrl}`);
            navigate(relativeUrl);
        }
        catch (e) {
            log.error(`Sign-in failed`, e);
        }
    }, [navigate]);

    const authStatus = useAppSelector(selectAuthStatus);
    useEffect(() => {
        if (authStatus === AuthStatus.Authenticated) {
            log.info("User is authenticated, redirecting to bond");
            navigate("/bond");
        }
        else if (authStatus === AuthStatus.Unauthenticated) {
            signIn();
        }
    }, [authStatus, navigate, signIn]);

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                signIn();
            }
        };
        document.addEventListener("keypress", handleKeyPress);

        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        };
    }, [signIn]);

    return (
        <div className="c-signin-wrapper">
            <fieldset className="c-signin">
                {isNativePlatform && <NativeTargetSwitcher />}
                <button
                    className="cp-btn cp-btn-signin"
                    title="Sign in"
                    onClick={signIn}
                >
                    Sign in
                </button>
            </fieldset>
        </div>
    );
}
