import { useCallback, useState } from "react";

import { CloseButton } from "@/components/buttons/Close";
import { FeatureFlagged } from "@/components/FeatureFlags";
import useRtcSessionContext from "@/hooks/rtc/useRtcSessionContext";
import useDialogOpenRef from "@/hooks/useDialogOpenRef";
import useDialogOutsideClick from "@/hooks/useDialogOutsideClick";
import { useNavigateBack } from "@/hooks/useNavigateBack";
import log from "@/misc/log";

function CallLocationModal(): JSX.Element {
    const { navigateBack } = useNavigateBack({ defaultPath: "..", replaceDefault: true });
    const { updateLocationConfig } = useRtcSessionContext();

    const closeModal = useCallback(() => {
        log.debug("Closing modal: call location");
        navigateBack();
    }, [navigateBack]);

    const dialogRef = useDialogOpenRef();
    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    const [locationEntry, setLocationEntry] = useState("");
    const [headsetMode, setHeadsetMode] = useState(false);

    const onConfirm = useCallback(() => {
        updateLocationConfig?.(locationEntry, headsetMode);
        closeModal();
    }, [updateLocationConfig, closeModal, locationEntry, headsetMode]);

    return (
        <dialog
            className="c-dialog c-dialog--short"
            onClose={closeModal}
            onClick={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            <header className="c-dialog__header c-dialog__header--centered">
                <h1 className="c-dialog__title">What is your live location?</h1>
                <CloseButton side="right" onClick={closeModal} />
            </header>

            <article className="c-dialog__content-wrapper">
                <div className="c-dialog__content">
                    <div className="c-form-element c-form-element--margin">
                        <input
                            type="text"
                            placeholder="Enter location"
                            value={locationEntry}
                            className="c-input-new c-input-new--full"
                            onChange={e => setLocationEntry(e.target.value.toLocaleLowerCase())}
                            onKeyDown={e => {
                                if (e.key === "Enter") {
                                    onConfirm();
                                }
                            }}
                        />
                    </div>
                    <div className="c-form-element c-form-element--margin c-form-element--toggle">
                        <label className="c-label">
                            <strong>Headset mode</strong>
                            Keep audio from live location?
                        </label>
                        <label className="c-toggle">
                            <input
                                type="checkbox"
                                className="c-toggle__input"
                                checked={headsetMode}
                                onChange={() => setHeadsetMode(prev => !prev)}
                            />
                            <span className="c-toggle__switch"></span>
                        </label>
                    </div>
                </div>
            </article>
            <div className="c-dialog__footer c-dialog__footer--new">
                <button
                    className="c-btn-solid c-btn-solid--update"
                    onClick={onConfirm}
                >
                    Update location details
                </button>
            </div>
        </dialog>
    );
}

export default function FlaggedCallLocationModal(): JSX.Element {
    return (
        <FeatureFlagged flag="call-location-controls" match={true}>
            <CallLocationModal />
        </FeatureFlagged>
    );
}
