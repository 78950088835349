import {
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    redirect,
    Route,
} from "react-router-dom";

import FlaggedCallLocationModal from "@/components/CallLocationModal.tsx";
import { ErrorElement } from "@/components/gui/ErrorElement";
import InviteUsersModal from "@/components/InviteUsersModal";
import { LocationLogger } from "@/components/LocationLogger";
import AttachmentLightbox from "@/components/messages/AttachmentLightbox";
import ModifyBondModal from "@/components/ModifyBondModal.tsx";
import EmailView from "@/login/EmailView";
import LoginView from "@/login/LoginView";
import LogoutView from "@/login/LogoutView";
import PasscodeView from "@/login/PasscodeView";
import UserDetailsView from "@/login/UserDetailsView";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { setRouter } from "@/misc/router";
import { AvatarView } from "@/views/AvatarView.tsx";
import BondView from "@/views/BondView";
import DiscoverView from "@/views/DiscoverView";
import InviteRedeptionView from "@/views/InviteRedemptionView";
import { MobileSquadSelectorView } from "@/views/MobileViews";
import { MyBondsView } from "@/views/MyBondsView";
import { NewBondView } from "@/views/NewBondView";
import { AuthenticatedRootView } from "@/views/RootView";
import SettingsView from "@/views/SettingsView";
import { SquadView } from "@/views/SquadView";

const indexRedirect = async () => {
    log.info(`Redirecting index -> /bond`);

    // Keep query params so we have the OIDC state for login
    return redirect(`/bond${window.location.search}`);
};

const mobileDiscoverRedirect = async () => {
    if (isMobileBrowser()) {
        return redirect("/mobile/tab/discover");
    }
    return null;
};

const mobileBondsRedirect = async () => {
    if (isMobileBrowser()) {
        const from = window.location.href;
        const to = `/mobile/tab/mybonds${window.location.search}`;

        log.info(`Redirecting ${from} -> ${to}`);
        return redirect(to);
    }
    return null;
};

const nonMobileBondsRedirect = async () => {
    if (!isMobileBrowser()) {
        log.info(`Redirecting /mobile/tab -> /bond`);
        return redirect("/bond");
    }
    return null;
};

const errorHandler = {
    errorElement: <ErrorElement />,
};

const routeTree = (
    <Route
        path="/"
        element={
            <>
                <LocationLogger />
                <Outlet />
            </>
        }
        {...errorHandler}
    >
        {/* /login,/logout routes */}
        <Route
            path="login"
            element={<LoginView />}
            {...errorHandler}
        />
        {(
            // Only enabled in local dev until we migrate idp
            // TODO: nest in login route when we migrate idp
            <>
                <Route
                    path="login/email"
                    element={<EmailView />}
                    {...errorHandler}
                />
                <Route
                    path="login/details"
                    element={<UserDetailsView />}
                    {...errorHandler}
                />
                <Route
                    path="login/passcode"
                    element={<PasscodeView />}
                    {...errorHandler}
                />
            </>
        )}
        <Route
            path="logout"
            element={<LogoutView />}
            {...errorHandler}
        />

        {
            /* Index. We always redirect to /bond since that is our authenticated area
            today. */
        }
        <Route index={true} loader={indexRedirect} {...errorHandler} />

        {/* /bond routes */}
        <Route
            path="bond"
            element={<AuthenticatedRootView />}
            {...errorHandler}
        >
            <Route path=":bondId" element={<BondView />} {...errorHandler}>
                <Route path="message/:messageId" element={<Outlet />} {...errorHandler}>
                    <Route
                        path="attachment/:attachmentVersion/:attachmentIdx"
                        element={<AttachmentLightbox />}
                        {...errorHandler}
                    />
                </Route>
                <Route path="invite" element={<InviteUsersModal />} {...errorHandler} />
                <Route path="modify" element={<ModifyBondModal />} {...errorHandler} />
                <Route path="location" element={<FlaggedCallLocationModal />} {...errorHandler} />
            </Route>
            <Route
                path="settings"
                element={
                    <>
                        <SettingsView />
                        <MyBondsView />
                    </>
                }
                {...errorHandler}
            />
            <Route
                path="avatar"
                element={
                    <>
                        <AvatarView />
                        <MyBondsView />
                    </>
                }
                {...errorHandler}
            />
            <Route path="new" element={<NewBondView />} {...errorHandler} />

            <Route
                index={true}
                loader={mobileBondsRedirect}
                element={<MyBondsView />}
                {...errorHandler}
            />
        </Route>

        {/* Invite Redemption */}

        <Route
            path="invite"
            element={<AuthenticatedRootView />}
            {...errorHandler}
        >
            <Route path=":inviteCode" element={<InviteRedeptionView />} {...errorHandler} />
        </Route>

        {/* Desktop "Discover" */}
        <Route
            path="discover"
            loader={mobileDiscoverRedirect}
            element={<AuthenticatedRootView />}
            {...errorHandler}
        >
            <Route index={true} element={<DiscoverView />} {...errorHandler} />
        </Route>

        {/* Filtering bonds by a squad */}
        <Route path="squad" element={<AuthenticatedRootView />} {...errorHandler}>
            <Route path=":squadId" element={<SquadView />} {...errorHandler} />
        </Route>

        {/* Mobile only tabbed view */}
        <Route
            path="mobile"
            loader={nonMobileBondsRedirect}
            element={<AuthenticatedRootView />}
            {...errorHandler}
        >
            <Route path="tab">
                <Route path="mybonds" element={<MyBondsView />} {...errorHandler} />
                <Route path="mysquads" element={<MobileSquadSelectorView />} {...errorHandler} />
                <Route path="discover" element={<DiscoverView />} {...errorHandler} />
            </Route>
        </Route>
    </Route>
);

export function setupRoutes() {
    setRouter(createBrowserRouter(createRoutesFromElements(routeTree)));
}
