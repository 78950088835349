import classNames from "classnames";
import { CSSProperties, ForwardedRef, forwardRef, useImperativeHandle, useRef } from "react";

import SensitiveText from "@/components/gui/SensitiveText";
import TextFields, { TextField } from "@/components/gui/TextFields";
import { ContentMention } from "@/domain/mentions";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import { Scrollable } from "@/misc/types";
import { partialComponent } from "@/misc/utils";

interface HighlightMentionProps {
    text: string;
    isInPrefix: boolean;
}

const HighlightMentionView = (props: HighlightMentionProps): React.JSX.Element => {
    const { text, isInPrefix } = props;
    const classes = classNames("cp-composer-mention-highlight", {
        "cp-composer-mention-highlight--prefix": isInPrefix,
    });
    return (
        <span className={classes}>
            <SensitiveText>{text}</SensitiveText>
        </span>
    );
};

interface MessageHighlighterProps {
    text: string;
    mentions: ContentMention[];
    prefixLength: number;
    style?: CSSProperties;
}

/**
 * @deprecated
 */
export const MessageHighlighter = forwardRef((
    props: MessageHighlighterProps,
    ref: ForwardedRef<Scrollable>,
): React.JSX.Element => {
    const { text, mentions, prefixLength, style } = props;

    const divRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
        scrollTo: (x: number, y: number) => divRef.current?.scrollTo(x, y),
    }), []);

    const fields: TextField[] = useShallowEqualsMemo(
        () =>
            mentions.map(m => ({
                range: { start: m.startOffset, end: m.endOffset },
                Component: partialComponent(HighlightMentionView, {
                    mention: m,
                    isInPrefix: m.endOffset <= prefixLength,
                }),
            })),
        [mentions, prefixLength],
    );

    return (
        <div
            ref={divRef}
            aria-hidden="true"
            className="c-composer__highlighter"
            style={style}
        >
            <TextFields text={`${text}\xa0`} fields={fields} />
        </div>
    );
});

export default MessageHighlighter;
