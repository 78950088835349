import { Action, createListenerMiddleware, TaskAbortError } from "@reduxjs/toolkit";

import type { AnySelector } from "@/features/selectors";
import log from "@/misc/log";
import type { AppDispatch, RootState } from "@/store/types";

/** Create a single instance of the middleware.
 * Listeners add themselves by calling `startAppListening()` with their callback.
 */
export const appListenerMiddleware = createListenerMiddleware();
export type AppListenerMiddleware = typeof appListenerMiddleware;

export const startAppListening = (
    middleware: typeof appListenerMiddleware = appListenerMiddleware,
) => middleware.startListening.withTypes<RootState, AppDispatch>();

export const appAppendListener = startAppListening();

export type AppAppendListener = ReturnType<typeof startAppListening>;

type AppListenerArg = Parameters<AppAppendListener>[0];
type ListenerPredicate = AppListenerArg["predicate"];
export type ListenerAPI = Parameters<AppListenerArg["effect"]>[1];

export const statesDifferBySelectors =
    (...selectors: AnySelector<[]>[]): ListenerPredicate => (_, a, b) => {
        for (const s of selectors) {
            if (s(a) != s(b)) return true;
        }
        return false;
    };

export const firstOrStatesDifferBySelectors = (...selectors: AnySelector<[]>[]) => {
    let first = true;

    return (action: Action, currentState: any, previousState: any) => {
        if (first) {
            first = false;
            return true;
        }

        return statesDifferBySelectors(...selectors)(action, currentState, previousState);
    };
};

export const startLongLivedListener = (
    predicate: ListenerPredicate,
    f: (api: ListenerAPI, state: RootState) => void | Promise<void>,
    name: string,
    appendListener: AppAppendListener = appAppendListener,
    actionStartPredicate?: (action: Action) => boolean,
) => {
    // As we unsubscribe from the listenerApi in our effect, we can't lean on regular
    // listener cancellation alone. So we use an AbortController to forward cleanup
    // to the part of the listenerApi we can use; calling .cancel() will cleanup as
    // expected.
    const abortController = new AbortController();
    let alreadyRunning = false;

    const cleanupListener = appendListener(
        {
            predicate: (action, _currentState, _previousState) => {
                if (alreadyRunning) return true;

                if (!actionStartPredicate || actionStartPredicate(action)) {
                    alreadyRunning = true;
                    return true;
                }

                return false;
            },
            effect: async (_action, api) => {
                // Don't spawn any further instances of this listener.
                // This one will keep running.
                api.unsubscribe();
                abortController.signal.addEventListener("abort", api.cancel, { once: true });

                let previousState: RootState | undefined;

                try {
                    while (!api.signal.aborted) {
                        // If `f` calls any dispatches, we don't want to miss them.
                        // Hence we have to *synchronously* call `getState` and
                        // compare projected values here.
                        const currentState = api.getState();

                        if (
                            !previousState || predicate({} as Action, previousState, currentState)
                        ) {
                            await f(api, currentState);
                            previousState = currentState;
                            continue;
                        }

                        await api.condition(predicate);
                    }
                }
                catch (e) {
                    if (!(e instanceof TaskAbortError)) {
                        log.warn(`Unexpected middleware exception (${name})`, e);
                    }
                }

                // If we are aborted for some reason, start up again.
                log.info(`Listener ${name} restarting`);
                api.subscribe();

                abortController.signal.removeEventListener("abort", api.cancel);
            },
        },
    );

    return () => {
        abortController.abort("cleanup");
        cleanupListener();
    };
};
