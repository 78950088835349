import { DateTime, Duration } from "luxon";
import { useCallback, useEffect, useRef, useState } from "react";

import {
    selectIdleSubscribers,
    selectWakeLockHeld,
    setIdleSince,
    setNotIdle,
} from "@/features/meta";
import useLocalDispatch from "@/hooks/useLocalDispatch";
import useTimerTarget from "@/hooks/useTimerTarget";
import log from "@/misc/log";
import { useAppSelector } from "@/store/redux";

export type PresenceType = {
    type: "idle" | "active";
};

const defaultElement: Document | HTMLElement = document;
const defaultEvents = [
    "mousemove",
    "keydown",
    "wheel",
    "DOMMouseScroll",
    "mousewheel",
    "mousedown",
    "touchstart",
    "touchmove",
    "MSPointerDown",
    "MSPointerMove",
    "visibilitychange",
    "focus",
];

// Reimplementation of react-idle-timer
export function useIdleTimer(
    { timeout, onPresenceChange, neverTimeout }: {
        timeout: number;
        onPresenceChange: (p: PresenceType) => void;
        neverTimeout?: boolean;
    },
) {
    const { nowMs, setTarget } = useTimerTarget();
    const goesIdle = useRef<number | undefined>();
    const [isIdle, setIsIdle] = useState<boolean | undefined>(undefined);

    // Whenever we're mounted with a different timeout, recalculate when we will
    // go idle.
    useEffect(() => {
        const next = Date.now() + timeout;
        goesIdle.current = next;
        setTarget(next);
    }, [timeout, setTarget]);

    // This is potentially called back a lot due to a lot of DOM events firing. So we
    // want to be careful to do very little work. Setting react state is considered to
    // be in that category.
    const onActivity = useCallback(() => {
        const next = Date.now() + timeout;

        goesIdle.current = next;
        setIsIdle(false);
    }, [timeout]);

    // Tell the invoker about presence changes; this effect will fire on isIdle changes
    useEffect(() => {
        if (isIdle === undefined) return;
        onPresenceChange({ type: isIdle ? "idle" : "active" });
    }, [isIdle, onPresenceChange]);

    // Subscribe to any events that will mark as as active on mount
    useEffect(() => {
        defaultEvents.forEach(event => {
            defaultElement.addEventListener(event, onActivity, {
                passive: true,
                capture: true,
            });
        });

        return () => {
            defaultEvents.forEach(event => {
                // removeEventListener API is a bit odd; it's not symmetrical with addEventListener
                // as the "passive" flag does not exist.
                defaultElement.removeEventListener(event, onActivity, { capture: true });
            });
        };
    }, [onActivity]);

    // On timeout, or the idle target changing
    useEffect(() => {
        if (goesIdle.current === undefined || neverTimeout) return;

        if (nowMs >= goesIdle.current) {
            setIsIdle(true);
        }
        else {
            setTarget(goesIdle.current);
        }
    }, [neverTimeout, nowMs, setTarget, isIdle]);

    return isIdle;
}

export default function IdleTracker(): React.JSX.Element {
    const localDispatch = useLocalDispatch();
    const idleTimeouts = useAppSelector(selectIdleSubscribers);
    const timeout = Math.min(
        ...Object.values(idleTimeouts).map(x => Duration.fromDurationLike(x!).valueOf()),
        15 * 60 * 1000,
    );

    // don't timeout activity when we have the wake lock
    const wakeLockHeld = useAppSelector(selectWakeLockHeld);

    const onPresenceChange = useCallback((p: PresenceType) => {
        if (p.type === "idle") {
            const idleSince = DateTime.now().minus(timeout);
            log.info("User idle since", idleSince.toISO());
            localDispatch(setIdleSince(idleSince.toMillis()));
        }
        else {
            log.info("User no longer idle");
            localDispatch(setNotIdle());
        }
    }, [localDispatch, timeout]);

    useIdleTimer({ timeout, onPresenceChange, neverTimeout: wakeLockHeld });

    return <></>;
}
