import classNames from "classnames";
import { useCallback, useMemo } from "react";

import Avatar from "@/components/gui/Avatar";
import ParticipantTile, {
    ParticipantTileProps,
    ParticipantType,
} from "@/components/gui/ParticipantTile";
import ScrollDetector from "@/components/gui/ScrollDetector";
import * as d from "@/domain/domain";
import {
    type DisplayMediaParticipant,
    getAudio,
    getParticipantUserId,
    getTileId,
    getVideo,
    hasActiveVideo,
    isDisplay,
    isTrackActive,
    type OrderedParticipant,
    orderParticipants,
    type Participant,
} from "@/domain/rtc";
import { selectCurrentUserId } from "@/features/auth";
import {
    LiveActivityArgs,
    selectChannelIdByBondId,
    selectLiveCallIdByBondId,
    selectSortedBondParticipantIdsPair,
} from "@/features/bonds";
import {
    CallActionStatus,
    closeLiveView,
    JoinedCallView,
    openLiveView,
    selectCallJoinStatus,
    selectGridTilesSet,
    selectJoinedCallView,
} from "@/features/calls";
import { selectUserIdSetWithMentions, selectUserIdSetWithUnread } from "@/features/channels";
import { useInterestedUsers } from "@/hooks/interest/useInterest";
import useRtcSessionContext from "@/hooks/rtc/useRtcSessionContext";
import useFreshBondObservers from "@/hooks/useFreshBondObservers";
import useLocalDispatch from "@/hooks/useLocalDispatch";
import useScrollDetector from "@/hooks/useScrollDetector";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import useSortedUsers from "@/hooks/useSortedUsers";
import { removeDuplicates } from "@/misc/primatives";
import { Optional } from "@/misc/types";
import { separateDiscriminatedUnion } from "@/misc/utils";
import { useAppSelector } from "@/store/redux";

// A fake OrderedParticipant.
// Used to treat non-live Bond Observers alongside OrderedParticipants.
type OrderedObserver = { userId: d.UserId; };
// Ordered Observer or Ordered Participant
type BondChatParticipant = OrderedObserver | OrderedParticipant;

const toOrderedObserver = (
    userId: d.UserId,
): OrderedObserver => ({ userId });

const isOrderedParticipant = (
    participant: BondChatParticipant,
): participant is OrderedParticipant => ("id" in participant);

const getTileIdWrapped = (participant: BondChatParticipant) => (
    isOrderedParticipant(participant) ? getTileId(participant) : `${participant.userId}-observer`
);

export enum BondChatParticipantsLocation {
    Default,
    MultitaskingControls,
}

export interface BondChatParticipantsProps {
    bondId: d.BondId;
    location?: BondChatParticipantsLocation;
    canOpenLiveView?: boolean;
    hideExpandButton?: boolean;
}

function BondChatParticipants(props: BondChatParticipantsProps): React.JSX.Element {
    const { bondId, canOpenLiveView, hideExpandButton } = props;
    const location = props.location ?? BondChatParticipantsLocation.Default;

    const { callParticipants, isMultitasking } = useRtcSessionContext();

    const liveCallId = useSelectorArgs(selectLiveCallIdByBondId, bondId);
    const bondIsLive = !!liveCallId;

    const isInMultitaskingControls = location === BondChatParticipantsLocation.MultitaskingControls;
    // logical XOR:
    const livenessAppliesHere = isMultitasking === isInMultitaskingControls;

    const { orderedParticipants: liveParticipants } = useShallowEqualsMemo(
        () => orderParticipants(livenessAppliesHere ? callParticipants : []),
        [callParticipants, livenessAppliesHere],
    );

    const localDispatch = useLocalDispatch();
    const { isScrolled: isScrolledAvatars, handleScroll: handleAvatarScroll } = useScrollDetector();
    const { isScrolled: isScrolledDisplays, handleScroll: handleDisplayScroll } =
        useScrollDetector();

    const currentUserId = useAppSelector(selectCurrentUserId);
    const callJoinStatus = useAppSelector(selectCallJoinStatus);

    const { ids: bondObserverIds, idSet: bondObserverSet } = useFreshBondObservers(bondId);
    const liveParticipantIds = useShallowEqualsMemo(
        () => new Set(liveParticipants?.map(p => p.id)),
        [liveParticipants],
    );
    const joinedCallView = useAppSelector(selectJoinedCallView);
    const isGrid = joinedCallView === JoinedCallView.Live;
    const showGrid = bondIsLive && !isInMultitaskingControls && isGrid;

    const openLiveViewWithFocus = useCallback((id: Optional<string>) => () => {
        localDispatch(openLiveView(id));
    }, [localDispatch]);
    const tileIsClickable = useCallback(
        (op: OrderedParticipant) => hasActiveVideo(op) && (showGrid || canOpenLiveView),
        [showGrid, canOpenLiveView],
    );

    const closeGrid = useCallback(() => localDispatch(closeLiveView()), [localDispatch]);

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const userSetWithUnread = useSelectorArgs(selectUserIdSetWithUnread, channelId);
    const userSetWithMentions = useSelectorArgs(selectUserIdSetWithMentions, channelId);

    const gridTileSet = useAppSelector(selectGridTilesSet);
    const removeGridParticipants = useCallback(
        (bcp: BondChatParticipant) =>
            !(isOrderedParticipant(bcp) && gridTileSet.has(getTileIdWrapped(bcp))),
        [gridTileSet],
    );

    const [displayParticipants, liveUserParticipants] = useShallowEqualsMemo(
        () =>
            separateDiscriminatedUnion<DisplayMediaParticipant, Participant>(
                isDisplay,
                liveParticipants,
            ),
        [liveParticipants],
    );
    const displayParticipantsFiltered = useShallowEqualsMemo(
        () => showGrid ? displayParticipants.filter(removeGridParticipants) : displayParticipants,
        [displayParticipants, removeGridParticipants, showGrid],
    );

    // It is important that this includes all live users that we are aware of,
    // i.e. apply the removeGridParticipants filter at the end, NOT here.
    // This is so that we de-duplicate user tiles correctly.
    const liveParticipantUserIds = useShallowEqualsMemo(
        () => liveUserParticipants.map(getParticipantUserId),
        [liveUserParticipants],
    );

    // Store a map from user ID to participants so we can render their tracks after sorting them.
    // A user can join a call from multiple devices, so maintain an array for each user.
    const liveUserParticipantsMap = useShallowEqualsMemo(
        () =>
            liveUserParticipants.reduce((acc, part) => {
                const curr = acc.get(part.userId);
                acc.set(part.userId, curr ? curr.concat(part) : [part]);
                return acc;
            }, new Map<d.UserId, OrderedParticipant[]>()),
        [liveUserParticipants],
    );

    // Filter live participants with video so we can order them first
    const videoLiveUserIdSet = useShallowEqualsMemo(() => {
        return liveUserParticipants.filter(hasActiveVideo)
            .map(getParticipantUserId)
            .toSet();
    }, [liveUserParticipants]);
    const liveUserIdSet = useShallowEqualsMemo(
        () => liveParticipantUserIds.toSet(),
        [liveParticipantUserIds],
    );
    const liveUserArgs = useMemo((): LiveActivityArgs => ({
        videoUserSet: videoLiveUserIdSet,
        liveUserSet: liveUserIdSet,
        orderCurrentUserFirst: true,
    }), [liveUserIdSet, videoLiveUserIdSet]);
    const liveParticipantUserIdsSorted = useSortedUsers(
        selectSortedBondParticipantIdsPair,
        bondId,
        liveParticipantUserIds,
        liveUserArgs,
    );

    const participantUserIds = useShallowEqualsMemo(() => {
        return removeDuplicates(
            liveParticipantUserIdsSorted,
            bondObserverIds,
        );
    }, [
        liveParticipantUserIdsSorted,
        bondObserverIds,
    ]);
    useInterestedUsers(participantUserIds);

    const userParticipants = useShallowEqualsMemo(
        () =>
            participantUserIds.flatMap((userId): BondChatParticipant[] =>
                liveUserParticipantsMap.get(userId) ?? [toOrderedObserver(userId)]
            ),
        [liveUserParticipantsMap, participantUserIds],
    );
    const userParticipantsFiltered = useShallowEqualsMemo(
        () => showGrid ? userParticipants.filter(removeGridParticipants) : userParticipants,
        [showGrid, userParticipants, removeGridParticipants],
    );

    const getTileParams = useCallback(
        (op: BondChatParticipant): ParticipantTileProps => {
            const isLiveParticipant = isOrderedParticipant(op);
            return {
                participantUserId: op.userId,
                isSelf: currentUserId === op.userId,
                hideName: true,
                videoTitle: true,
                participantType: ParticipantType.User, // conditionally overwritten below

                ...isLiveParticipant && {
                    audioTrack: getAudio(op),
                    videoTrack: getVideo(op),

                    ...isDisplay(op) && { participantType: ParticipantType.Display },
                    ...tileIsClickable(op) && { onClick: openLiveViewWithFocus(getTileId(op)) },
                },
            };
        },
        [currentUserId, openLiveViewWithFocus, tileIsClickable],
    );

    const presenceClasses = useCallback((scrolled: boolean) =>
        classNames("c-presence", {
            "c-presence--live": bondIsLive,
            "c-presence--scrolled": scrolled && !bondIsLive,
            "c-presence--live-scrolled": bondIsLive && scrolled,
        }), [bondIsLive]);

    const renderAvatar = useCallback((tile: BondChatParticipant) => {
        const tileParams = getTileParams(tile);
        const userId = tile.userId;

        const isObserver = bondObserverSet.has(userId);
        const videoOn = isTrackActive(tileParams.videoTrack);
        const audioOn = isTrackActive(tileParams.audioTrack);
        const isDisplay = tileParams.participantType === ParticipantType.Display;
        const screenSharing = isDisplay && videoOn;

        // Determine liveness based on whether participants are in the RTC session.
        // Others are in the RTC session iff they are in liveParticipants.
        //
        // selfParticipant is always in liveParticipants, so use the inCallStatus
        // for the current user. Also check whether audio or video are on, in the
        // case that the user is attempting to start call by publishing.
        const isLiveParticipant = (userId === currentUserId) ?
            (callJoinStatus === CallActionStatus.Completed || audioOn || videoOn)
            : (isOrderedParticipant(tile) && liveParticipantIds.has(tile.id));

        return (
            <Avatar
                key={getTileIdWrapped(tile)}
                userId={userId}
                showPresence={true}
                context={{
                    isScreenShare: screenSharing,
                }}
                modifiers={{
                    observingBond: isObserver || isLiveParticipant,
                    callParticipation: isLiveParticipant ? tileParams : undefined,
                    contributed: isLiveParticipant || userSetWithUnread.has(userId),
                    mentionedCurrentUser: userSetWithMentions.has(userId),
                }}
            />
        );
    }, [
        bondObserverSet,
        currentUserId,
        getTileParams,
        callJoinStatus,
        liveParticipantIds,
        userSetWithMentions,
        userSetWithUnread,
    ]);

    const renderDisplay = (p: DisplayMediaParticipant) => (
        <div className="c-presence__share" key={`live-message-${p.id}`}>
            <ParticipantTile
                key={p.id}
                isSelf={false}
                participantUserId={p.userId}
                videoTrack={p.displayMediaTrack}
                participantType={ParticipantType.Display}
                onClick={openLiveViewWithFocus(getTileId(p))}
                hideName={true}
                videoTitle={true}
            />
        </div>
    );

    const showDisplaysInBar = isInMultitaskingControls || showGrid;

    return (
        <>
            <div className={presenceClasses(isScrolledAvatars)}>
                {canOpenLiveView && !hideExpandButton && (
                    <button
                        onClick={showGrid ? closeGrid : openLiveViewWithFocus(undefined)}
                        className="c-btn-call-grid c-btn-call-grid--presence"
                        title={`${showGrid ? "Close" : "Expand"} call grid`}
                    >
                        {showGrid ? "Close" : "Expand"}
                    </button>
                )}
                <ScrollDetector
                    onScroll={handleAvatarScroll}
                    className="c-presence__wrapper"
                >
                    <div className="c-presence__humans">
                        {showDisplaysInBar && displayParticipantsFiltered.map(renderAvatar)}
                        {userParticipantsFiltered.map(renderAvatar)}
                    </div>
                </ScrollDetector>
            </div>
            {!showDisplaysInBar && displayParticipantsFiltered.length > 0 && (
                <div className={presenceClasses(isScrolledDisplays)}>
                    <ScrollDetector
                        onScroll={handleDisplayScroll}
                        className="c-presence__wrapper"
                    >
                        <div className="c-presence__shares">
                            {displayParticipantsFiltered.map(renderDisplay)}
                        </div>
                    </ScrollDetector>
                </div>
            )}
        </>
    );
}

export default BondChatParticipants;
