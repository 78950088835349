import { nanoid } from "@reduxjs/toolkit";
import {
    FC,
    KeyboardEventHandler,
    MouseEventHandler,
    useCallback,
    useMemo,
    useRef,
    useState,
} from "react";

import { SuggestionDomain, SuggestionsList } from "@/components/SuggestionsList";
import { Optional } from "@/misc/types";
import { setOnChange } from "@/misc/utils";

interface Item<TId extends string> {
    id: TId;
}

type PillSelectorProps<
    TId extends string,
    T extends Item<TId>,
    D extends any[],
> = {
    label?: string;
    placeholder?: string;

    PillContent: FC<{ data: T; }>;
    getPillTitle?: (data: T) => Optional<string>;

    selected: T[];
    suggestionDomain: SuggestionDomain<D>;

    selectAction?: (id: TId) => void;
    deselectAction?: (id: TId) => void;

    tabIndex?: number;
    autoFocus?: boolean;
};

export const PillSelector = <
    TId extends string,
    T extends Item<TId>,
    D extends any[],
>({
    label,
    placeholder,
    PillContent,
    getPillTitle,
    selected,
    suggestionDomain,
    deselectAction,
    tabIndex,
    autoFocus,
}: PillSelectorProps<TId, T, D>): React.JSX.Element => {
    const renderedPlaceholder = (selected.length == 0) ? placeholder : undefined;

    const inputRef = useRef<HTMLInputElement>(null);
    const inputId = useMemo(() => `c-pill-select__input__${nanoid(16)}`, []);

    const onClickInputArea: MouseEventHandler = useCallback(e => {
        if (e.currentTarget === e.target) {
            inputRef.current?.focus();
        }
    }, []);

    const pills = useMemo(
        () =>
            selected.map(t => (
                <button
                    key={t.id}
                    className="c-pill-select__pill"
                    title={getPillTitle?.(t)}
                    onClick={() => deselectAction?.(t.id)}
                >
                    <PillContent data={t} />
                </button>
            )),
        [selected, PillContent, getPillTitle, deselectAction],
    );

    const [query, setQuery] = useState("");

    // Deselect the last pill when backspace is pressed in the input
    const onInputKeydown: KeyboardEventHandler = useCallback(e => {
        switch (e.key) {
            case "Backspace": {
                if (inputRef.current?.selectionStart != 0) return;
                if (inputRef.current?.selectionEnd != 0) return;

                e.preventDefault();
                if (selected.length > 0) {
                    deselectAction?.(selected.at(-1)!.id);
                }
                break;
            }
        }
    }, [selected, deselectAction]);

    const [arrowKeysActive, setArrowKeysActive] = useState(false);

    const clearQuery = useCallback(() => setQuery(""), []);
    const clickAction = useCallback(() => {
        clearQuery();
        inputRef.current?.focus();
    }, [clearQuery]);

    return (
        <>
            <div
                className="c-pill-select"
                onBlur={() => setArrowKeysActive(false)}
            >
                <div className="c-pill-select__input" onClick={onClickInputArea}>
                    {label && (
                        <label htmlFor={inputId} className="c-pill-select__label">
                            {label}&nbsp;
                        </label>
                    )}
                    {pills}
                    <input
                        ref={inputRef}
                        id={inputId}
                        type="text"
                        placeholder={renderedPlaceholder}
                        onKeyDown={onInputKeydown}
                        onChange={setOnChange(setQuery)}
                        onFocus={() => setArrowKeysActive(true)}
                        tabIndex={tabIndex}
                        autoFocus={autoFocus}
                        value={query}
                    />
                </div>
                <div className="c-pill-select__suggestions">
                    <SuggestionsList
                        domain={suggestionDomain}
                        query={query}
                        arrowKeysActive={arrowKeysActive}
                        allowTab={false}
                        useUnselectedState={true}
                        enterAction={clearQuery}
                        clickAction={clickAction}
                        suggestionClassName="c-pill-select__suggestion"
                    />
                </div>
            </div>
        </>
    );
};

export default PillSelector;
