import { backoffManagerCreator } from "@/ds/backoff";
import log from "./log";
import { delay } from "./promises";

type Config<T> = {
    logPrefix?: string;
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
} & (T extends undefined ? {} : { withResponse: T; });

// `fetch()`, handling retried, then return `response.json()`. On a non-retryable error,
// returns the Response object.
export async function retryingFetch(
    config: Config<"json">,
    ...args: Parameters<typeof fetch>
): Promise<any>;

export async function retryingFetch(
    config: Config<"arraybuffer">,
    ...args: Parameters<typeof fetch>
): Promise<ArrayBuffer>;

export async function retryingFetch(
    config: Config<undefined>,
    ...args: Parameters<typeof fetch>
): ReturnType<typeof fetch>;

export async function retryingFetch(
    config: Config<"json" | "arraybuffer" | undefined>,
    ...args: Parameters<typeof fetch>
): Promise<any> {
    const backoffManager = backoffManagerCreator();
    let haveFailed = false;
    const startTime = Date.now();

    while (true) {
        const finish = backoffManager.begin();

        try {
            if (config.logPrefix /* && haveFailed*/) {
                log.info(`${config.logPrefix}: fetch ${args[0]}`);
            }

            const response = await fetch(...args);

            if (response.ok) {
                if (config.logPrefix && haveFailed) {
                    log.info(
                        `${config.logPrefix}: fetch ${args[0]} succeeded in ${
                            Date.now() - startTime
                        }ms`,
                    );
                }

                // By awaiting on the response here, we capture exceptions and back-off correctly
                if ("withResponse" in config) {
                    if (config.withResponse === "json") {
                        return await response.json();
                    }
                    if (config.withResponse === "arraybuffer") {
                        return await response.arrayBuffer();
                    }
                }
                return response;
            }

            haveFailed = true;

            if (response.status >= 400 && response.status < 500) {
                return response;
            }
        }
        catch (e) {
            log.warn(`fetch error: ${e}`);
            if (e instanceof Error && e.name === "AbortError") {
                throw e;
            }
        }

        finish(false);

        if (!backoffManager.permitted()) {
            if (config.logPrefix) {
                log.info(`${config.logPrefix}: backoff ${backoffManager.getDelay()}ms`);
            }

            await delay(backoffManager.getDelay(), args[1]?.signal || undefined);
        }
    }
}
