const webcrypto = (globalThis.window !== undefined) ? window.crypto : globalThis.crypto;

export function ascii_to_uint8array(ascii: string): Uint8Array {
    return Uint8Array.from(ascii, c => c.charCodeAt(0));
}

export function uint8array_to_ascii(arr: Uint8Array): string {
    return arr.reduce<string>((prev: string, cur: number) => prev + String.fromCharCode(cur), "");
}

export function cryptoRandomBytes(numBytes: number): Uint8Array {
    const arr = new Uint8Array(numBytes);
    return webcrypto.getRandomValues(arr);
}

export function uint8array_hexstring(arr: Uint8Array): string {
    return Array.from(arr).reduce<string>(
        (prev: string, cur: number) => prev + cur.toString(16).padStart(2, "0"),
        "",
    );
}

// Source: https://thewoods.blog/base64url/
export function base64url_encode(buffer: ArrayBuffer): string {
    return btoa(
        uint8array_to_ascii(new Uint8Array(buffer)),
    )
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
}

// Source: https://thewoods.blog/base64url/
export function base64url_decode(value: string): ArrayBuffer {
    const m = value.length % 4;
    return ascii_to_uint8array(
        atob(
            value.replace(/-/g, "+")
                .replace(/_/g, "/")
                .padEnd(value.length + (m === 0 ? 0 : 4 - m), "="),
        ),
    );
}

export async function sha256(arr: Uint8Array): Promise<ArrayBuffer> {
    return webcrypto.subtle.digest("SHA-256", arr);
}

export async function cbckey_from_string(ascii: string): Promise<CryptoKey> {
    return webcrypto.subtle.importKey("raw", ascii_to_uint8array(ascii), "AES-CBC", true, [
        "encrypt",
        "decrypt",
    ]);
}

export async function encrypt_cbc(
    key: CryptoKey,
    iv: ArrayBuffer,
    plaintext: ArrayBuffer,
): Promise<ArrayBuffer> {
    return webcrypto.subtle.encrypt({ name: "AES-CBC", iv }, key, plaintext);
}

export function decrypt_cbc(
    key: CryptoKey,
    iv: ArrayBuffer,
    ciphertext: ArrayBuffer,
): Promise<ArrayBuffer> {
    return webcrypto.subtle.decrypt({ name: "AES-CBC", iv }, key, ciphertext);
}
