import { PostHogProvider } from "posthog-js/react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import PostHogMeta from "./components/PostHogMeta";
import { setupRoutes } from "./components/Routes";
import Session from "./components/Session";
import { capacitorSetup, isNativePlatform } from "./misc/capacitor";
import { isDevEnv } from "./misc/environment";
import log, { configureLogs, LogPersistenceType } from "./misc/log";
import { getPosthogClient } from "./misc/posthog";
import { setupTypeExtensions } from "./misc/primatives";
import { initSentry } from "./misc/sentry";
import StrictModeWrapper from "./misc/strictModeWrapper";
import "./sass/main.scss";
import { startStore } from "./workers/setup";

async function main() {
    setupTypeExtensions();

    const persistenceMode = isNativePlatform ? LogPersistenceType.Native
        : LogPersistenceType.OPFS;

    configureLogs({
        forwardToConsole: isDevEnv,
        forwardToSentry: true,
        persistenceMode,
    });

    if (isNativePlatform) {
        capacitorSetup();
    }

    log.info("Frontend startup", __BEYOND_FRONTEND_VERSION__);
    log.info(`Location changed: ${window.location.href} (initial load)`);

    initSentry();

    const posthogClient = getPosthogClient();

    setupRoutes();

    const store = await startStore();

    const rootElem = document.getElementById("root");
    if (!rootElem) {
        return;
    }

    const root = ReactDOM.createRoot(rootElem);

    root.render(
        <StrictModeWrapper>
            <Provider store={store}>
                <PostHogProvider client={posthogClient}>
                    <PostHogMeta />
                    <Session />
                </PostHogProvider>
            </Provider>
        </StrictModeWrapper>,
    );
}

main();
