export default function debounce<T>(debounceMs: number, parser: (ts: T[]) => void) {
    if (debounceMs <= 0) {
        throw new Error(`debounceMs must be positive, got ${debounceMs}`);
    }

    let vals: T[] = [];
    let timer: ReturnType<typeof setTimeout> | null = null;

    return (t: T) => {
        vals.push(t);
        if (!timer) {
            timer = setTimeout(() => {
                timer = null;
                parser(vals);
                vals = [];
            }, debounceMs);
        }
    };
}
