import { DateTime } from "luxon";
import { describeTimeAgoCatchup } from "../../misc/timeAgoCatchup";

export interface TimeAgoCatchupProps {
    from: number;
    describeLive: boolean;
    liveSessionStartNum?: number;
}

export default function TimeAgoCatchup(props: TimeAgoCatchupProps): React.JSX.Element {
    const { from, describeLive, liveSessionStartNum } = props;

    const now = DateTime.now();
    const described = describeTimeAgoCatchup(now, from, describeLive, liveSessionStartNum);

    return described?.descFrom ? (
        <div className="c-summary__date-range">
            {`${described.descFrom} `}
            <span className="c-summary-arrow">&#x27F6;</span>
            {` ` + described.descTo}
        </div>
    ) :
        (
            <div className="c-summary__date-range">
                {described.descTo}
            </div>
        );
}
