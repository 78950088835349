import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Outlet } from "react-router-dom";

import { FeatureFlagged } from "@/components/FeatureFlags";
import * as d from "@/domain/domain";
import {
    clearSelectedBond,
    getShareableBondInviteLinkThunk,
    selectBondById,
    selectChannelIdByBondId,
    updateSelectedBond,
} from "@/features/bonds";
import { closeLiveView, JoinedCallView, selectJoinedCallView } from "@/features/calls";
import { MetaInterestCounterKey, selectInterestInKey } from "@/features/interest";
import { createToast, updateShowSidebarSummary } from "@/features/meta";
import { setSquadLastReadThunk } from "@/features/squads";
import {
    useInterestedCall,
    useInterestedSquads,
    useInterestedVisibleBond,
} from "@/hooks/interest/useInterest";
import useAddressParams from "@/hooks/useAddressParams.ts";
import useLocalDispatch from "@/hooks/useLocalDispatch";
import { useNavigateBack } from "@/hooks/useNavigateBack.ts";
import usePrevious from "@/hooks/usePrevious";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import { BondChildRouteContext } from "@/misc/bondChildRouteContext";
import { isMobileBrowser } from "@/misc/mobile";
import type { Focusable, Optional } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import { BondSidebarSummaryView } from "@/views/BondSidebarSummaryView.tsx";
import ChatView from "@/views/ChatView";

export default BondView;

function EarlyBondButtons({ bondId }: { bondId: d.BondId; }): React.JSX.Element {
    const dispatch = useAppDispatch();
    const { navigate } = useNavigateBack();

    const showRenameBondModalAction = useCallback(() => {
        navigate("modify");
    }, [navigate]);

    const showUserInvitationAction = useCallback(() => {
        navigate("invite");
    }, [navigate]);

    const copyShareableLinkToClipboard = useCallback(async () => {
        if (!bondId) {
            return;
        }

        // Consider sensible offline behaviour for this button
        const link = await dispatch(
            getShareableBondInviteLinkThunk({ bondId }),
        ).unwrap().catch(
            () => {
                dispatch(createToast({
                    message: "Failed to get link.",
                    duration: { seconds: 3 },
                }));
                throw new Error("Failed to get link.");
            },
        );

        navigator.clipboard.writeText(link).catch(() => {
            throw new Error("Failed to copy invite link to clipboard.");
        });

        dispatch(createToast({
            message: "Link copied to clipboard",
            duration: { seconds: 3 },
        }));
    }, [dispatch, bondId]);

    const controlClasses = classNames("c-bond-controls", {
        "c-bond-controls--desktop": !isMobileBrowser(),
    });

    const btnClasses = classNames("c-btn-lineal", {
        "c-btn-lineal--desktop": !isMobileBrowser(),
    });

    return (
        <div className={controlClasses}>
            <button
                className={btnClasses}
                title="Change name"
                onClick={showRenameBondModalAction}
            >
                <div className="c-btn-lineal__icon c-btn-lineal__icon--name"></div>
                Change name
            </button>
            <button
                className={btnClasses}
                title="Add people"
                onClick={showUserInvitationAction}
            >
                <div className="c-btn-lineal__icon c-btn-lineal__icon--people"></div>
                Add people
            </button>
            <FeatureFlagged flag={"early-bond-invite-buttons"} match={true}>
                <button
                    className={btnClasses}
                    title="Add link"
                    onClick={copyShareableLinkToClipboard}
                >
                    <div className="c-btn-lineal__icon c-btn-lineal__icon--link"></div>
                    Copy link
                </button>
            </FeatureFlagged>
        </div>
    );
}

export function BondView(): React.JSX.Element {
    const { bondId, messageId } = useAddressParams();

    const dispatch = useAppDispatch();
    const localDispatch = useLocalDispatch();
    const { navigate, navigateBack } = useNavigateBack();

    const prevBondId = usePrevious(bondId);
    const bond = useSelectorArgs(selectBondById, bondId);

    const joinedCallView = useAppSelector(selectJoinedCallView);
    const isLiveView = joinedCallView === JoinedCallView.Live;

    const chatRef = useRef<Focusable>(null);
    useEffect(() => {
        if (isMobileBrowser()) {
            return;
        }

        if (bondId != prevBondId) {
            chatRef.current?.focus({ goToEnd: true });
        }
    }, [bondId, prevBondId]);

    useEffect(() => {
        localDispatch(updateSelectedBond(bondId));
        return () => {
            localDispatch(clearSelectedBond());
        };
    }, [localDispatch, bondId]);

    useInterestedVisibleBond(bondId);
    useInterestedCall(bond?.liveCallIds[0]);
    useInterestedSquads(bond?.squadIds);

    const bondSquadIds = useShallowEqualsMemo(() => (
        bond?.squadIds ?? []
    ), [bond?.squadIds]);

    // Marks the squads for this bond as read whenever new messages are shown
    useEffect(() => {
        if (bondSquadIds && bond?.lastActivityAt) {
            dispatch(setSquadLastReadThunk({
                squadIds: bondSquadIds,
                lastRead: bond.lastActivityAt,
            }));
        }
    }, [dispatch, bond?.lastActivityAt, bondSquadIds]);

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));

    // Escape key handler
    const navigateUp = useCallback(() => {
        if (channelId) navigateBack();
    }, [channelId, navigateBack]);

    const minimiseGrid = useCallback(() => localDispatch(closeLiveView()), [localDispatch]);

    // NB: both callbacks are memoised above, so goBack is stable when passed to the hook.
    const goBack = isLiveView ? minimiseGrid : navigateUp;

    const hotkeyBlockingInterest = useSelectorArgs(
        selectInterestInKey,
        MetaInterestCounterKey.BlockHotkey,
    );
    const isEscHotkeyBlocked = useCallback(() => hotkeyBlockingInterest, [hotkeyBlockingInterest]);
    useHotkeys("esc", goBack, {
        ignoreEventWhen: isEscHotkeyBlocked,
        enableOnFormTags: ["textarea"],
    });

    const shouldRedirect = !bondId || !bond;

    useEffect(() => {
        if (shouldRedirect) {
            const t = setTimeout(() => {
                navigate("/bond");
            }, 1 * 1000);

            return () => {
                clearTimeout(t);
            };
        }
    }, [navigate, shouldRedirect]);

    useEffect(() => {
        return () => {
            localDispatch(updateShowSidebarSummary(false));
        };
    }, [localDispatch]);

    // Memoise the outlet context to avoid unnecessary rerenders
    const outletCtx: Optional<BondChildRouteContext> = useMemo(
        () => bondId ? { bondId } : undefined,
        [bondId],
    );

    if (shouldRedirect) {
        return <h1>No bond found. Redirecting...</h1>;
    }

    return (
        <section className="c-content c-content--bond">
            <div className="c-content__bond-suggestion">
                <ChatView
                    ref={chatRef}
                    bondId={bondId}
                    scrollToMessageId={messageId}
                    preChatContent={<EarlyBondButtons bondId={bondId} />}
                />
            </div>
            <BondSidebarSummaryView bondId={bondId} />
            <Outlet context={outletCtx} />
        </section>
    );
}
