import {
    AuthMachine,
    createAuthStateMachine,
    runAuthStateMachine,
    State,
    TokenChangeDetails,
} from "@/auth/stateMachine";
import { OidcConfig } from "@/auth/types";
import * as d from "@/domain/domain";
import { Optional } from "@/misc/types";
import { Action } from "@reduxjs/toolkit";
import log from "../misc/log";
import {
    type AppAppendListener,
    appAppendListener,
    statesDifferBySelectors,
} from "../store/middleware";
import { ListenerAPI, startLongLivedListener } from "../store/middleware";
import type { RootState } from "../store/types";
import {
    AuthStatus,
    clearOidc,
    selectAuthStatus,
    selectCurrentUserId,
    startAuthForUser,
    updateAuthStatus,
    updateOidc,
} from "./auth";

/**
 * Manages the authentication state machine,
 * using the AuthStatus in Redux to drive the state machine.
 * The auth state machine is not started until an auth action is dispatched
 * with the required bootrapping data.
 */

const authStartActions = [
    startAuthForUser,
];

const authStatusComparer = statesDifferBySelectors(
    selectAuthStatus,
    selectCurrentUserId,
);

type ManageAuthParameters = {
    oidcConfig: () => Promise<OidcConfig>;
};

const toAuthStatus = (state: State) => {
    switch (state) {
        case "Initialising":
            return AuthStatus.Initialising;
        case "Refreshing":
            return AuthStatus.Refreshing;
        case "Authenticated":
            return AuthStatus.Authenticated;
        case "Error":
            return AuthStatus.ClearingAuthentication;
    }
};

const getAuthStateMachine = (params: ManageAuthParameters) => {
    const { oidcConfig } = params;

    const transition = (api: ListenerAPI, status: AuthStatus) => {
        api.dispatch(updateAuthStatus(status));
    };

    let machine: Optional<AuthMachine> = undefined;

    const onAbort = () => {
        if (machine) {
            machine.stop(false).catch(_e => {});
        }
    };

    return async (api: ListenerAPI, state: RootState) => {
        const initialState = selectAuthStatus(state);

        // If we ever properly lose auth, we'll eventually end up here; so clean up our state
        if (
            machine &&
            (initialState === AuthStatus.ClearingAuthentication ||
                initialState === AuthStatus.Initialising)
        ) {
            await machine.stop(true);
            machine = undefined;
            api.signal.removeEventListener("abort", onAbort);
            return;
        }

        // Wait until we have a user ID to manage
        if (selectCurrentUserId(state) === undefined) {
            await api.condition((_action, curState, _prevState) =>
                selectCurrentUserId(curState) !== undefined
            );
        }

        const userId = selectCurrentUserId(api.getState());

        // Mostly to keep TS happy. The api.condition above should ensure this is never undefined.
        if (!userId) return;

        const onTokenChange = (details: TokenChangeDetails) => {
            let { token } = details;
            const { subject, validUntil } = details;

            if (subject === null) {
                api.dispatch(clearOidc());
                return;
            }

            if (validUntil !== null && Date.now() + 60 * 1000 >= validUntil) {
                token = null;
            }

            let personId: d.PersonId;
            try {
                personId = d.fromRawPersonId(subject);
            }
            catch (_e) {
                log.error("failed to parse OIDC person ID");
                return;
            }

            // XXX: consider calling setBearerToken explicitly here, and not storing
            // it in Redux at all. We could then remove the management from
            // connectionManagement.ts

            api.dispatch(updateOidc({
                token,
                personId,
            }));
        };
        const onStateChange = (state: State) => {
            log.info(`Auth state change: ${state}`);

            if (state !== "Authenticated") {
                api.dispatch(clearOidc());
            }

            transition(api, toAuthStatus(state));
        };

        if (!machine) {
            log.info(`Starting auth state machine for user ${userId}`);
            machine = createAuthStateMachine({
                oidc: await oidcConfig(),
                onTokenChange,
                onStateChange,
                userId,
            });

            // Run the state machine in the background.
            runAuthStateMachine(machine).catch(_e => {});

            api.signal.addEventListener("abort", onAbort, { once: true });
        }
    };
};

export const startManagingAuth = (
    appendListener: AppAppendListener = appAppendListener,
    params: ManageAuthParameters,
) => {
    const authStateMachine = getAuthStateMachine(params);

    const actionStartPredicate = (action: Action) =>
        authStartActions.some(a => action.type === a.type);

    const cleanup = startLongLivedListener(
        authStatusComparer,
        authStateMachine,
        "auth-manager",
        appendListener,
        actionStartPredicate,
    );

    return cleanup;
};
