// ~polyfill for Promise.withResolvers(), which was only introduced recently and is not supported everywhere.
// See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/withResolvers
export function promiseWithResolvers<T>() {
    let resolver: ((value: T) => void) | undefined = undefined;
    let rejecter: ((value: any) => void) | undefined = undefined;
    const promise = new Promise<T>((resolve, reject) => {
        resolver = resolve;
        rejecter = reject;
    });
    return {
        promise: promise,
        resolve: resolver!,
        reject: rejecter!,
    };
}

export type PromiseWithResolvers<T> = ReturnType<typeof promiseWithResolvers<T>>;

export async function delay(ms: number, signal?: AbortSignal): Promise<void> {
    signal?.throwIfAborted();
    if (ms < 0) return Promise.resolve();

    if (!signal) return new Promise(r => setTimeout(r, ms));

    let cancel: () => void;
    const waitPromise = new Promise<void>(resolve => {
        const timerId = setTimeout(resolve, ms);
        cancel = () => {
            clearTimeout(timerId);
            resolve();
        };
    });

    signal.addEventListener("abort", cancel!);
    await waitPromise;
    signal.removeEventListener("abort", cancel!);
    signal.throwIfAborted();
}
