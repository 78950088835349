import { selectShowSidebar } from "@/features/meta.ts";
import useClientBounds from "@/hooks/useClientBounds.ts";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import classNames from "classnames";
import React, {
    ForwardedRef,
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { createPortal } from "react-dom";

import AttachmentUploadControls from "@/components/AttachmentUploadControls";
import AudienceHint from "@/components/AudienceHint";
import { CloseButton } from "@/components/buttons/Close";
import CallControls, { CallControlsLocation } from "@/components/gui/CallControls";
import ComposerButton from "@/components/gui/ComposerButton";
import { FileStashContext } from "@/components/managers/AttachmentManager.tsx";
import { MentionAutoComplete } from "@/components/MentionAutoComplete";
import QuillRichTextEditor from "@/components/QuillRichTextEditor";
import type { RichTextEditorOps } from "@/components/RichTextEditor";
import { createProposedAttachment } from "@/domain/attachments.ts";
import { fileToBlobMetadata } from "@/domain/blobs.ts";
import { DraftTarget } from "@/domain/channels";
import { changeDeltaBuilder, EmitterSources, isMarkupDeltaEmpty } from "@/domain/delta";
import * as d from "@/domain/domain.ts";
import { genLocalAttachmentId } from "@/domain/domain.ts";
import { messageHysteresisBondTitleSuggestion, minimumTriggerMessageLength } from "@/domain/intel";
import { Mention } from "@/domain/mentions";
import {
    DraftChatMessage,
    MessageSendableStatus,
    messageSendableStatusText,
} from "@/domain/messages";
import { selectCurrentOrgId } from "@/features/auth.ts";
import { clearBondTitleSuggestion } from "@/features/bondCreation";
import {
    addAttachmentsToDraft,
    selectDraft,
    selectDraftMarkup,
    selectDraftMessageSendableStatus,
    selectDraftText,
    updateDraftMarkup,
} from "@/features/channels";
import { fetchBondTitleSuggestionThunk } from "@/features/intel";
import { MetaInterestCounterKey, selectInterestInKey } from "@/features/interest";
import { useMetaInterest } from "@/hooks/interest/useInterest";
import { useConnectedEffect } from "@/hooks/useConnectedEffect";
import useDebounceWithoutTimeout from "@/hooks/useDebounceWithoutTimeout";
import useDeltaSync from "@/hooks/useDeltaSync";
import useOutsideClick from "@/hooks/useOutsideClick";
import usePrevious from "@/hooks/usePrevious";
import useRichTextAutoCompleteQuery from "@/hooks/useRichTextAutoCompleteQuery";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { getAttachmentDimensionsNoThrow } from "@/misc/attachments.ts";
import log from "@/misc/log.ts";
import { isMobileBrowser } from "@/misc/mobile";
import { Focusable, NumberRange, Optional } from "@/misc/types";
import { selectCurrentUserId, useAppDispatch, useAppSelector } from "@/store/redux";
import type { AppDispatch } from "@/store/types.ts";

export const typingTimeoutDelay = 5 * 1000;

export interface MessageComposerProps {
    id: string;
    draftTarget: DraftTarget;

    msgCompletionAction: (draft: DraftChatMessage | undefined) => void;
    mediaEnableAction?: (draft: DraftChatMessage | undefined) => void;

    numberOfParticipants?: number;
    bondComposer?: boolean;
    placeholder?: string;

    showCallLocationAction?: () => void;

    onModalChange?: (current: boolean) => void;
    onEditorFocus?: () => void;
    onEditorBlur?: () => void;

    escapeAction?: () => void;
    discardAction?: () => void;

    tabIndex?: number;
    autoFocus?: boolean;
}

function attachFile(
    file: File,
    fileStash: Map<string, File>,
    draftTarget: DraftTarget,
    dispatch: AppDispatch,
    orgId: d.OrgId,
    userId: d.UserId,
) {
    getAttachmentDimensionsNoThrow(file).then(dimensions => {
        const localId = genLocalAttachmentId();
        fileStash.set(localId, file);
        const initiatedAt = Date.now();
        const attachment = createProposedAttachment({
            localId,
            draftTarget,
            initiatedAt,
            metadata: { ...fileToBlobMetadata(file), dimensions },
            ownership: { uploaderId: userId, orgId: orgId },
        });

        dispatch(addAttachmentsToDraft([attachment]));
    }).catch(e => {
        log.error("Failed to get attachment dimensions", e);
    });
}

const triggers = ["@"];

export const RichTextMessageComposer = forwardRef((
    props: MessageComposerProps,
    ref: ForwardedRef<Focusable>,
): React.JSX.Element => {
    const {
        draftTarget,
        msgCompletionAction: onMessageCompletion,
        mediaEnableAction: onMediaEnable,
        discardAction,
        escapeAction,
        bondComposer,
        placeholder,
        onModalChange,
        onEditorFocus,
        onEditorBlur,
        tabIndex,
        autoFocus,
        showCallLocationAction,
    } = props;
    const numberOfParticipants = props.numberOfParticipants ?? 0;

    const dispatch = useAppDispatch();

    const typingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    const [currentlyTyping, setCurrentlyTyping] = useState<boolean>(false);

    const draftText = useSelectorArgs(selectDraftText, draftTarget);
    const draft = useSelectorArgs(selectDraft, draftTarget);
    const draftMarkup = useSelectorArgs(selectDraftMarkup, draftTarget);

    const [selection, setSelection] = useState<NumberRange>({ start: 0, end: 0 });
    const onSelectionChange = useCallback(
        (range: NumberRange | null) => {
            if (range) {
                setSelection(range);
            }
        },
        [],
    );

    const fileStash = useContext(FileStashContext);

    const userId = useAppSelector(selectCurrentUserId);
    const orgId = useAppSelector(selectCurrentOrgId);

    const editorRef = useRef<RichTextEditorOps>(null);

    const composerRef = useRef<HTMLDivElement>(null);

    const mentionQuery = useRichTextAutoCompleteQuery(
        triggers,
        draftMarkup,
        selection.end,
    );

    const prevMentionOffset = usePrevious(mentionQuery?.range.start);

    // Get the screen position of mention query (if one is active)
    const bounds = useClientBounds();
    const showSidebar = useAppSelector(selectShowSidebar);
    const calcMentionAnchor = useCallback(
        () => editorRef.current?.getOffsetScreenPos(mentionQuery?.range.start),
        [mentionQuery],
    );
    const [mentionAnchor, setMentionAnchor] = useState(calcMentionAnchor());
    useLayoutEffect(
        () => {
            const newMentionPos = calcMentionAnchor();
            if (newMentionPos) {
                newMentionPos.x += bounds.visualViewportOffset?.x ?? 0;
                newMentionPos.y += bounds.visualViewportOffset?.y ?? 0;
            }
            setMentionAnchor(newMentionPos);
        },
        // If the sidebar changes the implementation of `getOffsetScreenPos`
        // may give a different result, so we need to rerun this memo
        [calcMentionAnchor, bounds, showSidebar],
    );

    // Mark the mention autocomplete active if a mention query is present and the autocomplete
    // has not already been shown for this mention query offset
    const [mentionAutoCompleteActive, setMentionAutoCompleteActive] = useState(false);
    useEffect(() => {
        if (prevMentionOffset != mentionQuery?.range.start) {
            setMentionAutoCompleteActive(mentionQuery !== undefined);
        }
    }, [prevMentionOffset, mentionQuery]);

    useEffect(() => {
        // Both the window and the editor must be focused to be considered typing
        if (
            !isMarkupDeltaEmpty(draftMarkup) &&
            document.hasFocus() &&
            editorRef.current?.hasFocus()
        ) {
            setCurrentlyTyping(true);

            // Clear the existing timeout and start a new one
            if (typingTimeout.current !== null) {
                clearTimeout(typingTimeout.current);
            }
            typingTimeout.current = setTimeout(() => {
                setCurrentlyTyping(false);
            }, typingTimeoutDelay);
        }
        else {
            setCurrentlyTyping(false);
        }
    }, [draftMarkup]);

    useMetaInterest(currentlyTyping, MetaInterestCounterKey.Typing);

    const msgCompletionBlocked = useSelectorArgs(
        selectInterestInKey,
        MetaInterestCounterKey.BlockMsgCompletion,
    );
    const msgSendableStatus = useSelectorArgs(
        selectDraftMessageSendableStatus,
        draftTarget,
        false,
    );
    const complete = useCallback(() => {
        // Suppress completion when e.g. the mention autocomplete is shown with suggestions
        if (msgCompletionBlocked) {
            return;
        }

        if (msgSendableStatus == MessageSendableStatus.Ready) {
            onMessageCompletion(draft);
        }
    }, [draft, msgCompletionBlocked, msgSendableStatus, onMessageCompletion]);

    const onToggleMedia = useCallback((isEnabling: boolean) => {
        if (isEnabling) onMediaEnable?.(draft);
    }, [onMediaEnable, draft]);

    const sendButtonText = messageSendableStatusText(msgSendableStatus);

    const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
    const showEmojiPickerRef = useRef<HTMLButtonElement>(null);
    const outsideClickCallback = useCallback((target: EventTarget | null) => {
        // Filter out clicks on the button which shows the emoji picker itself.
        if (showEmojiPickerRef && showEmojiPickerRef.current?.contains(target as Node)) {
            return;
        }
        setShowEmojiPicker(false);
    }, [showEmojiPickerRef, setShowEmojiPicker]);
    const [emojiPickerDivRef] = useOutsideClick<HTMLDivElement>(outsideClickCallback);

    const insertEmoji = useCallback((emoji: { native: string; }) => {
        dispatch(
            updateDraftMarkup({
                draftTarget,
                update: {
                    change: changeDeltaBuilder()
                        .retain(selection.start)
                        .delete(selection.end - selection.start)
                        .insert(emoji.native)
                        .buildPlain(),
                },
                source: EmitterSources.API,
            }),
        );

        setShowEmojiPicker(false);

        editorRef.current?.focus();
    }, [dispatch, draftTarget, selection]);

    const insertMention = useCallback(
        (mention: Mention, text: string, range: NumberRange) =>
            dispatch(
                updateDraftMarkup({
                    draftTarget,
                    update: {
                        change: changeDeltaBuilder()
                            .retain(range.start)
                            .delete(range.end - range.start)
                            .insert({ mention: { ...mention, text } })
                            .insert(" ")
                            .buildPlain(),
                    },
                    source: EmitterSources.API,
                }),
            ),
        [dispatch, draftTarget],
    );

    // Notify the parent when a modal opens or closes
    const modalState = mentionAutoCompleteActive || showEmojiPicker;
    const prevModalState = usePrevious(modalState);
    useLayoutEffect(() => {
        if (modalState != prevModalState) {
            onModalChange?.(modalState);
        }
    }, [onModalChange, modalState, prevModalState]);

    // Sync the markup delta between the redux store and the rich text editor
    const onEditorUserChange = useCallback(() => setShowEmojiPicker(false), []);
    const onEditorChange = useDeltaSync(draftTarget, editorRef, onEditorUserChange);

    // Document-level escape key handler (for when composer is not focussed)
    useEffect(() => {
        if (!showEmojiPicker) return;

        const check = (e: KeyboardEvent) => {
            // Ignore events for all keys bar 'Escape'
            if (e.key != "Escape") return;

            // Always close the emoji viewer if open
            if (!showEmojiPicker) return;

            e.preventDefault();

            setShowEmojiPicker(false);

            editorRef.current?.focus();
        };

        document.addEventListener("keydown", check);
        return () => document.removeEventListener("keydown", check);
    }, [showEmojiPicker]);

    useEffect(() => {
        const currentComposerRef = composerRef.current;
        if (!currentComposerRef) return;

        const onPaste = (e: ClipboardEvent) => {
            e.preventDefault();

            if (!composerRef) return;

            if (!userId || !orgId) {
                log.error(`Failed to create attachment: userId=${userId} orgId=${orgId}`);
                return;
            }

            const items = e.clipboardData?.items;
            if (!items) return;

            [...items].forEach(item => {
                if (item.kind === "file") {
                    const file = item.getAsFile();
                    if (file) {
                        attachFile(file, fileStash, draftTarget, dispatch, orgId, userId);
                    }
                }
            });
        };

        currentComposerRef.addEventListener("paste", onPaste, false);
        return () => {
            currentComposerRef.removeEventListener("paste", onPaste, false);
        };
    }, [dispatch, draftTarget, fileStash, userId, orgId]);

    useEffect(() => {
        const onDrop = (e: DragEvent) => {
            e.preventDefault();

            if (!userId || !orgId) {
                log.error(`Failed to create attachment: userId=${userId} orgId=${orgId}`);
                return;
            }

            if (e.dataTransfer && e.dataTransfer.items) {
                [...e.dataTransfer.items].forEach((item, _) => {
                    if (item.kind === "file") {
                        const file = item.getAsFile();
                        if (file) {
                            attachFile(file, fileStash, draftTarget, dispatch, orgId, userId);
                        }
                    }
                });
            }
        };

        const onDragover = (e: Event) => {
            e.preventDefault(); // apparently we have to do this to enable drop
        };

        document.addEventListener("dragover", onDragover);
        document.addEventListener("drop", onDrop, false);

        return () => {
            document.removeEventListener("drop", onDrop, false);
            document.removeEventListener("dragover", onDragover);
        };
    }, [dispatch, draftTarget, fileStash, userId, orgId]);

    const escapeVariant = useCallback((action: Optional<() => void>) => {
        // Always close the mention autocomplete if open
        if (mentionAutoCompleteActive) {
            setMentionAutoCompleteActive(false);
        }
        // Blur the editor if focussed
        else if (editorRef.current) {
            editorRef.current.blur();
            action?.();
        }
    }, [mentionAutoCompleteActive]);

    const onEditorEscape = useCallback(
        () => escapeVariant(escapeAction),
        [escapeAction, escapeVariant],
    );

    const onEditorShiftEscape = useCallback(
        () => escapeVariant(discardAction),
        [discardAction, escapeVariant],
    );

    const [editorFocused, setEditorFocused] = useState(false);

    const onEditorFocusWrapper = useCallback(() => {
        onEditorFocus?.();
        setEditorFocused(true);
    }, [onEditorFocus]);

    const onEditorBlurWrapper = useCallback(() => {
        onEditorBlur?.();
        setCurrentlyTyping(false);
        setEditorFocused(false);
    }, [onEditorBlur]);

    useImperativeHandle(ref, () => ({
        focus: () => editorRef.current?.focus(),
        blur: () => editorRef.current?.blur(),
        hasFocus: () => editorRef.current?.hasFocus() ?? false,
    }), []);

    // Block hotkeys when the emoji picker is open
    useMetaInterest(showEmojiPicker, MetaInterestCounterKey.BlockHotkey);

    // Focus the editor when the background of the control area is clicked (visually this
    // looks to the user like part of the editor so it makes sense to focus)
    const onClickControlArea = useCallback((e: React.MouseEvent<HTMLElement>) => {
        if (e.currentTarget === e.target) {
            editorRef.current?.focus({ goToEnd: true });
        }
    }, []);

    const debouncedSuggestionQueryString = useDebounceWithoutTimeout(
        draftText,
        undefined,
        messageHysteresisBondTitleSuggestion,
    );

    useConnectedEffect(() => {
        // We don't generate title suggestions for bonds that already created
        if (numberOfParticipants !== 0) return;
        // Whitespace at the start of the message is removed by
        // the selector. We need whitespace at the end as part of our
        // determination of when to fetch new suggestions. But we don't
        // want to include that whitespace in the soliciting text.
        const solicitingText = debouncedSuggestionQueryString.trimEnd();

        if (!solicitingText) {
            dispatch(clearBondTitleSuggestion());
            return;
        }

        if (solicitingText.length < minimumTriggerMessageLength) return;

        dispatch(
            fetchBondTitleSuggestionThunk({ bondContentDraft: solicitingText }),
        );
    }, [
        dispatch,
        debouncedSuggestionQueryString,
        numberOfParticipants,
    ]);

    const showMentionAutoComplete = mentionAutoCompleteActive &&
        (editorFocused || isMobileBrowser()) &&
        !showEmojiPicker &&
        !!mentionAnchor &&
        !!mentionQuery;

    const classes = classNames(
        "c-composer",
        "c-composer--rich",
        {
            "c-composer--bond": bondComposer,
        },
    );

    const showHeader = bondComposer && discardAction;
    const headerClasses = classNames(
        "c-composer__header",
        {
            "c-composer__header--bond": bondComposer,
        },
    );

    return (
        <>
            {showMentionAutoComplete && createPortal(
                <MentionAutoComplete
                    anchor={mentionAnchor}
                    query={mentionQuery}
                    insert={insertMention}
                    draftTarget={draftTarget}
                />,
                document.body,
            )}
            <div className={classes} data-testid={props.id}>
                {showEmojiPicker && (
                    <div ref={emojiPickerDivRef} className="c-emoji-wrapper">
                        <Picker
                            data={data}
                            onEmojiSelect={insertEmoji}
                            emojiSize={19}
                            previewPosition={"none"}
                            theme="dark"
                            autoFocus={true}
                        />
                    </div>
                )}
                {showHeader && (
                    <div className={headerClasses}>
                        <div className="c-composer-header-count">
                            {bondComposer && discardAction && (
                                <CloseButton
                                    title="Discard draft"
                                    onClick={discardAction}
                                    side={"composer"}
                                />
                            )}
                        </div>
                    </div>
                )}
                <div className="c-composer__actions" ref={composerRef}>
                    <AudienceHint draftTarget={draftTarget} />
                    <QuillRichTextEditor
                        ref={editorRef}
                        tabIndex={tabIndex}
                        autoFocus={autoFocus}
                        markupForInit={draftMarkup}
                        placeholder={placeholder}
                        onChange={onEditorChange}
                        onSelect={onSelectionChange}
                        onSubmit={complete}
                        onEscape={onEditorEscape}
                        onShiftEscape={onEditorShiftEscape}
                        onFocus={onEditorFocusWrapper}
                        onBlur={onEditorBlurWrapper}
                    />
                    <div
                        className="c-composer__controls"
                        onClick={onClickControlArea}
                    >
                        <div className="c-composer__btn-group">
                            {!isMobileBrowser() && (
                                <ComposerButton
                                    content="Emojis"
                                    onClick={() => setShowEmojiPicker(old => !old)}
                                    buttonRef={showEmojiPickerRef}
                                    extraStyle="c-btn-composer--emojis"
                                    title="Emoji"
                                />
                            )}
                            <AttachmentUploadControls draftTarget={draftTarget} />
                        </div>
                        <div className="c-composer__btn-group">
                            <CallControls
                                location={CallControlsLocation.LiveBond}
                                onToggle={onToggleMedia}
                            />
                        </div>
                        <div className="c-composer__btn-group">
                            {showCallLocationAction && (
                                <ComposerButton
                                    content="Set location"
                                    onClick={showCallLocationAction}
                                    extraStyle="c-btn-composer--location"
                                    title="Set call location"
                                />
                            )}
                            <ComposerButton
                                content={"Send"}
                                onClick={complete}
                                extraStyle="c-btn-composer--send"
                                disabled={msgSendableStatus != MessageSendableStatus.Ready}
                                title={sendButtonText}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default RichTextMessageComposer;
