import { hashCode, modulo } from "@/misc/utils";

export const getGradientFromName = (name: string) => {
    const nameHash = hashCode(name);
    const hue = modulo(nameHash, 360);
    const saturation = 40;
    const lightness = 50;
    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return color;
};
