import { useCallback, useEffect, useState } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

import { completeAuthRequest, updateUserDetails } from "@/api/auth";
import { finalLoginSteps } from "@/auth/login";
import log from "@/misc/log";
import { z } from "zod";

// The shape of the location state passed from PasscodeView
const LocationStateSchema = z.object({
    codeVerifier: z.string(),
    userId: z.string(),
});

export default function UserDetailsView(): React.JSX.Element {
    const [params, _] = useSearchParams();
    const authReqId = params.get("auth_request_id");

    const location = useLocation();
    const locationState = LocationStateSchema.safeParse(location.state);
    const { codeVerifier, userId } = locationState.success ? locationState.data : {};

    const [isFailed, setIsFailed] = useState(false);
    const [username, setUsername] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [nickname, setNickname] = useState("");

    const updateDetails = useCallback(async () => {
        try {
            if (!authReqId || !codeVerifier || !username || !displayName || !nickname || !userId) {
                setIsFailed(true);
                return;
            }

            const resp = await updateUserDetails(userId, username, displayName, nickname, {
                authRequestId: authReqId,
                codeVerifier,
            });
            if (!resp.isSuccess) {
                log.error("Failed to update user details:", resp.reason);
                setIsFailed(true);
                return;
            }

            const completeAuthResp = await completeAuthRequest(authReqId, codeVerifier);
            if (!completeAuthResp.isSuccess) {
                log.error("Failed to complete auth request:", completeAuthResp.reason);
                setIsFailed(true);
                return;
            }
            const { callbackUri } = completeAuthResp.response;

            const cbResponse = await fetch(callbackUri, {
                headers: {
                    "X-Avos-302-To-200": "true",
                },
            });

            const [_id, _usersAndOrgs] = await finalLoginSteps(cbResponse);

            // See PasscodeView.tsx
            // There is a bit of dupicated code above. Once we no longer support Zitadel,
            // some of that will be removed, and it might make sense to look to see if
            // there is a better approach to code sharing then.
        }
        catch (err) {
            log.error("Failed to update user details", err);
            setIsFailed(true);
        }
    }, [authReqId, codeVerifier, displayName, nickname, userId, username]);

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                updateDetails();
            }
        };
        document.addEventListener("keypress", handleKeyPress);

        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        };
    }, [updateDetails]);

    if (!authReqId || !userId) {
        log.error("No auth request ID or user ID provided");
        return <Navigate to="/login" />;
    }

    // TODO: consolidate form elements - maybe https://react-hook-form.com/?
    return (
        <div className="c-signin-wrapper">
            <fieldset className="c-signin">
                <>
                    <div className="c-signin__element c-signin__element--active">
                        <label htmlFor="username" className="c-signin__label">Username</label>
                        <div className="c-signin__value">
                            <input
                                type="text"
                                id="username"
                                name="signin-input"
                                placeholder="Enter your username"
                                value={username}
                                autoComplete="off"
                                maxLength={100}
                                className="c-signin__input"
                                onChange={e => setUsername(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="c-signin__element c-signin__element--active">
                        <label htmlFor="displayName" className="c-signin__label">
                            Display Name
                        </label>
                        <div className="c-signin__value">
                            <input
                                type="text"
                                id="displayName"
                                name="signin-input"
                                placeholder="Enter your display name"
                                value={displayName}
                                autoComplete="off"
                                maxLength={100}
                                className="c-signin__input"
                                onChange={e => setDisplayName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="c-signin__element c-signin__element--active">
                        <label htmlFor="nickname" className="c-signin__label">Nickname</label>
                        <div className="c-signin__value">
                            <input
                                type="text"
                                id="nickname"
                                name="signin-input"
                                placeholder="Enter your nickname"
                                value={nickname}
                                autoComplete="off"
                                maxLength={100}
                                className="c-signin__input"
                                onChange={e => setNickname(e.target.value)}
                            />
                        </div>
                    </div>
                </>
                <button
                    className="cp-btn cp-btn-signin"
                    title="Continue"
                    onClick={updateDetails}
                >
                    Continue
                </button>
                {isFailed && (
                    <div className="c-signin__element c-signin__element--error">
                        <label className="c-signin__error">Failed to update user details</label>
                    </div>
                )}
            </fieldset>
        </div>
    );
}
