import { SetStateAction, useCallback, useEffect, useRef } from "react";

import {
    Client as RtcClient,
    createClient,
    policy,
    RemoteTrack,
    RtcResolutionInit as RtcResolution,
    RtcTrack_Source,
    setLogger as setRtcLogger,
} from "@avos-io/rtc-client";

import * as d from "@/domain/domain";
import { MediaInputKind, MediaInputKind_Type } from "@/domain/mediaDevices";
import {
    getDisabledTrack,
    Participant,
    toRtcResolution,
    Track,
    TrackResolution,
} from "@/domain/rtc";
import { selectCurrentUserId } from "@/features/auth";
import {
    CallActionStatus,
    getAccessToken,
    selectCallById,
    selectCurrentParticipantId,
    setCallJoinStatus,
    setJoinedCall,
    setLeftCall,
} from "@/features/calls";
import { selectActiveStatus } from "@/features/connection";
import { useTakeSingleRtcSessionLock } from "@/hooks/createSharedWebLock";
import { useRtcClientEventListeners } from "@/hooks/rtc/useRtcClientEventListeners";
import { useRtcClientPublishers } from "@/hooks/rtc/useRtcClientPublishers";
import { useRtcConnection } from "@/hooks/rtc/useRtcConnection";
import useMap from "@/hooks/useMap";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { getEnvironmentConfig } from "@/misc/environment";
import log from "@/misc/log";
import { Optional } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";

setRtcLogger(log.with({ from: "rtc" }).externalLogger());

const joinedRtcClients = new Map<d.RtcSessionId, RtcClient>();

let lastSessionId: Optional<d.RtcSessionId>;
let lastSessionStats: string[] = [];

const targetConfig = getEnvironmentConfig();

const SOCKET_URL = new URL(targetConfig.sigUrl);

const toLocalTrack = (remoteTrack: Optional<RemoteTrack>, client: Optional<RtcClient>): Track => {
    const stream = remoteTrack?.getMediaStream();
    if (!stream || !remoteTrack) {
        return getDisabledTrack();
    }

    // give the track a function it can use to call updateRenderHints on the client
    const rtcTrack = remoteTrack?.getRtcTrack();
    const updateRenderHint = client && rtcTrack && ((hint: TrackResolution) => {
        try {
            client.updateRenderHints(
                new Map<string, RtcResolution>([[
                    rtcTrack.id,
                    toRtcResolution(hint),
                ]]),
            );
        }
        catch (err) {
            log.error("Failed to send render hint", err);
        }
    });

    return {
        enabled: !!stream,
        muted: remoteTrack.isMuted(),
        source: stream,
        rtcTrackId: remoteTrack.getRtcTrack().id,
        updateRenderHint,
    };
};

const toTrackSource = (inputKind: MediaInputKind_Type): Optional<RtcTrack_Source> => {
    switch (inputKind) {
        case MediaInputKind.AudioInput:
            return RtcTrack_Source.MICROPHONE;
        case MediaInputKind.VideoInput:
            return RtcTrack_Source.CAMERA;
        case MediaInputKind.Display:
            return RtcTrack_Source.SCREEN_SHARE;
    }
};

// Return a copy of a participant, with the audio track updated
const updateAudioTrack = (
    part: Participant,
    remoteTrack: Optional<RemoteTrack>,
    client: Optional<RtcClient>,
): Participant => {
    return { ...part, audioTrack: toLocalTrack(remoteTrack, client) };
};

// Return a copy of a participant, with the video track updated
const updateVideoTrack = (
    part: Participant,
    remoteTrack: Optional<RemoteTrack>,
    client: Optional<RtcClient>,
): Participant => {
    return { ...part, videoTrack: toLocalTrack(remoteTrack, client) };
};

// Return a copy of a participant, with the display media updated
const updateDisplayTrack = (
    part: Participant,
    remoteTrack: Optional<RemoteTrack>,
    client: Optional<RtcClient>,
): Participant => {
    return { ...part, displayTrack: toLocalTrack(remoteTrack, client) };
};

const updatePeer = (
    track: RemoteTrack,
    client: Optional<RtcClient>,
): SetStateAction<Participant> => {
    switch (track.getRtcTrack().source) {
        case RtcTrack_Source.MICROPHONE:
            return p => updateAudioTrack(p, track, client);
        case RtcTrack_Source.CAMERA:
            return p => updateVideoTrack(p, track, client);
        case RtcTrack_Source.SCREEN_SHARE:
            return p => updateDisplayTrack(p, track, client);
        default:
            log.warn(`Received track update for track of unknown type
                ${track.getRtcTrack().source}`);
            return p => p;
    }
};

export interface UseRtcClientProps {
    callId?: d.CallId;
    // Called when the client leaves and cannot reconnect
    onLeave?: () => void;
}

export function useRtcClient(props: UseRtcClientProps) {
    const { callId, onLeave } = props;
    const clientRef = useRef<RtcClient>();

    const dispatch = useAppDispatch();
    const userId = useAppSelector(selectCurrentUserId);

    const call = useSelectorArgs(selectCallById, callId);
    const sessionId = call?.sessionId;

    // The participant tracks to return to the caller
    // Note by using a map we get the entries sorted by arrival time
    const {
        set: addParticipant,
        remove: removeParticipant,
        reset: resetParticipants,
        update: updateParticipant,
        values: otherParticipants,
    } = useMap<d.RtcParticipantId, Participant>();

    const updateParticipantTrack = useCallback((id: d.RtcParticipantId, track: RemoteTrack) => {
        if (!track.getMediaStream()) {
            log.warn(
                `Received track update for participant ${id} without media stream - ignoring update`,
            );
            return;
        }

        updateParticipant(id, updatePeer(track, clientRef.current));
    }, [updateParticipant]);

    const removeParticipantTrack = useCallback((id: d.RtcParticipantId, trackId: string) => {
        const peerUpdate = (part: Participant) => {
            switch (trackId) {
                case part.audioTrack.rtcTrackId:
                    return updateAudioTrack(part, undefined, clientRef.current); // remove audio
                case part.displayTrack.rtcTrackId:
                    return updateDisplayTrack(part, undefined, clientRef.current); // remove display
                case part.videoTrack.rtcTrackId:
                    return updateVideoTrack(part, undefined, clientRef.current); // remove video
                default:
                    log.warn(
                        `Received track removal request for peer ${id} for unknown track ${trackId}`,
                    );
                    return part;
            }
        };

        updateParticipant(id, peerUpdate);
    }, [updateParticipant]);

    // The metadata about the current RTC connection.
    const { connectionSignal, getCanConnect, sendConnectionSignal } = useRtcConnection();

    // Trigger connection useEffect to retrigger and not reconnect
    const leaveSession = useCallback(() => {
        sendConnectionSignal(false);
    }, [sendConnectionSignal]);

    const updateLocationConfig = useCallback(
        (location: string, receiveMediaFromSameLocation?: boolean) => {
            if (!clientRef.current) {
                log.error("Unable to update location before joining a session");
                return;
            }

            const config = { location, receiveMediaFromSameLocation };
            log.debug(`Updating RTC location config with`, config);
            clientRef.current.updateLocationConfig(config);
        },
        [],
    );

    const muteTrack = useCallback((isMuted: boolean, trackKind: MediaInputKind_Type) => {
        const trackSource = toTrackSource(trackKind);
        if (!trackSource) {
            return;
        }

        log.debug(`${isMuted ? "Muting" : "Unmuting"} media track`, trackKind);
        clientRef.current?.setTrackMuteBySource(isMuted, trackSource);
    }, []);

    // Listen to session/peer events
    const onSessionJoin = useCallback(() => {
        if (callId) {
            dispatch(setJoinedCall(callId));
        }
        else {
            log.warn("Tried to join call without call ID");
        }
    }, [callId, dispatch]);
    const onSessionLeave = useCallback(() => {
        sendConnectionSignal(false); // TODO: remove canReconnect
    }, [sendConnectionSignal]);
    const { addClientEventListeners, removeClientEventListeners } = useRtcClientEventListeners({
        sessionId,
        onSessionJoin,
        onSessionLeave,
        addParticipant,
        removeParticipant,
        updateParticipantTrack,
        removeParticipantTrack,
    });

    // Define functions to publish/unpublish media into the session for each media kind
    const getClient = useCallback(() => clientRef.current, []);
    const { publishers, resetPublishers } = useRtcClientPublishers({ getClient });

    // Handle leaving session on navigating away
    const onLeaveSession = useCallback(() => {
        dispatch(setLeftCall());
        onLeave?.();
    }, [dispatch, onLeave]);
    useEffect(() => {
        return onLeaveSession;
    }, [onLeaveSession]);

    const active = useAppSelector(selectActiveStatus);
    const currentParticipantId = useAppSelector(selectCurrentParticipantId);
    const haveSingleSessionLock = useTakeSingleRtcSessionLock(active || !!currentParticipantId);

    // Handle connecting to the session if we've reached the state we can connect with.
    // Fetch an access token from the backend, and connect to the session.
    // Handle leaving and cleaning up the session when exiting this useEffect.
    useEffect(() => {
        if (!getCanConnect()) {
            return;
        }
        if (!sessionId || !userId || !haveSingleSessionLock) {
            return;
        }
        dispatch(setCallJoinStatus(CallActionStatus.Pending)); // TODO: move to getAccessToken?

        // Get access token from API
        const tokenFetch = dispatch(getAccessToken({ sessionId, userId }));

        // Connect to call with access token
        let isCleaningUp = false;
        tokenFetch.unwrap().then(token => {
            // Early return if already cleaning up useEffect
            if (isCleaningUp) {
                return;
            }

            // Connect to session and mark as connected upon joining
            const client = createClient(
                SOCKET_URL,
                d.extractRawRtcSessionId(sessionId),
                () => Promise.resolve(token.accessToken),
            );
            client.setSubscriptionPolicy(policy.SubscribeToAll);

            clientRef.current = client;
            joinedRtcClients.set(sessionId, client);
            addClientEventListeners(client);
        })
            .catch(e => {
                if (e.name === "AbortError" || isCleaningUp) {
                    return;
                }

                dispatch(setCallJoinStatus(CallActionStatus.Error));
                log.error(e);
                log.info(`Get access token exception: ${e}`);
            });

        return () => {
            isCleaningUp = true;

            // Leave session on closing
            tokenFetch.abort();

            // Call on leave callback if we will not reconnect
            if (!getCanConnect()) {
                onLeaveSession();
            }

            // Capture current client object before resetting for next `useEffect`.
            // We need a reference to the old value so we can asynchronously
            // capture RTC stats and leave.
            const currentClient = clientRef.current;
            clientRef.current = undefined;
            joinedRtcClients.delete(sessionId);

            // Remove event listeners
            removeClientEventListeners(currentClient);

            // Reset publisher functions and participants before joining the next session
            resetPublishers();
            resetParticipants();

            // Capture RTC stats and leave session
            if (currentClient) {
                captureLastSession(sessionId, currentClient)
                    .finally(() => {
                        // Note: by not using sessionRef.current::leave we keep
                        // the session in scope until this callback is called.
                        currentClient.leave();
                        log.info("Left session", sessionId);
                    });
            }
        };
    }, [
        addClientEventListeners,
        connectionSignal,
        dispatch,
        getCanConnect,
        onLeaveSession,
        removeClientEventListeners,
        resetParticipants,
        resetPublishers,
        sessionId,
        userId,
        haveSingleSessionLock,
    ]);

    return {
        leaveSession,
        muteTrack,
        otherParticipants,
        publishers,
        updateLocationConfig,
    };
}

async function captureLastSession(id?: d.RtcSessionId, client?: RtcClient) {
    if (client) {
        lastSessionId = id;
        lastSessionStats = await getFormattedDumpFromSession(client, id);
    }
    else {
        lastSessionId = undefined;
        lastSessionStats = [];
    }
}

function formatRtcStats(local: RTCStatsReport, remote: RTCStatsReport): string[] {
    const statsDump = [] as string[];
    const dump = (prefix: string) => (v: any, k: string) => {
        statsDump.push(`${prefix} ${k}: ${JSON.stringify(v)}`);
    };
    local.forEach(dump("local"));
    remote.forEach(dump("remote"));
    return statsDump;
}

async function getFormattedDumpFromSession(client: RtcClient, id?: d.RtcSessionId) {
    const stats = client.getStats();
    try {
        const [local, remote] = await stats;
        return formatRtcStats(local, remote);
    }
    catch (e) {
        log.error("Failed to get RTC stats dump for RTC session", id, e);
        return [] as string[];
    }
}

export function getRtcStats(): Map<d.RtcSessionId, string[]> {
    const statsDump = new Map<d.RtcSessionId, string[]>();
    joinedRtcClients.forEach(async (session, id) => {
        const formattedDump = await getFormattedDumpFromSession(session, id);
        statsDump.set(id, formattedDump);
    });
    return statsDump;
}

export const getRecentRtcStats = () => ({ lastSessionId, lastSessionStats });

// Export these to the global namespace on the browser. We want it to be possible
// to open up the developer tools and get RTC stats.
if (typeof window !== "undefined") {
    (window as any).getRtcStats = getRtcStats;
    (window as any).getRecentRtcStats = getRecentRtcStats;
}
