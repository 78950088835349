export function isMobileBrowser(): boolean {
    // navigator.userAgentData is new/experimental, but supported on newer Chrome based browsers at least
    if ("userAgentData" in navigator && (navigator as any).userAgentData.mobile !== undefined) {
        return (navigator as any).userAgentData.mobile;
    }

    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection
    // "In summary, we recommend looking for the string Mobi anywhere in the User Agent to detect a mobile device."
    return /Mobi/i.test(window.navigator.userAgent);
}

export function hasTouchScreen(): boolean {
    // Code from https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    }
    else if ("msMaxTouchPoints" in navigator) {
        hasTouchScreen = (navigator as any).msMaxTouchPoints > 0;
    }
    else {
        const mQ = matchMedia?.("(pointer:coarse)");
        if (mQ?.media === "(pointer:coarse)") {
            hasTouchScreen = !!mQ.matches;
        }
        else if ("orientation" in window) {
            hasTouchScreen = true; // deprecated, but good fallback
        }
        else {
            // Only as a last resort, fall back to user agent sniffing
            const UA = (navigator as any).userAgent;
            hasTouchScreen = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
        }
    }
    return hasTouchScreen;
}
