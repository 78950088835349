import { MouseEventHandler, MutableRefObject, RefCallback } from "react";

import { RootState } from "@/store/types";

export type Optional<T> = T | undefined;

export type SerializableSet<T extends PropertyKey> = Record<T, true>;

export type NumberRange = {
    start: number;
    end: number;
};

export type Point = {
    x: number;
    y: number;
};

export type BoundingBox = {
    top: number;
    bottom: number;
    left: number;
    right: number;
};

export type BoxSize = {
    width: number;
    height: number;
};

export const TypedEntries = <T extends PropertyKey, E>(
    record: Record<T, E>,
): [T, E][] => {
    return Object.entries<E>(record).map(([id, entry]) => {
        return [id as T, entry];
    });
};

export const TypedKeys = <T extends PropertyKey>(
    record: Record<T, any>,
): T[] => {
    return Object.keys(record).map(id => {
        return id as T;
    });
};

export interface FocusableOptions {
    goToEnd?: boolean;
}

export interface Focusable {
    focus: (options?: FocusableOptions) => void;
    blur: () => void;
    hasFocus: () => boolean;
}

export interface Scrollable {
    scrollTo: (x: number, y: number) => void;
}

export interface Styleable {
    setProperty: (propertyName: string, value: string | null, priority?: string) => void;
}

export interface Setter<T> {
    setValue: (value: T) => void;
}

export interface MouseEvents {
    onMouseEnter: MouseEventHandler<HTMLElement> | undefined;
    onMouseLeave: MouseEventHandler<HTMLElement> | undefined;
}

type Arg<F> = F extends (state: RootState, arg: infer S) => unknown ? S : never;
export const optionalFriendly =
    <F extends (state: RootState, id: Arg<F>) => R, R = ReturnType<F>>(fn: F) =>
    (state: RootState, id: Optional<Arg<F>>): Optional<R> => id && fn(state, id);

export type PartialRecord<K extends PropertyKey, V> = Partial<Record<K, V>>;

export const partialRecord = <
    F extends (state: RootState) => R,
    R = ReturnType<F>,
>(
    fn: F,
) =>
(state: RootState): Partial<R> => fn(state);

export interface Diff<T> {
    added?: Array<T>;
    removed?: Array<T>;
}

export const isString = (v: any): v is string => {
    return v !== undefined && (typeof v === "string" || v instanceof String);
};

export type MutableRefList<T> = Array<RefCallback<T> | MutableRefObject<T> | undefined | null>;

export type ExpandType<T> = T extends (...args: any[]) => any ? T : { [K in keyof T]: T[K]; };
