import { Capacitor } from "@capacitor/core";

export function selfIsSharedWorker(selfArg: unknown): selfArg is SharedWorkerGlobalScope {
    return typeof selfArg !== "undefined" &&
        typeof SharedWorkerGlobalScope !== "undefined" &&
        selfArg instanceof SharedWorkerGlobalScope;
}

export function isSharedWorker(selfArg?: unknown): boolean {
    return selfIsSharedWorker(selfArg ?? self);
}

export function selfIsDedicatedWorker(selfArg: unknown): selfArg is WorkerGlobalScope {
    return typeof selfArg !== "undefined" &&
        typeof WorkerGlobalScope !== "undefined" &&
        selfArg instanceof WorkerGlobalScope &&
        typeof SharedWorkerGlobalScope === "undefined";
}

export function isDedicatedWorker(selfArg?: unknown): boolean {
    return selfIsDedicatedWorker(selfArg ?? self);
}

export const isWorkerContext = () => isSharedWorker() || isDedicatedWorker();

export function sharedWorkerAvailable(): boolean {
    return !isSharedWorker() && typeof SharedWorker !== "undefined" &&
        !Capacitor.isNativePlatform();
}

export function dedicatedWorkerAvailable(): boolean {
    return !isSharedWorker();
}
