import EmbedBlot from "quill/blots/embed";

import { AudienceMember, isUserId } from "@/domain/audience";
import { MentionOpValue } from "@/domain/delta";
import { parseSquadUrn, parseUserUrn } from "@/domain/domain";
import { Optional } from "@/misc/types";

const unknownChar = "\ufffd";

export class MentionBlot extends EmbedBlot {
    static blotName = "mention";
    static tagName = "SPAN";
    static className = "cp-composer-mention";

    static create(value: MentionOpValue) {
        const node = super.create(value) as HTMLElement;

        node.innerText = value.text;

        node.setAttribute("data-mention-target", value.target);
        node.setAttribute("data-mention-text", value.text);

        // You can't convince me otherwise:
        node.animate([
            {
                opacity: 0,
                transform: "rotateX(90deg)",
                easing: "cubic-bezier(0.7, 0.71, 0.64, 1.95)",
            },
            {
                opacity: 1,
                transform: "unset",
            },
        ], 200);

        return node;
    }

    static isMentionCase(raw: string): raw is "user" | "squad" {
        return ["user", "squad"].includes(raw);
    }

    static parseTarget<T>(raw: string, parser: (raw: string) => T): Optional<T> {
        try {
            return parser(raw);
        }
        catch {
            return undefined;
        }
    }

    static value(domNode: Node): MentionOpValue | string {
        const elem = domNode as HTMLElement;

        const targetRaw = elem.getAttribute("data-mention-target") ?? "";
        const targetParser = isUserId(targetRaw as AudienceMember) ? parseUserUrn : parseSquadUrn;
        const target = MentionBlot.parseTarget(targetRaw, targetParser);
        if (target === undefined) {
            return unknownChar;
        }

        const text = elem.getAttribute("data-mention-text") ?? "";

        return { target, text };
    }
}

export default MentionBlot;
