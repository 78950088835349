import { ActionCreatorWithPayload, createSlice, PayloadAction } from "@reduxjs/toolkit";
import isEqual from "lodash.isequal";

import { markRead, subBondMsgSeqNums } from "@/api/channels";
import {
    fetchMessages,
    flattenOfficialMessageAndAttachments,
    OfficialMessagesBundle,
} from "@/api/chats";
import { UserSquadLastRead } from "@/api/squads";
import { isAnyLocalAttachment, ProposedAttachment } from "@/domain/attachments";
import { BondOverview } from "@/domain/bonds";
import {
    bondCreationDraftTarget,
    DeletedSequenceNumberWrapper,
    DraftTarget,
    DraftType,
    getChannelOfDraftTarget,
    isBondCreationDraftTarget,
    isChannelDraftTarget,
    SequenceNumberOrDeleted,
    SequenceNumberWrapper,
    unreadMessageCount,
} from "@/domain/channels";
import {
    type EmitterSource,
    emptyMarkupDelta,
    PlainChangeDelta,
    PlainMarkupDelta,
} from "@/domain/delta";
import * as d from "@/domain/domain";
import {
    convertDraftContentToV1,
    convertDraftContentToV2,
    getDraftContentMentions,
    getDraftLastApiChange,
    getDraftMentions,
    getDraftMessageMarkup,
    getDraftMessageText,
    withChange,
    withMarkup,
} from "@/domain/draftChatContent";
import { filterMentions, Mention } from "@/domain/mentions";
import {
    AnyUnsentLocalMessage,
    convertDraftToUnsentMessage,
    determineOfficialMessageContributors,
    DraftChatMessage,
    emptyDraftChatMessage,
    isErroredMsg,
    isOfficialChatMessage,
    isUnsentMsg,
    messageIsSendable,
    messageMentionsAny,
    MessageSendableStatus,
    UnsentChatMessage,
} from "@/domain/messages";
import { userSortByBondActivity } from "@/domain/users";
import * as cm from "@/ds/ChannelMap";
import { resetStore, selectCurrentUserId } from "@/features/auth";
import {
    clearDraftThunk,
    createBondFromMessageThunk,
    selectBondCreationAudience,
    selectBondCreationDraft,
} from "@/features/bondCreation";
import { archiveBond, selectBonds, streamedBonds } from "@/features/bonds";
import {
    deleteAttachmentFromDraftThunk,
    selectLocalAttachment,
    selectUnsentMessages,
    sendMessageThunk,
    streamedMessages,
    testInsertUnsentMessage,
} from "@/features/chats";
import { fetchDeltaKnowledgeBondThunk } from "@/features/intel";
import { createProxiedAsyncThunk } from "@/features/proxiedThunk";
import { createSelectorPair, memoizeOptions } from "@/features/selectors";
import { selectCurrentSquadIds, selectSquadLatestActivity } from "@/features/squads";
import { createStreamingAsyncThunk } from "@/features/streamingThunk";
import { unaryThunkHandler } from "@/features/thunk";
import { selectUsers } from "@/features/users";
import log from "@/misc/log";
import {
    applyTextToContent,
    ChangeSelections,
    insertMentionIntoContent,
    insertTextIntoContent,
} from "@/misc/messageContent";
import { filterRecordByEntry } from "@/misc/primatives";
import type { Diff, NumberRange, Optional } from "@/misc/types";
import { optionalToList, range, separateDiscriminatedUnion } from "@/misc/utils";
import { checkPersistor, fetchIdbStore, idbStore } from "@/persist/shared";
import type { Connection, IdbStoreUpdate, PersistenceUpdate } from "@/persist/types";
import { tagActionCreator, tagLocalAction } from "@/store/locations";
import { createAppSelector } from "@/store/redux";
import type { RootState } from "@/store/types";

export const catchupMessages = createProxiedAsyncThunk(
    "messages/catchup",
    async (
        { channelId }: { channelId: d.ChannelId; },
        thunkAPI,
    ): Promise<OfficialMessagesBundle> => {
        const state = thunkAPI.getState();
        const localSequenceNumber = selectLocalSequenceNumber(state, channelId);
        // Note: the sequence numbers of messages start at 1, not 0(!)
        const from = (localSequenceNumber ?? 0) + 1;

        const bundles = await unaryThunkHandler(
            thunkAPI,
            fetchMessages(channelId, from),
            `fetchMessages ${channelId} from ${from}`,
        );

        if (bundles.length === 0) {
            throw thunkAPI.rejectWithValue({
                error: "no relevant messages in range",
                at: Date.now(),
            });
        }

        return flattenOfficialMessageAndAttachments(bundles, channelId);
    },
);

type StageMessageForChannelResult = [d.ChannelId, UnsentChatMessage];

export const stageMessageForChannel = createProxiedAsyncThunk(
    "messages/stageForChannel",
    async (draftMsg: DraftChatMessage, thunkAPI): Promise<StageMessageForChannelResult> => {
        if (!isChannelDraftTarget(draftMsg.draftTarget)) {
            log.warn(`Cannot stage a message without a channel draft target`, draftMsg);
            throw thunkAPI.rejectWithValue({
                error: `stageMessage requires a channel draft target`,
            });
        }

        const channelId = getChannelOfDraftTarget(draftMsg.draftTarget);

        const unsentMsg = convertDraftToUnsentMessage(draftMsg, Date.now());

        return [channelId, unsentMsg];
    },
);

export const subChannelMsgSeqNumsThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.BondId>>,
    SequenceNumberOrDeleted
>(
    "messages/subBondMsgSeqNums",
    {
        rpc: ({ arg, state, signal }) => {
            const userId = selectCurrentUserId(state)!;
            return subBondMsgSeqNums(arg, { userId }, signal);
        },
        parser: ({ thunkAPI, content }) => {
            const [deleted, seqNosWrapped] = separateDiscriminatedUnion<
                DeletedSequenceNumberWrapper,
                SequenceNumberWrapper
            >(msg => msg.case === "deletedId", content);

            if (deleted.length) {
                const ids = deleted.map(msg => msg.value);
                thunkAPI.dispatch(deletePublishedSequenceNumbers(ids));
            }

            if (seqNosWrapped.length) {
                const seqNos = seqNosWrapped
                    .map(msg => msg.value)
                    .filterLatest(sn => sn.channelId);
                thunkAPI.dispatch(
                    updatePublishedSequenceNumbers(seqNos),
                );
            }
        },
    },
);

interface UpdateUserReadSequenceNumberArgs {
    channelId: d.ChannelId;
    seqNum: number;
}

export const updateUserReadSequenceNumber = createProxiedAsyncThunk(
    "messages/markRead",
    async (args: UpdateUserReadSequenceNumberArgs, thunkAPI) => {
        if (!args.channelId) {
            throw thunkAPI.rejectWithValue({ error: `Tried to markRead for the empty channel` });
        }

        const store = thunkAPI.getState();
        const userId = selectCurrentUserId(store)!;

        await unaryThunkHandler(
            thunkAPI,
            markRead({
                ...args,
                userId: userId,
                sequenceNumber: args.seqNum,
            }),
            `markRead ${args.channelId}:${args.seqNum}`,
        );
    },
);

type ChannelUpdate =
    | IdbStoreUpdate<typeof stores.map>
    | IdbStoreUpdate<typeof stores.drafts>
    | IdbStoreUpdate<typeof stores.pubSeqNos>
    | IdbStoreUpdate<typeof stores.stagedSeqNos>;

export interface ChannelsState {
    map: cm.ChannelMap;
    drafts: Record<d.ChannelId, DraftChatMessage>;
    // Server-side record of latest-read-message-sequence-number.
    publishedSequenceNumbers: Record<d.ChannelId, number>;
    // Local record of latest-read-message-sequence-number.
    stagedSequenceNumbers: Record<d.ChannelId, number>;
    // Local record of latest-message-sequence-number-on-server.
    maxSequenceNumbers: Record<d.ChannelId, number>;
    // Record of when squads are marked as read
    squadReadTimes: Record<d.SquadId, number>;
    updates: ChannelUpdate[];
}

export interface UpdateDraftMarkupArgs {
    draftTarget: DraftTarget;
    update:
        | { markup: PlainMarkupDelta; change?: PlainChangeDelta; }
        | { markup?: PlainMarkupDelta; change: PlainChangeDelta; };
    source: EmitterSource;
}

export interface UpdateDraftTextArgs {
    draftTarget: DraftTarget;
    newText: string;
    selections: ChangeSelections;
}

export interface InsertDraftTextArgs {
    draftTarget: DraftTarget;
    insertedText: string;
    range: NumberRange;
}

export interface InsertDraftMentionArgs {
    draftTarget: DraftTarget;
    mention: Mention;
    text: string;
    range: NumberRange;
}

export interface TransferDraftArgs {
    from: DraftTarget;
    to: DraftTarget;
    content: DraftChatMessage;
}

interface UpdateStagedSequenceNumberArgs {
    channelId: d.ChannelId;
    sequenceNumber: number;
}

const getInitialState = (
    props?: {
        map?: Record<d.ChannelId, cm.PerChannelData>;
        drafts?: Record<d.ChannelId, DraftChatMessage>;
        pubSeqNos?: Record<d.ChannelId, number>;
        stagedSeqNos?: Record<d.ChannelId, number>;
        maxSeqNos?: Record<d.ChannelId, number>;
        squadReadTimes?: Record<d.SquadId, number>;
    },
): ChannelsState => ({
    map: props?.map ?? {},
    drafts: props?.drafts ?? {},
    publishedSequenceNumbers: props?.pubSeqNos ?? {},
    stagedSequenceNumbers: props?.stagedSeqNos ?? {},
    maxSequenceNumbers: props?.maxSeqNos ?? {},
    squadReadTimes: props?.squadReadTimes ?? {},
    updates: [],
});

/*
    ******************* Sequence number glossary *******************
    Local sequence number:
    For a given channel/bond, the maximum sequence number of a message available
    locally.
    e.g. a bond with 7 messages in total, all available in the store, would have
    a local sequence number of 7.

    Maximum sequence number:
    The largest sequence number of a message that exists within a channel, that is,
    the largest sequence number present within the backend. This information exists
    on the BondOverview.
    e.g. Arun sends a message into a channel, and the backend gives that message
    a sequence number of 10. The maximum sequence number of that channel is then 10
    until a further message is processed and accepted by the backend for the channel.

    Published sequence number:
    For a specific user and channel, the published sequence number is the sequence
    number of the 'latest read' message distributed by the backend.
    e.g. If the backend believes Arun had read up to and including the message
    with sequence number 3 on a channel, then the published sequence number
    would be 3 for Arun on that channel.

    Staged sequence number:
    For a specific user and channel, the staged sequence number is a specific
    frontend's local knowledge of what messages have been read by the user on
    that channel. It is used to determine how many messages are unread on a
    channel, and can both update and be updated by the backend.
    e.g. If the backend publishes sequence number 3 for Arun and a channel, then
    Arun's client goes offline before he reads the message with the sequence
    number 4, the published sequence number will be 3 and the staged will be 4.
    The MessageReadUpdater will then attempt to notify the backend because of
    the discrepancy.
    Further to this, if Arun logs in and the backend publishes sequence number 3
    for Arun and a channel, then the redux store will have the published and
    staged sequence numbers for that channel as 3. If the backend then publishes
    sequence number 4, then both will be updated to 4.

    Hopefully helpful diagram: dots = messages in store, underscores = messages
    not in store

                                published      staged            local       max
                                  seqno        seqno             seqno     seqno
                                    v            v                 v           v
                      ..............................................____________
    Server unaware of read status - ^^^^^^^^^^^^^ ^^^^^^^^^^^^^^^^^ ^^^^^^^^^^^^
                                                  /                   Client not
                                Client not yet read                   downloaded

    `local == max` when we have downloaded all available messages.
    `staged == local` when we a client has read all the messages it has.
    `published == staged` when the backend is aware of our read status.
*/

const updateFromBonds = (
    state: ChannelsState,
    bos: BondOverview[],
): ChannelsState => {
    bos.forEach(bo => {
        const channelId = bo.channelId;
        state.map = cm.addChannelToMap(state.map, channelId);
        state.maxSequenceNumbers[channelId] = bo.maxSequenceNumber;
        state.updates.push([stores.map, channelId, state.map[channelId]]);
    });
    return state;
};

const updateStagedSequenceNumber = (
    state: ChannelsState,
    channelId: d.ChannelId,
    newSeqNo: number,
    force?: boolean,
) => {
    if (state.stagedSequenceNumbers[channelId] >= newSeqNo && force !== true) {
        return;
    }

    state.stagedSequenceNumbers[channelId] = newSeqNo;
    state.updates.push([
        stores.stagedSeqNos,
        channelId,
        newSeqNo,
    ]);
};

export const channelsSlice = createSlice({
    name: "channels",
    initialState: getInitialState(),
    selectors: {
        draftByChannelId: (state, id: Optional<d.ChannelId>): Optional<DraftChatMessage> =>
            id && state.drafts[id],
        publishedSequenceNumber: (state, id: Optional<d.ChannelId>): Optional<number> =>
            id && state.publishedSequenceNumbers[id],
        stagedSequenceNumber: (state, id: Optional<d.ChannelId>): Optional<number> =>
            id && state.stagedSequenceNumbers[id],
        localSequenceNumber: (state, id: Optional<d.ChannelId>): Optional<number> =>
            id && state.map[id]?.localSequenceNumber,
        maxSequenceNumber: (state, id: Optional<d.ChannelId>): Optional<number> =>
            id && state.maxSequenceNumbers[id],
        channelMap: (state, id: Optional<d.ChannelId>): Optional<cm.PerChannelData> =>
            id && state.map[id],
        allChannelMaps: state => state.map,
        allStagedSequenceNumbers: state => state.stagedSequenceNumbers,
        squadReadTimes: state => state.squadReadTimes,
    },
    reducers: {
        updateDraftMarkup: (state, { payload }: PayloadAction<UpdateDraftMarkupArgs>) => {
            if (!isChannelDraftTarget(payload.draftTarget)) return;

            const {
                draftTarget: { channelId },
                update: { markup, change },
                source,
            } = payload;

            const draft = state.drafts[channelId] ??
                emptyDraftChatMessage({ channelId, contentVer: 2 });
            const convertedDraft = {
                ...draft,
                content: convertDraftContentToV2(draft.content),
            };

            const newDraft = {
                ...draft,
                content: change ?
                    withChange(convertedDraft.content, change, source) :
                    withMarkup(convertedDraft.content, markup!, source),
            };

            state.drafts[channelId] = newDraft;
            state.updates = [[stores.drafts, channelId, newDraft]];
        },
        /**
         * @deprecated
         */
        updateDraftText: (state, { payload }: PayloadAction<UpdateDraftTextArgs>) => {
            if (!isChannelDraftTarget(payload.draftTarget)) return;

            const {
                draftTarget: { channelId },
                newText,
                selections,
            } = payload;

            const draft = state.drafts[channelId] ??
                emptyDraftChatMessage({ channelId, contentVer: 1 });
            const content = convertDraftContentToV1(draft.content);

            const newDraft = {
                ...draft,
                content: applyTextToContent(content, { newText, selections }),
            };

            state.drafts[channelId] = newDraft;
            state.updates = [[stores.drafts, channelId, newDraft]];
        },
        /**
         * @deprecated
         */
        insertDraftText: (state, { payload }: PayloadAction<InsertDraftTextArgs>) => {
            if (!isChannelDraftTarget(payload.draftTarget)) return;

            const {
                draftTarget: { channelId },
                insertedText,
                range,
            } = payload;

            const draft = state.drafts[channelId] ??
                emptyDraftChatMessage({ channelId, contentVer: 1 });
            const content = convertDraftContentToV1(draft.content);

            const newDraft = {
                ...draft,
                content: insertTextIntoContent(content, { insertedText, range }),
            };

            state.drafts[channelId] = newDraft;
            state.updates = [[stores.drafts, channelId, newDraft]];
        },
        /**
         * @deprecated
         */
        insertDraftMention: (state, { payload }: PayloadAction<InsertDraftMentionArgs>) => {
            if (!isChannelDraftTarget(payload.draftTarget)) return;

            const {
                draftTarget: { channelId },
                mention,
                text,
                range,
            } = payload;

            const draft = state.drafts[channelId] ??
                emptyDraftChatMessage({ channelId, contentVer: 1 });
            const content = convertDraftContentToV1(draft.content);

            const newDraft = {
                ...draft,
                content: insertMentionIntoContent(content, { mention, text, range }),
            };

            state.drafts[channelId] = newDraft;
            state.updates = [[stores.drafts, channelId, newDraft]];
        },

        updatePublishedSequenceNumber: (
            state,
            action: PayloadAction<{ channelId: d.ChannelId; sequenceNumber: number; }>,
        ) => {
            const { channelId, sequenceNumber } = action.payload;
            state.publishedSequenceNumbers[channelId] = sequenceNumber;
            state.map = cm.publishedSeqNoUpdated(state.map, channelId, sequenceNumber);

            state.updates = [
                [stores.pubSeqNos, channelId, sequenceNumber],
                [stores.map, channelId, state.map[channelId]],
            ];

            updateStagedSequenceNumber(state, channelId, sequenceNumber);
        },
        updatePublishedSequenceNumbers: (
            state,
            { payload: seqNos }: PayloadAction<
                { channelId: d.ChannelId; sequenceNumber: number; }[]
            >,
        ) => {
            if (seqNos.length === 0) return;

            state.updates = [];
            seqNos.forEach(({ channelId, sequenceNumber }) => {
                state.publishedSequenceNumbers[channelId] = sequenceNumber;
                state.map = cm.publishedSeqNoUpdated(state.map, channelId, sequenceNumber);

                state.updates.push(
                    [stores.pubSeqNos, channelId, sequenceNumber],
                    [stores.map, channelId, state.map[channelId]],
                );

                updateStagedSequenceNumber(state, channelId, sequenceNumber);
            });
        },
        deletePublishedSequenceNumber: (state, action: PayloadAction<d.ChannelId>) => {
            delete state.publishedSequenceNumbers[action.payload];
            delete state.stagedSequenceNumbers[action.payload];
            state.updates = [
                [stores.pubSeqNos, action.payload],
                [stores.stagedSeqNos, action.payload],
            ];
        },
        deletePublishedSequenceNumbers: (state, { payload: ids }: PayloadAction<d.ChannelId[]>) => {
            if (ids.length === 0) return;

            state.updates = [];
            ids.forEach(id => {
                delete state.publishedSequenceNumbers[id];
                delete state.stagedSequenceNumbers[id];
                state.updates.push(
                    [stores.pubSeqNos, id],
                    [stores.stagedSeqNos, id],
                );
            });
        },
        updateStagedSequenceNumberToLocalMax: (
            state,
            { payload: channelId }: PayloadAction<d.ChannelId>,
        ) => {
            const localSequenceNumber = state.map[channelId]?.localSequenceNumber;
            if (!localSequenceNumber) {
                return;
            }

            updateStagedSequenceNumber(state, channelId, localSequenceNumber);
        },
        updateStagedSequenceNumberForTests: (
            state,
            action: PayloadAction<UpdateStagedSequenceNumberArgs>,
        ) => {
            updateStagedSequenceNumber(
                state,
                action.payload.channelId,
                action.payload.sequenceNumber,
                true,
            );
        },
        updateStagedSequenceNumberIfGreater: (
            state,
            action: PayloadAction<UpdateStagedSequenceNumberArgs>,
        ) => {
            updateStagedSequenceNumber(
                state,
                action.payload.channelId,
                action.payload.sequenceNumber,
                false,
            );
        },
        addAttachmentsToDraft: (
            state,
            { payload: attachments }: PayloadAction<ProposedAttachment[]>,
        ) => {
            if (attachments.length === 0) return;

            state.updates = [];

            attachments.forEach(attachment => {
                if (!isChannelDraftTarget(attachment.draftTarget)) return;

                const { localId, draftTarget: { channelId } } = attachment;

                if (!state.drafts[channelId]) {
                    state.drafts[channelId] = emptyDraftChatMessage({ channelId, contentVer: 1 });
                }

                const draft = state.drafts[channelId];
                if (-1 === draft.attachmentIds.indexOf(localId)) {
                    draft.attachmentIds.push(localId);
                    state.updates.push([stores.drafts, channelId, draft]);
                }
            });
        },
        clearAttachmentsFromDraft: (
            state,
            { payload: draftTarget }: PayloadAction<DraftTarget>,
        ) => {
            if (!isChannelDraftTarget(draftTarget)) return;

            const channelId = draftTarget.channelId;
            const draft = state.drafts[channelId];

            if (draft && draft.attachmentIds.length > 0) {
                draft.attachmentIds = [];
                state.updates = [[stores.drafts, channelId, draft]];
            }
        },
        streamedSquadLastReads: (
            state,
            { payload: lastReads }: PayloadAction<UserSquadLastRead[]>,
        ) => {
            if (lastReads.length === 0) return;

            state.updates = [];
            lastReads.forEach(({ squadId, lastRead }) => {
                state.squadReadTimes[squadId] = lastRead;
                state.updates.push([stores.squadReadTimes, squadId, lastRead]);
            });
        },
    },
    extraReducers: builder => {
        builder.addCase(
            deleteAttachmentFromDraftThunk.fulfilled,
            (state, { payload: { localId, draftTarget } }) => {
                if (!isChannelDraftTarget(draftTarget)) return;
                const { channelId } = draftTarget;

                const draft = state.drafts[channelId];
                if (!draft) return;

                const index = draft.attachmentIds.indexOf(localId);
                if (index !== -1) {
                    draft.attachmentIds.splice(index, 1);
                    state.updates = [[stores.drafts, channelId, draft]];
                }
            },
        );
        builder.addCase(
            stageMessageForChannel.fulfilled,
            (
                state,
                { payload: [channelId, unsentMsg] }: PayloadAction<StageMessageForChannelResult>,
            ) => {
                state.map = cm.addUnsentToChannel(state.map, channelId, unsentMsg);
                delete state.drafts[channelId];

                state.updates = [
                    [stores.map, channelId, state.map[channelId]],
                    [stores.drafts, channelId],
                ];
            },
        );
        builder.addCase(testInsertUnsentMessage, (
            state,
            { payload: [channelId, msg] }: PayloadAction<[d.ChannelId, AnyUnsentLocalMessage]>,
        ) => {
            state.map = cm.addUnsentToChannel(state.map, channelId, msg);

            state.updates = [[stores.map, channelId, state.map[channelId]]];
        });
        builder.addCase(sendMessageThunk.fulfilled, (state, { payload: [channelId, msg] }) => {
            if (isErroredMsg(msg) || !msg.messageId) return;

            state.map = cm.messageSent(state.map, channelId, msg);
            state.updates = [[stores.map, channelId, state.map[channelId]]];
        });
        builder.addCase(streamedMessages, (state, { payload: { msgs } }) => {
            if (!msgs || msgs.length === 0) return;

            state.updates = [];
            msgs.forEach(msg => {
                const channelId = msg.channelId;
                state.map = cm.addToChannel(state.map, channelId, [msg]);
                state.updates.push([stores.map, channelId, state.map[channelId]]);
            });
        });
        builder.addCase(
            catchupMessages.fulfilled,
            (state, { payload: { channelId, msgs } }: PayloadAction<OfficialMessagesBundle>) => {
                if (!msgs || msgs.length === 0) return;

                state.map = cm.addToChannel(state.map, channelId, msgs);

                state.updates = [[stores.map, channelId, state.map[channelId]]];
            },
        );
        builder.addCase(streamedBonds, (state, { payload: bos }: PayloadAction<BondOverview[]>) => {
            if (bos.length === 0) return;

            state.updates = [];
            return updateFromBonds(state, bos);
        });
        builder.addCase(createBondFromMessageThunk.fulfilled, (state, { payload: bo }) => {
            state.updates = [];
            return updateFromBonds(state, [bo]);
        });
        builder.addCase(
            clearDraftThunk.fulfilled,
            (state, { payload: { draftTarget } }) => {
                if (!isChannelDraftTarget(draftTarget)) return;

                const { channelId } = draftTarget;

                delete state.drafts[channelId];
                state.updates.push([stores.drafts, channelId]);
            },
        );
        builder.addCase(archiveBond.fulfilled, (state, action) => {
            const args = action.meta.arg;
            if (!args || !args.channelId || !args.archive) {
                return;
            }

            const channelId = args.channelId;
            const localSequenceNumber = state.map[channelId]?.localSequenceNumber;
            if (!localSequenceNumber) {
                return;
            }

            state.stagedSequenceNumbers[channelId] = localSequenceNumber;
            state.updates = [[
                stores.stagedSeqNos,
                channelId,
                localSequenceNumber,
            ]];
        });

        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
        builder.addCase(fetchDeltaKnowledgeBondThunk.fulfilled, (state, action) => {
            state.map = cm.addDeltaKnowledgeBondToChannel(state.map, action.payload);
        });
    },
});

const {
    updatePublishedSequenceNumbers,
    deletePublishedSequenceNumbers,
} = channelsSlice.actions;

export const {
    updateDraftMarkup: updateDraftMarkupInternal,
    updateDraftText: updateDraftTextInternal,
    insertDraftText: insertDraftTextInternal,
    insertDraftMention: insertDraftMentionInternal,

    updatePublishedSequenceNumber,
    updateStagedSequenceNumberToLocalMax,
    updateStagedSequenceNumberForTests,
    updateStagedSequenceNumberIfGreater,

    addAttachmentsToDraft,
    clearAttachmentsFromDraft,
} = channelsSlice.actions;

const tagLocalForBondCreationDraftTarget = <
    P extends { draftTarget: DraftTarget; },
    T extends string,
>(
    creator: ActionCreatorWithPayload<P, T>,
) => tagActionCreator<P, T, typeof creator>(
    creator,
    tagLocalAction,
    p => isBondCreationDraftTarget(p.draftTarget),
);

export const updateDraftMarkup = tagLocalForBondCreationDraftTarget(updateDraftMarkupInternal);
export const updateDraftText = tagLocalForBondCreationDraftTarget(updateDraftTextInternal);
export const insertDraftText = tagLocalForBondCreationDraftTarget(insertDraftTextInternal);
export const insertDraftMention = tagLocalForBondCreationDraftTarget(insertDraftMentionInternal);

const selectors = channelsSlice.getSelectors((state: RootState) => state.channels);

export const {
    draftByChannelId: selectDraftByChannelId,
    localSequenceNumber: selectLocalSequenceNumber,
    allChannelMaps: selectAllChannelMaps,
    publishedSequenceNumber: selectPublishedSequenceNumber,
    allStagedSequenceNumbers: selectAllStagedSequenceNumbers,
    maxSequenceNumber: selectMaxSequenceNumber,
} = selectors;

export const selectDraftMessageSendableStatus = (
    state: RootState,
    draftTarget: DraftTarget,
    requireAudienceForNewBonds: boolean = true,
): MessageSendableStatus => {
    const message = selectDraft(state, draftTarget);
    if (!message) return MessageSendableStatus.Denied;

    const isNewBond = isEqual(draftTarget, bondCreationDraftTarget);
    const attachments = selectLocalAttachmentsDraftForMessage(state, draftTarget);
    const newBondAudience = isNewBond ? selectBondCreationAudience(state) : undefined;

    return messageIsSendable(
        message,
        requireAudienceForNewBonds && isNewBond,
        attachments,
        newBondAudience,
    );
};

export const selectUnreadMessageCount = createAppSelector(
    [selectors.stagedSequenceNumber, selectors.maxSequenceNumber],
    unreadMessageCount,
    memoizeOptions.weakMapShallow,
);

export const selectIsRead = createAppSelector(
    [selectUnreadMessageCount],
    count => count === undefined || count === 0,
);

const selectUnreadMessages = createAppSelector(
    [
        selectors.stagedSequenceNumber,
        selectLocalSequenceNumber,
        selectors.channelMap,
    ],
    (stagedSeqNo, localSeqNo, cm) => {
        if (!cm) return [];

        const local = localSeqNo ?? 0;
        const staged = stagedSeqNo ?? 0;
        const n = Math.max(local - staged, 0);

        return range(n)
            // Start from the sequence number *after* stagedSeqNo
            .map(i => cm.unreadMessagesIndex[staged + 1 + i]);
    },
    memoizeOptions.weakMapShallow,
);

export const selectDeltaKnowledgeBond = createAppSelector(
    [
        selectors.channelMap,
    ],
    cm => {
        if (!cm) return;
        return cm.deltaKnowledgeBond;
    },
    memoizeOptions.weakMapShallow,
);

const selectUserIdsWithUnread = createAppSelector(
    [selectUnreadMessages],
    msgs => msgs.flatMap(determineOfficialMessageContributors).removeDuplicates(),
    memoizeOptions.weakMapShallow,
);

export const selectUserIdSetWithUnread = createAppSelector(
    [selectUserIdsWithUnread],
    msgs => msgs.toSet(),
    memoizeOptions.weakMapShallow,
);

const isUserId = (userId: Optional<d.UserId>): userId is d.UserId => !!userId;

// Bodge to fix import cycle in tests
const selectCurrentSquadIdsDelayed = (state: RootState) => selectCurrentSquadIds(state);

export const selectUserIdSetWithMentions = createAppSelector(
    [selectUnreadMessages, selectCurrentUserId, selectCurrentSquadIdsDelayed],
    (msgs, currentUserId, currentSquadIds): Set<d.UserId> =>
        msgs
            .filter(isOfficialChatMessage)
            .filter(msg => messageMentionsAny(msg, optionalToList(currentUserId), currentSquadIds))
            .map(msg => msg.senderId)
            .filter(isUserId)
            .toSet(),
    memoizeOptions.weakMapShallow,
);

export const selectHasMentions = createAppSelector(
    [selectUnreadMessages, selectCurrentUserId, selectCurrentSquadIdsDelayed],
    (msgs, currentUserId, currentSquadIds): boolean =>
        msgs.some(msg => messageMentionsAny(msg, optionalToList(currentUserId), currentSquadIds)),
    memoizeOptions.weakMapShallow,
);

const selectChannelActivityAdjustments = createAppSelector(
    [selectUserIdSetWithMentions, selectUserIdSetWithUnread],
    (mentionUserSet, unreadUserSet) => ({
        sort: userSortByBondActivity({
            mentionUserSet,
            unreadUserSet,
        }),
    }),
    memoizeOptions.weakMap,
);

const selectSortedUsersWithUnread = (state: RootState, id: Optional<d.ChannelId>) => {
    const usersWithUnread = selectUserIdsWithUnread(state, id);
    const adjustments = selectChannelActivityAdjustments(state, id);
    return selectUsers(state, usersWithUnread, adjustments);
};

export const selectSortedUserIdsWithUnreadPair = createSelectorPair(
    selectUserIdsWithUnread,
    createAppSelector(
        [selectSortedUsersWithUnread],
        uos => uos.map(uo => uo.id),
        memoizeOptions.weakMapShallow,
    ),
);

export const selectSortedUserIdSetWithUnread = createAppSelector(
    [selectUserIdsWithUnread],
    uIds => uIds.toSet(),
    memoizeOptions.weakMapShallow,
);

export const selectChannelInfosForSeqNumUpdate = createAppSelector(
    [
        state => state.channels.stagedSequenceNumbers,
        state => state.channels.publishedSequenceNumbers,
    ],
    (staged, published) =>
        filterRecordByEntry(staged, (id, stagedSeqNum) => (published[id] ?? -1) < stagedSeqNum),
);

export const selectDebugSequenceNumbers = createAppSelector(
    [
        selectPublishedSequenceNumber,
        selectors.stagedSequenceNumber,
        selectLocalSequenceNumber,
        selectors.maxSequenceNumber,
    ],
    (published, staged, local, max) => ({
        published,
        staged,
        local,
        max,
    }),
);

export const selectMessageIdsByChannelId = (id: Optional<d.ChannelId>) => (state: RootState) =>
    // We should not hit the empty array case here as we assume the curatedList
    // has been set up for each channel correctly
    selectors.channelMap(state, id)?.curatedList ?? [];

export const selectUnsentQueue = (id: d.ChannelId) => (state: RootState) =>
    selectors.channelMap(state, id)?.unsent ?? [];

export const selectDraft = (
    state: RootState,
    dt: DraftTarget,
): Optional<DraftChatMessage> => {
    switch (dt.type) {
        case DraftType.Channel:
            return selectDraftByChannelId(state, dt.channelId);
        case DraftType.BondCreation:
            return selectBondCreationDraft(state);
    }
};

export const selectDraftText = createAppSelector(
    [selectDraft],
    draft => getDraftMessageText(draft?.content) ?? "",
);

export const selectDraftMarkup = createAppSelector(
    [selectDraft],
    draft => getDraftMessageMarkup(draft?.content) ?? emptyMarkupDelta,
);

export const selectDraftLastApiChange = createAppSelector(
    [selectDraft],
    draft => getDraftLastApiChange(draft?.content),
);

/**
 * @deprecated
 */
export const selectDraftContentMentions = createAppSelector(
    [selectDraft],
    draft => getDraftContentMentions(draft?.content) ?? [],
    memoizeOptions.weakMapShallow,
);

export const selectDraftMentions = createAppSelector(
    [selectDraft],
    draft => getDraftMentions(draft?.content) ?? [],
    memoizeOptions.weakMapShallow,
);

const selectFilteredMentions = createAppSelector(
    [selectDraftMentions],
    filterMentions,
    memoizeOptions.weakMapShallow,
);

export const selectDraftUserMentions = createAppSelector(
    [selectFilteredMentions],
    ({ userIds }) => userIds,
    memoizeOptions.weakMapShallow,
);

export const selectDraftSquadMentions = createAppSelector(
    [selectFilteredMentions],
    ({ squadIds }) => squadIds,
    memoizeOptions.weakMapShallow,
);

export const selectLocalAttachmentsDraftForMessage = createAppSelector(
    [state => state, selectDraft],
    (state, msg) =>
        msg?.attachmentIds.map(a => selectLocalAttachment(state, a)).filter(isAnyLocalAttachment),
    memoizeOptions.weakMapShallow,
);

const nextAttempt = (msg: UnsentChatMessage) => msg.backoffState?.nextAttempt;
const nextSendAttemptOrder = (a: UnsentChatMessage, b: UnsentChatMessage): number => {
    const hA = nextAttempt(a);
    const hB = nextAttempt(b);

    if (!hA) return !hB ? a.clientTxTs - b.clientTxTs : 1;
    if (!hB) return -1;

    return hA - hB;
};

export const selectFirstUnsentMessages = createAppSelector(
    [
        selectors.allChannelMaps,
        state => selectUnsentMessages(state),
    ],
    (c, unsents) =>
        Object
            .values(c)
            .filter(pcd => pcd.unsent.length > 0)
            .map(pcd => {
                for (const id of pcd.unsent) {
                    const msg = unsents[id];
                    if (isErroredMsg(msg)) return;
                    if (isUnsentMsg(msg) && !msg.messageId) return msg;
                }
            })
            .filter(isUnsentMsg) // remove undefineds
            .sort(nextSendAttemptOrder),
    memoizeOptions.weakMapShallow,
);

export const selectNumberOfQueuedMessages = createAppSelector(
    [selectors.allChannelMaps],
    cm => Object.values(cm).reduce((acc, pcd) => acc + pcd.unsent.length, 0),
);

export const selectBondsForSquad = createAppSelector(
    [state => selectBonds(state), (_state, id: Optional<d.SquadId>) => id],
    (previews, id) => {
        return previews.filter(bp => (id && bp.squadIds?.includes(id)) ?? false);
    },
);

export const selectUnreadBondsForSquad = createAppSelector(
    [
        selectBondsForSquad,
        state => selectAllStagedSequenceNumbers(state),
    ],
    (bonds, stagedSequenceNumberMap) => {
        return bonds.filter(
            bo =>
                unreadMessageCount(stagedSequenceNumberMap[bo.channelId], bo.maxSequenceNumber) > 0,
        );
    },
    memoizeOptions.weakMap,
);

// Bodge to fix import cycle in tests
const selectSquadLatestActivityDelayed = (state: RootState, squadId: Optional<d.SquadId>) =>
    selectSquadLatestActivity(state, squadId);

export const selectSquadIsUnread = createAppSelector(
    [
        selectors.squadReadTimes,
        (_state: RootState, squadId: Optional<d.SquadId>) => squadId,
        selectSquadLatestActivityDelayed,
    ],
    (squadReadTimes, squadId, squadLatestActivity) => {
        if (!squadId || !squadLatestActivity) return false;

        const readTime = squadReadTimes[squadId];
        return readTime === undefined || squadLatestActivity > readTime;
    },
);

export const selectSquadIsUnreadAsOf = createAppSelector(
    [
        selectors.squadReadTimes,
        (_state: RootState, squadId: Optional<d.SquadId>) => squadId,
        selectSquadLatestActivityDelayed,
    ],
    (squadReadTimes, squadId, squadLatestActivity) => {
        if (!squadId || !squadLatestActivity) return false;

        const readTime = squadReadTimes[squadId];
        if (readTime === undefined || squadLatestActivity > readTime) {
            return squadLatestActivity;
        }
        else {
            return false;
        }
    },
);

export const reducer = channelsSlice.reducer;

// Persistence.

const stores = {
    map: idbStore<d.ChannelId, cm.PerChannelData>("channel-map-2", 14),
    drafts: idbStore<d.ChannelId, DraftChatMessage>("channel-drafts-2", 14),
    pubSeqNos: idbStore<d.ChannelId, number>("published-sequence-numbers", 1),
    stagedSeqNos: idbStore<d.ChannelId, number>("staged-sequence-numbers", 1),
    maxSeqNos: idbStore<d.ChannelId, number>("maximum-sequence-numbers", 14),
    _squadUnreadStates: idbStore<d.SquadId, boolean>("unread-squad-states", 17, 18),
    squadReadTimes: idbStore<d.SquadId, number>("squad-read-times", 18),
};

const persist = (
    previousState: ChannelsState,
    currentState: ChannelsState,
): PersistenceUpdate[] => {
    if (previousState.updates == currentState.updates) {
        return [];
    }

    return currentState.updates;
};

const hydrate = async (conn: Connection) => {
    const map = await fetchIdbStore(conn, stores.map);
    const drafts = await fetchIdbStore(conn, stores.drafts);
    const pubSeqNos = await fetchIdbStore(conn, stores.pubSeqNos);
    const stagedSeqNos = await fetchIdbStore(conn, stores.stagedSeqNos);
    const maxSeqNos = await fetchIdbStore(conn, stores.maxSeqNos);
    const squadReadTimes = await fetchIdbStore(conn, stores.squadReadTimes);

    return getInitialState({ map, drafts, pubSeqNos, stagedSeqNos, maxSeqNos, squadReadTimes });
};

export const persistor = {
    stores,
    hydrate,
    persist,
};
checkPersistor<ChannelsState>(persistor);
