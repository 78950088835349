import * as devices_pb from "../../gen/proto/devices/devices_pb";
import { SquadService } from "../../gen/proto/squads/squads_connect";
import * as squads_pb from "../../gen/proto/squads/squads_pb";

import deviceService, { observersParser } from "@/api/devices";
import { streamHandler } from "@/api/stream";
import { transport } from "@/api/transport";
import { translateUserDefinition } from "@/api/users";
import {
    fromProtoOrgId,
    fromProtoSquadId,
    fromProtoTimestamp,
    fromProtoUserId,
    pbOrgId,
    pbPersonId,
    pbSquadId,
    pbSquadSet,
    pbTimestamp,
    pbUserId,
    toProtoOrgSet,
    toProtoSquadSet,
} from "@/api/util";
import * as d from "@/domain/domain";
import { UserObservation } from "@/domain/presence";
import { OrgOverview, SquadOverview } from "@/domain/squads";
import { UserDefinition } from "@/domain/users";
import { translateAsyncIterable } from "@/misc/iterable";
import { Diff } from "@/misc/types";
import { separateDiscriminatedUnion } from "@/misc/utils";
import { createPromiseClient } from "@connectrpc/connect";

export const squadsService = createPromiseClient(SquadService, transport);
export default squadsService;

type OrgOverviewOrDeletedA = {
    case: "overview";
    value: OrgOverview;
};
type OrgOverviewOrDeletedB = {
    case: "deleted";
    deletedId: d.OrgId;
};
export type OrgOverviewOrDeleted =
    | OrgOverviewOrDeletedA
    | OrgOverviewOrDeletedB;
export const separateOrgOverviewOrDeleteds = (xs: OrgOverviewOrDeleted[]) =>
    separateDiscriminatedUnion<
        OrgOverviewOrDeletedA,
        OrgOverviewOrDeletedB
    >(x => x.case == "overview", xs);
export const toOrgOverviewOrDeleted = (
    value: OrgOverview,
): OrgOverviewOrDeletedA => ({
    case: "overview",
    value,
});
export const deletedOrgOverviewOrDeleted = (
    deletedId: d.OrgId,
): OrgOverviewOrDeletedB => ({
    case: "deleted",
    deletedId,
});

function orgOverviewParser(res: squads_pb.SubOrgOverviewsResponse): OrgOverviewOrDeleted {
    switch (res.orgOverviewOrDeleted?.case) {
        case "orgOverview": {
            const org = res.orgOverviewOrDeleted.value;
            return toOrgOverviewOrDeleted({
                id: fromProtoOrgId(org.id),
                name: org.name,
                personal: org.isPersonal,
            });
        }
        case "deletedId":
            return deletedOrgOverviewOrDeleted(fromProtoOrgId(res.orgOverviewOrDeleted.value));
        default:
            throw new Error(`unexpected orgOrDeleted case: ${res.orgOverviewOrDeleted?.case}`);
    }
}

type SquadOverviewOrDeletedA = {
    case: "overview";
    value: SquadOverview;
};
type SquadOverviewOrDeletedB = {
    case: "deleted";
    deletedId: d.SquadId;
};
export type SquadOverviewOrDeleted =
    | SquadOverviewOrDeletedA
    | SquadOverviewOrDeletedB;
export const separateSquadOverviewOrDeleteds = (xs: SquadOverviewOrDeleted[]) =>
    separateDiscriminatedUnion<
        SquadOverviewOrDeletedA,
        SquadOverviewOrDeletedB
    >(x => x.case == "overview", xs);
export const toSquadOverviewOrDeleted = (
    value: SquadOverview,
): SquadOverviewOrDeletedA => ({
    case: "overview",
    value,
});
export const deletedSquadOverviewOrDeleted = (
    deletedId: d.SquadId,
): SquadOverviewOrDeletedB => ({
    case: "deleted",
    deletedId,
});

function squadOverviewParser(res: squads_pb.SubSquadOverviewsResponse): SquadOverviewOrDeleted {
    switch (res.squadOverviewOrDeleted?.case) {
        case "squadOverview": {
            const squad = res.squadOverviewOrDeleted.value;
            return toSquadOverviewOrDeleted({
                id: fromProtoSquadId(squad.id),
                name: squad.name,
                userIds: squad.userIds?.ids?.map(fromProtoUserId) || [],
            });
        }
        case "deletedId":
            return deletedSquadOverviewOrDeleted(
                fromProtoSquadId(res.squadOverviewOrDeleted.value),
            );
        default:
            throw new Error(`unexpected squadOrDeleted case: ${res.squadOverviewOrDeleted?.case}`);
    }
}

interface CreateOrgArgs {
    name: string;
    initalPerson: d.PersonId;
}
export async function createOrg(args: CreateOrgArgs): Promise<d.OrgId> {
    const resp = await squadsService.createOrg(
        new squads_pb.CreateOrgRequest({
            name: args.name,
            initialPersonId: pbPersonId(args.initalPerson),
        }),
    );
    return fromProtoOrgId(resp.newOrgId);
}

export interface CreateUserArgs {
    orgId: d.OrgId;
    personId: d.PersonId;
}
export async function createUser(args: CreateUserArgs): Promise<void> {
    await squadsService.createUser(
        new squads_pb.CreateUserRequest({
            orgId: pbOrgId(args.orgId),
            personId: pbPersonId(args.personId),
        }),
    );
}

interface CreateSquadArgs {
    orgId: d.OrgId;
    name: string;
    initalUser: d.UserId;
}
export async function createSquad(args: CreateSquadArgs): Promise<d.SquadId> {
    const resp = await squadsService.createSquad(
        new squads_pb.CreateSquadRequest({
            orgId: pbOrgId(args.orgId),
            name: args.name,
            initialUserId: pbUserId(args.initalUser),
        }),
    );
    return fromProtoSquadId(resp.newSquadId);
}

export interface AddSquadMemberArgs {
    squadId: d.SquadId;
    userId: d.UserId;
}
export async function addSquadMember(args: AddSquadMemberArgs): Promise<void> {
    await squadsService.addSquadMember(
        new squads_pb.AddSquadMemberRequest({
            squadId: pbSquadId(args.squadId),
            userId: pbUserId(args.userId),
        }),
    );
}

export type SetSquadLastReadArgs = {
    squadIds: d.SquadId[];
    userId: d.UserId;
    lastRead: d.Timestamp;
};
export async function setSquadLastRead(args: SetSquadLastReadArgs): Promise<void> {
    await squadsService.setSquadLastRead(
        new squads_pb.SetSquadLastReadRequest({
            squadIds: pbSquadSet(args.squadIds),
            userId: pbUserId(args.userId),
            lastRead: pbTimestamp(args.lastRead),
        }),
    );
}

export type UserSquadLastRead = {
    squadId: d.SquadId;
    userId: d.UserId;
    lastRead: d.Timestamp;
};

function lastReadsParser(res: squads_pb.SubUserSquadLastReadsResponse): UserSquadLastRead {
    return {
        squadId: fromProtoSquadId(res.squadId),
        userId: fromProtoUserId(res.userId),
        lastRead: fromProtoTimestamp(res.lastRead),
    };
}

function translateUserSquadLastReads(userId: d.UserId) {
    return async function* (v: Diff<d.SquadId>) {
        if (v.added?.length) {
            yield new squads_pb.SubUserSquadLastReadsRequest({
                squadIds: toProtoSquadSet(v.added),
                userId: pbUserId(userId),
                addToSub: true,
            });
        }
        if (v.removed?.length) {
            yield new squads_pb.SubUserSquadLastReadsRequest({
                squadIds: toProtoSquadSet(v.removed),
                userId: pbUserId(userId),
                addToSub: false,
            });
        }
    };
}

export async function* subUserSquadLastReads(
    reqStream: AsyncIterableIterator<Diff<d.SquadId>>,
    userId: d.UserId,
    signal: AbortSignal,
) {
    const translation = translateAsyncIterable(reqStream, translateUserSquadLastReads(userId));

    const stream = squadsService.subUserSquadLastReads(translation, { signal });

    yield* streamHandler(stream, lastReadsParser, subUserSquadLastReads.name);
}

async function* translateOrgOverviewDiff(v: Diff<d.OrgId>) {
    if (v.added?.length) {
        yield new squads_pb.SubOrgOverviewsRequest({
            orgIds: toProtoOrgSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new squads_pb.SubOrgOverviewsRequest({
            orgIds: toProtoOrgSet(v.removed),
            addToSub: false,
        });
    }
}

export async function* subOrgOverviews(
    reqStream: AsyncIterableIterator<Diff<d.OrgId>>,
    signal: AbortSignal,
) {
    const logPrefix = `subOrgOverviews`;

    const translation = translateAsyncIterable(reqStream, translateOrgOverviewDiff);

    const stream = squadsService.subOrgOverviews(translation, { signal });

    yield* streamHandler(stream, orgOverviewParser, logPrefix);
}

async function* translateSquadOverviewsDiff(v: Diff<d.SquadId>) {
    if (v.added?.length) {
        yield new squads_pb.SubSquadOverviewsRequest({
            squadIds: toProtoSquadSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new squads_pb.SubSquadOverviewsRequest({
            squadIds: toProtoSquadSet(v.removed),
            addToSub: false,
        });
    }
}

export async function* subSquadOverviews(
    reqStream: AsyncIterableIterator<Diff<d.SquadId>>,
    signal: AbortSignal,
) {
    const logPrefix = "subSquadOverviews";

    const translation = translateAsyncIterable(reqStream, translateSquadOverviewsDiff);

    const stream = squadsService.subSquadOverviews(translation, { signal });

    yield* streamHandler(stream, squadOverviewParser, logPrefix);
}

export async function listUsersByPerson(
    personId: d.PersonId,
): Promise<Array<UserDefinition>> {
    const resp = await squadsService.listUsersByPerson(
        new squads_pb.ListUsersByPersonRequest({
            personId: pbPersonId(personId),
        }),
    );

    return resp.userDefinitions.map(translateUserDefinition) || [];
}

export interface ListPersonOrgsResponse {
    orgIds: Array<d.OrgId>;
}

export async function listPersonOrgs(
    personId: d.PersonId,
): Promise<ListPersonOrgsResponse> {
    const resp = await squadsService.listPersonOrgs(
        new squads_pb.ListPersonOrgsRequest({
            personId: pbPersonId(personId),
        }),
    );

    return {
        orgIds: resp.orgIds?.map(fromProtoOrgId) || [],
    };
}

export interface ListUserSquadsResponse {
    squadIDs: Array<d.SquadId>;
}

async function* translateSubUserSquadListsDiff(v: Diff<d.UserId>) {
    if (v.added?.length) {
        yield new squads_pb.SubUserSquadListsRequest({
            userIds: {
                ids: v.added.map(pbUserId),
            },
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new squads_pb.SubUserSquadListsRequest({
            userIds: { ids: v.removed.map(pbUserId) },
            addToSub: false,
        });
    }
}

export async function* subUserSquadLists(
    reqStream: AsyncIterableIterator<Diff<d.UserId>>,
    signal: AbortSignal,
): AsyncGenerator<d.SquadId[], void, unknown> {
    const logPrefix = `subUserSquadLists`;

    const translation = translateAsyncIterable(reqStream, translateSubUserSquadListsDiff);

    const resp = squadsService.subUserSquadLists(translation, { signal });

    const parse = (res: squads_pb.SubUserSquadListsResponse) =>
        res.squadIds?.map(fromProtoSquadId) || [];

    yield* streamHandler(resp, parse, logPrefix);
}

export type SquadLatestActivity = {
    squadId: d.SquadId;
    latestActivity: d.Timestamp;
};

function squadActivityParser(res: squads_pb.SubSquadLatestActivityResponse): SquadLatestActivity {
    return {
        squadId: fromProtoSquadId(res.squadId),
        latestActivity: fromProtoTimestamp(res.latestActivity),
    };
}

async function* translateSquadLatestActivity(v: Diff<d.SquadId>) {
    if (v.added?.length) {
        yield new squads_pb.SubSquadLatestActivityRequest({
            squadIds: toProtoSquadSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new squads_pb.SubSquadLatestActivityRequest({
            squadIds: toProtoSquadSet(v.removed),
            addToSub: false,
        });
    }
}

export async function* subSquadLatestActivity(
    reqStream: AsyncIterableIterator<Diff<d.SquadId>>,
    signal: AbortSignal,
) {
    const logPrefix = `subSquadLatestActivity`;

    const translation = translateAsyncIterable(reqStream, translateSquadLatestActivity);

    const stream = squadsService.subSquadLatestActivity(translation, { signal });

    yield* streamHandler(stream, squadActivityParser, logPrefix);
}

export type SquadObservations = {
    viewId: d.SquadId;
    observations: Array<UserObservation>;
};

function rmPath(path: string): string {
    const parts = path.split("/");
    return parts[0];
}

async function* translateSquadObserversDiff(v: Diff<d.SquadId>) {
    if (v.added?.length) {
        yield new devices_pb.SubObserversV2Request({
            viewUrns: v.added.map(sqid => `${sqid as string}/bondPreviews`),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new devices_pb.SubObserversV2Request({
            viewUrns: v.removed.map(sqid => `${sqid as string}/bondPreviews`),
            addToSub: false,
        });
    }
}

export async function* subSquadObservers(
    reqStream: AsyncIterableIterator<Diff<d.SquadId>>,
    signal: AbortSignal,
): AsyncGenerator<SquadObservations, void, unknown> {
    const logPrefix = `subSquadObservers`;

    const translation = translateAsyncIterable(reqStream, translateSquadObserversDiff);

    const resp = deviceService.subObserversV2(translation, { signal });

    const urnParser = (urn: string) => d.parseSquadUrn(rmPath(urn));

    yield* streamHandler(resp, observersParser(urnParser), logPrefix);
}

export async function* subFollowedUnreadBondsCount(
    userId: d.UserId,
    signal: AbortSignal,
): AsyncGenerator<number, void, unknown> {
    const req = new squads_pb.SubFollowedUnreadCountRequest({
        userId: pbUserId(userId),
    });

    const resp = squadsService.subFollowedUnreadCount(req, { signal });

    yield* streamHandler(
        resp,
        res => res.followedUnreadCount,
        subFollowedUnreadBondsCount.name,
    );
}
