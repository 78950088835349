import { useEffect, useMemo } from "react";

import useTimerTarget from "@/hooks/useTimerTarget";
import { describeTimeAgo } from "@/misc/timeAgo";

export interface TimeAgoProps {
    from: number;
    live: boolean;
    describeCallback?: () => void;
    precise?: boolean;
    to?: number;
}

export default function TimeAgo(props: TimeAgoProps): React.JSX.Element {
    const { from, live, describeCallback, to, precise = false } = props;

    // `now` is suitably memoized, and changes to it mean there really is
    // work to do to recalculate the description.
    const { now, setDelay } = useTimerTarget();

    const described = useMemo(() => {
        describeCallback?.();
        return describeTimeAgo(now, from, precise, to);
    }, [describeCallback, now, from, precise, to]);

    useEffect(() => {
        if (live) {
            setDelay(described.next);
        }
    }, [live, described, setDelay]);

    return (
        <>
            {described.desc}
        </>
    );
}
