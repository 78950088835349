import type { Properties } from "posthog-js";
import posthog from "posthog-js";
import { useEffect, useMemo } from "react";

import { isClearingAuth } from "@/features/auth";
import { selectCurrentUser } from "@/features/users";
import { useAppSelector } from "@/store/redux";

export default function PostHogMeta(): React.JSX.Element {
    const { id, email, name } = useAppSelector(selectCurrentUser) ?? {};
    const clearingAuth = useAppSelector(isClearingAuth);

    // We pass the user info through a memo so we only call posthog.identify() when
    // something important (to us) changes about the user. posthog.identify() schedules
    // a feature-flag update / ends up making a REST call so we want to minimise this
    // happening.
    const phUser = useMemo(() => {
        if (!id) return null;

        return [id, { email, name }] as [string, Properties];
    }, [id, email, name]);

    useEffect(() => {
        if (phUser) {
            posthog.identify(...phUser);
        }
    }, [phUser]);

    useEffect(() => {
        if (clearingAuth) {
            posthog.reset();
        }
    }, [clearingAuth]);

    return <></>;
}
