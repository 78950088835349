import { useMemo } from "react";

import * as d from "@/domain/domain";
import { selectCurrentUserId } from "@/features/auth";
import { selectBondById } from "@/features/bonds";
import {
    selectKnownSquadNamesSortedByAudienceSize,
    selectUserIdsBySquadIds,
} from "@/features/squads";
import { selectUsers } from "@/features/users";
import { useInterestedSquads, useInterestedUsers } from "@/hooks/interest/useInterest";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { Optional } from "@/misc/types";
import { useAppSelector } from "@/store/redux";

export interface UsePrivacyDomainStringProps {
    bondId: Optional<d.BondId>;
}

export const nameLimitCount = 3;

function usePrivacyDomainString(props: UsePrivacyDomainStringProps): string {
    const { bondId } = props;

    const bo = useSelectorArgs(selectBondById, bondId);
    useInterestedUsers(bo?.followers);
    useInterestedSquads(bo?.squadIds);
    const squadIds = bo?.squadIds || [];
    const squadNames = useSelectorArgs(selectKnownSquadNamesSortedByAudienceSize, squadIds);
    const currentUserId = useAppSelector(selectCurrentUserId);
    const userIdsInSquads = useSelectorArgs(selectUserIdsBySquadIds, squadIds);

    const additionalUserIds = useMemo(() => {
        const allFollowers = bo?.followers || [];
        const inSquadsSet = new Set(userIdsInSquads);
        return allFollowers.filter(uid => !inSquadsSet.has(uid) && uid !== currentUserId);
    }, [bo, userIdsInSquads, currentUserId]);

    const users = useSelectorArgs(selectUsers, additionalUserIds);

    const privacyDomainString = useMemo(() => {
        const allNames = [...squadNames, ...(users.map(u => u.nickname).sort())];
        const limitedNames = allNames.slice(0, nameLimitCount);
        const numLimited = allNames.length - limitedNames.length;
        const listFormat = new Intl.ListFormat("en-GB", { style: "narrow" });
        const formattedNames = listFormat.format(limitedNames);
        const numberOfHiddenNames = (numLimited > 0) ? ` + ${numLimited}` : ``;
        return formattedNames + numberOfHiddenNames;
    }, [squadNames, users]);

    return privacyDomainString;
}

export default usePrivacyDomainString;
