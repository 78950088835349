// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=js+ts"
// @generated from file identity/identity.proto (package identity, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetPersonalInfosRequest, GetPersonalInfosResponse, GetPersonByEmailRequest, GetPersonByEmailResponse, SubPersonalInfosRequest, SubPersonalInfosResponse } from "./identity_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service identity.IdentityService
 */
export const IdentityService = {
  typeName: "identity.IdentityService",
  methods: {
    /**
     * GetPersonalInfos returns the personal details of all users requested.
     *
     * @generated from rpc identity.IdentityService.GetPersonalInfos
     */
    getPersonalInfos: {
      name: "GetPersonalInfos",
      I: GetPersonalInfosRequest,
      O: GetPersonalInfosResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetPersonByEmail returns the user ID of the user with the given email.
     *
     * @generated from rpc identity.IdentityService.GetPersonByEmail
     */
    getPersonByEmail: {
      name: "GetPersonByEmail",
      I: GetPersonByEmailRequest,
      O: GetPersonByEmailResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SubPersonalInfos streams the personal details of all users requested.
     *
     * @generated from rpc identity.IdentityService.SubPersonalInfos
     */
    subPersonalInfos: {
      name: "SubPersonalInfos",
      I: SubPersonalInfosRequest,
      O: SubPersonalInfosResponse,
      kind: MethodKind.BiDiStreaming,
    },
  }
};

