import * as d from "@/domain/domain";
import { DeltaKnowledgeBond } from "@/domain/intel";
import {
    AnyOfficialMessage,
    AnyUnsentLocalMessage,
    isCallEndedMessage,
    isOfficialChatMessage,
    UnsentChatMessage,
} from "@/domain/messages";
import log from "@/misc/log";

export interface PerChannelData {
    // List of messages ids (official or unsent), ordered by "correct latest message
    // in correct order for displaying".
    // Always use UnsentMessageIds until we get the full message from the server.
    // "Correct latest message" means we will keep the most-recent-message for any
    // given message, i.e. this list takes into account edits and deletions.
    curatedList: d.AnyMessageId[];
    // For efficient checking of "has this user sent a message into this bond/
    // mentioned me", we need to create an index here. It is cleared as the
    // published sequence number increases.
    unreadMessagesIndex: Record<number, AnyOfficialMessage>;
    // The sequence number of the most recent message we have received into the store.
    localSequenceNumber?: number;
    // Index of messages ids into `curatedList` for efficient updates.
    index: Record<d.AnyMessageId, number>;
    // List of messages waiting to be sent.
    // The logic that sends the messages should only over look at unsent[0] until
    // we are sure that the server has received that message.
    // Should only ever be small, so ignore the O(n) search complexity.
    // (We could always add an index for lookups if we needed to.)
    unsent: d.UnsentMessageId[];
    // Map of messages ids confirmed sent, but that we haven't received via
    // a subscription or a fetch.
    // This is an efficient way to correctly switch over the indexing key from
    // our `localId` to the official UUID.
    pending: Record<d.MessageId, d.UnsentMessageId>;
    // The delta knowledge we want to display inside the bond chat view
    deltaKnowledgeBond?: DeltaKnowledgeBond;
}
export type ChannelMap = Record<d.ChannelId, PerChannelData>;

function getChan(m: ChannelMap, c: d.ChannelId): PerChannelData {
    if (!m[c]) {
        m[c] = { curatedList: [], unreadMessagesIndex: {}, index: {}, unsent: [], pending: {} };
    }
    return m[c];
}

export function addChannelToMap(m: ChannelMap, id: d.ChannelId): ChannelMap {
    getChan(m, id);
    return m;
}

// Record an intent to send a message.
// TODO: apply edit if we lose the race and the message is already sent?
// This case is actually quite complex to handle, I think.
export function addUnsentToChannel(
    m: ChannelMap,
    channelId: d.ChannelId,
    msg: AnyUnsentLocalMessage,
): ChannelMap {
    const { curatedList, index, unsent } = getChan(m, channelId);

    if (unsent.includes(msg.localId)) {
        log.info(`Tried to send message ${msg.localId} twice`);
    }
    else {
        // This is the "send a new message" case.
        unsent.push(msg.localId);
        const i = curatedList.push(msg.localId) - 1;
        index[msg.localId] = i;
    }

    return m;
}

export function addDeltaKnowledgeBondToChannel(
    m: ChannelMap,
    knowledge: DeltaKnowledgeBond,
): ChannelMap {
    const chan = getChan(m, knowledge.channelId);
    chan.deltaKnowledgeBond = knowledge;
    return m;
}

// Remove an intent to send a message. Only has an effect if we haven't managed
// to act on the intent and push the message to the server yet.
export function removeUnsentFromChannel(
    m: ChannelMap,
    channelId: d.ChannelId,
    msg: UnsentChatMessage,
): ChannelMap {
    const pcd = getChan(m, channelId);

    const { curatedList, index, unsent } = pcd;

    const i = unsent.indexOf(msg.localId);
    if (i === -1) {
        return m;
    }

    for (let j = i + 1; j < unsent.length; j++) {
        const msg = unsent[j];
        index[msg]--;
    }

    unsent.splice(i, 1);

    // Only delete from `curated` if we haven't managed to send to the server.
    // TODO check case of disconnect before receiving confirmation.
    const t = index[msg.localId];
    curatedList.splice(t, 1);
    delete index[msg.localId];

    // TODO: check pending if we've managed to send and want to delete server-side

    return m;
}

// Marks a message as confirmed sent to the server.
// This moves it out of `unsent` and into `pending`.
// Requires msg.messageId to be set.
export function messageSent(
    m: ChannelMap,
    channelId: d.ChannelId,
    msg: UnsentChatMessage,
): ChannelMap {
    if (!msg.messageId) {
        log.error(`Message ${msg.localId} "sent" but with no confirmed id`);
        return m;
    }

    const { unsent, pending } = getChan(m, channelId);

    if (unsent.length > 0 && unsent[0] === msg.localId) {
        unsent.splice(0, 1);
    }
    else {
        // TODO: assert
        // We should always be respecting the order of the unsent list here.
        let i = 1;
        for (; i < unsent.length; i++) {
            if (unsent[i] === msg.localId) {
                break;
            }
        }
        if (i === unsent.length) {
            log.error(`Sent ${msg.localId}, but not not found in unsent list ${unsent}`);
        }
        else {
            log.error(
                `Sent ${msg.localId}, but not first in unsent list (position ${i}) ${unsent}`,
            );
            unsent.splice(i, 1);
        }
    }

    pending[msg.messageId] = msg.localId;

    return m;
}

// TODO order the messages sensibly
// For now, we always get every message when we subscribe.
// TODO update edited/deleted versions
// TODO non-contiguous message ranges
const cmpSeqNo = (a: AnyOfficialMessage, b: AnyOfficialMessage) =>
    a.sequenceNumber - b.sequenceNumber;

export function addToChannel(
    m: ChannelMap,
    c: d.ChannelId,
    msgs: AnyOfficialMessage[],
): ChannelMap {
    const chan = getChan(m, c);
    const {
        curatedList,
        unreadMessagesIndex,
        index,
        unsent,
        pending,
        localSequenceNumber,
    } = chan;

    const sorted = msgs.sort(cmpSeqNo);

    for (const msg of sorted) {
        // Can't check index[msg.id] as it might be zero, which is false-y
        if (Object.prototype.hasOwnProperty.call(index, msg.id)) {
            continue;
        }

        const isChatMsg = isOfficialChatMessage(msg);
        const isCallEndMsg = isCallEndedMessage(msg);
        if (isChatMsg || isCallEndMsg) {
            // Don't worry about if the seqNo is smaller than our staged seqNo.
            // The selector will handle that, and it will be removed on the next
            // update to published seqNos.
            unreadMessagesIndex[msg.sequenceNumber] = msg;
        }

        const pendingId = pending[msg.id];
        let curatedIndex: number;

        // Messages should not ever be in both `unsent` and `pending`.
        // Hence the `else if` is correct.
        if (isChatMsg && unsent.length > 0 && msg.content.id === unsent[0]) {
            // Message sent, but did not receive confirmation before disconnecting.
            curatedIndex = index[msg.content.id];
            delete index[msg.content.id];

            unsent.splice(0, 1);
        }
        else if (pendingId) {
            // Normal flow: got confirmation of receipt, message then appears
            // through a subscription.
            curatedIndex = index[pendingId];
            delete index[pendingId];
        }
        else {
            curatedIndex = curatedList.length;
        }

        curatedList[curatedIndex] = msg.id;
        index[msg.id] = curatedIndex;
    }

    if (sorted.length > 0) {
        const seq = sorted[sorted.length - 1].sequenceNumber;
        chan.localSequenceNumber = Math.max(seq, localSequenceNumber ?? -1);
    }

    return m;
}

export function publishedSeqNoUpdated(
    cm: ChannelMap,
    channelId: d.ChannelId,
    seqNo: number,
): ChannelMap {
    const { unreadMessagesIndex } = getChan(cm, channelId);

    Object.keys(unreadMessagesIndex)
        .map(n => parseInt(n, 10)) // hooray Javascript!
        .filter(n => n <= seqNo)
        .forEach(n => delete unreadMessagesIndex[n]);

    return cm;
}
