import { BlockBlobClient, newPipeline } from "@azure/storage-blob";
import { nanoid } from "@reduxjs/toolkit";
import * as faceapi from "@vladmandic/face-api";
import modelsUrl from "@vladmandic/face-api/model/ssd_mobilenetv1_model-weights_manifest.json?url";
import { useCallback, useContext, useRef, useState } from "react";

import { AvatarCropRectangle } from "@/api/users";
import Avatar from "@/components/gui/Avatar";
import { BlockBlobClientFactory, FileStashContext } from "@/components/managers/AttachmentManager";
import * as d from "@/domain/domain.ts";
import { UploadableAvatarImage } from "@/domain/users";
import { selectCurrentOrgId, selectCurrentUserId } from "@/features/auth";
import {
    clearUploadableAvatar,
    completeAvatarUploadThunk,
    getAvatarUploadCredentialsThunk,
    selectUploadableAvatar,
    selectUser,
} from "@/features/users";
import { useSelfInterest } from "@/hooks/interest/useInterest";
import { useConnectedEffect } from "@/hooks/useConnectedEffect";
import useSelectorArgs from "@/hooks/useSelectorArgs.ts";
import { fileNamePreparer } from "@/misc/attachments";
import log from "@/misc/log";
import { useAppDispatch, useAppSelector } from "@/store/redux";

const blockBlobClientGenerator = (url: string) => {
    return new BlockBlobClient(url, newPipeline());
};

async function detectFaceAndUpload(
    faceApiLoaded: React.MutableRefObject<boolean>,
    cropRectangleRef: React.MutableRefObject<AvatarCropRectangle>,
    fileStash: Map<string, File>,
    file: File,
    dispatch: ReturnType<typeof useAppDispatch>,
    orgId: d.OrgId,
    userId: d.UserId,
) {
    if (!faceApiLoaded.current) {
        await faceapi.loadSsdMobilenetv1Model(modelsUrl);
        faceApiLoaded.current = true;
    }

    const img = document.createElement("img");
    img.src = URL.createObjectURL(file);
    try {
        await new Promise(resolve => {
            img.onload = resolve;
        });

        const detection = await faceapi
            .detectSingleFace(img, new faceapi.SsdMobilenetv1Options());
        if (detection && detection.box) {
            cropRectangleRef.current = makeFaceRectangle(detection);
        }
        else {
            cropRectangleRef.current = { x: 0, y: 0, width: 1, height: 1 };
        }

        const localId = nanoid();
        fileStash.set(localId, file);

        try {
            const receivedCredentials = dispatch(getAvatarUploadCredentialsThunk({
                uploadableAvatarId: localId,
                properties: {
                    fileName: file.name,
                    fileSize: file.size,
                    mimeType: file.type,
                    dimensions: {
                        width: img.width,
                        height: img.height,
                    },
                },
                ownership: {
                    orgId: orgId,
                    uploaderId: userId,
                },
            }));

            await receivedCredentials;
            log.info("Got avatar image upload credentials");
        }
        catch (e) {
            log.error("Problem when getting attachment upload credentials", e);
        }
    }
    finally {
        URL.revokeObjectURL(img.src);
    }
}

function makeFaceRectangle(detection: faceapi.FaceDetection) {
    const faceRectangle = {
        x: detection.box.x,
        y: detection.box.y,
        width: detection.box.width,
        height: detection.box.height,
    };

    // margin values are based on face dimensions returned from face detection
    const xMargin = 0.6;
    const yMargin = 0.35;
    faceRectangle.x -= faceRectangle.width * xMargin;
    faceRectangle.y -= faceRectangle.height * yMargin;
    faceRectangle.width += 2 * xMargin * faceRectangle.width;
    faceRectangle.height += 2 * yMargin * faceRectangle.height;

    if (faceRectangle.x < 0) {
        faceRectangle.x = 0;
    }
    if (faceRectangle.y < 0) {
        faceRectangle.y = 0;
    }
    if (faceRectangle.x + faceRectangle.width > detection.imageWidth) {
        faceRectangle.width = detection.imageWidth - faceRectangle.x;
    }
    if (faceRectangle.y + faceRectangle.height > detection.imageHeight) {
        faceRectangle.height = detection.imageHeight - faceRectangle.y;
    }

    // make sure it's square
    faceRectangle.width = Math.min(faceRectangle.width, faceRectangle.height);
    faceRectangle.height = faceRectangle.width;

    return {
        x: faceRectangle.x / detection.imageWidth,
        y: faceRectangle.y / detection.imageHeight,
        width: faceRectangle.width / detection.imageWidth,
        height: faceRectangle.height / detection.imageHeight,
    };
}

export default function AvatarUploadControls(
    { clientFactory }: { clientFactory?: BlockBlobClientFactory; },
): React.JSX.Element {
    const dispatch = useAppDispatch();

    const blobClientFactory = clientFactory || blockBlobClientGenerator;
    const fileStash = useContext(FileStashContext);

    const userId = useAppSelector(selectCurrentUserId);
    const user = useSelectorArgs(selectUser, userId);

    // We should consider which org should really own a user's avatar image
    const orgId = useAppSelector(selectCurrentOrgId);

    const cropRectangleRef = useRef({ x: 0, y: 0, width: 0, height: 0 });
    const faceApiLoaded = useRef(false);

    const [isStartingAvatarUpload, setIsStartingAvatarUpload] = useState(false);
    const [oldBlobId, setOldBlobId] = useState<string | undefined>(undefined);

    useSelfInterest();

    const startAvatarUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        log.info("Starting avatar image upload");

        if (!e.target.files || e.target.files.length != 1) {
            e.target.value = "";
            return;
        }

        if (!userId || !orgId) {
            log.error("Starting avatar upload: UserId or OrgId missing");
            return;
        }

        const file = e.target.files.item(0);
        if (!file) {
            log.warn("File missing: skipping");
            return;
        }

        setIsStartingAvatarUpload(true);

        detectFaceAndUpload(
            faceApiLoaded,
            cropRectangleRef,
            fileStash,
            file,
            dispatch,
            orgId,
            userId,
        )
            .catch(
                e => log.error("error in detectFaceAndUpload", e),
            )
            .finally(
                () => setIsStartingAvatarUpload(false),
            );
    }, [dispatch, orgId, userId, fileStash]);

    const uploadableAvatar = useAppSelector(selectUploadableAvatar);
    const uploadableExists = !!uploadableAvatar;
    const canUploadAvatar = !isStartingAvatarUpload && !uploadableExists;
    const isAvatarBlobUpdating = user?.picture?.blobId && user?.picture?.blobId === oldBlobId;

    const finishUploadableAvatar = useCallback(() => {
        // This may become more complex in the future, with different states to
        // represent the upload completing, or failing at different stages.
        dispatch(clearUploadableAvatar());
    }, [dispatch]);

    const completeAvatarImageUpload = useCallback((uai: UploadableAvatarImage) => () => {
        setOldBlobId(user?.picture?.blobId);
        const attachmentCompleted = dispatch(completeAvatarUploadThunk({
            uploadableAvatarId: uai.localId,
            blobId: uai.credentials!.blobId,
            completerId: uai.uploaderId,
            cropRectangle: cropRectangleRef.current,
        }));
        const successHandler = () => {
            log.info("Avatar image upload completed");
        };
        const failureHandler = (e: any) => {
            // No retry mechanism for now
            setOldBlobId(undefined);
            log.error("Completing avatar image upload failed", e);
        };

        attachmentCompleted
            .then(
                successHandler,
                failureHandler,
            ).finally(
                () => {
                    fileStash.delete(uai.localId);
                    finishUploadableAvatar();
                },
            );
    }, [dispatch, fileStash, finishUploadableAvatar, cropRectangleRef, user]);

    useConnectedEffect(() => {
        if (!uploadableAvatar) {
            return;
        }

        const file = fileStash.get(uploadableAvatar.localId);
        if (!file) {
            log.error("File missing from stash");
            finishUploadableAvatar();
            return;
        }

        // Copied straight from AttachmentManager.tsx, should consider DRY
        // TODO: maybe AttachmentManager is really BlobUploadManager?
        const blockBlobClient = blobClientFactory(
            uploadableAvatar.credentials.url,
        );
        const dataUpload = blockBlobClient.uploadData(file, {
            maxSingleShotSize: 4 * 1024 * 1024,
            blobHTTPHeaders: {
                blobContentType: file.type,
                blobCacheControl: "max-age=31536000, immutable",
                blobContentDisposition: `attachment;filename="${fileNamePreparer(file.name)}"`,
            },
        });

        const uploadAvatarFailureHandler = (e: any) => {
            log.error("Failed to upload avatar image file", e);
            finishUploadableAvatar();
        };

        dataUpload
            .then(
                completeAvatarImageUpload(uploadableAvatar),
                uploadAvatarFailureHandler,
            );
    }, [
        uploadableAvatar,
        blobClientFactory,
        completeAvatarImageUpload,
        fileStash,
        finishUploadableAvatar,
    ]);

    if (!userId) {
        return <></>;
    }

    return (
        <>
            <div className="c-dialog-photo-upload">
                {canUploadAvatar && !isAvatarBlobUpdating
                    ? (
                        <Avatar
                            userId={userId}
                            showPresence={false}
                            size="upload"
                        />
                    ) : <div className="c-spinner"></div>}
            </div>
            <div className="c-form-element c-form-element--profile">
                {canUploadAvatar
                    ? (
                        <input
                            type="file"
                            id="avatarImageUpload"
                            multiple={false}
                            onChange={startAvatarUpload}
                            className="c-fileupload c-fileupload--profile"
                            title="Upload profile photo"
                            accept="image/jpeg, image/png"
                        />
                    )
                    : (`Processing and uploading image...`)}
            </div>
        </>
    );
}
