import { createPromiseClient } from "@connectrpc/connect";

import { DeviceService } from "../../gen/proto/devices/devices_connect";
import * as devices_pb from "../../gen/proto/devices/devices_pb";

import { transport } from "@/api/transport";
import { fromProtoTimestamp, fromProtoUserId } from "@/api/util";
import { UserObservation } from "@/domain/presence";

export const deviceService = createPromiseClient(DeviceService, transport);
export default deviceService;

interface Observations<T> {
    viewId: T;
    observations: Array<UserObservation>;
}

export function observersParser<T>(
    parseUrn: (_: string) => T,
): (res: devices_pb.SubObserversResponse) => Observations<T> {
    return (res: devices_pb.SubObserversResponse) => {
        if (!res.viewUrn) {
            throw ("viewURN is false-y, no sensible action");
        }
        return {
            viewId: parseUrn(res.viewUrn),
            observations: res.observers?.map((observation): UserObservation => {
                return {
                    userId: fromProtoUserId(observation.userId),
                    current: observation.current,
                    startedAt: observation.startedAt && fromProtoTimestamp(observation.startedAt),
                    endedAt: observation.endedAt && fromProtoTimestamp(observation.endedAt),
                };
            }) || [],
        };
    };
}
