import * as d from "@/domain/domain";
import { fetchCurrentPersonUserRefs } from "@/features/auth";
import {
    invitedBondStreamingManager,
    startBondCatchupSummariesStream,
    startBondContemporariesStream,
    startBondInterestStream,
    startSubBondObserversStream,
    streamArchivedBondsList,
    subBondsListThunk,
    subFollowedUnreadBondsCountThunk,
    visibleBondObservationManager,
} from "@/features/bonds";
import { startCallInterestStream } from "@/features/calls";
import { visibleChannelMessageStreamingManager } from "@/features/chats";
import { sustainBrowserFocusReport, sustainTypingActivityReport } from "@/features/connection";
import {
    selectActiveForNotifications,
    selectAnyTabActive,
    selectDisplayingFollowedUnreadCount,
    selectTypingInterest,
} from "@/features/interest";
import {
    startConnectedEffectListener,
    startConnectedListener,
    startConnectedStatefulListener,
} from "@/features/middleware";
import { streamNotifications } from "@/features/notifications";
import {
    messageFetcher,
    messageReadUpdater,
    messageSender,
    squadBondListObservationManager,
    startChannelMsgSeqNoStream,
} from "@/features/shared";
import {
    fetchCurrentPersonMemberships,
    startOrgInterestStream,
    startSquadInterestStream,
    startSquadObserversInterestStream,
    startSubSquadLatestActivityStream,
    startSubUserSquadLastReadsStream,
    startSubUserSquadListsStream,
} from "@/features/squads";
import { startUserInterestStream } from "@/features/users";
import { startConnectionRttWatcher } from "@/misc/connectionRttWatcher";
import { type AppAppendListener, appAppendListener } from "@/store/middleware";

export const startManagingAllInterest = (
    appendListener: AppAppendListener = appAppendListener,
) => [
    // Start streaming bonds based on interest (and heralds)
    startBondInterestStream(appendListener),
    // Start streams which augment streamed bonds
    startSubBondObserversStream(appendListener),
    startBondCatchupSummariesStream(appendListener),
    startBondContemporariesStream(appendListener),
    startChannelMsgSeqNoStream(appendListener),
    // Start streams for other interest types
    startCallInterestStream(appendListener),
    startOrgInterestStream(appendListener),
    startSquadInterestStream(appendListener),
    startSquadObserversInterestStream(appendListener),
    startSubUserSquadListsStream(appendListener),
    startSubSquadLatestActivityStream(appendListener),
    startSubUserSquadLastReadsStream(appendListener),
    startUserInterestStream(appendListener),

    startConnectedStatefulListener(appendListener)(...visibleChannelMessageStreamingManager),
    startConnectedStatefulListener(appendListener)(...visibleBondObservationManager),
    startConnectedStatefulListener(appendListener)(...invitedBondStreamingManager),
    startConnectedStatefulListener(appendListener)(...squadBondListObservationManager),
];

export const startConnectedListeners = (
    appendListener: AppAppendListener = appAppendListener,
    userId?: d.UserId,
) => [
    ...userId ? [
        startConnectedListener(fetchCurrentPersonMemberships, { appendListener }),
        startConnectedListener(fetchCurrentPersonUserRefs, { appendListener }),

        // Perpetually stream all (unarchived) bond heralds, and the list of archived bond IDs
        startConnectedListener(subBondsListThunk, { appendListener }),
        startConnectedListener(streamArchivedBondsList, { appendListener }),

        ...startManagingAllInterest(appendListener),

        // TODO: tests for these
        startConnectedListener(streamNotifications, {
            appendListener,
            argGen: selectActiveForNotifications,
            abortOldThunk: true,
        }),
        startConnectedListener(sustainBrowserFocusReport, {
            appendListener,
            extraSelectors: [selectAnyTabActive],
        }),
        startConnectedListener(sustainTypingActivityReport, {
            appendListener,
            extraSelectors: [selectTypingInterest],
        }),
        startConnectedListener(subFollowedUnreadBondsCountThunk, {
            appendListener,
            extraSelectors: [selectDisplayingFollowedUnreadCount],
        }),

        startConnectedEffectListener(startConnectionRttWatcher, appendListener),

        startConnectedStatefulListener(appendListener)(...messageFetcher),
        startConnectedStatefulListener(appendListener)(...messageReadUpdater),
        startConnectedStatefulListener(appendListener)(...messageSender),
    ] : [],
];
