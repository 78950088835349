import { useCallback } from "react";

import { useNavigateBack } from "@/hooks/useNavigateBack";

export interface LinkButtonBack {
    where: "back";
}

export type LinkButtonProps<T> = {
    to: string | LinkButtonBack;
    state?: T;
    onClick?: () => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

// Like a react-router <Link/>, but renders a <button> rather than an <a>.
// In general, for accessibility reasons, one should prefer an <a> for links,
// but given we're an SPA and it's not real navigation, it can make sense to
// use buttons in some cases.
//
// See https://stackoverflow.com/questions/42463263/wrapping-a-react-router-link-in-an-html-button
// for why we don't nest a <Link> inside a <button> or vice versa.
export const LinkButton = <T,>(props: LinkButtonProps<T>) => {
    const { to, state, onClick } = props;
    const { navigate, navigateBack } = useNavigateBack();
    const onButtonClick = useCallback(() => {
        if (typeof to === "string") {
            navigate(to, { state });
        }
        else if (to.where === "back") {
            navigateBack();
        }
        onClick?.();
    }, [onClick, to, state, navigate, navigateBack]);

    return (
        <button {...props} onClick={onButtonClick}>
            {props.children}
        </button>
    );
};

export default LinkButton;
