import { useEffect, useRef } from "react";

export interface UseOutsideClickOptions {
    active?: boolean;
    stopOutsideClicks?: boolean;
}

export default function useOutsideClick<ElementType extends HTMLElement>(
    callback: (target: EventTarget | null) => void,
    opts?: UseOutsideClickOptions,
) {
    const ref = useRef<ElementType>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent | TouchEvent) => {
            if (
                (opts?.active === undefined || opts.active) &&
                ref.current &&
                !ref.current.contains(event.target as Node)
            ) {
                callback(event.target);

                if (opts?.stopOutsideClicks) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            }
        };

        const eventListenerOpts: AddEventListenerOptions = {
            capture: opts?.stopOutsideClicks,
        };

        document.addEventListener("click", handleClickOutside, eventListenerOpts);
        document.addEventListener("touchend", handleClickOutside, eventListenerOpts);

        return () => {
            document.removeEventListener("click", handleClickOutside, eventListenerOpts);
            document.removeEventListener("touchend", handleClickOutside, eventListenerOpts);
        };
    }, [callback, opts]);

    return [ref] as [
        ref: React.RefObject<ElementType>,
    ];
}
