import {
    bondHasSquadId,
    bondIsFollowed,
    bondIsNotArchived,
    bondIsNotFollowed,
    bondIsUnread,
} from "@/domain/bonds";
import * as d from "@/domain/domain";
import { getDominantPresenceMode, getPresenceIcon } from "@/domain/presence";
import { userOverviewToDominantPresence } from "@/domain/users";
import {
    selectArchivedBondsSet,
    selectBondLiveParticipantIds,
    selectBondObserverIds,
    selectBonds,
} from "@/features/bonds";
import { selectAllStagedSequenceNumbers } from "@/features/channels";
import { createSelectorPair, memoizeOptions } from "@/features/selectors";
import { selectUser } from "@/features/users";
import { Optional } from "@/misc/types";
import { createAppSelector, selectCurrentUserId } from "@/store/redux";
import { mobileSquadsTabReadTimesKey, selectReadTimes } from "./squads";

// selectUserDominantPresence selects the dominant presence mode of a user.
const selectUserDominantPresence = createAppSelector(
    [selectUser],
    userOverviewToDominantPresence,
    memoizeOptions.weakMap,
);

const selectUnsortedBondParticipants = createAppSelector(
    [selectBondLiveParticipantIds, selectBondObserverIds],
    (live, obs) => (live ?? []).concat(obs),
    memoizeOptions.weakMapShallow,
);

const selectPresenceIconFromBonds = createAppSelector(
    [state => state, (_state, bondIds: d.BondId[]) => bondIds],
    (state, bondIds) =>
        getPresenceIcon(
            getDominantPresenceMode(
                bondIds
                    .flatMap(id => selectUnsortedBondParticipants(state, id))
                    .map(userId => selectUserDominantPresence(state, userId)),
            ),
        ),
    memoizeOptions.weakMap,
);

// selectNotFollowedSquadTopNBonds selects the top N bonds in the specified squad that are
// not followed.
//
// The default limit is 10.
const selectNotFollowedSquadTopNBonds = createAppSelector(
    [
        selectBonds,
        selectCurrentUserId,
        (_state, squadId: Optional<d.SquadId>) => squadId,
        (_state, _squadId: Optional<d.SquadId>, limit: number = 10) => limit,
    ],
    (bonds, currentUserId, squadId, limit) => {
        if (!squadId) {
            return [];
        }

        const predicates = [
            bondHasSquadId(squadId),
            bondIsNotFollowed(currentUserId),
        ];
        return bonds
            .filter(bo => predicates.every(p => p(bo)))
            .slice(0, limit)
            .map(bo => bo.id);
    },
    memoizeOptions.weakMapShallow,
);

// selectSquadDominantPresenceIcon selects the icon representing the dominant
// presence mode of any user in the top N bonds of the specified squad.
//
// The default limit is 10.
const selectSquadDominantPresenceIcon = createAppSelector(
    [
        state => state,
        selectNotFollowedSquadTopNBonds,
    ],
    selectPresenceIconFromBonds,
    memoizeOptions.weakMap,
);

// selectSquadDominantPresenceIconPair selects the icon representing the dominant
// presence mode of any user in the top N bonds of the specified squad while registering
// interest in all bonds considered.
//
// The default limit is 10.
export const selectSquadDominantPresenceIconPair = createSelectorPair(
    selectNotFollowedSquadTopNBonds,
    selectSquadDominantPresenceIcon,
);

// selectInboxTopNBonds selects the top N bonds in the inbox.
//
// The default limit is 10.
const selectInboxTopNBonds = createAppSelector(
    [
        selectBonds,
        selectCurrentUserId,
        selectArchivedBondsSet,
        (_state, limit: number = 10) => limit,
    ],
    (bonds, currentUserId, archivedSet, limit) => {
        const predicates = [
            bondIsFollowed(currentUserId),
            bondIsNotArchived(archivedSet),
        ];

        return bonds
            .filter(bo => predicates.every(p => p(bo)))
            .slice(0, limit)
            .map(bo => bo.id);
    },
    memoizeOptions.weakMapShallow,
);

// selectInboxDominantPresenceIcon selects the icon representing the dominant
// presence mode of any user in the top N bonds in the inbox.
//
// The default limit is 10.
const selectInboxDominantPresenceIcon = createAppSelector(
    [
        state => state,
        selectInboxTopNBonds,
    ],
    selectPresenceIconFromBonds,
    memoizeOptions.weakMap,
);

// selectInboxDominantPresenceIconPair selects the icon representing the dominant
// presence mode of any user in the top N bonds in the inbox while registering
// interest in all bonds considered.
//
// The default limit is 10.
export const selectInboxDominantPresenceIconPair = createSelectorPair(
    selectInboxTopNBonds,
    selectInboxDominantPresenceIcon,
);

// selectNotFollowedTopNBonds selects the top N bonds in all squads combined.
//
// The default limit is 50.
const selectNotFollowedTopNBonds = createAppSelector(
    [
        selectBonds,
        selectCurrentUserId,
        (_state, limit: number = 50) => limit,
    ],
    (bonds, currentUserId, limit) =>
        bonds
            .filter(bondIsNotFollowed(currentUserId))
            .map(bo => bo.id)
            .slice(0, limit),
    memoizeOptions.weakMapShallow,
);

// selectAllSquadsDominantPresenceIcon selects the icon representing the dominant
// presence mode of any user in the top N bonds in all squads combined.
//
// The default limit is 50.
const selectAllSquadsDominantPresenceIcon = createAppSelector(
    [
        state => state,
        selectNotFollowedTopNBonds,
    ],
    selectPresenceIconFromBonds,
    memoizeOptions.weakMap,
);

// selectAllSquadsDominantPresenceIconPair selects the icon representing the dominant
// presence mode of any user in the top N bonds in all squads combined while registering
// interest in all bonds considered.
//
// The default limit is 10.
export const selectAllSquadsDominantPresenceIconPair = createSelectorPair(
    selectNotFollowedTopNBonds,
    selectAllSquadsDominantPresenceIcon,
);

// selectMobileSquadsTabHighlighted selects whether there are any unread bonds that are not in the
// inbox that have had activity since the last time the mobile squads tab was marked as read.
export const selectMobileSquadsTabHighlighted = createAppSelector(
    [
        selectBonds,
        selectCurrentUserId,
        selectReadTimes,
        selectAllStagedSequenceNumbers,
    ],
    (bonds, currentUserId, readTimes, stagedSequenceNumberMap) => {
        const predicates = [
            bondIsNotFollowed(currentUserId),
            bondIsUnread(stagedSequenceNumberMap),
        ];

        return bonds
            .filter(bo => predicates.every(p => p(bo)))
            .some(bo => bo.lastActivityAt > (readTimes[mobileSquadsTabReadTimesKey] ?? 0));
    },
    memoizeOptions.weakMap,
);

// selectLatestUnfollowedActivityTimestamp selects the timestamp of the latest activity for
// any bond that is not in the inbox.
export const selectLatestUnfollowedActivityTimestamp = createAppSelector(
    [
        selectBonds,
        selectCurrentUserId,
    ],
    (bonds, currentUserId) =>
        bonds
            .filter(bondIsNotFollowed(currentUserId))
            .map(bo => bo.lastActivityAt)
            .reduce((previous, current) => Math.max(previous, current), 0),
    memoizeOptions.weakMap,
);

// selectInboxUnreadBondsExist selects whether there are any unread bonds in the inbox.
export const selectInboxUnreadBondsExist = createAppSelector(
    [
        selectBonds,
        selectCurrentUserId,
        selectArchivedBondsSet,
        selectAllStagedSequenceNumbers,
    ],
    (bonds, currentUserId, archivedSet, stagedSequenceNumberMap) => {
        const predicates = [
            bondIsFollowed(currentUserId),
            bondIsNotArchived(archivedSet),
            bondIsUnread(stagedSequenceNumberMap),
        ];

        return bonds.some(bo => predicates.every(p => p(bo)));
    },
    memoizeOptions.weakMapShallow,
);
