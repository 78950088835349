import { DateTime, Interval } from "luxon";

import { BondCatchupDivider } from "@/components/messages/BondCatchupDivider";
import { BondNewDivider, BondTimeDivider } from "@/components/messages/BondDivider";
import { CallMessageView } from "@/components/messages/CallMessageView";
import { ChatMessageView } from "@/components/messages/ChatMessageView";
import DebugMessageView from "@/components/messages/DebugMessageView";
import * as d from "@/domain/domain";
import { AnyMessageId } from "@/domain/domain";
import {
    AnyOfficialMessage,
    AnyUnsentLocalMessage,
    getMsgChannelId,
    getMsgSequenceNumber,
    getMsgTs,
    isCallEndedMessage,
    isOfficialChatMessage,
    isOfficialMessage,
    OfficialMessageType,
} from "@/domain/messages";
import { selectCurrentUserId } from "@/features/auth";
import { selectCallById } from "@/features/calls";
import { selectIsRead } from "@/features/channels";
import { selectMessage } from "@/features/chats";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppSelector } from "@/store/redux";
import { Ref } from "react";

export interface MessageViewProps {
    id: AnyMessageId;
    currentCallId?: d.CallId;
    previousId?: AnyMessageId;
    isLastMessage?: boolean;
    savedPublishedSequenceNumber?: number;
    extraStyle?: React.CSSProperties;
    messageContentRef?: Ref<HTMLDivElement>;
}

// straddleDay decides whether the there is more than one calendar day in the
// interval [previousMsgTs, msgTs).
//
// For example, if msgTs and previousMsgTs reflected 23:59 and the following
// 00:01 respectively, then this would return true.
//
// For more information, see the function documentation for the count function
// on Luxon's Interval class.
function straddleDay(msgTs: Date, previousMsgTs: Date): boolean {
    const dT = DateTime.fromMillis(msgTs.getTime());
    const previousDT = DateTime.fromMillis(previousMsgTs.getTime());

    const interval = Interval.fromDateTimes(previousDT, dT);
    const dayCount = interval.count("days");

    return dayCount >= 2;
}

// TODO render emoji reactions
export default function MessageView(props: MessageViewProps): React.JSX.Element {
    const {
        savedPublishedSequenceNumber,
        currentCallId,
        id,
        previousId,
        isLastMessage,
        messageContentRef,
    } = props;

    const currentUserId = useAppSelector(selectCurrentUserId);

    const msg = useSelectorArgs(selectMessage, id);
    const previousMsg = useSelectorArgs(selectMessage, previousId);
    const msgSequenceNumber = getMsgSequenceNumber(msg);
    const channelId = getMsgChannelId(msg);

    const isFirstUnreadMessage = ((savedPublishedSequenceNumber ?? 0) + 1) === msgSequenceNumber;
    const wasSentByUs = isOfficialChatMessage(msg) && msg.senderId === currentUserId;
    const lastMessageWasCallEnded = isLastMessage && isCallEndedMessage(msg);
    const showNewDivider = isFirstUnreadMessage && !wasSentByUs && !lastMessageWasCallEnded;

    const liveCall = useSelectorArgs(selectCallById, currentCallId);
    const callIsLive = liveCall !== undefined && !liveCall.endedAt;
    // participantsByContributions is a list of call participants with
    // transcript events in the call. If this list is empty, then the call does
    // not have any transcript events
    const callHasTranscriptEvents = liveCall !== undefined &&
        liveCall.participantsByContribution.length > 0;
    const displayCatchupForLiveCall = callIsLive && callHasTranscriptEvents;

    const isRead = useSelectorArgs(selectIsRead, channelId);
    const showCatchupDivider = showNewDivider && !isMobileBrowser() &&
        (!isRead || displayCatchupForLiveCall);

    const msgTs = getMsgTs(msg);
    const previousMsgTs = getMsgTs(previousMsg);
    const dateDivider = !!msgTs && !!previousMsgTs && !isFirstUnreadMessage &&
        straddleDay(msgTs, previousMsgTs);

    // Note we force a minimum height of 1px because virtual rendering requires all elements
    // to have a height. The one awkward edge case there is the call end message.

    return (
        <div id={id} style={props.extraStyle}>
            {dateDivider && <BondTimeDivider />}
            {showNewDivider && !showCatchupDivider && <BondNewDivider />}
            {showCatchupDivider && (
                <BondCatchupDivider
                    channelId={channelId}
                    savedPublishedSequenceNumber={savedPublishedSequenceNumber}
                />
            )}
            <MessageViewInternal
                currentCallId={currentCallId}
                msg={msg}
                previousId={previousId}
                messageContentRef={messageContentRef}
            />
        </div>
    );
}

interface MessageViewInternalProps {
    currentCallId?: d.CallId;
    msg?: AnyOfficialMessage | AnyUnsentLocalMessage;
    previousId?: AnyMessageId;
    messageContentRef?: Ref<HTMLDivElement>;
}

function MessageViewInternal(props: MessageViewInternalProps): React.JSX.Element {
    const { msg, previousId, currentCallId, messageContentRef } = props;

    const showDebug = useBooleanFeatureFlag("debug-message-view");
    if (showDebug) {
        return <DebugMessageView msg={msg} />;
    }

    if (msg && !isOfficialMessage(msg)) {
        return (
            <ChatMessageView
                currentCallId={currentCallId}
                msg={msg}
                previousMsgId={previousId}
                messageContentRef={messageContentRef}
            />
        );
    }

    switch (msg?.type) {
        case OfficialMessageType.Chat: {
            return (
                <ChatMessageView
                    currentCallId={currentCallId}
                    msg={msg}
                    previousMsgId={previousId}
                    messageContentRef={messageContentRef}
                />
            );
        }
        case OfficialMessageType.CallStart: {
            return (
                <CallMessageView
                    callId={msg.callId}
                    messageContentRef={messageContentRef}
                />
            );
        }
        case OfficialMessageType.CallEnd: {
            return <div ref={messageContentRef}></div>;
        }
        default: {
            return <div className="c-message c-message--unknown"></div>;
        }
    }
}
