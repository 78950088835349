import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import LinkButton from "@/components/gui/LinkButton";
import SensitiveText from "@/components/gui/SensitiveText";
import * as d from "@/domain/domain";
import { selectHeraldsInSquad } from "@/features/bonds";
import { selectSquadIsUnreadAsOf } from "@/features/channels";
import { selectSquadDominantPresenceIconPair } from "@/features/presence";
import { setQuery } from "@/features/search";
import { selectSquadById } from "@/features/squads";
import { useInterestedBonds } from "@/hooks/interest/useInterest";
import useAddressParams from "@/hooks/useAddressParams";
import useDebounce from "@/hooks/useDebounce";
import useLocationState from "@/hooks/useLocationState";
import usePresenceIcon from "@/hooks/usePresenceIcon";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { backendRTTDebounceDuration } from "@/misc/backend";
import { newBondLocationStateSelector } from "@/misc/locationState";
import { useAppDispatch } from "@/store/redux";

const isSquadSelected = (
    id: d.SquadId,
    pathname: string,
    pathSquadId: d.SquadId | undefined,
    navigatedFromSquad?: d.SquadId,
) => pathname.startsWith("/squad") && pathSquadId === id
    || pathname == "/bond/new" && navigatedFromSquad === id;

function SquadEntry(props: { id: d.SquadId; }) {
    const { id } = props;
    const squad = useSelectorArgs(selectSquadById, id);
    const { pathname } = useLocation();
    const { squadId: pathSquadId } = useAddressParams();
    const { newBond: { navigatedFromSquad } } = useLocationState(newBondLocationStateSelector);
    const thisSquadSelected = isSquadSelected(id, pathname, pathSquadId, navigatedFromSquad);
    const dispatch = useAppDispatch();

    // Register interest in all bonds in this squad, if selected
    const squadHeralds = useSelectorArgs(selectHeraldsInSquad, id);
    const selectedSquadBondIds = useMemo(() => {
        const selectedSquadHeralds = thisSquadSelected ? (squadHeralds || []) : [];
        return selectedSquadHeralds.map(h => h.bondId);
    }, [thisSquadSelected, squadHeralds]);
    useInterestedBonds(selectedSquadBondIds);

    const presenceIconString = usePresenceIcon(selectSquadDominantPresenceIconPair, id);

    const squadIsUnreadAsOf = useSelectorArgs(selectSquadIsUnreadAsOf, id);
    const squadIsUnread = squadIsUnreadAsOf != false;
    const debouncedSquadIsUnread = useDebounce(
        squadIsUnread,
        backendRTTDebounceDuration,
        undefined,
        (_, newValue) => !newValue,
    );
    const clearSearch = useCallback(() => {
        dispatch(setQuery(""));
    }, [dispatch]);

    if (!squad) {
        return <></>;
    }

    const classes = classNames("c-squad-listing__squad", {
        "is-selected": thisSquadSelected,
        "is-unread": debouncedSquadIsUnread,
    });

    return (
        <LinkButton
            to={`/squad/${d.extractUUID(id)}`}
            className={classes}
            onClick={clearSearch}
        >
            <span className="c-squad-label">
                <SensitiveText>{squad.name}</SensitiveText>
            </span>

            {presenceIconString && (
                <span
                    className="c-icon-animated"
                    style={{
                        backgroundImage: `url("${presenceIconString}")`,
                    }}
                />
            )}
        </LinkButton>
    );
}

export default SquadEntry;
