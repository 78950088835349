import {
    AnswerDeltaOrOther,
    askQuery,
    getBondTitleSuggestion,
    getDeltaSummaryBond,
} from "@/api/intel";
import * as d from "@/domain/domain";
import { QueryId } from "@/domain/intel";
import { selectCurrentUserId } from "@/features/auth";
import { createProxiedAsyncThunk } from "@/features/proxiedThunk";
import { streamThunkHandler, TrimmedThunkAPI, unaryThunkHandler } from "@/features/thunk";
import { Optional, PartialRecord } from "@/misc/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryAnswerStatus } from "../../gen/proto/domain/domain_pb";

interface fetchBondTitleSuggestionArgs {
    bondContentDraft: string;
}

export const fetchBondTitleSuggestionThunk = createProxiedAsyncThunk(
    "intel/fetchBondTitleSuggestion",
    async ({ bondContentDraft }: fetchBondTitleSuggestionArgs, thunkAPI) => {
        const state = thunkAPI.getState();
        const userId = selectCurrentUserId(state);
        if (!userId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to fetch bond title suggestion but did not find current user id`,
            });
        }

        return await unaryThunkHandler(
            thunkAPI,
            getBondTitleSuggestion({
                userId,
                bondContentDraft,
            }),
            "fetchBondTitleSuggestion",
        );
    },
);

interface fetchDeltaKnowledgeBondArgs {
    channelId: d.ChannelId;
    lastReadSequenceNumber: number;
}

export const fetchDeltaKnowledgeBondThunk = createProxiedAsyncThunk(
    "intel/fetchDeltaKnowledgeBond",
    async (
        { channelId, lastReadSequenceNumber }: fetchDeltaKnowledgeBondArgs,
        thunkAPI,
    ) => {
        const state = thunkAPI.getState();
        const userId = selectCurrentUserId(state);
        if (!userId) {
            throw thunkAPI.rejectWithValue({
                error:
                    `Tried to fetch bond catchup knowledge long but did not find current user id`,
            });
        }

        return await unaryThunkHandler(
            thunkAPI,
            getDeltaSummaryBond({
                userId,
                channelId,
                lastReadSequenceNumber,
            }),
            "fetchDeltaKnowledgeBond",
        );
    },
);

interface QueryArgs {
    question: string;
    queryId: QueryId;
}

// write a function that does the streaming
// createstreamingthunk is just boilerplate remval
// this will be function (arg,thunkapi) and will do streaming as implementation

export const queryThunk = createProxiedAsyncThunk(
    "intel/query",
    async (args: QueryArgs, thunkAPI) => {
        const state = thunkAPI.getState();
        const userId = selectCurrentUserId(state)!;
        const logId = `query-${args.queryId}`;

        return await streamThunkHandler(
            thunkAPI,
            askQuery({ userId, question: args.question }),
            queryStreamParser(args, thunkAPI),
            logId,
        );
    },
);

const queryStreamParser =
    (arg: QueryArgs, thunkAPI: TrimmedThunkAPI) => (t: AnswerDeltaOrOther) => {
        const id = arg.queryId;

        switch (t.case) {
            case "answer": {
                thunkAPI.dispatch(pushQueryAnswer({ queryId: id, answer: t.answer }));
                break;
            }
            case "search": {
                thunkAPI.dispatch(setSearchResults({ queryId: id, results: t.results }));
                break;
            }
            case "progress": {
                thunkAPI.dispatch(setProgress({ queryId: id, status: t.status }));
                break;
            }
        }
    };

interface Query {
    queryId: QueryId;
    question: string;
    answer: string;
    searchResults: string;
    status: QueryAnswerStatus;
}

const emptyQuery = (queryId: QueryId): Query => ({
    queryId,
    question: "",
    answer: "",
    searchResults: "",
    status: QueryAnswerStatus.UNSPECIFIED,
});

export interface IntelState {
    currentQueryId: Optional<QueryId>;
    queries: PartialRecord<QueryId, Query>;
}

const initialState: IntelState = {
    currentQueryId: undefined,
    queries: {},
};

const intelSlice = createSlice({
    name: "intel",
    initialState,
    selectors: {
        currentQueryId: state => state.currentQueryId,
        queries: state => state.queries,
        queryById: (state, queryId: QueryId) => state.queries[queryId],
        queryAnswer: (state, queryId: QueryId) => state.queries[queryId]?.answer,
        searchResults: (state, queryId: QueryId) => state.queries[queryId]?.searchResults,
        queryStatus: (state, queryId: QueryId) => state.queries[queryId]?.status,
        queryQuestion: (state, queryId: QueryId) => state.queries[queryId]?.question,
    },
    reducers: {
        updateQueryId(state, action: PayloadAction<QueryId>) {
            state.currentQueryId = action.payload;
        },
        updateQueryQuestion(
            state,
            { payload: { queryId, question } }: PayloadAction<
                { queryId: QueryId; question: string; }
            >,
        ) {
            if (!state.queries[queryId]) {
                state.queries[queryId] = emptyQuery(queryId);
            }
            state.queries[queryId].question = question;
        },
        pushQueryAnswer(
            state,
            { payload: { queryId, answer } }: PayloadAction<{ queryId: QueryId; answer: string; }>,
        ) {
            if (!state.queries[queryId]) {
                state.queries[queryId] = emptyQuery(queryId);
            }
            state.queries[queryId].answer += answer;
        },
        purgeQuery(state, action: PayloadAction<QueryId>) {
            delete state.queries[action.payload];
            state.currentQueryId = undefined;
        },
        setProgress(
            state,
            { payload: { queryId, status } }: PayloadAction<
                { queryId: QueryId; status: QueryAnswerStatus; }
            >,
        ) {
            if (!state.queries[queryId]) {
                state.queries[queryId] = emptyQuery(queryId);
            }
            state.queries[queryId].status = status;
        },
        setSearchResults(
            state,
            { payload: { queryId, results } }: PayloadAction<
                { queryId: QueryId; results: string; }
            >,
        ) {
            if (!state.queries[queryId]) {
                state.queries[queryId] = emptyQuery(queryId);
            }
            state.queries[queryId].searchResults += results;
        },
    },
});

export const {
    currentQueryId: selectCurrentQueryId,
    queryById: selectQueryById,
    queryAnswer: selectQueryAnswer,
    searchResults: selectQuerySearchResults,
    queryStatus: selectQueryStatus,
    queryQuestion: selectQueryQuestion,
} = intelSlice.selectors;

export const {
    updateQueryQuestion,
    updateQueryId,
    pushQueryAnswer,
    purgeQuery,
    setProgress,
    setSearchResults,
} = intelSlice.actions;

export const reducer = intelSlice.reducer;

export const persistor = {
    stores: {},
};
