import classNames from "classnames";
import { useCallback, useLayoutEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import NewBondAudienceInput from "@/components/NewBondAudienceInput";
import NewBondMessageComposer from "@/components/NewBondMessageComposer";
import { bondCreationDraftTarget } from "@/domain/channels";
import { addToBondCreationAudience, clearDraftThunk } from "@/features/bondCreation";
import { MetaInterestCounterKey, selectInterestInKey } from "@/features/interest";
import useLocationState from "@/hooks/useLocationState";
import { useNavigateBack } from "@/hooks/useNavigateBack";
import usePrevious from "@/hooks/usePrevious";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { newBondLocationStateSelector } from "@/misc/locationState";
import { useAppDispatch } from "@/store/redux";

export const NewBondView = (): React.JSX.Element => {
    const dispatch = useAppDispatch();
    const { navigateBack } = useNavigateBack();

    const locationState = useLocationState(newBondLocationStateSelector);
    const prevLocationStateId = usePrevious(locationState.newBond.id);

    // Clear the bond creation draft and prefill the 'To:' field once according to the location state
    useLayoutEffect(() => {
        if (locationState.newBond.id != prevLocationStateId) {
            dispatch(clearDraftThunk(bondCreationDraftTarget))
                .unwrap()
                .then(() =>
                    locationState.newBond.prefillAudienceMembers?.forEach(m =>
                        dispatch(addToBondCreationAudience(m))
                    )
                );
        }
    }, [dispatch, locationState, prevLocationStateId]);

    // TODO: "Are you sure?"
    const backAction = navigateBack;

    // Navigate back when escape is pressed
    const hotkeyBlocked = useSelectorArgs(
        selectInterestInKey,
        MetaInterestCounterKey.BlockHotkey,
    );

    const hotkeyBlockedWrapper = useCallback(() => hotkeyBlocked, [hotkeyBlocked]);
    useHotkeys("esc", backAction, {
        ignoreEventWhen: hotkeyBlockedWrapper,
        enableOnFormTags: ["input", "textarea"],
    });

    // Animation experiment
    const [entering, setEntering] = useState(true);
    useLayoutEffect(() => setEntering(false), []);
    const classes = classNames("c-content c-content--new-bond", {
        "c-content--entering": entering,
    });

    return (
        <div className={classes}>
            <NewBondAudienceInput draftTarget={bondCreationDraftTarget} tabIndex={2} />
            <NewBondMessageComposer backAction={backAction} tabIndex={3} />
        </div>
    );
};
