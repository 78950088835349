import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { finalLoginSteps } from "@/auth/login";
import { hasAuthRequestState, hasOidcState } from "@/auth/stateMachine";
import BadgeCountUpdater from "@/components/BadgeCountUpdater";
import Capacitor from "@/components/Capacitor";
import {
    AuthStatus,
    getLocalPreferredUserOrgId,
    selectAuthStatus,
    startAuthForUser,
    updateAuthStatus,
} from "@/features/auth";
import { useDispatch } from "react-redux";
import { Footer } from "../components/Footer";
import Header from "../components/Header";
import NotificationFeed from "../components/NotificationFeed";
import { SentryReportDialog } from "../components/SentryReportButton";
import { SystemMessage } from "../components/SystemMessage";
import { selectIdbUpgradeRequired, selectShowSidebar } from "../features/meta";
import { useSelfInterest } from "../hooks/interest/useInterest";
import { isNativePlatform } from "../misc/capacitor";
import log from "../misc/log";
import { isMobileBrowser } from "../misc/mobile";
import { mobilePathToViewName } from "../misc/mobilePathToTab";
import { useAppSelector } from "../store/redux";
import { SidebarView } from "./SidebarView";

function IDBUpgrader(): React.JSX.Element {
    // There's probably a better place to put this, but I've put
    // it here for lack of imagination of where that place is.
    const idbUpgradeNeeded = useAppSelector(selectIdbUpgradeRequired);
    useEffect(() => {
        if (idbUpgradeNeeded) {
            log.warn("IndexedDB upgrade required, about to reload page");
            new Promise(r => setTimeout(r, 1000)).then(() => location.reload());
        }
    }, [idbUpgradeNeeded]);

    return <></>;
}

function MainContainer({ children }: { children: React.ReactNode; }) {
    const isMobile = isMobileBrowser();
    const sidebarToggleEnabled = useAppSelector(selectShowSidebar);
    const { pathname } = useLocation();
    const showSidebar = (!isMobile && sidebarToggleEnabled) ||
        (isMobile && mobilePathToViewName(pathname) === "mybonds");

    const wrapperClasses = classNames("l-wrapper", {
        "l-wrapper--desktop": !isMobile,
    });

    return (
        <div className={wrapperClasses}>
            {showSidebar && <SidebarView />}
            <main className="l-main">
                <Header />
                {children}
                <Footer />
            </main>
        </div>
    );
}

export function RootView(): React.JSX.Element {
    useSelfInterest();
    return (
        <>
            {!isNativePlatform && <NotificationFeed />}
            {isNativePlatform && <Capacitor />}
            <IDBUpgrader />
            <SentryReportDialog />
            <BadgeCountUpdater />
            <MainContainer>
                <Outlet />
            </MainContainer>
        </>
    );
}

// Just a hack around strict mode meaning a useEffect() with no dependencies gets called twice
function useOnceCall(cb: () => void, condition = true) {
    const isCalledRef = useRef(false);

    useEffect(() => {
        if (condition && !isCalledRef.current) {
            isCalledRef.current = true;
            cb();
        }
    }, [cb, condition]);
}

export function AuthenticatedRootView(): React.JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authStatus = useAppSelector(selectAuthStatus);
    const [initialised, setInitialised] = useState(false);

    useOnceCall(() => {
        (async () => {
            const uid = getLocalPreferredUserOrgId();

            if (uid && await hasOidcState(uid.userId)) {
                log.info("Starting auth with OIDC state");
                dispatch(startAuthForUser(uid));
            }
            else if (await hasAuthRequestState()) {
                // Compatibility code for logging in with Zitadel.
                // TODO: remove me once we move over to Avos auth backend.
                log.info("Starting auth with auth request state");
                const sp = new URLSearchParams(window.location.search);

                // Remove query params from URL
                window.history.replaceState({}, document.title, window.location.pathname);

                // This will reload the view, so no need to do anything after
                await finalLoginSteps(sp);
            }
            else {
                log.warn(
                    "No auth state to start from for " + JSON.stringify(uid) +
                        ": loading login view",
                );
                navigate("/login");
                return;
            }

            setInitialised(true);
        })().catch(e => {
            log.error(`Failed to initialise auth: ${e}`);
            navigate("/logout");
        });
    });

    useEffect(() => {
        if (!initialised) return;

        switch (authStatus) {
            case AuthStatus.Unauthenticated:
                log.info("User is not authenticated, clearing auth", AuthStatus[authStatus]);
                dispatch(updateAuthStatus(AuthStatus.ClearingAuthentication));
                break;
            case AuthStatus.ClearingAuthentication:
                log.info("User is not authenticated, redirecting to logout");
                navigate("/logout");
                break;
        }
    }, [initialised, authStatus, dispatch, navigate]);

    if (!initialised) {
        return <SystemMessage message="Initialising..." />;
    }

    if (authStatus === AuthStatus.Unauthenticated) {
        return <SystemMessage message="Loading external login UI..." />;
    }

    return <RootView />;
}
