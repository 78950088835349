import { DraftTarget } from "@/domain/channels";
import * as d from "@/domain/domain";
import { SquadOverview } from "@/domain/squads";
import { selectCurrentUserId } from "@/features/auth";
import { selectBondByDraftTarget } from "@/features/bonds";
import { selectDraftSquadMentions, selectDraftUserMentions } from "@/features/channels";
import { memoizeOptions } from "@/features/selectors";
import {
    selectSquadIdsContainingUserIds,
    selectSquads,
    selectUserIdsBySquadIds,
    selectUserIdsKnownToCurrentUser,
} from "@/features/squads";
import { createAppSelector } from "@/store/redux";
import type { RootState } from "@/store/types";

// Compile-time constant that may change in a future release
const oneSquadPerBond = false;

const selectDraftSquadScopeForMention = createAppSelector(
    [
        (state, draftTarget) => selectDraftSquadMentions(state, draftTarget),
        (state, draftTarget) => selectBondByDraftTarget(state, draftTarget),
    ],
    (squadIds, bond) => [
        ...squadIds,
        ...(bond?.squadIds ?? []),
    ],
    memoizeOptions.weakMapShallow,
);

const selectDraftUserScopeForMention = createAppSelector(
    [
        (state, draftTarget) => selectDraftUserMentions(state, draftTarget),
        (state, draftTarget) => selectBondByDraftTarget(state, draftTarget),
    ],
    (userIds, bond) => [
        ...userIds,
        ...(bond?.followers ?? []),
    ],
    memoizeOptions.weakMapShallow,
);

const selectSquadIdsContainingUserScope = (state: RootState, dt: DraftTarget) => {
    const userIds = selectDraftUserScopeForMention(state, dt);
    return selectSquadIdsContainingUserIds(state, userIds);
};

const selectSquadIdsContainingCurrentUser = (state: RootState, _dt: DraftTarget) => {
    const userId = selectCurrentUserId(state);
    if (!userId) return [];
    return selectSquadIdsContainingUserIds(state, [userId]);
};

const selectShouldIgnoreSquadsForMention = (state: RootState, dt: DraftTarget) => {
    const squadIds = selectDraftSquadScopeForMention(state, dt);
    return squadIds.length > 0 && oneSquadPerBond;
};

export const selectValidSquadIdsForMention = createAppSelector(
    [
        selectShouldIgnoreSquadsForMention,
        oneSquadPerBond ? selectSquadIdsContainingUserScope : selectSquadIdsContainingCurrentUser,
    ],
    (ignore, userIds) => ignore ? [] : userIds,
    memoizeOptions.weakMapShallow,
);

export const selectValidSquadsForMention = (state: RootState, dt: DraftTarget): SquadOverview[] => {
    const ids = selectValidSquadIdsForMention(state, dt);
    return selectSquads(state, ids);
};

const selectAllUserIdsForMention = (state: RootState, dt: DraftTarget): d.UserId[] => {
    const squadIds = selectDraftSquadScopeForMention(state, dt);

    if (squadIds.length === 0) return selectUserIdsKnownToCurrentUser(state);

    return selectUserIdsBySquadIds(state, squadIds);
};

export const selectValidUserIdsForMention = createAppSelector(
    [selectAllUserIdsForMention, selectCurrentUserId],
    (ids, currentUserId) => ids.filter(id => id !== currentUserId),
    memoizeOptions.weakMapShallow,
);
