import { useLocation } from "react-router-dom";

import { LocationState, LocationStateSelector } from "@/misc/locationState";

export const useLocationState = <T extends LocationState>(
    selector: LocationStateSelector<T>,
): T => {
    const location = useLocation();
    return selector.guard(location.state) ? location.state : selector.defaultState;
};

export default useLocationState;
