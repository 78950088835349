import * as d from "@/domain/domain";

export enum NotificationPriority {
    Unspecified = "unspecified",
    Low = "low",
    Medium = "medium",
    High = "high",
    TimeSensitive = "timesensitive",
    Urgent = "urgent",
    Critical = "critical",
}

export interface TextWebNotificationDetails {
    text: string;
}

export interface ChatMessageWebNotificationDetails {
    bond: {
        id: d.BondId;
        name: string;
    };
    previewText: string;
    messageId: d.MessageId;
    senderId: d.UserId;
}

export interface CallStartWebNotificationDetails {
    bond: {
        id: d.BondId;
        name: string;
    };
    callId: d.CallId;
    initiator: {
        id: d.UserId;
        name: string;
    };
}

export enum WebNotificationType {
    Text = "text",
    ChatMessage = "chatmessage",
    CallStart = "callstart",
}
export type WebNotificationDetails = {
    case: WebNotificationType.Text;
    value: TextWebNotificationDetails;
} | {
    case: WebNotificationType.ChatMessage;
    value: ChatMessageWebNotificationDetails;
} | {
    case: WebNotificationType.CallStart;
    value: CallStartWebNotificationDetails;
};

export interface WebNotification {
    id: d.NotificationId;
    userId: d.UserId;
    eventTs: d.Timestamp;
    createdTs: d.Timestamp;
    priority: NotificationPriority;
    dismissed: boolean;
    details: WebNotificationDetails;
}
