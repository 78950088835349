import classNames from "classnames";
import { ReactEventHandler, useEffect, useMemo, useRef } from "react";

import * as d from "@/domain/domain";
import { isTrackActive, Track } from "@/domain/rtc";
import { getScreenShareText, getUserNickname } from "@/domain/users";
import { selectUser } from "@/features/users";
import useAudioSinkRef from "@/hooks/media/useAudioSinkRef";
import useMediaElementRef from "@/hooks/media/useMediaElementRef";
import useSelectorArgs from "@/hooks/useSelectorArgs";

export enum ParticipantType {
    Display = "display",
    User = "user",
}

export interface ParticipantTileProps {
    audioTrack?: Track;
    isSelf: boolean;
    participantUserId: d.UserId;
    participantType?: ParticipantType;
    videoTrack?: Track;
    hideName?: boolean;
    videoTitle?: boolean;
    onClick?: () => void;
    handleVideoSize?: ReactEventHandler<HTMLVideoElement>;
}

export default function ParticipantTile(props: ParticipantTileProps): React.JSX.Element {
    const {
        audioTrack,
        isSelf,
        participantUserId,
        participantType,
        videoTrack,
        videoTitle,
        onClick,
        handleVideoSize,
    } = props;

    const participant = useSelectorArgs(selectUser, participantUserId);

    const audioRef = useAudioSinkRef({ track: audioTrack });
    const videoRef = useMediaElementRef<HTMLVideoElement>({ track: videoTrack });
    const resizeRef = useRef<HTMLDivElement>(null);

    const name = getUserNickname(participant) || participantUserId;
    const label = participantType === ParticipantType.Display ? getScreenShareText(name) : name;

    const renderVideo = useMemo(() => isTrackActive(videoTrack), [videoTrack]);
    const videoClassName = classNames("c-human__video", !onClick && "unclickable");
    const video = renderVideo &&
        (
            <video
                ref={videoRef}
                className={videoClassName}
                autoPlay
                muted
                playsInline
                loop
                title={videoTitle ? label : undefined}
                onLoadedMetadata={handleVideoSize}
                onResize={handleVideoSize}
            />
        );

    useEffect(() => {
        if (!renderVideo) {
            return;
        }

        const updateHint = () => {
            const height = resizeRef.current?.offsetHeight;
            const width = resizeRef.current?.offsetWidth;

            if (height === undefined || width === undefined) {
                return;
            }

            videoTrack?.updateRenderHint?.({ height, width });
        };

        if (resizeRef.current) {
            updateHint();
        }

        window.addEventListener("resize", updateHint);

        return () => {
            window.removeEventListener("resize", updateHint);
        };
    }, [videoTrack, renderVideo]);

    return (
        <span onClick={onClick} ref={resizeRef}>
            {video}
            {!isSelf && <audio ref={audioRef} autoPlay />}
            {props.hideName || <figcaption className="c-call-item__caption">{label}</figcaption>}
        </span>
    );
}
