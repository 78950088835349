import { RefCallback, useCallback, useRef } from "react";

import { isTrackActive, Track } from "@/domain/rtc";
import useRefCallback from "@/hooks/useRefCallback";

interface useMediaElementProps {
    track?: Track;
}
export default function useMediaElementRef<T extends HTMLMediaElement>(
    props: useMediaElementProps,
): RefCallback<T> {
    const { track } = props;

    const elementRef = useRef<T | null>(null);

    const assignMediaSource = useCallback((node: T) => {
        elementRef.current = node;
        if (isTrackActive(track)) {
            node.srcObject = track.source ?? null;
        }
        else {
            node.srcObject = null;
        }
    }, [track]);

    const cleanupMediaSource = useCallback(() => {
        if (elementRef.current) {
            elementRef.current.srcObject = null;
        }
    }, []);

    return useRefCallback(assignMediaSource, { onUnmount: cleanupMediaSource });
}
