import classNames from "classnames";
import Graphemer from "graphemer";
import { Opts as LinkifyOpts } from "linkifyjs";
import { Ref, useMemo } from "react";

import { FeatureFlagged } from "@/components/FeatureFlags";
import Avatar from "@/components/gui/Avatar";
import TimeAgo from "@/components/gui/TimeAgo";
import AttachmentView from "@/components/messages/AttachmentView";
import { ChatMessageMarkupView } from "@/components/messages/ChatMessageMarkupView";
import ChatMessageTextView from "@/components/messages/ChatMessageTextView";
import {
    getContent_ContentMentions,
    SanitisedChatContent,
    switchChatContentVersion,
} from "@/domain/chatContent";
import * as d from "@/domain/domain";
import { AnyMessageId, UserId } from "@/domain/domain";
import {
    AnyMessage,
    getAttachmentMsgId,
    getCallId,
    getMsgTs,
    getSenderId,
    haveSameMessageType,
    haveSameSender,
    isOfficialChatMessage,
    isUnsentLocalMessage,
} from "@/domain/messages";
import { selectCurrentUserId } from "@/features/auth";
import { selectMessage } from "@/features/chats";
import { selectUserName } from "@/features/users";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { Optional } from "@/misc/types";
import { useAppSelector } from "@/store/redux";

const linkifyOpts: LinkifyOpts = {
    target: "_blank",
};

// The context in which a message was sent
// e.g. this message was sent during a live call
enum MessageCallContext {
    // Not sent during a call
    None,
    // Sent during a call which has since ended
    EndedCall,
    // Sent during a call which is live
    LiveCall,
}

export const maxJumbomojiLength = 40;

// Get the status of the call the message was sent in, returning None if it was
// not sent in a call.
function getMessageCallStatus(msgCallId?: d.CallId, currentCallId?: d.CallId): MessageCallContext {
    if (!msgCallId) {
        return MessageCallContext.None;
    }
    if (currentCallId === msgCallId) {
        return MessageCallContext.LiveCall;
    }

    return MessageCallContext.EndedCall;
}

const whitespaceRegex = /\s/g;
const emojiRegex = new RegExp(
    "^(?:" +
        "(?:\\p{Extended_Pictographic}|\\p{Emoji_Component})+" +
        "(?:\\p{Emoji_Modifier}|\\p{Emoji_Modifier_Base}|\\p{Emoji_Presentation}|\\u200d)*" +
        ")+$",
    "u",
);

const alphaNumericRegex = /[\p{L}\p{N}]/u;

function isEmojiOnly(str: string): boolean {
    const noWhiteSpace = str.replace(whitespaceRegex, "");
    const splitter = new Graphemer();

    if (splitter.countGraphemes(noWhiteSpace) > maxJumbomojiLength) return false;

    return emojiRegex.test(noWhiteSpace) && !alphaNumericRegex.test(noWhiteSpace);
}

export interface ChatMessageViewProps {
    msg: AnyMessage;
    previousMsgId?: AnyMessageId;
    currentCallId?: d.CallId;
    messageContentRef?: Ref<HTMLDivElement>;
}

export const ChatMessageView = (
    { currentCallId, previousMsgId, msg, messageContentRef }: ChatMessageViewProps,
): React.JSX.Element => {
    const invalidMessage = !msg || (!isOfficialChatMessage(msg) && !isUnsentLocalMessage(msg));

    const currentUserId = useAppSelector(selectCurrentUserId);
    const senderId = getSenderId(msg, currentUserId);

    const senderName = useSelectorArgs(selectUserName, senderId);
    const previousMsg = useSelectorArgs(selectMessage, previousMsgId);

    const ts = useMemo(() => getMsgTs(msg), [msg]);
    const previousTs = useMemo(() => getMsgTs(previousMsg), [previousMsg]);

    const callId = getCallId(msg);
    const callStatus = getMessageCallStatus(callId, currentCallId);

    const messageId = useMemo(() => getAttachmentMsgId(msg), [msg]);

    if (invalidMessage) {
        return <div className="c-message c-message--unknown"></div>;
    }

    const props: ChatMessageViewInternalProps = {
        userId: senderId,
        content: msg.content,
        callStatus,
        attachmentIds: msg.attachmentIds,
        messageId,
        messageContentRef,
    };

    if (
        shouldShowMessageHeader(previousMsg, msg, currentUserId)
        || shouldShowTimestamp(ts, previousTs)
    ) {
        props.name = senderName;
        props.ts = ts;
    }

    return <ChatMessageViewInternal {...props} />;
};

interface ChatMessageViewInternalProps {
    userId?: UserId;
    name?: string;
    ts?: Date;
    content: SanitisedChatContent;
    callStatus: MessageCallContext;
    attachmentIds: d.BlobId[] | d.LocalAttachmentId[];
    messageId: d.AttachmentMessageId;
    messageContentRef?: Ref<HTMLDivElement>;
}

export interface RenderChatMessageViewProps {
    content: SanitisedChatContent;
    inline: boolean;
}

export const RenderChatMessageView = ({ content, inline }: RenderChatMessageViewProps) => {
    const enableRichTextMessageView = useBooleanFeatureFlag("rich-text-message-view");
    const invalidMessage = "Invalid message content";
    const renderedContent = useMemo(
        () =>
            switchChatContentVersion(
                content,
                c1 => (
                    <ChatMessageTextView
                        text={c1.message ?? ""}
                        mentions={c1.mentions ?? []}
                        linkifyOpts={linkifyOpts}
                        inline={inline}
                    />
                ),
                c2 =>
                    enableRichTextMessageView ? (
                        <ChatMessageMarkupView
                            markup={c2.messageMarkup ?? []}
                            linkifyOpts={linkifyOpts}
                            inline={inline}
                        />
                    ) : (
                        <ChatMessageTextView
                            text={c2.message ?? ""}
                            mentions={getContent_ContentMentions(c2) ?? []}
                            linkifyOpts={linkifyOpts}
                            inline={inline}
                        />
                    ),
                () => inline ? <>{invalidMessage}</> : <p>{invalidMessage}</p>,
            ),
        [content, enableRichTextMessageView, inline],
    );
    return renderedContent;
};

const ChatMessageViewInternal = (props: ChatMessageViewInternalProps): React.JSX.Element => {
    const { name, ts, content, attachmentIds, messageContentRef } = props;

    const emojiOnly = useMemo(
        () => isEmojiOnly(content.message ?? ""),
        [content],
    );

    const isEnded = props.callStatus === MessageCallContext.EndedCall;
    const isLive = props.callStatus === MessageCallContext.LiveCall;
    const containerClassNames = classNames("c-message", {
        "c-message--live": isLive,
        "c-message--ended": isEnded,
    });
    const postClassNames = classNames("c-message__post", {
        "c-message__post--live": isLive,
        "c-message__post--ended": isEnded,
        "c-message__post--emoji": emojiOnly,
    });

    return (
        <div className={containerClassNames}>
            {props.userId && (
                <Avatar
                    userId={props.userId}
                    showPresence={false}
                    hidden={!name}
                    size="message"
                />
            )}
            <div className="c-message__content">
                {(name || ts) && (
                    <div className="c-message__meta">
                        {name &&
                            (
                                <span className="c-message__author">
                                    {name}
                                </span>
                            )}
                        {ts && (
                            <span className="c-message__timestamp">
                                <TimeAgo from={ts?.valueOf() || 0} live={true} precise={true} />
                            </span>
                        )}
                    </div>
                )}
                <div ref={messageContentRef} className={postClassNames}>
                    <RenderChatMessageView content={content} inline={false} />
                </div>
                <FeatureFlagged flag="message-attachments-view" match={true}>
                    {attachmentIds.map((id, i) => (
                        <AttachmentView
                            attachmentId={id}
                            key={id}
                            msgId={props.messageId}
                            index={i}
                        />
                    ))}
                </FeatureFlagged>
            </div>
        </div>
    );
};

const shouldShowMessageHeader = (
    previousMsg: Optional<AnyMessage>,
    msg: AnyMessage,
    currentUserId?: d.UserId,
) => {
    // `!haveSameSender` includes the case of 2 call messages.
    return !previousMsg || !haveSameMessageType(previousMsg, msg) ||
        !haveSameSender(previousMsg, msg, currentUserId);
};

const bigEnoughTimeDifference = (a: Date, b: Date) => a.getTime() - b.getTime() >= 5 * 60 * 1000;
const shouldShowTimestamp = (a: Optional<Date>, b: Optional<Date>) =>
    !a || !b || bigEnoughTimeDifference(a, b);
