import { FC } from "react";

import { DraftTarget } from "@/domain/channels";
import { isSquadMention, isUserMention } from "@/domain/mentions";
import { selectDraftMentions } from "@/features/channels";
import { selectUserIdsBySquadIds } from "@/features/squads";
import { selectUsers } from "@/features/users";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import { formatNodeList } from "@/misc/utils";

interface AudienceHintProps {
    draftTarget: DraftTarget;
}

export const AudienceHint: FC<AudienceHintProps> = ({ draftTarget }) => {
    const mentions = useSelectorArgs(selectDraftMentions, draftTarget);

    const squadIds = useShallowEqualsMemo(
        () =>
            mentions.filter(isSquadMention)
                .map(s => s.target)
                .removeDuplicates(),
        [mentions],
    );
    const userMentions = useShallowEqualsMemo(
        () => mentions.filter(isUserMention),
        [mentions],
    );

    const indirectUserIds = useSelectorArgs(selectUserIdsBySquadIds, squadIds);
    const directUserIds = useShallowEqualsMemo(
        () =>
            userMentions
                .map(m => m.target)
                .removeDuplicates(),
        [userMentions],
    );
    const notifiableUserIds = useShallowEqualsMemo(
        () => [...indirectUserIds, ...directUserIds].removeDuplicates(),
        [indirectUserIds, directUserIds],
    );

    const notifiableUsers = useSelectorArgs(selectUsers, notifiableUserIds);
    const sortedNotifiableUsers = useShallowEqualsMemo(
        () => [...notifiableUsers].sort((a, b) => a.nickname.localeCompare(b.nickname)),
        [notifiableUsers],
    );

    const notifiableUserNodes = sortedNotifiableUsers.map(u => (
        <strong key={u.id}>{u.nickname}</strong>
    ));

    if (notifiableUsers.length == 0) return <></>;

    return (
        <div className="c-audience">
            {formatNodeList(notifiableUserNodes, 5)} will be notified
        </div>
    );
};

export default AudienceHint;
