import { SetStateAction, useCallback, useState } from "react";

import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import log from "@/misc/log";
import { setStateReduce } from "@/misc/setStateReduce";
import { Optional } from "@/misc/types";

// useMap maintains an internal Map wrapped in React state.
// It returns a set of callbacks to update the map in a React-ful way (copying
// the Map before updating to ensure a rerender is triggered).
// Because of the properties of the Map, the keys/values/entries of the
// Map will be read in insertion order, despite any updates/deletes to the Map.
// Any operator on the Map should respect this constraint.
//
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
export default function useMap<K, V>(initialEntries?: Iterable<readonly [K, V]>) {
    const [map, setMap] = useState<Map<K, V>>(new Map(initialEntries));

    const get = useCallback((key: K): Optional<V> => {
        return map.get(key);
    }, [map]);

    const set = useCallback((key: K, value: V) => {
        setMap(prev => {
            const copy = new Map(prev);
            copy.set(key, value);
            return copy;
        });
    }, []);

    const remove = useCallback((key: K) => {
        setMap(prev => {
            if (!prev.has(key)) {
                log.warn(`Tried to remove a value for key '${key}' not present in map.`);
                return prev;
            }

            const copy = new Map(prev);
            copy.delete(key);
            return copy;
        });
    }, []);

    const reset = useCallback(() => {
        setMap(() => new Map());
    }, []);

    // Update an already-present value in the Map
    const update = useCallback((key: K, updateAction: SetStateAction<V>) => {
        setMap(prev => {
            if (!prev.has(key)) {
                log.warn(`Tried to update a value for key '${key}' not present in map.`);
                return prev;
            }

            const copy = new Map(prev);
            const toUpdate = copy.get(key)!;
            copy.set(key, setStateReduce(toUpdate, updateAction));
            return copy;
        });
    }, []);

    const entries = useShallowEqualsMemo(() => [...map.entries()], [map]);

    const values = useShallowEqualsMemo(() => [...map.values()], [map]);

    return { entries, get, remove, reset, set, update, values };
}
