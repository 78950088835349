import { useEffect, useState } from "react";

export default function useDebounceWithoutTimeout<T>(
    currentValue: T,
    initialValue?: T,
    shouldForceChange?: (oldValue: T, newValue: T) => boolean,
): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(initialValue ?? currentValue);

    useEffect(() => {
        if (shouldForceChange?.(debouncedValue, currentValue)) {
            setDebouncedValue(currentValue);
        }
    }, [currentValue, shouldForceChange, debouncedValue]);

    return debouncedValue;
}
