// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file notifications/notifications.proto (package notifications, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { BondId, CallId, MessageId, NotifId, NotifPriority, NotifType, PersonId, UserId } from "../domain/domain_pb.js";

/**
 * @generated from message notifications.NotificationOverview
 */
export const NotificationOverview = proto3.makeMessageType(
  "notifications.NotificationOverview",
  () => [
    { no: 1, name: "id", kind: "message", T: NotifId },
    { no: 2, name: "user_id", kind: "message", T: UserId },
    { no: 3, name: "event_ts", kind: "message", T: Timestamp },
    { no: 4, name: "created_ts", kind: "message", T: Timestamp },
    { no: 5, name: "priority", kind: "enum", T: proto3.getEnumType(NotifPriority) },
    { no: 8, name: "type", kind: "enum", T: proto3.getEnumType(NotifType) },
    { no: 6, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "dismissed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "delivered", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message notifications.TextNotificationDetails
 */
export const TextNotificationDetails = proto3.makeMessageType(
  "notifications.TextNotificationDetails",
  () => [
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message notifications.ChatNotificationDetails
 */
export const ChatNotificationDetails = proto3.makeMessageType(
  "notifications.ChatNotificationDetails",
  () => [
    { no: 1, name: "bond_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "preview_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "bond_id", kind: "message", T: BondId },
    { no: 4, name: "chat_message_id", kind: "message", T: MessageId },
    { no: 5, name: "sender_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message notifications.CallStartNotificationDetails
 */
export const CallStartNotificationDetails = proto3.makeMessageType(
  "notifications.CallStartNotificationDetails",
  () => [
    { no: 1, name: "bond_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bond_id", kind: "message", T: BondId },
    { no: 3, name: "call_id", kind: "message", T: CallId },
    { no: 4, name: "initiator_id", kind: "message", T: UserId },
    { no: 5, name: "initiator_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message notifications.WebNotification
 */
export const WebNotification = proto3.makeMessageType(
  "notifications.WebNotification",
  () => [
    { no: 1, name: "id", kind: "message", T: NotifId },
    { no: 2, name: "user_id", kind: "message", T: UserId },
    { no: 3, name: "event_ts", kind: "message", T: Timestamp },
    { no: 4, name: "created_ts", kind: "message", T: Timestamp },
    { no: 5, name: "priority", kind: "enum", T: proto3.getEnumType(NotifPriority) },
    { no: 6, name: "text_details", kind: "message", T: TextNotificationDetails, oneof: "details" },
    { no: 7, name: "chat_details", kind: "message", T: ChatNotificationDetails, oneof: "details" },
    { no: 8, name: "call_start_details", kind: "message", T: CallStartNotificationDetails, oneof: "details" },
  ],
);

/**
 * @generated from message notifications.GetNotificationsRequest
 */
export const GetNotificationsRequest = proto3.makeMessageType(
  "notifications.GetNotificationsRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message notifications.GetNotificationsResponse
 */
export const GetNotificationsResponse = proto3.makeMessageType(
  "notifications.GetNotificationsResponse",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "notifications", kind: "message", T: NotificationOverview, repeated: true },
  ],
);

/**
 * @generated from message notifications.DismissNotificationRequest
 */
export const DismissNotificationRequest = proto3.makeMessageType(
  "notifications.DismissNotificationRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "notif_id", kind: "message", T: NotifId },
  ],
);

/**
 * @generated from message notifications.DismissNotificationResponse
 */
export const DismissNotificationResponse = proto3.makeMessageType(
  "notifications.DismissNotificationResponse",
  [],
);

/**
 * @generated from message notifications.AckNotificationRequest
 */
export const AckNotificationRequest = proto3.makeMessageType(
  "notifications.AckNotificationRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "notif_id", kind: "message", T: NotifId },
  ],
);

/**
 * @generated from message notifications.AckNotificationResponse
 */
export const AckNotificationResponse = proto3.makeMessageType(
  "notifications.AckNotificationResponse",
  [],
);

/**
 * @generated from message notifications.SubActiveNotificationsRequest
 */
export const SubActiveNotificationsRequest = proto3.makeMessageType(
  "notifications.SubActiveNotificationsRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message notifications.SubActiveNotificationsResponse
 */
export const SubActiveNotificationsResponse = proto3.makeMessageType(
  "notifications.SubActiveNotificationsResponse",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "notifications", kind: "message", T: NotificationOverview, repeated: true },
  ],
);

/**
 * @generated from message notifications.SubNotificationChangesRequest
 */
export const SubNotificationChangesRequest = proto3.makeMessageType(
  "notifications.SubNotificationChangesRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message notifications.SubNotificationChangesResponse
 */
export const SubNotificationChangesResponse = proto3.makeMessageType(
  "notifications.SubNotificationChangesResponse",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "notification", kind: "message", T: NotificationOverview, oneof: "notification_or_dismissed" },
    { no: 3, name: "dismissed_id", kind: "message", T: NotifId, oneof: "notification_or_dismissed" },
  ],
);

/**
 * @generated from message notifications.SubWebNotificationChangesRequest
 */
export const SubWebNotificationChangesRequest = proto3.makeMessageType(
  "notifications.SubWebNotificationChangesRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "exclusive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message notifications.SubWebNotificationChangesResponse
 */
export const SubWebNotificationChangesResponse = proto3.makeMessageType(
  "notifications.SubWebNotificationChangesResponse",
  () => [
    { no: 1, name: "notification", kind: "message", T: WebNotification, oneof: "notification_or_dismissed" },
    { no: 2, name: "dismissed_id", kind: "message", T: NotifId, oneof: "notification_or_dismissed" },
  ],
);

/**
 * @generated from message notifications.SendTestNativeNotificationRequest
 */
export const SendTestNativeNotificationRequest = proto3.makeMessageType(
  "notifications.SendTestNativeNotificationRequest",
  () => [
    { no: 1, name: "person_id", kind: "message", T: PersonId },
    { no: 2, name: "notification_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "notification_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "bond_id", kind: "message", T: BondId },
  ],
);

/**
 * @generated from message notifications.SendTestNativeNotificationResponse
 */
export const SendTestNativeNotificationResponse = proto3.makeMessageType(
  "notifications.SendTestNativeNotificationResponse",
  [],
);

