import { useEffect, useRef } from "react";

type CleanupCallback = (duringCleanup: boolean) => void | Promise<void>;

/** @function
 * Fire a callback when a component unmounts.
 *
 * Uses the most-recently-passed `action`, so will work with `useCallback`
 * functions as the provided argument.
 *
 * @param {CleanupCallback} action function to be called. Function signature
 * allows the caller to a) either ignore the passed value, or b) use it to
 * affect the callback's logic. (See `useDelayedAction` for an example.)
 */
export default function useOnComponentUnmount(action: CleanupCallback) {
    // Call the action immediately if the component (and so this hook) unmounts.
    // Achieve this by having a `useEffect` with empty dependencies, that
    // just returns a function that runs the action if we have been triggered.
    // We need to stash a callback into a ref to accomplish this without
    // upsetting the `useEffect` dependencies.
    const cbRef = useRef(action);

    // Keep the ref up-to-date.
    useEffect(() => {
        cbRef.current = action;
    }, [action]);

    // Fire the callback when the component unmounts.
    useEffect(() => {
        return () => {
            cbRef.current?.(true);
        };
    }, []);
}
