import { createSlice, isActionCreator } from "@reduxjs/toolkit";

import { initLocal } from "@/features/meta";
import { tagActionCreator, tagLocalAction } from "@/store/locations";

// TODO: support for session storage (github.com/avos-io/beyond/issues/3433)
// TODO: don't store local slices in the overseer (github.com/avos-io/beyond/issues/3434)
// TODO: handle local tagging of slice-defined thunks and prepared actions as well? (github.com/avos-io/beyond/issues/3435)
/**
 * Create a new slice which is not synced between tabs. This involves:
 *  - Instead of using the initial state from the overseer, the slice is initialised
 *    when the local store is created
 *  - All action creators defined in the slice are automatically tagged as local
 *
 * CAVEAT: Prepared actions and slice-defined thunks are not currently supported for tagging
 */
export const createLocalSlice: typeof createSlice = options => {
    const base = createSlice({
        ...options,
        extraReducers: builder => {
            options.extraReducers?.(builder);

            builder.addCase(initLocal, () => {
                return (typeof options.initialState == "function") ?
                    (options.initialState as () => any)() :
                    options.initialState;
            });
        },
    });
    type K = keyof (typeof base.actions);
    Object.entries(base.actions).forEach(([k, c]) => {
        if (isActionCreator(c)) {
            base.actions[k as K] = tagActionCreator<
                Parameters<(typeof base.actions)[K]>[0],
                string,
                any // I really tried...
            >(c, tagLocalAction);
        }
    });
    return base;
};
