import { retryingFetch } from "@/misc/fetch";
import { AuthRequestState, OidcConfig, TokenResponse, TokenResponseSchema } from "./types";
import { base64url_encode, cryptoRandomBytes, sha256, uint8array_hexstring } from "./util";

function code_verifier(): string {
    // There is an argument that using crypto.subtle.generateKey() gives slightly better guarantees here
    const randomBytes = cryptoRandomBytes(32);
    return base64url_encode(randomBytes);
}

async function code_challenge_s256(code_verifier: string) {
    // code_challenge = BASE64URL-ENCODE(SHA256(ASCII(code_verifier)))
    return base64url_encode(await sha256(Uint8Array.from(code_verifier, x => x.charCodeAt(0))));
}

/*
3.1.1.  Authorization Code Flow Steps
The Authorization Code Flow goes through the following steps.

1. Client prepares an Authentication Request containing the desired request parameters.
2. Client sends the request to the Authorization Server.
3. Authorization Server Authenticates the End-User.
4. Authorization Server obtains End-User Consent/Authorization.
5. Authorization Server sends the End-User back to the Client with an Authorization Code.
6. Client requests a response using the Authorization Code at the Token Endpoint.
7. Client receives a response that contains an ID Token and Access Token in the response body.
8. Client validates the ID token and retrieves the End-User's Subject Identifier.
*/

interface AuthenticationRequest {
    scope: string;
    response_type: "code";
    client_id: string;
    redirect_uri: string;
    state: string;
    response_mode?: string;
    nonce?: string;
    display?: string;
    prompt?: string;
    max_age?: number;
    ui_locales?: string;
    id_token_hint?: string;
    login_hint?: string;
    acr_values?: string;
    code_challenge?: string;
    code_challenge_method?: string;
}

export async function prepareAuthRequest(config: OidcConfig): Promise<[AuthRequestState, URL]> {
    const state: AuthRequestState = {
        // An opaque, un-guessable value that is used for CSRF protection. We check against it when
        // we are redirected back to.
        state: uint8array_hexstring(cryptoRandomBytes(16)),
        code_verifier: code_verifier(),
    };

    const code_challenge = await code_challenge_s256(state.code_verifier);

    const request: AuthenticationRequest = {
        scope: "openid profile email offline_access",
        response_type: "code",
        client_id: config.client_id,
        redirect_uri: config.redirect_uri,
        state: state.state,
        code_challenge: code_challenge,
        code_challenge_method: "S256",
    };

    const url = new URL(config.authorization_endpoint + "?" + urlEncodeObjectToString(request));

    return [state, url];
}

interface TokenRequest {
    grant_type: "authorization_code";
    code: string;
    redirect_uri: string;
    client_id: string;
    code_verifier?: string;
}

interface RefreshTokenRequest {
    grant_type: "refresh_token";
    client_id: string;
    refresh_token: string;
    scope: string;
    // client_secret?: string
}

function urlEncodeObject<IT extends Partial<Record<string, string>>>(o: IT) {
    return new URLSearchParams(
        Object.keys(o).filter(x => o[x]).map(x => [x, o[x]!]),
    );
}

function urlEncodeObjectToString<IT extends object>(o: IT) {
    return urlEncodeObject(o).toString();
}

async function makeAuthRequest(
    logPrefix: string,
    endpoint: string,
    body: string,
    signal?: AbortSignal,
) {
    const headers: [string, string][] = [
        ["Content-Type", "application/x-www-form-urlencoded"],
    ];
    const method = "POST";

    const response = await retryingFetch(
        { logPrefix: logPrefix },
        endpoint,
        { headers, method, body, signal },
    );

    if (!response.ok) {
        throw new Error(`${logPrefix}: Fatal HTTP error ${response.status}`);
    }

    try {
        const jsonResponse = await response.json();
        return TokenResponseSchema.parse(jsonResponse);
    }
    catch (e) {
        throw new Error(`${logPrefix}: Invalid response from server: ${e}`);
    }
}

// 4.1.3.  Access Token Request
export async function requestAccessToken(
    config: OidcConfig,
    state: AuthRequestState,
    code: string,
    signal?: AbortSignal,
): Promise<TokenResponse> {
    const requestBody: TokenRequest = {
        grant_type: "authorization_code",
        code,
        redirect_uri: config.redirect_uri,
        client_id: config.client_id,
        code_verifier: state.code_verifier,
    };
    return makeAuthRequest(
        "request-access-token",
        config.token_endpoint,
        urlEncodeObjectToString(requestBody),
        signal,
    );
}

// 12.1.  Refresh Request
export async function refreshAccessToken(
    config: OidcConfig,
    refreshToken: string,
    signal?: AbortSignal,
): Promise<TokenResponse> {
    const requestBody: RefreshTokenRequest = {
        client_id: config.client_id,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
        scope: config.token_scope,
    };
    return makeAuthRequest(
        "refresh-access-token",
        config.token_endpoint,
        urlEncodeObjectToString(requestBody),
        signal,
    );
}
