import { useCallback, useRef } from "react";

import { DeviceKind, MediaPublishFunc } from "@/domain/mediaDevices";
import { selectCurrentDevice } from "@/features/mediaDevices";
import { useMediaDevice } from "@/hooks/media/useMediaDevice";
import useSelectorArgs from "@/hooks/useSelectorArgs";

interface IVideoPreviewProps {
    className?: string;
}

export default function VideoPreview(props: IVideoPreviewProps): React.JSX.Element {
    const deviceId = useSelectorArgs(selectCurrentDevice, DeviceKind.VideoInput);
    const videoRef = useRef<HTMLVideoElement>(null);

    const onGetVideo: MediaPublishFunc = useCallback(stream => {
        if (!videoRef.current) {
            throw new Error("Unable to find video element.");
        }
        videoRef.current.srcObject = stream;
    }, []);

    useMediaDevice({ enabled: true, kind: DeviceKind.VideoInput, onGetMedia: onGetVideo });

    // TODO add camera toggle button which re-requests permissions on first click

    const videoComponent = deviceId ?
        (
            <video
                ref={videoRef}
                data-testid="preview-video"
                className="c-settings-preview-video"
                playsInline
                autoPlay
            />
        ) :
        <>No device selected</>;
    return (
        <div className={props.className ?? ""}>
            {videoComponent}
        </div>
    );
}
