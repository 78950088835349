import useResizeObserver from "@react-hook/resize-observer";
import { ChangeEventHandler, FC, useCallback, useRef, useState } from "react";

import AutoExpandInput from "@/components/gui/AutoExpandInput";
import {
    selectBondTitleSuggestionWithEmojiSplit,
    selectBondUserSpecifiedTitle,
    updateUserSpecifiedTitle,
} from "@/features/bondCreation";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppDispatch, useAppSelector } from "@/store/redux";

interface NewBondTitleBarProps {
    backAction: () => void;
    tabIndex?: number;
}

export const NewBondTitleBar: FC<NewBondTitleBarProps> = ({ backAction, tabIndex }) => {
    const dispatch = useAppDispatch();

    const showEmoji = useBooleanFeatureFlag("display-bond-emoji");
    const userSpecifiedTitle = useAppSelector(selectBondUserSpecifiedTitle);
    const bondTitleSuggestion = useAppSelector(selectBondTitleSuggestionWithEmojiSplit);

    const barRef = useRef<HTMLDivElement>(null);

    const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(e => {
        const title = e.currentTarget.value;
        dispatch(updateUserSpecifiedTitle(title));
    }, [dispatch]);

    // Limit the max width of the title input to the title bar width less a fixed margin
    const [maxWidth, setMaxWidth] = useState("unset");
    useResizeObserver(
        barRef,
        e => {
            // In a horizontal writing mode, `inlineSize` is equivalent to width
            const width = e.contentBoxSize?.[0].inlineSize;
            if (width == undefined) {
                setMaxWidth("unset");
            }
            setMaxWidth(`${width - 110}px`);
        },
    );

    const titleEmoji = showEmoji && bondTitleSuggestion.emoji ?
        bondTitleSuggestion.emoji + " " : "";
    const placeholder = titleEmoji + bondTitleSuggestion.title || "New bond";
    const isMobile = isMobileBrowser();

    return (
        <>
            {!isMobile ? (
                <button
                    className="c-btn-return c-btn-return--desktop"
                    onClick={backAction}
                    title="Return"
                >
                    Return
                </button>
            ) : (
                <button
                    className="c-btn-close-bond"
                    onClick={backAction}
                    title="Close bond"
                >
                    Close bond
                </button>
            )}
            <div ref={barRef} className="c-new-bond-title-bar">
                <AutoExpandInput
                    style={{ maxWidth }}
                    onChange={onInputChange}
                    placeholder={placeholder}
                    value={userSpecifiedTitle}
                    tabIndex={tabIndex}
                />
            </div>
        </>
    );
};

export default NewBondTitleBar;
