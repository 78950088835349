import { useCallback, useEffect, useRef } from "react";

import { SystemMessage } from "@/components/SystemMessage";
import {
    AuthStatus,
    clearLocalPreferredUserOrgId,
    resetStore,
    updateAuthStatus,
} from "@/features/auth";
import log from "@/misc/log";
import { purgeCache } from "@/persist/persist";
import { useAppDispatch } from "@/store/redux";

const verySafeWrapper = async (t: Promise<unknown> | (() => Promise<unknown>)): Promise<void> => {
    try {
        const p = typeof t === "function" ? t() : t;
        await p;
    }
    catch (e) {
        log.warn(`Promise failed`, e);
    }
};

export default function LogoutView(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const asyncCleanup = useCallback(async () => {
        log.info(`Resetting auth and purging cache`);

        try {
            await Promise.all([
                purgeCache,
                new Promise(r => setTimeout(r, 2000)), // enforce minimum 2s total delay
            ].map(verySafeWrapper));
        }
        catch (err) {
            log.warn(`Auth cleanup failed`, err);
        }
    }, []);

    const cleaningRef = useRef<boolean>(false);

    useEffect(() => {
        if (cleaningRef.current) return;

        cleaningRef.current = true;

        dispatch(updateAuthStatus(AuthStatus.ClearingAuthentication));

        asyncCleanup().finally(() => {
            dispatch(resetStore());

            clearLocalPreferredUserOrgId();

            // TODO: big hack to ensure the page reloads after logout
            // This closes the shared worker and starts a local store
            // so we can redirect to the auth provider to sign in again...
            window.location.href = "/login";
        });
    }, [asyncCleanup, dispatch]);

    return <SystemMessage message="Logging you out" />;
}
