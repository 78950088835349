import classNames from "classnames";
import { Link } from "react-router-dom";

import { SentryReportButton } from "@/components/SentryReportButton";
import { isMobileBrowser } from "@/misc/mobile";

export function SidebarLinks(): React.JSX.Element {
    const isMobile = isMobileBrowser();

    const navClasses = classNames("c-links", {
        "c-links--desktop": !isMobile,
    });

    return (
        <nav className={navClasses}>
            <Link to="/bond/settings" className="c-links__item">
                Settings
            </Link>
            <Link to="/bond/avatar" className="c-links__item">
                Profile
            </Link>
            <SentryReportButton buttonClassNames="c-links__item c-links__item--report" />
            <Link to="/logout" className="c-links__item">Sign out</Link>
        </nav>
    );
}
