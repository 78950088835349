import { DependencyList, useCallback } from "react";

import { AnySelector, SelectorDeps } from "@/features/selectors";
import { useAppSelector } from "@/store/redux";
import type { RootState } from "@/store/types";

/** @function
 * Use a multi-arity selector function with `useSelector`.
 *
 * Example usage:
 * `const bondOverview = useSelectorArgs(selectBondById, bondId);`
 *
 * For whatever reason, there does not appear to be a variant of redux's
 * `useSelector` that takes multi-arity selectors. Instead, you are required
 * to use HOF chicanery to produce a 1-arity function based on the extra
 * arguments you require in the selector.
 *
 * This works _okay_, but produces a lot of boilerplate HOF code. It also
 * causes a lot of unnecessary updates, as the HOF selectors produce "new"
 * functions each time, which will force the `useSelector` to run again,
 * even if the result will be unchanged.
 *
 * A solution to these is to use react's `useCallback` around the selector,
 * avoiding updates where possible, and using the multi-arity selectors
 * directly. This is what `useSelectorArgs` does, with sensible types being
 * inferred from the selector passed as the first argument.
 *
 * @param {Function} f the selector function, `f(state: RootState, ...deps)`, to operate
 * on the store
 * @param deps the further arguments to the selector function `f`
 * @returns {any} the result of calling `useSelector(fn(...deps))`
 */
export default function useSelectorArgs<
    F extends AnySelector<Deps, R>,
    Deps extends DependencyList = SelectorDeps<F>,
    R = ReturnType<F>,
>(
    f: F,
    ...deps: Deps
): R {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cb = useCallback((state: RootState) => f(state, ...deps), deps);

    return useAppSelector(cb);
}
