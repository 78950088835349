import { shallowEqual as rrShallowEqual } from "react-redux";

export default function shallowEqual(a: unknown, b: unknown): boolean {
    if (a instanceof Set) {
        return b instanceof Set && a.shallowEquals(b);
    }
    else if (a instanceof Map) {
        return b instanceof Map && a.shallowEquals(b);
    }

    return rrShallowEqual(a, b);
}
