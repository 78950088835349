import classNames from "classnames";
import { useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import SquadEntry from "@/components/buttons/BondsTopbarSquadEntry";
import LinkButton from "@/components/gui/LinkButton";
import { SquadId } from "@/domain/domain";
import { selectNumberOfFollowedUnreadMentions } from "@/features/filterPanel";
import {
    selectInboxDominantPresenceIconPair,
    selectLatestUnfollowedActivityTimestamp,
    selectMobileSquadsTabHighlighted,
} from "@/features/presence";
import { setQuery } from "@/features/search";
import { markSquadTabRead, selectCurrentSquadIdsOrderedBySquadName } from "@/features/squads";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import useDebouncedFollowedUnreadCount from "@/hooks/useDebouncedFollowedUnreadCount";
import useLocationState from "@/hooks/useLocationState";
import usePresenceIcon from "@/hooks/usePresenceIcon";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { newBondLocationStateSelector } from "@/misc/locationState";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppDispatch, useAppSelector } from "@/store/redux";

const isMyBondsSelected = (pathname: string, navigatedFromSquad?: SquadId) =>
    pathname === "/mobile/tab/mybonds"
    || pathname == "/bond"
    || pathname == "/bond/new" && navigatedFromSquad === undefined;

function MyBondsEntry() {
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();

    const debouncedUnreadCount = useDebouncedFollowedUnreadCount();

    const mentionsCount = useSelectorArgs(selectNumberOfFollowedUnreadMentions);
    const { newBond: { navigatedFromSquad } } = useLocationState(newBondLocationStateSelector);
    const myBondsSelected = isMyBondsSelected(pathname, navigatedFromSquad);
    const selectedClass = myBondsSelected ? "is-selected" : "";

    const presenceIconString = usePresenceIcon(selectInboxDominantPresenceIconPair);

    const clearSearch = useCallback(() => {
        dispatch(setQuery(""));
    }, [dispatch]);

    return (
        <Link
            to="/bond"
            className={`c-sidebar__my-bonds c-btn-mybonds ${selectedClass}`}
            onClick={clearSearch}
        >
            <div className="c-btn-mybonds__label">
                <div className="c-btn-mybonds__icon" />
                Inbox
            </div>

            <div className="c-sidebar-counts">
                {presenceIconString && (
                    <span
                        className="c-icon-animated"
                        style={{
                            backgroundImage: `url("${presenceIconString}")`,
                        }}
                    />
                )}
                {mentionsCount > 0 && (
                    <div
                        className={`c-mentions-count ${
                            mentionsCount > 99 ? "c-mentions-count--large" : ""
                        }`}
                    >
                        {mentionsCount}
                    </div>
                )}
                {debouncedUnreadCount > 0 && (
                    <div
                        className={`c-bond-count ${
                            debouncedUnreadCount > 99 ? "c-bond-count--large" : ""
                        }`}
                    >
                        {debouncedUnreadCount}
                    </div>
                )}
            </div>
        </Link>
    );
}

function MyBondsDiscover() {
    const count = 0;
    const { pathname } = useLocation();
    const showingDiscover = pathname === "/discover";

    return (
        <LinkButton
            className={`c-btn-discover-main ${showingDiscover ? "is-selected" : ""}`}
            to="/discover"
        >
            <div className="c-btn-discover-main__label">
                <div className="c-btn-discover-main__icon" />
                Discover
            </div>
            {count > 0 && (
                <div className={`c-bond-count ${count > 99 ? "c-bond-count--large" : ""}`}>
                    {count}
                </div>
            )}
        </LinkButton>
    );
}

export function SquadAndMyBondsSelector(
    props: {
        showMyBonds: boolean;
        location?: "isSidebar";
    },
): React.JSX.Element {
    const isMobile = isMobileBrowser();
    const allMySquadIds = useAppSelector(selectCurrentSquadIdsOrderedBySquadName);
    const enableDiscover = useBooleanFeatureFlag("discover-feature");
    const mobileSquadsTabUnread = useSelectorArgs(selectMobileSquadsTabHighlighted);
    const latestUnfollowedActivityTs = useSelectorArgs(selectLatestUnfollowedActivityTimestamp);

    const dispatch = useAppDispatch();

    const classes = classNames("c-squad-listing", {
        "c-squad-listing--sidebar": props.location === "isSidebar",
    });
    const wrapperClasses = classNames({
        "c-content c-content--groups": isMobile && props.location !== "isSidebar",
    });

    // Keep the mobile squads tab marked as read on mobile while this is rendered.
    useEffect(() => {
        if (isMobile && mobileSquadsTabUnread) {
            dispatch(markSquadTabRead(latestUnfollowedActivityTs));
        }
    }, [dispatch, isMobile, mobileSquadsTabUnread, latestUnfollowedActivityTs]);

    const squadList = (
        <div className={classes}>
            {allMySquadIds.map(squadId => <SquadEntry key={squadId} id={squadId} />)}
        </div>
    );

    return (
        <>
            {props.showMyBonds && (
                <>
                    <MyBondsEntry />
                    {enableDiscover && <MyBondsDiscover />}
                </>
            )}
            {isMobile ? (
                <div className={wrapperClasses}>
                    {squadList}
                </div>
            ) : squadList}
        </>
    );
}
