import classNames from "classnames";

import SensitiveText from "@/components/gui/SensitiveText";
import { isSquadMention, isUserMention, Mention } from "@/domain/mentions";
import { selectCurrentUserId } from "@/features/auth";
import { selectCurrentSquadIds, selectSquadById } from "@/features/squads";
import { selectUser } from "@/features/users";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useAppSelector } from "@/store/redux";

interface MentionProps {
    text: string;
    mention: Mention;
}

export const MentionView = (props: MentionProps): React.JSX.Element => {
    const { text, mention } = props;

    const currentUserId = useAppSelector(selectCurrentUserId);
    const currentSquadIds = useAppSelector(selectCurrentSquadIds);

    const userId = isUserMention(mention) ? mention.target : undefined;
    const user = useSelectorArgs(selectUser, userId);

    const squadId = isSquadMention(mention) ? mention.target : undefined;
    const squad = useSelectorArgs(selectSquadById, squadId);

    const isSelfMention = userId && userId == currentUserId ||
        squadId && currentSquadIds.includes(squadId);

    const title = user ? user.name : squad?.name;

    const classes = classNames("cp-mention", {
        "cp-mention--self": isSelfMention,
    });

    return (
        <span className={classes} title={title}>
            <SensitiveText>{text}</SensitiveText>
        </span>
    );
};

export default MentionView;
