import { DependencyList, useMemo, useRef } from "react";

import shallowEqual from "@/misc/shallowEqual";

export function useShallowEquals<T extends object>(thing: T): T {
    const ref = useRef<T>(thing);

    return useMemo(() => {
        if (shallowEqual(ref.current, thing)) {
            return ref.current;
        }

        ref.current = thing;
        return thing;
    }, [thing]);
}

export function useShallowEqualsMemo<T extends object>(
    thingGenerator: () => T,
    deps: DependencyList,
) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const generated = useMemo(thingGenerator, deps);

    return useShallowEquals(generated);
}
