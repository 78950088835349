import { UnknownAction } from "@reduxjs/toolkit";

import * as d from "@/domain/domain";
import { getLocalPreferredUserOrgId } from "@/features/auth";
import { getDeviceTag, initLocal } from "@/features/meta";
import { dispatchThunkResponse } from "@/features/proxiedThunk";
import { isNativePlatform } from "@/misc/capacitor";
import log from "@/misc/log";
import { promiseWithResolvers } from "@/misc/promises";
import { getHydrationData } from "@/persist/persist";
import { tagLocalAction } from "@/store/locations";
import {
    isProxiedResponse,
    listenForProxiedDispatches,
    type ProxiedMessage,
    ProxiedMessageDirection,
} from "@/store/proxy";
import { setupStore } from "@/store/setup";
import type { AppStore } from "@/store/types";
import Overseer from "@/workers/overseer.ts?sharedworker";
import { workerInitRequest, type WorkerInitResponse } from "@/workers/types";
import { isSharedWorker, sharedWorkerAvailable } from "@/workers/util";

export const usingOverseer = () => sharedWorkerAvailable();

const spawnWorker = async (userId: d.UserId, deviceTag: string): Promise<AppStore> => {
    if (isSharedWorker()) {
        throw new Error(`Cannot spawn workers from within a SharedWorker`);
    }

    const worker = new Overseer({ name: `overseer-${userId}` });

    const statePromise = promiseWithResolvers<WorkerInitResponse["payload"]>();

    worker.port.addEventListener(
        "message",
        ({ data: { payload } }: MessageEvent<WorkerInitResponse>) => {
            statePromise.resolve(payload);
        },
        { once: true },
    );

    worker.port.start();

    // Capture all messages broadcast by the overseer.
    // When we receive the full `state`, we will apply any
    // actions that have been broadcast since the serial number
    // provided to us at the same time as the `state`.
    const q: [number, UnknownAction][] = [];

    const onMessage = (msg: ProxiedMessage) => {
        if (!isProxiedResponse(msg)) return;

        const { seqNo, action } = msg;

        q.push([seqNo, action]);
    };

    const stopListening = listenForProxiedDispatches(
        userId,
        ProxiedMessageDirection.Broadcast,
        onMessage,
    );

    // Open our connection to the shared worker.
    worker.port.postMessage(workerInitRequest({ userId, deviceTag }));

    const { state, readFromSeqNo } = await statePromise.promise;

    const store = setupStore(state, { proxy: true, dispatchThunkResponse });

    store.dispatch(tagLocalAction(initLocal()));

    const i = q.findIndex(([seqNo, _]) => seqNo === readFromSeqNo);
    q.slice(i).map(([_, action]) => action).forEach(store.dispatch);
    // This is a Javascript-wut API, but we want to clear out references to
    // actions that we no longer need references to, without redefining `q`.
    q.length = 0;

    stopListening();

    return store;
};

/** Create a store that will *not* proxy to an overseer.
 *
 * This is currently only for the "shared worker unavailable" case.
 */
const fetchLocalStore = async (userId: d.UserId): Promise<AppStore> => {
    const reason = isNativePlatform ? "native platform" : "no shared worker";
    log.info(`Running store locally as ${reason}`);
    const data = await getHydrationData(userId);
    // TODO: implement tab leadership contest, winning tab becomes
    // the "overseer" in spirit, and persists, manages connection, etc.
    return setupStore(data, {
        persist: true,
        manageConnection: true,
        startListeners: true,
        manageAuth: true,
    });
};

/** Get a handle to a working store.
 *
 * If shared workers are available, this means a store that's up-to-date with
 * the overseer, and automatically applying updates broadcast from the overseer.
 *
 * If shared workers aren't available, that means a store hydrated with data
 * from IndexedDB, and that is managing its own connection.
 */
export const startStore = async (): Promise<AppStore> => {
    const ids = getLocalPreferredUserOrgId();
    const deviceTag = getDeviceTag();

    if (!ids?.userId) {
        log.info(`Running store locally as no stored userId`);
        // Don't start listeners - there's nothing for them to do.
        return setupStore({}, { manageConnection: true, manageAuth: true });
    }

    if (!usingOverseer()) return await fetchLocalStore(ids.userId);

    return await spawnWorker(ids.userId, deviceTag);
};
