// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=js+ts"
// @generated from file intel/intel.proto (package intel, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetDeltaSummaryBondRequest, GetDeltaSummaryBondResponse, QueryRequest, QueryResponse, SuggestBondTitleRequest, SuggestBondTitleResponse } from "./intel_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service intel.IntelService
 */
export const IntelService = {
  typeName: "intel.IntelService",
  methods: {
    /**
     * SuggestBondtitle suggests a bond title given a string containing a draf of a message
     *
     * @generated from rpc intel.IntelService.SuggestBondTitle
     */
    suggestBondTitle: {
      name: "SuggestBondTitle",
      I: SuggestBondTitleRequest,
      O: SuggestBondTitleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetDeltaSummaryBond returns a bond summary of the new messages since a users last read state
     *
     * @generated from rpc intel.IntelService.GetDeltaSummaryBond
     */
    getDeltaSummaryBond: {
      name: "GetDeltaSummaryBond",
      I: GetDeltaSummaryBondRequest,
      O: GetDeltaSummaryBondResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Query answers a question from the user with a streamed response
     *
     * @generated from rpc intel.IntelService.Query
     */
    query: {
      name: "Query",
      I: QueryRequest,
      O: QueryResponse,
      kind: MethodKind.ServerStreaming,
    },
  }
};

