import { useEffect } from "react";

import { DeviceGroup } from "@/domain/mediaDevices";
import { fetchAllDevices, resetPermissions } from "@/features/mediaDevices";
import { useAppDispatch } from "@/store/redux";

export default function useEnumeratedDevices(deviceGroup?: DeviceGroup) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const updateDevices = () => {
            // Re-request permissions and list all media devices for device group
            dispatch(fetchAllDevices(deviceGroup));
        };

        // Fetch initial list of devices
        updateDevices();

        // Listen to device changes
        navigator.mediaDevices?.addEventListener(
            "devicechange",
            updateDevices,
        );

        return () => {
            navigator.mediaDevices?.removeEventListener("devicechange", updateDevices);

            // Reset permissions in the store to empty, so the next
            // time we select all devices, we have to first re-request permissions
            dispatch(resetPermissions());
        };
    }, [deviceGroup, dispatch]);
}
